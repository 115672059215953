import * as React from 'react';

import Chunk from 'components/Chunk';

// prettier-ignore
const load = () => import('containers/SurveysNext' /* webpackChunkName: "SurveysNext" */); // eslint-disable-line prettier/prettier

export default class SurveysApplicationsRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
