// @flow
import castArray from 'lodash/castArray';
import type { FeatureFlags } from 'types/featureFlags';

/**
 * Return array of permissions for a group name
 */
export const getPermissionGroup = (permissions: ?Object, group: string): ?(string[]) =>
  permissions ? permissions.resources[group] : [];

/**
 * Return true if the target permission(s) are allowed
 */
export const isAllowed = ({
  acl,
  group,
  target,
  // $FlowFixMe
  checkFor = 'all',
}: {
  acl: ?Object,
  group: ?string,
  target: ?(string | string[]),
  checkFor?: ?string,
}): boolean => {
  // we don need to have a group mandatory
  if (!group) {
    return true;
  }

  const permissions = getPermissionGroup(acl, group);

  if (!permissions) {
    return false;
  }

  // for target we have support for a single string or an array of strings
  if (Array.isArray(target)) {
    // $FlowFixMe
    return target[checkFor === 'all' ? 'every' : 'some']((t) => permissions.includes(t));
  }
  return Boolean(target && permissions.includes(target));
};

/**
 * Return true if the target feature flag is allowed
 *
 * Check if *any* flag(s) are allowed. Allow multiple flags as array.
 */
export const isFeatureFlagAllowed = (target: ?string | string[], featureFlags: ?FeatureFlags) =>
  castArray(target).some((currentTarget) => {
    const featureFlagValue = featureFlags && currentTarget && featureFlags[currentTarget];

    return !currentTarget || featureFlagValue !== false;
  });
