import * as React from 'react';
import { NeonModalDialog, NeonButton } from '@ps-refarch-ux/neon';

import styles from './style.scss';

interface Props {
  handleClose: any;
  handleModalState?: any;
  headed2URL: string;
  largeText?: string;
  boldText: string;
  firstParagraph?: string;
  secondParagraph?: string;
  showFooterButton?: boolean;
  hideFooterButton?: string;
  showSecondaryButton?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonFunction?: any;
  disablePrimaryButton?: boolean;
  linkStatus?: string;
  showGotoHomePageLink?: boolean;
  isInvalidStateCode?: boolean;
}

export default class GoToHeaded2Modal extends React.PureComponent<Props, {}> {
  redirectToHeaded2 = () => {
    location.href = this.props.headed2URL;
  };

  static defaultProps = {
    largeText:
      'Use Headed2 in Naviance to explore options for \n your future and put your career plans into action.',
    boldText: 'Create a Headed2 account to get started.',
    firstParagraph:
      'Already have a Headed2 account? \n Link your Headed2 and Naviance accounts to get started.',
    secondParagraph:
      'You can unlink Naviance and Headed2 anytime from \n the My Account section of About Me.',
    showFooterButton: true,
    primaryButtonText: 'Go to Headed2',
    showSecondaryButton: false,
    disablePrimaryButton: false,
    showGotoHomePageLink: false,
    isInvalidStateCode: false,
  };

  render() {
    const {
      handleClose,
      handleModalState,
      largeText,
      boldText,
      firstParagraph,
      secondParagraph,
      showFooterButton,
      hideFooterButton,
      showSecondaryButton,
      primaryButtonText,
      primaryButtonFunction,
      linkStatus,
      showGotoHomePageLink,
      isInvalidStateCode,
    } = this.props;

    const disablePrimaryButton = isInvalidStateCode || this.props.disablePrimaryButton;

    return (
      <NeonModalDialog
        // To remove completely remove footer with buttons, you may have to use hideFooterButton that sets display to none
        id={hideFooterButton || 'go-to-headed2-modal-dialog'}
        dataAnimate="true"
        data-backdrop="static"
        data-keyboard="false"
        // For temporary messages, use handleModalState to reset state of modal upon close
        neonDialogHasClosed={handleModalState}
        // TODO: the className for dialog-header-title is a temporary solution in order to meet a deadline while the Neon team prepares an official solution
        className={showGotoHomePageLink ? styles.hideModalHeader : 'nocss'}
      >
        <div data-slot="dialog-header-title" onClick={handleModalState} />
        <div data-slot="dialog-body" className={styles.centerAlignedText}>
          <img
            id="headed2-header-logo"
            src={require('./Headed2Logo.svg')}
            className={styles.headed2LogoWidth}
          />
          <section>
            {/* In order to make this component reusable, the paragraphs below represent the maximum
            amount of content. If there's anything you don't need, pass in an empty string into the prop  */}
            <p id="modal-dialog-largeText" className={styles.largeText}>
              {largeText}
            </p>
            <p id="modal-dialog-largeBoldText" className={styles.largeBoldText}>
              {boldText}
            </p>
            <p
              id="modal-dialog-firstsmallText"
              className={
                linkStatus === 'unlinked'
                  ? styles.smallText
                  : styles.largeLinkedStatusText
              }
            >
              {firstParagraph}
            </p>
            <p id="modal-dialog-secondsmallText" className={styles.smallText}>
              {secondParagraph}
            </p>
          </section>
        </div>
        {showFooterButton && (
          <div className={styles.buttons} data-slot="dialog-footer-content">
            <NeonButton
              id="primary-dialog-button"
              dataText={primaryButtonText}
              dataType="primary"
              disabled={disablePrimaryButton}
              onClick={primaryButtonFunction || this.redirectToHeaded2}
            />
            {showSecondaryButton && (
              <NeonButton
                id="secondary-dialog-button"
                dataText={showGotoHomePageLink ? 'Go to Homepage' : 'Cancel'}
                dataType={showGotoHomePageLink ? 'borderless' : 'cancel'}
                onClick={handleClose}
              />
            )}
          </div>
        )}
      </NeonModalDialog>
    );
  }
}
