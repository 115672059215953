import permissions from './permissions';

export default {
  permissions,
};

export const rootReducer = (appReducer) => (state, action) => {
  // cannot import from auth at this moment so action type is hardcoded
  if (action.type === 'fc/auth/logout...') {
    state = undefined;
  }

  return appReducer(state, action);
};
