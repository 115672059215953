// @flow
import constantsGenerator from 'utils/constantsGenerator';
import toResults from 'utils/collectIds';
import toEntities from 'utils/normalizeItems';
import hasOwnProperty from 'utils/hasOwnProperty';
import api from 'config/api';

import {
  SAVE_COLLEGES_IAM_THINKING_SUCCESS,
  DELETE_COLLEGES_IAM_THINKING_SUCCESS,
} from './considering';

const generateConstants = constantsGenerator('fc/colleges/compare');

const [
  GET_COLLEGES_COMPARE,
  GET_COLLEGES_COMPARE_SUCCESS,
  GET_COLLEGES_COMPARE_FAIL,
]: string[] = generateConstants('GET_COLLEGES_COMPARE');

type State = {
  loaded: boolean,
  loading: boolean,
  results: Array<string>,
  entities: Object,
};

const initialState: State = {
  loaded: true,
  loading: false,
  results: [],
  entities: {},
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  const { entities } = state;

  switch (action.type) {
    case GET_COLLEGES_COMPARE:
      return {
        ...initialState,
        loading: true,
      };
    case GET_COLLEGES_COMPARE_SUCCESS:
      return {
        loading: false,
        loaded: true,
        timestamp: Date.now(),
        startYear: action.result.startYear,
        endYear: action.result.endYear,
        studentScores: action.result.studentScores,
        results: toResults(action.result.colleges),
        entities: toEntities(action.result.colleges),
      };
    case GET_COLLEGES_COMPARE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };

    case SAVE_COLLEGES_IAM_THINKING_SUCCESS:
      action.result.forEach(({ college }) => {
        if (college && hasOwnProperty(entities, college.id)) {
          entities[college.id] = {
            ...entities[college.id],
            isConsidering: true,
          };
        }
      });

      return {
        ...state,
        entities: {
          ...entities,
        },
      };
    case DELETE_COLLEGES_IAM_THINKING_SUCCESS:
      action.result.forEach(({ collegeId }) => {
        if (hasOwnProperty(entities, collegeId)) {
          entities[collegeId] = {
            ...entities[collegeId],
            isConsidering: false,
          };
        }
      });

      return {
        ...state,
        entities: {
          ...entities,
        },
      };
    default:
      return state;
  }
}

export const load = (
  collegeIds: ?(number[]) = null,
  { forOverlaps }: { forOverlaps: boolean } = { forOverlaps: false }
) => ({
  types: [GET_COLLEGES_COMPARE, GET_COLLEGES_COMPARE_SUCCESS, GET_COLLEGES_COMPARE_FAIL],
  promise: (client: Object) =>
    client.post(
      `${api.colleges}/compare?forOverlaps=${forOverlaps ? 'true' : 'false'}&limit=${5}&page=${1}`,
      {
        data: {
          collegeIds: collegeIds,
        },
      }
    ),
});
