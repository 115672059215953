// @flow
import isOld from 'utils/isOld';
import mapEntitiesToResults from 'utils/mapEntitiesToResults';

export const shouldUpdateCount = (state: Object): boolean =>
  isOld(state.mail.messages.count.unreadTs);

export const getUnreadCount = (state: Object): number => state.mail.messages.count.unread;

export const areMessagesLoaded = (state: Object): boolean => state.mail.messages.loaded;

export const getMessages = (state: Object) => state.mail.messages.entities;

export const getMessagesArray = (state: Object) =>
  mapEntitiesToResults(state.mail.messages.results, state.mail.messages.entities);

export const getMessagesCount = (state: Object) => state.mail.messages.count;
