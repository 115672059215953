// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import { SAVE_COLLEGES_IAM_THINKING_SUCCESS } from 'modules/colleges/considering';

const generateConstants = constantsGenerator('fc/colleges/match/acceptedStudentsLikeYou');
const [
  GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU,
  GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU_SUCCESS,
  GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU_FAIL,
]: string[] = generateConstants('GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU');

export type Criteria = {
  minSAT: number,
  maxSAT: number,
  minACT: number,
  maxACT: number,
  minGPA: number,
  maxGPA: number,
  matchBasis: 'accepted' | 'applied',
  matchType: 'any' | 'regular' | 'early',
  SAT?: number,
  ACT?: number,
  GPA?: number,
};

type State = {
  colleges: Object[],
  criteria: Criteria,
  loading: boolean,
  loaded: boolean,
};

const initialCriteria = {
  minSAT: 0,
  maxSAT: 0,
  minACT: 0,
  maxACT: 0,
  minGPA: 0,
  maxGPA: 0,
  matchBasis: 'accepted',
  matchType: 'any',
};

const initialState: State = {
  colleges: [],
  criteria: initialCriteria,
  loading: false,
  loaded: false,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU_SUCCESS:
      return {
        ...state,
        ...action.result,
        loading: false,
        loaded: true,
      };
    case GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case SAVE_COLLEGES_IAM_THINKING_SUCCESS: {
      const newFavorites = action.result.map((favorite) => {
        const { college: { id } = { id: '' } } = favorite;
        return id;
      });
      const colleges = state.colleges.map((college) => ({
        ...college,
        favorite: college.favorite || newFavorites.includes(college.id),
      }));
      return {
        ...state,
        colleges,
      };
    }
    default:
      return state;
  }
}

export const fetchCollegesThatAcceptedStudentsLikeYou = (criteria: Criteria = initialCriteria) => ({
  types: [
    GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU,
    GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU_SUCCESS,
    GET_COLLEGES_THAT_ACCEPTED_STUDENTS_LIKE_YOU_FAIL,
  ],
  promise: (client: Object) =>
    client.post(`${api.colleges}/matches/accepted-students-like-you`, {
      data: {
        minSAT: Number(criteria.minSAT),
        maxSAT: Number(criteria.maxSAT),
        minACT: Number(criteria.minACT),
        maxACT: Number(criteria.maxACT),
        minGPA: Number(criteria.minGPA),
        maxGPA: Number(criteria.maxGPA),
        matchBasis: criteria.matchBasis,
        matchType: criteria.matchType,
      },
    }),
});
