// @flow

/**
 * Combine valid strings with a separator.
 * Default separator is white space.
 */
const combineStrings = (strings: (?string | boolean)[], separator: string = ' ') =>
  strings.filter(Boolean).join(separator);

export const combineWithNaturalLanguage = (strings: (?string | boolean)[]) => {
  const lastElement = strings.pop();
  return combineStrings([combineStrings(strings, ', '), lastElement], ' and ');
};

export default combineStrings;
