// @flow
import { createSelector } from 'reselect';
import { MiddleSchoolGrades } from 'constants/courses';
import has from 'lodash/has';
import get from 'lodash/get';

export const ROLES = {
  student: 'STUDENT',
  parent: 'PARENT',
  counselor: 'COUNSELOR',
  schoolUser: 'SCHOOL_USER',
  districUser: 'DISTRICT_USER',
  guest: 'GUEST',
  alumni: 'ALUMNI',
};

export const isLoading = (state: Object): string => state.auth.loading;
export const isLoggingIn = (state: Object): string => state.auth.loggingIn;
export const userLoaded = (state: Object): string => state.auth.userLoaded;
export const getToken = (state: Object): string => state.auth.token;
export const getAfterLogin = (state: Object): string => state.auth.afterLogin;
export const getParent = (state: Object): Object => state.auth.parent || {};
export const getGhostingParentUser = (state: Object): Object => state.auth.staffUser || {};
export const getCounselor = (state: Object): Object => state.auth.counselor || {};
export const getCurrentUser = (state: Object): Object => state.auth.currentUser;
export const getCurrentUsername = (state: Object): Object => state.auth.username;
export const getCurrentUserSchool = (state: Object): string => state.auth.currentUserSchool;
export const getCurrentUserRole = (state: Object): string => state.auth.role;
export const getCurrentUserGrade = (state: Object): string => state.auth.grade;
export const getAuth = (state: Object): string => state.auth;
export const getSessionExpirationDate = (state: Object): Date => new Date(state.auth.expDate);
export const isDelegatedAlumni = (state: Object): string => !!state.auth.alumniExcluded;
export const getCeebCode = (state: Object): Object => state.highschool.ceebCode;
export const getH2Status = (state: Object): Object => state.h2Auth?.linkStatus;
export const getH2AccountLink = (state: Object): Object => state.h2Auth?.headed2Endpoint;
export const getErrorFlag = (state: Object): Object => state.h2Auth.errorFlag;

export const isAuthenticated = createSelector([getToken], (token): boolean => !!token);
export const isParentUser = createSelector([getParent], (parent): boolean => !!parent.id);
// this is true for impersonating
export const isStaffUser = createSelector([getCounselor], (counselor): boolean => !!counselor.id);
// true if it is ghosting parent user
export const isGhostingParentUser = createSelector(
  [getGhostingParentUser],
  (user): boolean => !!user.id
);
// this is true when SSO as a school user like DISTRICT_USER, COUNSELOR, SCHOOL_USER
export const isSsoSchoolUser = createSelector([getCurrentUserRole], (role): boolean =>
  [ROLES.counselor, ROLES.schoolUser, ROLES.districUser].includes(role)
);
export const isGuestUser = createSelector(
  [getCurrentUserRole, getCurrentUserGrade],
  (role, grade): boolean => role === ROLES.guest || grade === 14
);
export const isAlumniUser = createSelector([getCurrentUserGrade], (grade): boolean => grade > 12);

export const isMiddleSchoolUser = createSelector([getCurrentUserGrade], (grade): boolean =>
  MiddleSchoolGrades.includes(grade)
);

export const isDemoUser = createSelector(
  [getCurrentUser, userLoaded, isStaffUser, getCurrentUserRole],
  (currentUser, loaded, counselor, role): boolean => {
    if (loaded && counselor) {
      if (has(currentUser, 'isDemo')) {
        return currentUser.isDemo;
      }
      return role === ROLES.guest;
    }
    return false;
  }
);

export const isViewAsStudent = createSelector(
  [getCurrentUser, userLoaded, isStaffUser],
  (currentUser, loaded, counselor): boolean =>
    loaded && counselor && has(currentUser, 'isDemo') ? !currentUser.isDemo : false
);
export const isStaffOrParentUser = createSelector(
  [isParentUser, isStaffUser, userLoaded],
  (isParent: boolean, isStaff: boolean, loaded: boolean) => (loaded ? isParent || isStaff : false)
);
export const getCurrentUserAlias = createSelector(
  [isParentUser, getCurrentUser, userLoaded],
  (isParent: boolean, currentUser: Object, loaded: boolean) =>
    isParent && loaded ? `${currentUser.firstName}'s` : 'My'
);

export const getEditableUser = createSelector(
  [getParent, getCurrentUser],
  (parent: Object, student: Object) => (parent.id ? parent : student)
);

export const needsToChooseStudent = createSelector(
  [getCurrentUserRole],
  (role: string) => role === ROLES.parent
);

export const isLoadingStaffUser = createSelector(
  [getCurrentUserRole, isStaffUser],
  (role: string, isStaff: boolean): boolean =>
    [ROLES.counselor, ROLES.schoolUser, ROLES.districUser].includes(role) || isStaff
);

const isCurrentUserProfile = (state: Object, props: Object) => props.isCurrentUserProfile;

export const getInitials: (state: Object, props: Object) => string = createSelector(
  [getCurrentUser, userLoaded, getEditableUser, isCurrentUserProfile],
  (student: Object, loaded: boolean, user: Object, isCurrent: boolean) => {
    if (!loaded) {
      return '';
    }
    const currentUser = isCurrent ? user : student;
    if (currentUser.fullName) {
      return currentUser.fullName.match(/\b\w/g).join('');
    }
    return '';
  }
);

export const getLogoutText: (state: Object) => string = createSelector(
  [isDemoUser],
  (isDemo: boolean) => (isDemo ? 'Close Demo' : 'Log Out')
);

export const getParentId = createSelector(
  [getParent, getAuth],
  (parent: Object, auth: Object) => parent.id || (auth.role === ROLES.parent ? auth.id : null)
);

export const getCurrentUserEmail = createSelector(
  [getCurrentUser, userLoaded],
  (user: Object, loaded: boolean) => (loaded ? user.email : '')
);

export const getCurrentUserBirthDate = createSelector(
  [getCurrentUser, userLoaded],
  (user: Object, loaded: boolean) => (loaded ? user.birthdate : '')
);

export const getCurrentUserId = createSelector([getAuth], (auth: Object) => auth.id);

export const getCurrentUserOutcomeInfo = createSelector(
  [getCurrentUser],
  (currentUser: Object): Object => ({
    outcome: currentUser.outcome,
    outcomeOther: currentUser.outcomeOther,
  })
);

export const getUserType = createSelector(
  [
    getCurrentUserRole,
    isParentUser,
    isStaffUser,
    isGhostingParentUser,
    isGuestUser,
    isAlumniUser,
    isMiddleSchoolUser,
  ],
  (currentUserRole, parent, staff, ghostingParent, guest, alumni, middleSchool) => {
    if (parent || ghostingParent) {
      return ROLES.parent;
    }
    if (staff) {
      return ROLES.counselor;
    }
    if (guest) {
      return ROLES.guest;
    }
    if (alumni) {
      return ROLES.alumni;
    }
    if (middleSchool) {
      return ROLES.schoolUser;
    }
    return currentUserRole;
  }
);

export const getUserDataForHubs = createSelector(
  [getAuth, getCurrentUser, getUserType, getCeebCode],
  (auth: Object, currentUser: Object, userType: string, ceebCode: Object): Object => ({
    gpaConcordance: get(currentUser, 'gpaConcordance', null),
    username: auth.username,
    firstName: get(currentUser, 'firstName', ''),
    lastName: get(currentUser, 'lastName', ''),
    birthDate: get(currentUser, 'birthdate', ''),
    emailAddress: get(currentUser, 'email', ''),
    weightedGpa: get(currentUser, 'weightedGpa', ''),
    cumulativeGpa: get(currentUser, 'gpa', 0),
    highestACT: get(currentUser, 'highestAct', 0),
    highestSAT: get(currentUser, 'highestComboSat') || get(currentUser, 'highestSat', 0),
    ferpaBlock: !!get(currentUser, 'ferpa', ''),
    ethnicity: get(currentUser, 'ethnicCode', ''),
    gender: get(currentUser, 'gender', ''),
    gradeLevel: auth.grade,
    letterGrade: undefined,
    address: get(currentUser, 'studentAddress', ''),
    institution: get(currentUser, 'highSchool', ''),
    defaultGpa: get(currentUser, 'gpa', ''),
    age: get(currentUser, 'age', ''),
    navigationItems: undefined,
    scattergramsSettings: undefined,
    recommendedEventCount: undefined,
    collegeVisitCount: undefined,
    userType: userType,
    studentHighestScores: get(currentUser, 'highestScores', ''),
    peerGpaMap: undefined,
    international: get(currentUser, 'highSchool.country', '') !== 'US',
    communityInfo: undefined,
    highSchoolId: get(currentUser, 'highSchoolId', ''),
    classYear: get(currentUser, 'classYear', ''),
    sat: get(currentUser, 'sat', ''),
    act: get(currentUser, 'act', ''),
    ceebCode,
    classSize: get(currentUser, 'classSize', ''),
  })
);
