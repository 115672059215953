import * as React from 'react';

import Chunk from 'components/Chunk';

const loadDemoUser = () => import('containers/Accounts/Demo' /* webpackChunkName: "demo-user" */);  // eslint-disable-line prettier/prettier

export default class DemoUserRoute extends React.Component {
  render() {
    return <Chunk load={loadDemoUser} />;
  }
}
