// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import errorImage from 'theme/icons/emoticon-worried.svg';
import ErrorPageTemplate from '../Template';

@withRouter
export default class Generic extends React.PureComponent<any> {
  render() {
    const id: string = this.props.id || this.props.match.params.id;
    return (
      <ErrorPageTemplate
        errorTitle="Error"
        errorMessage="We're sorry"
        errorDescription="Something went wrong."
        errorAction={`Why don't you try again? If the issue continues, contact your school counselor or school site manager. Your error code is: <strong>${id}</strong>`}
        errorImage={errorImage}
        goHomeButton="Try Again"
      />
    );
  }
}
