import * as React from 'react';

import Chunk from 'components/Chunk';

const loadColleges = () => import('containers/Colleges' /* webpackChunkName: "colleges" */); // eslint-disable-line prettier/prettier

export default class CollegesRoute extends React.Component {
  render() {
    return <Chunk load={loadColleges} {...this.props} />;
  }
}
