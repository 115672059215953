import React from 'react';
import style from './FavoriteCareers.scss';
interface Props {
  clusterId?: number;
  categoryName?: string;
  pathwayId?: string;
  imageType?: 'Card' | 'Header' | 'Thumbnail';
  imagePath?: boolean;
  militaryBranch?: string;
}

const pathwayImageMap: { [key: string]: string } = {
  '1.1000': 'Food_Products',
  '1.2000': 'Plant_Systems',
  '1.3000': 'Animal_Systems',
  '1.4000': 'Power_Structural',
  '1.5000': 'Natural_Resource',
  '1.6000': 'Environmental_Service',
  '1.7000': 'Agribusiness_Systems',
  '2.1000': 'Design_PreConstruction',
  '2.2000': 'Construction',
  '2.3000': 'Maintenance_Operation',
  '3.1000': 'Audio_TechnologyFilm',
  '3.2000': 'Printing_Technology',
  '3.3000': 'Visual_Arts',
  '3.4000': 'Performing_Arts',
  '3.5000': 'Journalism',
  '3.6000': 'Telecommunications',
  '4.1000': 'General_Management',
  '4.2000': 'Business_Information',
  '4.3000': 'Human_Resources',
  '4.4000': 'Operations_Management',
  '4.6000': 'Administrative_Support',
  '5.1000': 'Administration_Administrative',
  '5.2000': 'Professional_Support',
  '5.3000': 'Teaching_Training',
  '6.1000': 'Securities_Investments',
  '6.2000': 'Business_Finance',
  '6.3000': 'Banking_Services',
  '6.4000': 'Insurance',
  '6.5000': 'Accounting',
  '7.1000': 'Governance',
  '7.2000': 'National_Security',
  '7.3000': 'Foreign_Service',
  '7.4000': 'Planning',
  '7.5000': 'Revenue_Taxation',
  '7.6000': 'Regulation',
  '7.7000': 'Public_Administration',
  '8.1000': 'Therapeutic_Services',
  '8.2000': 'Diagnostic_Services',
  '8.3000': 'Health_Informatics',
  '8.4000': 'Support_Services',
  '8.5000': 'Biotechnology_Research',
  '9.1000': 'Restaurants_Food',
  '9.2000': 'Lodging',
  '9.3000': 'Travel_Tourism',
  '9.4000': 'Recreation_Attractions',
  '10.1000': 'Childhood_Development',
  '10.2000': 'Counseling_Health',
  '10.3000': 'Family_Community',
  '10.4000': 'Personal_Care',
  '10.5000': 'Consumer_Services',
  '11.1000': 'Network_Systems',
  '11.2000': 'Information_Service',
  '11.3000': 'Web_Communications',
  '11.4000': 'Programming_Software',
  '12.1000': 'Correction_Services',
  '12.2000': 'Emergency_Fire',
  '12.3000': 'Security_Protective',
  '12.4000': 'Law_Enforcement',
  '12.5000': 'Legal_Services',
  '13.1000': 'Production',
  '13.2000': 'Manufacturing_Production',
  '13.3000': 'Maintenance_Installation',
  '13.4000': 'Quality_Assurance',
  '13.5000': 'Logistics_Control',
  '13.6000': 'Health_Safety',
  '14.1000': 'Marketing_Management',
  '14.2000': 'Professional_Sales',
  '14.3000': 'Merchandising',
  '14.4000': 'Marketing_Communications',
  '14.5000': 'Marketing_Research',
  '15.1000': 'Engineering_Technology',
  '15.3000': 'Science_Mathematics',
  '16.1000': 'Transportation_Operations',
  '16.2000': 'Logistics_Planning',
  '16.3000': 'Warehousing_Operations',
  '16.4000': 'Facility_Maintenance',
  '16.5000': 'Transportation_Systems',
  '16.6000': 'Health_Environmental',
  '16.7000': 'Sales_Service',
};

export const getBaseImagePath = () => {
  return window.REWRITTEN_CONFIG?.CAREERS2_UI_URL?.split(/\/\w+\.js/)[0];
};

export const getPathwayImage = ({ pathwayId, imageType, imagePath }: Props) => {
  const constructedImagePath = `${
    pathwayId && pathwayImageMap[pathwayId] ? pathwayImageMap[pathwayId] : 'Unknown_Error'
  }_${imageType || 'Card'}.png`;
  if (imagePath) {
    return `${getBaseImagePath()}/images/${constructedImagePath}`;
  }
  return constructedImagePath;
};

export const getMilitaryImage = ({ militaryBranch, imageType }: Props) => {
  let militaryImage = militaryBranch
    ? militaryBranch.replace(/ /g, '_')
    : 'Unknown_Error';
  militaryImage += `_${imageType || 'Card'}.png`;
  return militaryImage;
};

export const PathwayImage = (props: Props) => {
  const image = props.militaryBranch ? getMilitaryImage(props) : getPathwayImage(props);
  return (
    <img
      className={`${style.careerFavoriteCardImage}`}
      src={`${getBaseImagePath()}/images/${image}`}
      alt={image}
      loading={'lazy'}
    />
  );
};
