// @flow
import memoryStorage from './memoryStorage';

function returnStorageObject(): Object {
  try {
    const key: string = `ss-test-${String(Date.now())}`;
    sessionStorage.setItem(key, key);

    const value = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);

    if (key !== value) {
      throw new Error();
    }

    return window.sessionStorage;
  } catch (e) {
    return memoryStorage;
  }
}

export default returnStorageObject();
