// @flow
import noop from 'utils/noop';

export type History = {
  location: {
    pathname: string,
  },
  push: Function,
  replace: Function,
  goBack: Function,
};

let history: History = {
  location: { pathname: window.location.pathname },
  push: noop,
  replace: noop,
  goBack: noop,
};

export function setHistory(newHistory: History) {
  history = newHistory;
  window.fcuiRouterHistory = history;
}

/**
 * Parse string to RelativeConfig
 * Match ../../foo locations and common url parts
 *
 * @param {string} str
 * @param {string} pathname
 * @returns RelativeConfig without location
 */
function parseStringConfig(str: string, pathname: string) {
  // parse ../../foo links
  const backPlacesMatch = str.match(/\.\.\//g);
  const backPlaces = backPlacesMatch ? backPlacesMatch.length : 0;

  // try to find the matching part
  // e.g. if pathname is /foo/bar/zoo
  // and requested string is bar/doo
  // then we should try to figure that out
  const strParts = str.split('/');
  const firstRequestedPart = strParts[0];
  const rootPart = pathname.split('/').find((part) => part === firstRequestedPart);

  // remove root part and .. from the appended path
  const shouldNotInclude = ['..', 'undefined', undefined].concat([rootPart]);
  const append = strParts.filter((part) => !shouldNotInclude.includes(part));

  return { backPlaces, rootPart, append };
}

type RelativeConfig = {
  location: {
    pathname: string,
  },
  backPlaces: number,
  append: string[],
  rootPart: string,
};

/**
 * Relative URL parsing utility
 *
 * Usage:
 *  Current pathname: /careers/roadtripnation/leaders/grid
 *
 *  relative('alpha');                    // --> /careers/roadtripnation/leaders/grid/alpha
 *  relative('../list');                  // --> /careers/roadtripnation/leaders/list
 *  relative('roadtripnation/leader');    // --> /careers/roadtripnation/leader
 *
 * @export
 * @param {(Object | string)} config
 * @returns
 */
export function relative(config: Object | string) {
  const defaults: RelativeConfig = {
    location: history.location,
    backPlaces: 0,
    append: [],
    rootPart: '',
  };

  const args =
    typeof config === 'string' ? parseStringConfig(config, history.location.pathname) : config;

  const cfg = Object.assign({}, defaults, args);

  // make everything an array so it's easier to work with the URL
  const parts = cfg.location.pathname.split('/').filter(Boolean);

  // we're gonna start from the root Part or we take the entire url
  let startPosition: number = cfg.rootPart
    ? parts.findIndex((part) => part === cfg.rootPart)
    : parts.length - 1;

  // if we need to move back, this is the time
  startPosition -= cfg.backPlaces;

  // start the new path, but mind the initial slash
  const startPath = [''].concat(parts.slice(0, startPosition + 1));

  // full new path
  const newPath = startPath.concat(cfg.append);

  // find position of first empty part of the path and break there
  //  to avoid duplicate slashes //
  let breakPosition = newPath.findIndex((part, index) => index > 0 && !part);

  if (breakPosition === -1) {
    breakPosition = newPath.length;
  }

  // return final part
  return newPath.slice(0, breakPosition).join('/');
}

export function push(url: string, stateParam?: any) {
  history.push({
    pathname: url,
    ...(stateParam != null && { state: { detail: stateParam } }),
  });
}

export function replace(url: string) {
  history.replace(url);
}

export function relativePush(relativeCfg: Object | string) {
  const url = relative(relativeCfg);
  push(url);
}

export function relativePushWithParam(relativeCfg: Object | string, stateParam: any) {
  const url = relative(relativeCfg);
  history.push({ pathname: url, state: { detail: stateParam } });
}

export function relativeReplace(relativeCfg: Object | string) {
  const url = relative(relativeCfg);
  replace(url);
}

export function back() {
  history.goBack();
}

export function validateUrl(urlPath) {
  const validationFlag = false;
  try {
    const regex = new RegExp(
      '/(careers|careers2|colleges|surveys-next|course-planner-next|about-me|my-planner|job-search)/'
    );

    if (regex.test(urlPath)) {
      return true;
    }
  } catch (err) {
    // nothing to handle here, just a failsafe try/catch block
  }

  return validationFlag;
}
