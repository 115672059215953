// @flow
import { injectAsyncReducers } from 'store';
import constantsGenerator from 'utils/constantsGenerator';
import api, { host } from 'config/api';

const generateConstants = constantsGenerator('fc/tracking');
const [
  TRACK_PRODUCT_PLACEMENT,
  TRACK_PRODUCT_PLACEMENT_SUCCESS,
  TRACK_PRODUCT_PLACEMENT_FAIL,
]: string[] = generateConstants('TRACK_PRODUCT_PLACEMENT');
const [TRACK_CLICK, TRACK_CLICK_SUCCESS, TRACK_CLICK_FAILURE]: string[] = generateConstants(
  'TRACK_CLICK'
);
const [
  TRACK_PRODUCT_CLICK,
  TRACK_PRODUCT_CLICK_SUCCESS,
  TRACK_PRODUCT_CLICK_FAILURE,
]: string[] = generateConstants('TRACK_PRODUCT_CLICK');

const [
  TRACK_VIEW_EVENT,
  TRACK_VIEW_EVENT_SUCCESS,
  TRACK_VIEW_EVENT_FAILURE,
]: string[] = generateConstants('TRACK_VIEW_EVENT');

const [
  TRACK_COLLEGE_EVENTS,
  TRACK_COLLEGE_EVENTS_SUCCESS,
  TRACK_COLLEGE_EVENTS_FAILURE,
]: string[] = generateConstants('TRACK_COLLEGE_EVENTS');

const [
  TRACK_MERIT_BASED_SCHOLARSHIPS,
  TRACK_MERIT_BASED_SCHOLARSHIPS_SUCCESS,
  TRACK_MERIT_BASED_SCHOLARSHIPS_FAILURE,
]: string[] = generateConstants('TRACK_MERIT_BASED_SCHOLARSHIPS');

export type State = {
  tracking: false,
};

const initialState: State = {
  tracking: false,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case TRACK_PRODUCT_PLACEMENT:
    case TRACK_VIEW_EVENT:
    case TRACK_COLLEGE_EVENTS:
    case TRACK_MERIT_BASED_SCHOLARSHIPS:
    case TRACK_CLICK:
      return {
        ...state,
        tracking: true,
      };
    case TRACK_PRODUCT_PLACEMENT_SUCCESS:
    case TRACK_PRODUCT_PLACEMENT_FAIL:
    case TRACK_VIEW_EVENT_SUCCESS:
    case TRACK_VIEW_EVENT_FAILURE:
    case TRACK_COLLEGE_EVENTS_SUCCESS:
    case TRACK_COLLEGE_EVENTS_FAILURE:
    case TRACK_MERIT_BASED_SCHOLARSHIPS_SUCCESS:
    case TRACK_MERIT_BASED_SCHOLARSHIPS_FAILURE:
    case TRACK_CLICK_SUCCESS:
    case TRACK_CLICK_FAILURE:
      return {
        ...state,
        tracking: false,
      };
    default:
      return state;
  }
}

export function trackProductPlacement(productPlacement: number | string) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        TRACK_PRODUCT_PLACEMENT,
        TRACK_PRODUCT_PLACEMENT_SUCCESS,
        TRACK_PRODUCT_PLACEMENT_FAIL,
      ],
      promise: (client: Object) => client.get(`${host}/college-products/ppId/${productPlacement}`),
    });
}

export const trackActiveMatch = (payload: Object) => ({
  types: [TRACK_CLICK, TRACK_CLICK_SUCCESS, TRACK_CLICK_FAILURE],
  promise: (client: Object) =>
    client.put(`${api.activeMatchNext}/track`, {
      data: {
        payload,
      },
    }),
});

export const trackCollegeProductClick = (payload: Object) => ({
  types: [TRACK_PRODUCT_CLICK, TRACK_PRODUCT_CLICK_SUCCESS, TRACK_PRODUCT_CLICK_FAILURE],
  promise: (client: Object) =>
    client.put(`${api.collegesProfilesNext}/track`, {
      data: {
        ...payload,
      },
    }),
});

export const trackCollegeViewEvent = (payload: Object) => ({
  types: [TRACK_VIEW_EVENT, TRACK_VIEW_EVENT_SUCCESS, TRACK_VIEW_EVENT_FAILURE],
  promise: (client: Object) =>
    client.put(`${api.collegesProfilesNext}/viewtrack`, {
      data: {
        ...payload,
      },
    }),
});

export const trackCollegeEvents = (payload: Object) => ({
  types: [TRACK_COLLEGE_EVENTS, TRACK_COLLEGE_EVENTS_SUCCESS, TRACK_COLLEGE_EVENTS_FAILURE],
  promise: (client: Object) =>
    client.post(`${api.collegesEventsNext}/college-event-track`, {
      data: payload,
    }),
});

export const trackMeritBasedScholarships = (payload: Object) => ({
  types: [
    TRACK_MERIT_BASED_SCHOLARSHIPS,
    TRACK_MERIT_BASED_SCHOLARSHIPS_SUCCESS,
    TRACK_MERIT_BASED_SCHOLARSHIPS_FAILURE,
  ],
  promise: (client: Object) =>
    client.post(`${api.scholarshipsMatch}/scholarships-track`, {
      data: payload,
    }),
});

injectAsyncReducers({ tracking: reducer });
