import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/StudentReadinessReports' /* webpackChunkName: "studentReports" */);  // eslint-disable-line prettier/prettier

export default class StudentReadinessReportsRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
