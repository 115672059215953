// @flow
import { areAcceptanceCollegesLoaded } from 'selectors/colleges';

import api from 'config/api';
import collectIds from 'utils/collectIds';
import normalizeItems from 'utils/normalizeItems';
import constantsGenerator from 'utils/constantsGenerator';
import { paginatedSearch } from 'utils/pagination';
import { getCurrentYear } from 'utils/date';

const generateConstants = constantsGenerator('fc/colleges/acceptance');

const [
  GET_ACCEPTANCE_COLLEGES,
  GET_ACCEPTANCE_COLLEGES_SUCCESS,
  GET_ACCEPTANCE_COLLEGES_FAIL,
]: string[] = generateConstants('GET_COLLEGES');

const [
  NEXT_PAGE_ACCEPTANCE_COLLEGES,
  NEXT_PAGE_ACCEPTANCE_COLLEGES_SUCCESS,
  NEXT_PAGE_ACCEPTANCE_COLLEGES_FAIL,
]: string[] = generateConstants('NEXT_PAGE_COLLEGES');

type State = {
  loaded: boolean,
  startYear: number,
  endYear: number,
  results: [],
  entities: {},
  loading: boolean,
  page: number,
  totalItems: number,
};

const initialState: State = {
  loaded: false,
  startYear: 2011,
  endYear: getCurrentYear(),
  results: [],
  entities: {},
  loading: false,
  page: 0,
  totalItems: 0,
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case NEXT_PAGE_ACCEPTANCE_COLLEGES:
    case GET_ACCEPTANCE_COLLEGES:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case NEXT_PAGE_ACCEPTANCE_COLLEGES_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        startYear: action.result.startYear,
        endYear: action.result.endYear,
        results: [...state.results, ...collectIds(action.result.data)],
        entities: { ...state.entities, ...normalizeItems(action.result.data) },
        totalItems: action.result.totalItems,
      };
    case GET_ACCEPTANCE_COLLEGES_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        startYear: action.result.startYear,
        endYear: action.result.endYear,
        results: collectIds(action.result.data),
        entities: normalizeItems(action.result.data),
        totalItems: action.result.totalItems,
      };
    default:
      return state;
  }
}

// remember last page we loaded. Set it to -1 so it's immediately invalidated
let lastPage: number = -1;

// how many pages per request?
const limit = 10;
export const load = (lastIndex: number = 0) => (
  dispatch: Function,
  getState: Function
): Function | Promise<any> => {
  const state = getState();
  const totalResults = getState().colleges.acceptance.results.length;
  const { totalItems } = getState().colleges.acceptance;

  const { requestUrl, types, nextPage, proceed } = paginatedSearch({
    baseUrl: `${api.colleges}/acceptance-history`,
    lastIndex,
    lastPage,
    limit,
    totalResults,
    totalItems,
    firstPageTypes: [
      GET_ACCEPTANCE_COLLEGES,
      GET_ACCEPTANCE_COLLEGES_SUCCESS,
      GET_ACCEPTANCE_COLLEGES_FAIL,
    ],
    nextPageTypes: [
      NEXT_PAGE_ACCEPTANCE_COLLEGES,
      NEXT_PAGE_ACCEPTANCE_COLLEGES_SUCCESS,
      NEXT_PAGE_ACCEPTANCE_COLLEGES_FAIL,
    ],
    customValidation: lastPage > 0 && !areAcceptanceCollegesLoaded(state),
  });

  if (!proceed) {
    // $FlowFixMe
    return Promise.resolve(true);
  }

  lastPage = nextPage || -1;
  return dispatch({
    types,
    promise: (client: Object) => client.get(requestUrl),
  });
};
