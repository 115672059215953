import getApolloClient from 'utils/ApolloClient';
import api from 'config/api';
import fetchCollegeEventsQuery from 'utils/graphql/colleges/events/fetchCollegeEvents';
import constantsGenerator from 'utils/constantsGenerator';

const generateConstants = constantsGenerator('fc/colleges/events');

const [
  FETCH_COLLEGE_EVENTS,
  FETCH_COLLEGE_EVENTS_SUCCESS,
  FETCH_COLLEGE_EVENTS_FAIL,
] = generateConstants('FETCH_COLLEGE_EVENTS');

const initialState = {
  collegeEvents: {},
  collegeEventsLoading: false,
  collegeEventsError: false,
};

export function fetchCollegeEvents(filter, studentProfile, pagination, session) {
  return (dispatch) =>
    dispatch({
      types: [FETCH_COLLEGE_EVENTS, FETCH_COLLEGE_EVENTS_SUCCESS, FETCH_COLLEGE_EVENTS_FAIL],
      promise: () => {
        const client = getApolloClient({ uri: api.collegesEventsNext, session });
        return fetchCollegeEventsQuery(client, filter, studentProfile, pagination);
      },
    });
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COLLEGE_EVENTS: {
      return {
        ...state,
        collegeEvents: {},
        collegeEventsLoading: true,
        collegeEventsError: false,
      };
    }

    case FETCH_COLLEGE_EVENTS_SUCCESS: {
      return {
        ...state,
        collegeEvents: action.result,
        collegeEventsLoading: false,
        collegeEventsError: false,
      };
    }

    case FETCH_COLLEGE_EVENTS_FAIL: {
      return {
        collegeEvents: {},
        collegeEventsLoading: false,
        collegeEventsError: true,
      };
    }

    default: {
      return state;
    }
  }
}
