import * as React from 'react';
import initGoogleOptimize from 'utils/googleOptimize';

interface Props {
  initPageHidingSnippet: boolean;
  dataLayerAttr?: any;
}

export default class GoogleOptimize extends React.Component<Props> {
  static defaultProps = {
    initPageHidingSnippet: false,
    dataLayerAttr: [],
  };

  componentDidMount() {
    const { initPageHidingSnippet, dataLayerAttr } = this.props;
    setTimeout(() => initGoogleOptimize(dataLayerAttr, initPageHidingSnippet), 0);
  }

  render() {
    return null;
  }
}
