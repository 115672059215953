import * as React from 'react';
import cx from 'classnames';
import styles from './styles.scss';

export enum variations {
  default = 'default',
  noStyle = 'noStyle',
  primary = 'primary',
  light = 'light',
  callToAction = 'callToAction',
  danger = 'danger',
  alternate = 'alternate',
  noChrome = 'noChrome',
  navLink = 'navLink',
  navLinkSelected = 'navLinkSelected',
  link = 'link',
  addButton = 'addButton',
}

export type Variations = keyof typeof variations;

export type Sizes = 'large' | 'medium' | 'small' | 'xs';

export enum sizes {
  medium = 'medium',
  large = 'large',
  small = 'small',
  xs = 'xs',
}

interface Props {
  variation: Variations;
  size: Sizes;
  block: boolean;
  ghost: boolean;
  pointer: boolean;
  disabled: boolean;
  className: string;
  children?: React.ReactNode;
  active?: boolean;
}

/**
 * HOC to extend Button and Link components with same features
 */
export default function ClickHOC<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  return class extends React.PureComponent<P & Props> {
    static defaultProps = {
      children: null,
      className: '',
      variation: variations.noStyle,
      size: sizes.medium,
      disabled: false,
      block: false,
      ghost: false,
      pointer: false,
      active: false,
    };

    static variation = variations;

    static size = sizes;

    render() {
      const {
        children,
        variation,
        size,
        disabled,
        block,
        ghost,
        pointer,
        active,
        ...rest
      } = this.props;

      const extendedClassNames = cx(
        {
          [styles[variation]]: !!variations[variation],
          [styles[size]]: !!sizes[size],
          [styles.isDisabled]: disabled,
          [styles.block]: block,
          [styles.ghost]: ghost,
          [styles.pointer]: pointer,
          [styles.active]: active,
        },
        this.props.className
      );

      const props = {
        ...rest,
        disabled,
        className: extendedClassNames,
      };

      return <WrappedComponent {...(props as P)}>{children}</WrappedComponent>;
    }
  };
}
