import React, { useEffect, useState } from 'react';
import { configureTranslation } from '@naviance/translation-client';
import ApiClient from 'utils/ApiClient';
import { host } from 'config/api';
import { useSelector } from 'react-redux';
import { defaultLanguage, translationMessages } from 'constants/languageOptions';
import Icon from 'components/Icon';
import Button from 'components/Button';
import cx from 'classnames';
import { convertPseudoElementToHTML } from 'utils/inlineManual';
import s from './styles.scss';
import { FeatureFlag } from '../../constants/featureFlags';
import { comboPreferredLanguageKey } from 'utils/translation';

const apiClient = new ApiClient();

const generalMessagePages = [
  '/about-me/strengths-explorer',
  '/colleges/scholarships/national',
  '/careers/career-key',
  '/careers2/assessments/career-key/questions',
];

export const isGeneralMessagePage = (currentPath: string) =>
  generalMessagePages.some((pagePath) => currentPath.startsWith(pagePath));

class PreferredLanguageManager {
  private key: string;

  constructor(
    {
      id,
      districtId,
      highSchoolId,
    }: { id?: string; districtId?: string; highSchoolId?: string },
    isComboPreferredLanguageKeyEnabled: boolean
  ) {
    if (id && districtId) {
      this.key = `NAVI_${districtId}_${id}_preferredLanguage`;
    } else {
      this.key = 'NAVI__preferredLanguage';
    }

    if (isComboPreferredLanguageKeyEnabled && id && highSchoolId) {
      this.key = comboPreferredLanguageKey(districtId, highSchoolId, id);
    }
  }

  get() {
    return localStorage.getItem(this.key) || defaultLanguage;
  }

  set(value: string) {
    return localStorage.setItem(this.key, value);
  }
}

const InnerTranslator = configureTranslation({
  selectorsToExclude: [
    '#donottranslate',
    '.donottranslate',
    '#components-Icon-style-icon',
    '.components-Icon-style-icon',
    '#result-row-decription-label',
    '.result-row-decription-label',
    '#college-name-profile-label',
    '.college-name-profile-label',
    // Exclude elements with data-navi-donottranslate attribute unless it is set to false
    '[data-navi-donottranslate]:not([data-navi-donottranslate="false"])',
    // The translation notification is translated by a different instance of the translator
    '#translationNotification',
  ],
  pathsToExclude: generalMessagePages,
  selectorActions: {
    '.inmplayer-trigger': convertPseudoElementToHTML,
  },
});

export const translationFetcher = (data) =>
  apiClient.post(`${host}/translation`, { data });

export const Translator = () => {
  const auth = useSelector((state: any) => state.auth);
  const featureFlags = useSelector(
    (state: { featureFlags: { loaded: boolean; flags: { [key: string]: boolean } } }) =>
      state.featureFlags
  );
  const isComboPreferredLanguageKeyEnabled: boolean =
    featureFlags &&
    featureFlags.loaded &&
    featureFlags.flags[FeatureFlag.ComboPreferredLanguageKeyEnabled];

  const langPref = new PreferredLanguageManager(auth, isComboPreferredLanguageKeyEnabled);
  const preferredLanguage = langPref.get();

  const showNotification =
    preferredLanguage !== defaultLanguage &&
    isGeneralMessagePage(window.location.pathname);
  return (
    <>
      <InnerTranslator
        translationRoot={document.body}
        preferredLanguage={preferredLanguage}
        translationFetcher={translationFetcher}
      />
      {showNotification && (
        <TranslationNotification
          currentPath={window.location.pathname}
          preferredLanguage={preferredLanguage}
        />
      )}
    </>
  );
};

// This instance of the translator is used to translate the notification
// since the notification appears on pages that otherwise shouldn't be translated
const NotificationTranslator = configureTranslation({
  selectorsToExclude: [],
  pathsToExclude: [],
});

interface TranslationNotificationProps {
  currentPath: string;
  preferredLanguage: string;
}

function TranslationNotification(props: TranslationNotificationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [notificationEl, setNotificationEl] = useState(null);

  // Animate notification into place
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 1);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {notificationEl && (
        <NotificationTranslator
          translationRoot={notificationEl}
          preferredLanguage={props.preferredLanguage}
          translationFetcher={translationFetcher}
        />
      )}
      <div
        className={cx(
          s.container,
          isOpen ? s.rightToLeftAnimation : s.LeftToRightAnimation
        )}
        id="translationNotification"
        data-test-id="page-translation-notification"
        ref={setNotificationEl}
      >
        <div className={s.wrapper}>
          <Icon icon="info-circle" className={s.infoIcon} />
          <h4 className={s.notificationText} data-test-id="notification-text">
            {translationMessages.noTranslation}
          </h4>
          <Button
            id="closeBtn"
            type="button"
            aria-label="Close"
            onClick={() => setIsOpen(false)}
            variation="noChrome"
          >
            <Icon icon="close" className={s.closeIcon} />
          </Button>
        </div>
      </div>
    </>
  );
}
