// @flow
import { createSelector } from 'reselect';
import { getFeatureFlags } from 'selectors/featureFlags';
import { isFeatureFlagAllowed } from 'utils/permissions';

export const getUserInfo = (state: Object) => state.colleges.supermatchnext.userInfo;

export const getSuperMatchNextLabel: (featureFlags: Object) => string = createSelector(
  [getFeatureFlags],
  (featureFlags) => `SuperMatch${isFeatureFlagAllowed('superMatch', featureFlags) ? ' Next' : '®'}`
);
