// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { notify } from 'modules/notification';
import ScrollToTop from 'components/ScrollToTop';
import Alert from 'components/Alertv2';
import type { Notification } from 'types/notification';

type Props = Notification & {
  notify?: Function,
  location?: { pathname: string },
};

@withRouter
@connect(
  (state) => {
    if (state.notification) {
      const stateObj = {
        type: state.notification.type,
        content: state.notification.content,
        icon: state.notification.icon,
        title: state.notification.title,
        id: state.notification.id, // in case of error id
        fixes: state.notification.fixes,
        closeButtonIcon: state.notification.closeButtonIcon,
        showCloseButtonText: state.notification.showCloseButtonText,
      };

      return stateObj;
    }

    return {};
  },
  { notify }
)
export default class Notify extends React.Component<Props> {
  static defaultProps = {
    className: '',
  };

  isValidNotification = (props: Props) => props.content || props.title || false;

  // $FlowExpectedPropsFromRedux
  closeAlert = () => this.props.notify(false);

  render() {
    // $FlowExpectedPropsFromRedux
    const {
      type,
      title,
      content,
      icon,
      className,
      titleClassName,
      messageClassName,
      fixes,
      closeButtonIcon,
      showCloseButtonText,
      customIcon,
    } = this.props;

    return this.isValidNotification(this.props) ? (
      <div>
        <ScrollToTop />
        <Alert
          display
          onClose={this.closeAlert}
          variation={type}
          message={content}
          alertClassName={className}
          customIcon={customIcon || icon}
          title={title}
          titleClassName={titleClassName}
          messageClassName={messageClassName}
          fixes={fixes}
          closeButtonIcon={closeButtonIcon}
          showCloseButtonText={showCloseButtonText}
        />
      </div>
    ) : null;
  }
}
