// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import persistedAction from '../../utils/temporaryPersist';

const generateConstants = constantsGenerator('fc/match');
const [
  GET_MATCH_USER_INFO,
  GET_MATCH_USER_INFO_SUCCESS,
  GET_MATCH_USER_INFO_FAIL,
]: string[] = generateConstants('GET_MATCH_USER_INFO');

export type UserInfo = {
  coppa: boolean,
  hasCoppaBlock: boolean,
  hasFerpaBlock: boolean,
};

type State = {
  loading: boolean,
  loaded: boolean,
  userInfo?: UserInfo,
};

const initialState: State = {
  loading: false,
  loaded: false,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_MATCH_USER_INFO:
      return {
        ...state,
        userInfo: null,
        loading: true,
      };
    case GET_MATCH_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.result,
        loading: false,
        loaded: true,
      };
    case GET_MATCH_USER_INFO_FAIL:
      return {
        ...state,
        userInfo: null,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export const getMatchUserInfo = persistedAction('match.userinfo', {
  types: [GET_MATCH_USER_INFO, GET_MATCH_USER_INFO_SUCCESS, GET_MATCH_USER_INFO_FAIL],
  promise: (client: Object) => client.get(`${api.match}/userinfo`),
});
