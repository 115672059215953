import React from 'react';
import { useEffect } from 'react';
import { useExternalScript } from 'utils/useExternalScript';
import { chatterboxUiUrl } from 'config/api';

export const Chatterbox = () => {
  const chatterboxScriptStatus = useExternalScript({
    url: chatterboxUiUrl,
    moduleType: true,
  });

  const divId = 'chatterbox-host-container';

  useEffect(() => {
    if (chatterboxScriptStatus === 'ready') {
      (window as any).initializeChatterbox?.(divId);

      return () => {
        (window as any).unmountChatterbox?.();
      };
    }
  }, [chatterboxScriptStatus]);

  return <div id={divId} />;
};
