// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import noop from 'utils/noop';

import s from './styles.scss';

const cx = classNames.bind(s);

const Grid = ({ className, ...rest }: { className?: string }) => (
  <section {...rest} className={cx('fc-grid', 'fc-grid--hugged', className)} />
);

Grid.defaultProps = {
  className: '',
};

const Row = ({ className, divRef, ...rest }: { className?: string, divRef?: Function }) => (
  <div {...rest} ref={divRef} className={cx('fc-grid__row', 'row', className)} />
);

Row.defaultProps = {
  className: '',
  divRef: noop,
};

export type ColumnSizeProp = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const Col = ({
  xs,
  sm,
  md,
  lg,
  offsetXs,
  offsetSm,
  offsetMd,
  offsetLg,
  className,
  ...rest
}: {
  xs?: ColumnSizeProp,
  sm?: ColumnSizeProp,
  md?: ColumnSizeProp,
  lg?: ColumnSizeProp,
  offsetXs?: ?ColumnSizeProp,
  offsetSm?: ?ColumnSizeProp,
  offsetMd?: ?ColumnSizeProp,
  offsetLg?: ?ColumnSizeProp,
  className?: string,
}) => {
  // Ensure that value of xs is always set to match the value at the smallest specified size
  if (!xs) {
    xs = sm || md || lg;

    // And that if both xs and offsetXs are unspecified, that offsetXs also matches the value at the smallest specified size
    if (!offsetXs) {
      offsetXs = offsetSm || offsetMd || offsetLg;
    }
  }

  const colClassNames = cx(
    {
      [`fc-grid__col--xs-${String(xs)}`]: !!xs,
      [`fc-grid__col--sm-${String(sm)}`]: !!sm,
      [`fc-grid__col--md-${String(md)}`]: !!md,
      [`fc-grid__col--lg-${String(lg)}`]: !!lg,
      [`fc-grid__col--xs-offset-${String(offsetXs)}`]: !!offsetXs,
      [`fc-grid__col--sm-offset-${String(offsetSm)}`]: !!offsetSm,
      [`fc-grid__col--md-offset-${String(offsetMd)}`]: !!offsetMd,
      [`fc-grid__col--lg-offset-${String(offsetLg)}`]: !!offsetLg,
    },
    className,
    'col'
  );

  return <div {...rest} className={colClassNames} />;
};

Col.defaultProps = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  offsetXs: 0,
  offsetSm: 0,
  offsetMd: 0,
  offsetLg: 0,
  className: '',
};

export { Grid, Row, Col };
