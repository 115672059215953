import { getUserDataForAnalytics } from './analytics';

/**
 * Installs gainsight client-side api in `window.gainsight` using the official gainsight snippet.
 * Repeated invocations will install and load the snippet only once, but
 * provided user and highschool information will be applied (or updated) on each
 * invocation.
 */
/* eslint-disable */
export const initGainsightAnalytics = (currentUser, highschool, NotSureWhatThisVarIs = undefined, featureFlags = {}) => {
  window.gainsight = window.gainsight || [];
  gainsight.load =
    gainsight.load ||
    function (n, t, a, e, co) {
      const i = 'aptrinsic';
      (n[i] =
        n[i] ||
        function () {
          (n[i].q = n[i].q || []).push(arguments);
        }),
        (n[i].p = e);
      n[i].c = co;
      const r = t.createElement('script');
      (r.async = !0), (r.src = a + '?a=' + e);
      const c = t.getElementsByTagName('script')[0];
      c.parentNode.insertBefore(r, c);
    };
  /* eslint-enable */

  if (!gainsight.loaded) {
    const apiCode =
      process.env.NODE_ENV === 'production'
        ? GAINSIGHT_ANALYTICS_API_CODE_PROD
        : GAINSIGHT_ANALYTICS_API_CODE_DEV_QA;

    gainsight.load(window, document, 'https://web-sdk-us2.aptrinsic.com/api/aptrinsic.js', apiCode);
  }

  if (!window.aptrinsic) {
    return;
  }

  if (currentUser) {
    const productCode = 'Naviance';
    const { nid, name, state, country, salesforceid } = highschool;
    const district = highschool.district?.district || {};
    const { userId, userType, identity } = getUserDataForAnalytics(currentUser, true);
    const {
      id: districtId,
      name: districtName,
      state: districtState,
      country: districtCountry,
      salesforceid: districtSalesforceId,
    } = district;
    const accountId = districtId || nid;

    /* eslint-disable camelcase */
    if (identity) {
      try {
        window.aptrinsic(
          'identify',
          {
            id: `${productCode}-${accountId}-${userId}`,
            role: userType.toUpperCase().replace(/\s/g, '_'),
          },
          {
            id: `${productCode}-${accountId}`,
            name: districtName || name,
            stateCode: districtState || state,
            countryCode: districtCountry || country,
            [featureFlags.featureStudentGainsightRenameSalesForceId
              ? 'NavianceLegacySalesForceID'
              : 'sfdcId']: districtSalesforceId || salesforceid,
          }
        );
      } catch (error) {
        console.error('Gainsight identify error', error);
      }
    }
    /* eslint-enable camelcase */
  }
};
