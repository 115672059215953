// @flow

import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import s from './styles.scss';
import { getDocumentTitle } from '../../utils/documentTitle';

/*
  These are the intended props for this component.
  Leaving as a comment for future investigation, since Flow decided to be difficult.

  type Props = {
    title?: string,
    children?: React.Node,
    size?: '1' | '2' | '3' | '4' | '5' | '6',
  };
*/
type Props = {
  title?: string,
  documentTitle?: string,
  children?: React.Node,
  size: string,
  className?: string,
  location: {
    pathname: string,
  },
};

@withRouter
export default class PageTitle extends React.Component<Props> {
  static defaultProps = {
    title: '',
    documentTitle: '',
    children: '',
    size: '3',
    headerLevel: 'h1',
    className: '',
    location: {
      pathname: '',
    },
  };

  static displayName = 'PageTitle';

  render() {
    const {
      title,
      documentTitle,
      children,
      size,
      className,
      headerLevel,
      staticContext,
      ...otherProps
    } = this.props;
    const titleInUse: any = documentTitle || title || children;
    const contents = children || title;
    const Header = headerLevel;

    return (
      <DocumentTitle title={getDocumentTitle(titleInUse)}>
        <Header className={cx(`h${size}`, s.pageTitle, className)} {...otherProps}>
          {contents}
        </Header>
      </DocumentTitle>
    );
  }
}
