import React from 'react';
import { RoadtripNationVideo, RoadtripNationData } from 'types/pbChatbotMfe';
import RtnVideo from 'containers/Careers/RoadtripNation/RtnVideo';
import styles from './styles.scss';

interface RoadtripNationVideosProps {
  roadTripNationData: RoadtripNationData;
  className?: string;
}

export function RoadtripNationVideos(
  props: RoadtripNationVideosProps
): React.ReactElement {
  const isValidVideo = (data: RoadtripNationVideo): boolean => {
    // Regex pattern to match URLs with https protocol and video file extensions
    const videoUrlPattern = /^https:\/\/.*\.(mp4|webm|ogg|avi|mov|mkv|flv|wmv)(\?.*)?$/i;

    // Regex pattern to match subtitle file URLs
    const subtitleUrlPattern = /^https?:\/\/.*\.(srt|vtt)(\?.*)?$/i;

    return videoUrlPattern.test(data.url) && subtitleUrlPattern.test(data.captionsUrl);
  };

  return (
    <div>
      {Object.keys(props.roadTripNationData).map((code) => {
        return (
          props.roadTripNationData[code] &&
          props.roadTripNationData[code]?.url &&
          isValidVideo(props.roadTripNationData[code]) && (
            <div
              className={`${props.className} ${styles.videoCard}`}
              data-testid={`pb-rtn-video-card-${code}`}
              key={code}
            >
              <Header {...props.roadTripNationData[code]} />
              <RtnVideo
                nowPlaying={{
                  url: props.roadTripNationData[code].url,
                  thumb: props.roadTripNationData[code].thumbUrl,
                  captionsUrl: props.roadTripNationData[code].captionsUrl,
                }}
                autoPlay={false}
              />
            </div>
          )
        );
      })}
    </div>
  );
}

const Header = ({
  title,
  speakerName,
  jobTitle,
  company,
}: {
  title: string;
  speakerName?: string;
  jobTitle?: string;
  company?: string;
}): React.ReactElement => {
  let subHeader = speakerName;
  if (jobTitle) {
    subHeader = `${speakerName}, ${jobTitle}${company ? ` at ${company}` : ''}`;
  } else if (company) {
    subHeader = `${speakerName} from ${company}`;
  }

  return (
    <>
      <p className={styles.videoHeader}>{title}</p>
      {subHeader && <span className={styles.videoSubHeader}>{subHeader}</span>}
    </>
  );
};
