import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/Accounts/Forgot/NewPassword' /* webpackChunkName: "reset-password" */); // eslint-disable-line prettier/prettier

export default class ResetPasswordRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
