export const ELEMENTARY_TENANT_KEY = 'elementaryTenantURL';

export const REPORT_NAME = 'SummaryReport.pdf';

export const ELEMENTARY_ID = 'ELEMENTARY_SUMMARY_REPORT';

export const DESCRIPTION = 'Elementary Summary Report';

export const ISELEMENTARY = 'isElementaryStudent';

export const HEADED2_TOKEN = 'headed2Token';

/* 
For the below object portfolioSummaryReportSectionFilter, the following properties are available:
showUpTheLadder: true,
showPlans: true,
*/
export const portfolioSummaryReportSectionFilter = {
  showIP: false,
  showWIP: false,
  showSP: false,
  showSkillsAssessment: false,
  showOccupations: false,
  showFlaggedItems: false,
  showHistorical: false,
  showJournals: false,
  showBudgets: false,
  showCourses: false,
  showResumes: false,
  showReferences: false,
  showLetters: false,
  showFiles: false,
};
