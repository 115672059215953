// @flow
import { getHash } from './sha3';

/* eslint-disable */
const dataLayerName = "dataLayer";

const initInlineManual = () => {
  let retries = 0;
  const timer = setInterval(() => {
    if (typeof window.createInlineManualPlayer !== 'undefined' && !window.inline_manual_player) {
      window.createInlineManualPlayer(window.inlineManualPlayerData);
      const inlineManualContainer = document.querySelector(".inmplayer-general");
      if (inlineManualContainer) {
        // Add the landmark role="complementary" for better accessibility
        inlineManualContainer.setAttribute('role', 'complementary');
      }
      clearInterval(timer);
    } else if (retries > 100) {
      clearInterval(timer);
    }
    retries++;
  }, 50);
};

export const updateInlineManual = () => {
    if (window.inline_manual_player) {
        window.inline_manual_player.update();
    }
}

export const prepareInlineManualPlayerData = data => {
  const hash = {
    uid: data.uid ? getHash(data.uid.toString()) : null,
    roles: Array.isArray(data.roles) && data.roles.length > 0 ? data.roles : null,
    userType: data.userType || null,
    group:
      Array.isArray(data.group) && data.group.length > 0
        ? [].concat(data.group).join(',')
        : null,
    username: data.username || null,
    email: data.email || null,
    name: data.name || null,
  };

  // only send those which are non null
  const playerData = {};
  Object.keys(hash).forEach(property => {
    if (hash[property] !== null) {
      playerData[property] = hash[property];
    }
  });
  return playerData;
};

export const enableInlineManual = () => {
  const inlinemanual = document.querySelector('.inmplayer-general');
  if (inlinemanual) {
    inlinemanual.style.display = 'block';
  }
}

export const disableInlineManual = () => {
  const inlinemanual = document.querySelector('.inmplayer-general');
  if (inlinemanual) {
    inlinemanual.style.display = 'none';
  }
}

/**
 * Convert pseudo element to HTML
 * 
 */
 export const convertPseudoElementToHTML = () => {
  // Check inline player info exists
  const inlineManualTriggerInfo = document.querySelector(".inmplayer-trigger-info");
  if(!inlineManualTriggerInfo){
    // Convert inline player info pseudo element to HTML
    const inlineManualTrigger = document.querySelector(".inmplayer-trigger");
    if(inlineManualTrigger){
      const afterElement = window.getComputedStyle(inlineManualTrigger, ':after');
      const span = document.createElement("span");
      span.className = `inmplayer-trigger-info`;
      let content = afterElement?.content?.replace(/["']/g, "");
      if(content != null) {
        span.innerHTML = content;
        inlineManualTrigger.appendChild(span);
        inlineManualTrigger.classList.add("without-after");
      }
    }
  }
}

export default initInlineManual;
