// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { fetchParentStudents, impersonateStudent } from 'modules/parent';
import { getParentId } from 'selectors/auth';
import { getStudentsForCombo } from 'selectors/parent';
import noop from 'utils/noop';

import ComboBox from 'components/ComboBox';
import s from '../../styles.scss';

type Props = {
  students: Object[],
  selection: number,
  impersonateStudent: Function,
  fetchParentStudents: Function,
  parentId: number,
  students: Object[],
};
@connect(
  (state) => ({
    parentId: getParentId(state),
    students: getStudentsForCombo(state),
  }),
  {
    fetchParentStudents,
    impersonateStudent,
  }
)
export default class StudentsDropdown extends React.Component<Props> {
  static defaultProps = {
    fetchParentStudents: noop,
    impersonateStudent: noop,
    parentId: 0,
    students: [],
  };

  UNSAFE_componentWillMount() {
    this.props.fetchParentStudents(this.props.parentId);
  }

  onSelect = (value: string) => {
    this.props.impersonateStudent(value);
  };

  render() {
    const { students, selection } = this.props;
    return (
      <ComboBox
        label="Switch Child:"
        options={students}
        onSelect={this.onSelect}
        noDefault
        invertColor
        inline
        value={selection}
        labelProp="label"
        valueProp="value"
        selectClassName={s.selectCls}
        labelClassName={s.labelCls}
      />
    );
  }
}
