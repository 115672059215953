// @flow
import { createSelector } from 'reselect';
import union from 'lodash/union';
import difference from 'lodash/difference';
import type { AMMatch, LegacyAMMatch } from 'types/colleges';
import type { State } from 'modules/colleges/activeMatchConnectionMatching';

export const getCollegesActiveMatchConnectionMatchingState = (state: Object): State =>
  state.colleges.activeMatchConnectionMatching;

export const getAllAMConnectibleColleges = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): string[] =>
    activeMatchConnectionMatchingState.amConnectibleColleges
);

export const getAmConnectibleMatches = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): AMMatch[] =>
    activeMatchConnectionMatchingState.amConnectibleMatches
);

export const getAmConnectedMatchScids = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): string[] =>
    activeMatchConnectionMatchingState.amConnectedMatchScids
);

export const getAmRecentlyConnectedMatchScids = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): string[] =>
    activeMatchConnectionMatchingState.amRecentlyConnectedMatchScids
);

export const getAmRecentlyDisconnectedMatchScids = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): string[] =>
    activeMatchConnectionMatchingState.amRecentlyDisconnectedMatchScids
);

export const getDisplayAMConnectorPopup = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): boolean =>
    activeMatchConnectionMatchingState.displayAMConnectorPopup
);

export const getDisplayAMDisconnectPopup = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): boolean =>
    activeMatchConnectionMatchingState.displayAMDisconnectPopup
);

export const getAmMatchesInConnector = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): AMMatch[] =>
    activeMatchConnectionMatchingState.amMatchesInConnector
);

export const getAmMatchesToDisconnect = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): AMMatch[] =>
    activeMatchConnectionMatchingState.amMatchesToDisconnect
);

export const getAmLegacyMatchesInConnector = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): LegacyAMMatch[] =>
    activeMatchConnectionMatchingState.amLegacyMatchesInConnector
);

export const getAmLegacyMatchesToDisconnect = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): LegacyAMMatch[] =>
    activeMatchConnectionMatchingState.amLegacyMatchesToDisconnect
);

export const getIsConnectorEnabled = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): boolean =>
    activeMatchConnectionMatchingState.isConnectorEnabled
);

export const getIsConnectorForLegacyEnabled = createSelector(
  [getCollegesActiveMatchConnectionMatchingState],
  (activeMatchConnectionMatchingState: State): boolean =>
    activeMatchConnectionMatchingState.isConnectorForLegacyEnabled
);

export const getAmFilteredConnectedMatchScids = createSelector(
  [getAmConnectedMatchScids, getAmRecentlyConnectedMatchScids, getAmRecentlyDisconnectedMatchScids],
  (amConnectedMatchScids, amRecentlyConnectedMatchScids, amRecentlyDisconnectedMatchScids) =>
    difference(
      union(amConnectedMatchScids, amRecentlyConnectedMatchScids),
      amRecentlyDisconnectedMatchScids
    )
);
