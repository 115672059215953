// @flow
import * as React from 'react';
import cx from 'classnames';
import noop from 'utils/noop';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import s from './styles.scss';

interface Props {
  icon?: string;
  title: string;
  caption?: string;
  onNavigate: () => void; // This likely needs a more detailed type in the future
  noBorder: boolean;
  linkCss: string;
  avatar?: React.ReactNode;
  isLink?: boolean;
  onLinkClick?: () => void;
  linkTo?: string;
}

const Section = ({
  caption,
  title,
  noBorder,
  linkCss,
  icon,
  avatar,
  isLink,
  onLinkClick,
  linkTo,
}: Props) => (
  <div
    className={cx(s.item, linkCss, {
      [s.withBorder]: !noBorder,
    })}
  >
    <section className={s.innerContainer}>
      <h2 className={cx('txt', s.title, 'h2', { xs: !linkCss, title: !!linkCss })}>
        {icon && <Icon icon={icon} className={s.icon} />}
        {avatar && avatar}
        {isLink ? (
          <Link
            style={{ all: 'inherit', cursor: 'pointer' }}
            onClick={onLinkClick}
            to={linkTo}
          >
            {title}
          </Link>
        ) : (
          title
        )}
      </h2>
      {caption ? <div className={cx('txt', s.text, s.caption)}>{caption}</div> : null}
    </section>
  </div>
);

Section.defaultProps = {
  date: null,
  section: null,
  highlighted: false,
  onNavigate: noop,
  caption: null,
  noBorder: false,
  linkCss: '',
};

export default Section;
