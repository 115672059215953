// @flow

import * as React from 'react';
import { Row, Col } from 'components/Grid';
import Icon from 'components/Icon';
import Link from 'components/Link';

import s from './styles.scss';

const toasterContent = {
  text: `You are previewing an improved mobile friendly experience for Naviance Student.
    This experience is turned off for students in your school.
    Your site administrator can turn this on for your students any time.`,
};

const AdminToast = () => (
  <Row className={s.row}>
    <Col lg={2} md={2} xs={12} className={s.asideColumn}>
      <Icon icon="tablet" className={s.mobileIcon} />
      <Icon icon="mobile" className={s.mobileIcon} />
    </Col>
    <Col lg={8} md={8} xs={12} className={s.content}>
      {toasterContent.text}
    </Col>
    <Col lg={2} md={2} xs={12} className={s.asideColumn}>
      <Link className={s.link} to="http://connection.naviance.com/mthighs">
        <Icon icon="rotate-left" className={s.returnIcon} />
        <span>RETURN TO NAVIANCE</span>
      </Link>
    </Col>
  </Row>
);

export default AdminToast;
