import * as React from 'react';
import IfAllowed from 'components/IfAllowed';
import { Redirect } from 'react-router-dom';
import Headed2 from 'containers/Headed2';

export default class JobSearchRoute extends React.Component {
  render() {
    return (
      <IfAllowed
        group="careers"
        target="headed2_job_search"
        whenDenied={() => <Redirect to="/main" />}
      >
        <Headed2 />
      </IfAllowed>
    );
  }
}
