import * as React from 'react';

import Chunk from 'components/Chunk';

const loadInbox = () => import('containers/Inbox' /* webpackChunkName: "inbox" */);  // eslint-disable-line prettier/prettier

export default class InboxRoute extends React.Component {
  render() {
    return <Chunk load={loadInbox} />;
  }
}
