import { getUserDataForAnalytics } from './analytics';

/**
 * Installs heap client-side api in `window.heap` using the official heap snippet.
 * Repeated invocations will install and load the snippet only once, but
 * provided user and highschool information will be applied (or updated) on each
 * invocation.
 */
export const initHeapAnalytics = (currentUser, highschool, enableSimplifiedConfig) => {
  if (enableSimplifiedConfig && !HEAP_ANALYTICS_API_CODE) return;
  /* eslint-disable */
  window.heap = window.heap || [];
  heap.load =
    heap.load ||
    function (e, t) {
      window.heap.appid = e;
      window.heap.config = t = t || {};
      var r = document.createElement('script');
      r.type = 'text/javascript';
      r.async = !0;
      r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';
      var a = document.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(r, a);
      for (
        var n = function (e) {
            return function () {
              heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            'addEventProperties',
            'addUserProperties',
            'clearEventProperties',
            'identify',
            'resetIdentity',
            'removeEventProperty',
            'setEventProperties',
            'track',
            'unsetEventProperty',
          ],
          o = 0;
        o < p.length;
        o++
      )
        heap[p[o]] = n(p[o]);
    };
  /* eslint-enable */
  if (!heap.loaded) {
    if (enableSimplifiedConfig) {
      heap.load(HEAP_ANALYTICS_API_CODE);
    } else {
      const apiCode =
        process.env.NODE_ENV === 'production'
          ? HEAP_ANALYTICS_API_CODE_PROD
          : HEAP_ANALYTICS_API_CODE_DEV;
      heap.load(apiCode);
    }
  }
  if (currentUser) {
    const { userType, identity } = getUserDataForAnalytics(currentUser);

    // Guests could not be identified.
    if (identity) {
      heap.identify(identity);
    }

    /* eslint-disable camelcase */
    heap.addUserProperties({
      External_ID: currentUser.highSchoolId,
      Type: userType.toUpperCase().replace(/\s/g, '_'),
      Grade: currentUser.grade,
      Salesforce_Account_ID_District:
        highschool &&
        highschool.district &&
        highschool.district.district &&
        highschool.district.district.salesforceid,
      Salesforce_Account_ID_School: highschool ? highschool.salesforceid : undefined,
    });
  }
};
