// @flow
import constantsGenerator from 'utils/constantsGenerator';
import type { CollegeLookingForStudentsLikeMe, SaveStudentInfoPayload } from 'types/colleges';
import api from 'config/api';

const generateConstants = constantsGenerator('fc/colleges/lookingForStudentsLikeYou');

export const clickTypes = {
  ADD_TO_LIST: 1,
  FILTER_VIEW: 2,
  NOT_INTERESTED: 3,
  REQUEST_INFO: 5,
  CONNECTOR_NO: 6,
  CONNECTOR_CONNECT: 7,
  CONNECTOR_NOT_NOW: 8,
  CONNECTOR_SHOWN: 9,
  CONNECTOR_DISCONNECT: 10,
};

export const disconnectTypes = {
  PROCESSING: 0,
  DISCONNECTED: 1,
  CONNECTED: 2,
  NO_THANKS: 3,
};

export const [
  GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME,
  GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_SUCCESS,
  GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_FAILURE,
]: string[] = generateConstants('getCollegesLookingForStudentsLikeYou');

export const [
  GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME,
  GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_SUCCESS,
  GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_FAILURE,
]: string[] = generateConstants('getUnfilteredCollegesLookingForStudentsLikeYou');

export const [
  REGISTER_CLICK,
  REGISTER_CLICK_SUCCESS,
  REGISTER_CLICK_FAILURE,
]: string[] = generateConstants('registerClick');

export const [
  BLACKLIST_COLLEGE,
  BLACKLIST_COLLEGE_SUCCESS,
  BLACKLIST_COLLEGE_FAILURE,
]: string[] = generateConstants('blacklistCollege');

const [
  SAVE_STUDENT_INFO,
  SAVE_STUDENT_INFO_SUCCESS,
  SAVE_STUDENT_INFO_FAILURE,
]: string[] = generateConstants('blacklistCollege');

export const [
  GET_BLACKLIST_COLLEGES,
  GET_BLACKLIST_COLLEGES_SUCCESS,
  GET_BLACKLIST_COLLEGES_FAILURE,
]: string[] = generateConstants('blacklistedColleges');

const CHANGE_DISPLAY_CONNECTOR_POPUP =
  'fc/colleges/lookingForStudentsLikeYou/displayConnectorPopup';
const CHANGE_COLLEGES_IN_POPUP = 'fc/colleges/lookingForStudentsLikeYou/changeCollegesInPopup';
const CHANGE_COLLEGES_WITH_CONNECTOR =
  'fc/colleges/lookingForStudentsLikeYou/changeCollegesWithConnector';
const CHANGE_COLLEGE_TO_DISCONNECT =
  'fc/colleges/lookingForStudentsLikeYou/changeCollegeToDisconnect';
const CHANGE_MATCHING_COLLEGE_TO_DISCONNECT =
  'fc/colleges/lookingForStudentsLikeYou/changeMatchingCollegeToDisconnect';
const CHANGE_DISPLAY_DISCONNECT_POPUP =
  'fc/colleges/lookingForStudentsLikeYou/changeDisplayDisconnectPopup';

export type State = {
  loading: boolean,
  matchingColleges: CollegeLookingForStudentsLikeMe[],
  collegesInPopup: CollegeLookingForStudentsLikeMe[],
  unfilteredMatchingColleges: CollegeLookingForStudentsLikeMe[],
  blacklistedColleges: number[],
  displayConnectorPopup: boolean,

  collegesToDisconnect: CollegeLookingForStudentsLikeMe[],
  displayDisconnectPopup: boolean,
  collegesWithConnector: Object[],
  matchingCollegesToDisconnect: Object[],
};

const initialState: State = {
  loading: false,
  matchingColleges: [],
  unfilteredMatchingColleges: [],
  blacklistedColleges: [],
  displayConnectorPopup: false,
  collegesInPopup: [],
  collegesToDisconnect: [],
  displayDisconnectPopup: false,
  collegesWithConnector: [],
  matchingCollegesToDisconnect: [],
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        matchingColleges: action.result.colleges,
      };
    case GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_FAILURE:
      return {
        ...state,
        loading: false,
        matchingColleges: [],
      };
    case GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME:
      return {
        ...state,
        loading: true,
      };
    case GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        unfilteredMatchingColleges: action.result.colleges,
      };
    case GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_FAILURE:
      return {
        ...state,
        loading: false,
        unfilteredMatchingColleges: [],
      };
    case BLACKLIST_COLLEGE:
      return {
        ...state,
        loading: true,
      };
    case BLACKLIST_COLLEGE_SUCCESS:
    case BLACKLIST_COLLEGE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_BLACKLIST_COLLEGES:
      return {
        ...state,
        loading: true,
      };
    case GET_BLACKLIST_COLLEGES_SUCCESS:
      return {
        ...state,
        loading: false,
        blacklistedColleges: action.result,
      };
    case GET_BLACKLIST_COLLEGES_FAILURE:
      return {
        ...state,
        loading: false,
        blacklistedColleges: [],
      };
    case REGISTER_CLICK:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_CLICK_SUCCESS:
    case REGISTER_CLICK_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SAVE_STUDENT_INFO:
      return {
        ...state,
        loading: true,
      };
    case SAVE_STUDENT_INFO_SUCCESS:
    case SAVE_STUDENT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_DISPLAY_CONNECTOR_POPUP:
      return {
        ...state,
        displayConnectorPopup: action.displayPopup,
      };
    case CHANGE_COLLEGES_IN_POPUP:
      return {
        ...state,
        collegesInPopup: action.collegesInPopup,
      };
    case CHANGE_COLLEGE_TO_DISCONNECT:
      return {
        ...state,
        collegesToDisconnect: action.collegesToDisconnect,
      };
    case CHANGE_DISPLAY_DISCONNECT_POPUP:
      return {
        ...state,
        displayDisconnectPopup: action.displayDisconnectPopup,
      };
    case CHANGE_COLLEGES_WITH_CONNECTOR:
      return {
        ...state,
        collegesWithConnector: action.collegesWithConnector,
      };
    case CHANGE_MATCHING_COLLEGE_TO_DISCONNECT:
      return {
        ...state,
        matchingCollegesToDisconnect: action.matchingCollegesToDisconnect,
      };
    default:
      return state;
  }
}

export const fetchMatchingColleges = () => ({
  types: [
    GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME,
    GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_SUCCESS,
    GET_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_FAILURE,
  ],
  promise: (client: Object) =>
    client.get(`${api.colleges}/matches/looking-for-students-like-you?filterApplications=true`),
});

export const fetchUnfilteredMatchingColleges = () => ({
  types: [
    GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME,
    GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_SUCCESS,
    GET_UNFILTERED_COLLEGES_LOOKING_FOR_STUDENTS_LIKE_ME_FAILURE,
  ],
  promise: (client: Object) =>
    client.get(`${api.colleges}/matches/looking-for-students-like-you?filterApplications=false`),
});

export const fetchBlacklistedColleges = () => ({
  types: [GET_BLACKLIST_COLLEGES, GET_BLACKLIST_COLLEGES_SUCCESS, GET_BLACKLIST_COLLEGES_FAILURE],
  promise: (client: Object) =>
    client.get(`${api.colleges}/matches/looking-for-students-like-you/blacklist`),
});

export const blacklistCollege = (id: string) => ({
  types: [BLACKLIST_COLLEGE, BLACKLIST_COLLEGE_SUCCESS, BLACKLIST_COLLEGE_FAILURE],
  promise: (client: Object) =>
    client.post(`${api.colleges}/matches/looking-for-students-like-you/blacklist/${id}`),
});

export const saveStudentInfo = (data: SaveStudentInfoPayload) => ({
  types: [BLACKLIST_COLLEGE, BLACKLIST_COLLEGE_SUCCESS, BLACKLIST_COLLEGE_FAILURE],
  promise: (client: Object) =>
    client.post(`${api.colleges}/matches/looking-for-students-like-you/student`, {
      data,
    }),
});

export const registerClick = (clickTypeId: number, orderFilterId: number) => ({
  types: [REGISTER_CLICK, REGISTER_CLICK_SUCCESS, REGISTER_CLICK_FAILURE],
  promise: (client: Object) =>
    client.post(`${api.colleges}/matches/looking-for-students-like-you/click/${clickTypeId}`, {
      data: {
        orderFilterId,
      },
    }),
});

export const changeDisplayConnectorPopup = (displayPopup: boolean) => ({
  type: CHANGE_DISPLAY_CONNECTOR_POPUP,
  displayPopup,
});

export const changeCollegesInPopup = (collegesInPopup: CollegeLookingForStudentsLikeMe) => ({
  type: CHANGE_COLLEGES_IN_POPUP,
  collegesInPopup,
});

export const changeCollegesToDisconnect = (collegesToDisconnect: Object) => ({
  type: CHANGE_COLLEGE_TO_DISCONNECT,
  collegesToDisconnect,
});

export const changeDisplayDisconnectPopup = (displayDisconnectPopup: boolean) => ({
  type: CHANGE_DISPLAY_DISCONNECT_POPUP,
  displayDisconnectPopup,
});

export const changeCollegesWithConnector = (collegesWithConnector: Object) => ({
  type: CHANGE_COLLEGES_WITH_CONNECTOR,
  collegesWithConnector,
});

export const changeMatchingCollegesToDisconnect = (matchingCollegesToDisconnect: Object) => ({
  type: CHANGE_MATCHING_COLLEGE_TO_DISCONNECT,
  matchingCollegesToDisconnect,
});
