import * as React from 'react';
import { connect } from 'react-redux';
import { getSessionExpirationDate } from 'selectors/auth';
import { logout, extendSession } from 'modules/auth';
import { getHsid } from 'selectors/highschool';
import { setHsid } from 'modules/highschool';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import ExtendSessionModal from '../ExtendSessionModal';
import { FIVE_MINUTES, getSecondsTillExpiration } from '../index';

interface Props extends RouteComponentProps {
  sessionExpiration: Date;
  hsid: string;
  logout: () => Promise<any>;
  setHsid: (hsid: string) => Promise<any>;
  extendSession: () => Promise<any>;
}

interface State {
  showModal: boolean;
  secondsRemaining: number;
}

class LegacyExtendSessionModal extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { sessionExpiration } = nextProps;

    if (!sessionExpiration || sessionExpiration.toString() === 'Invalid Date') {
      return { showModal: false };
    }

    const secondsTillExpiration = getSecondsTillExpiration(nextProps.sessionExpiration);

    if (!prevState.showModal && secondsTillExpiration <= FIVE_MINUTES) {
      return { showModal: true, secondsRemaining: secondsTillExpiration };
    }

    return null;
  }

  state = {
    secondsRemaining: 0,
    showModal: false,
  };

  intervalId: NodeJS.Timeout;

  componentDidUpdate(prevProps) {
    if (this.intervalId) {
      return;
    }

    const isValidPreviousDate: boolean = moment(prevProps.sessionExpiration).isValid();
    const isValidDate: boolean = moment(this.props.sessionExpiration).isValid();

    if (isValidDate && !isValidPreviousDate) {
      this.intervalId = setInterval(() => {
        const secondsRemaining = getSecondsTillExpiration(this.props.sessionExpiration);

        if (secondsRemaining <= 0) {
          this.logOut();
        }

        this.setState({ secondsRemaining });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  getFormattedTimeRemaining = (secondsRemaining: number) => {
    const minutes = Math.floor(secondsRemaining / 60);
    const seconds = secondsRemaining - minutes * 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${minutes}:${formattedSeconds}`;
  };

  logOut = async () => {
    clearInterval(this.intervalId);
    this.intervalId = undefined;

    const hsid = this.props.hsid;
    await this.props.logout();

    this.props.setHsid(hsid);
    this.props.history.push(`/${hsid}`);
  };

  extendSession = async () => {
    await this.props.extendSession();
    this.setState({ showModal: false });
  };

  render() {
    const { showModal, secondsRemaining } = this.state;
    return (
      <ExtendSessionModal
        showModal={showModal}
        onExtendSession={this.extendSession}
        onLogOut={this.logOut}
        secondsRemaining={secondsRemaining}
      />
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      sessionExpiration: getSessionExpirationDate(state),
      hsid: getHsid(state),
    }),
    { logout, setHsid, extendSession }
  )(LegacyExtendSessionModal)
);
