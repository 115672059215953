// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import withFeatureFlag from 'components/withFeatureFlag';
import ComboBox from 'components/ComboBoxNeon';
import { fetchDemoStudent } from 'modules/counselor';
import { getCurrentUserGrade } from 'selectors/auth';
import { getHighSchool } from 'selectors/highschool';
import type { State as School } from 'modules/highschool';

import noop from 'utils/noop';
import s from '../../styles.scss';

type Props = {
  grade: number,
  fetchDemoStudent: Function,
  highschool: School,
};

@withFeatureFlag('demoStudent')
@connect(
  (state) => ({
    grade: getCurrentUserGrade(state),
    highschool: getHighSchool(state),
  }),
  { fetchDemoStudent }
)
export default class SwitchGradeDropdown extends React.Component<Props> {
  static defaultProps = {
    grade: 0,
    fetchDemoStudent: noop,
    highschool: {
      hsid: null,
      nid: null,
      alias: null,
      name: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      country: null,
      zip: null,
      phone: null,
      fax: null,
      url: null,
      graphDisclaimer: null,
      loading: false,
      options: {},
      milestones: null,
      languages: [],
      preferredLanguageId: '',
      highestGrade: 12,
      lowestGrade: 1,
    },
  };

  onSelect = (value: string) => {
    this.props.fetchDemoStudent(Number(value));
  };

  getGrades = () => {
    const { highschool } = this.props;
    const { highestGrade, lowestGrade } = highschool;

    let grades = [];
    for (let i = lowestGrade; i <= highestGrade; i++) {
      grades.push({ value: i, label: `${i}th Grade` });
    }

    grades = [
      ...grades,
      {
        value: 0,
        label: 'Demo',
      },
      {
        value: 14,
        label: 'Guest',
      },
    ];
    /* eslint-disable indent */
    return highestGrade !== 12
      ? grades
      : [
          ...grades,
          {
            value: 13,
            label: 'Alumni',
          },
        ];
  };

  render() {
    const { grade } = this.props;

    return (
      <ComboBox
        label="Switch Grade:"
        options={this.getGrades()}
        onSelect={this.onSelect}
        noDefault
        invertColor
        inline
        value={grade}
        labelProp="label"
        valueProp="value"
        selectClassName={s.selectCls}
        labelClassName={s.labelCls}
      />
    );
  }
}
