// @flow
import * as React from 'react';
import cx from 'classnames';
import s from './styles.scss';

type Props = {
  read: string,
  showOnTablet?: boolean,
};

/**
  Screenreader component
  This component will put an invisible span on the page that will be picked up
  by screen readers.

  Example usage:
  * When a link opens in a new window, we want to let the user know
  that a new window will be opened
  * When we want screen reader to describe an image or ascii art

  <Link data-test-id={id} to={to}>
    {label}
    {isExternal && <ScreenReader read="Opens in new window" />}
  </Link>
*/

const ScreenReader = ({ read, showOnTablet }: Props) =>
  read ? (
    <span className={cx('visuallyhidden', { [s.showOnTablet]: showOnTablet })}>{read}</span>
  ) : null;

ScreenReader.defaultProps = {
  showOnTablet: false,
};

export default ScreenReader;
