// @flow
import api from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import { injectAsyncReducers } from 'store';
import type { FeatureFlags } from 'types/featureFlags';

const generateConstants = constantsGenerator('fc/featureFlags');

const [
  GET_FEATURE_FLAGS,
  GET_FEATURE_FLAGS_SUCCESS,
  GET_FEATURE_FLAGS_FAIL,
]: string[] = generateConstants('GET_FEATURE_FLAGS');

const [
  GET_FEATURE_FLAGS_HSID,
  GET_FEATURE_FLAGS_HSID_SUCCESS,
  GET_FEATURE_FLAGS_HSID_FAIL,
]: string[] = generateConstants('GET_FEATURE_FLAGS_HSID');

export type State = {
  flags: FeatureFlags,
  loaded: boolean,
  hsIdLoaded: boolean,
};

const initialState: State = {
  flags: {},
  loaded: false,
  hsIdLoaded: false,
};

export function filterByFlags(flags: Object) {
  return (item: Object) => {
    const featureFlag = flags[item.featureFlag];

    return featureFlag === undefined || featureFlag === true;
  };
}

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_FEATURE_FLAGS_HSID_SUCCESS:
      return {
        ...state,
        hsIdLoaded: true,
        flags: action.result,
      };
    case GET_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        loaded: true,
        flags: action.result,
      };
    default:
      return state;
  }
}

export function fetchFeatureFlags(hsid: ?string) {
  let customFeature = '';
  const featureByLS = localStorage.getItem('navFeatureByLS');
  if (featureByLS) {
    // user segmentation by local storage
    customFeature = `?customUserFeature=${encodeURIComponent(featureByLS)}`;
  }

  if (hsid) {
    return {
      types: [GET_FEATURE_FLAGS_HSID, GET_FEATURE_FLAGS_HSID_SUCCESS, GET_FEATURE_FLAGS_HSID_FAIL],
      promise: (client: Object) => client.get(`${api.host}/featureFlags/${hsid}${customFeature}`),
    };
  }
  return {
    types: [GET_FEATURE_FLAGS, GET_FEATURE_FLAGS_SUCCESS, GET_FEATURE_FLAGS_FAIL],
    promise: (client: Object) => client.get(`${api.host}/featureFlags${customFeature}`),
  };
}

injectAsyncReducers({ featureFlags: reducer });
