import * as React from 'react';
import Script from 'react-load-script';
import { inlineManualUrl } from 'config/api';
import initInlineManual, { prepareInlineManualPlayerData } from 'utils/inlineManual';

declare global {
  interface Window {
    inlineManualTracking: any;
  }
}

interface Props {
  trackingData?: any;
  enablePeopleTracking?: boolean;
}

export default class InlineManual extends React.Component<Props> {
  static defaultProps = {
    trackingData: {},
    enablePeopleTracking: false,
  };

  constructor(props) {
    super(props);
    // load Player Tracking Data into window before the script is mounted,
    // otherwise, Inline Manual will not pick the data up
    const { enablePeopleTracking, trackingData } = this.props;
    if (enablePeopleTracking && trackingData && Object.keys(trackingData).length > 0) {
      window.inlineManualTracking = { ...prepareInlineManualPlayerData(trackingData) };
    }
  }

  render() {
    return <Script url={inlineManualUrl} onLoad={() => initInlineManual()} />;
  }
}
