import * as React from 'react';

import Chunk from 'components/Chunk';

const loadCareers = () => import('containers/Careers' /* webpackChunkName: "careers" */); // eslint-disable-line prettier/prettier

export default class CareersRoute extends React.Component {
  render() {
    return <Chunk load={loadCareers} {...this.props} />;
  }
}
