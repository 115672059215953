// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import expandObject from 'utils/expandObject';
import { union } from 'utils/normalizeItems';
import type { LookupTerm } from 'types/colleges';
import collectIds from 'utils/collectIds';
import { notify } from 'modules/notification';
import { searchConsidering } from './considering';
import { CLEAR_COLLEGES } from './constants';

const generateConstants = constantsGenerator('fc/colleges');

const [GET_COLLEGES, GET_COLLEGES_SUCCESS, GET_COLLEGES_FAIL]: string[] = generateConstants(
  'GET_COLLEGES'
);
const [NEXT_PAGE, NEXT_PAGE_SUCCESS, NEXT_PAGE_FAIL]: string[] = generateConstants(
  'SEARCH_NEXT_PAGE'
);
const SET_CONTACT_EMAIL_INFO = 'fc/colleges/SET_CONTACT_EMAIL_INFO';
export const ON_EDIT_MAIL = 'fc/colleges/ON_EDIT_MAIL';
const SET_RESULT_FILTERS = 'fc/colleges/SET_FILTER';
const SET_HEADER_SEARCH = 'fc/colleges/SET_HEADER_SEARCH';
const [
  GET_COLLEGE_OPTIONS,
  GET_COLLEGE_OPTIONS_SUCCESS,
  GET_COLLEGE_OPTIONS_FAIL,
]: string[] = generateConstants('GET_COLLEGE_OPTIONS');
const [SEND_MAIL, SEND_MAIL_SUCCESS, SEND_MAIL_FAIL]: string[] = generateConstants('SEND_MAIL');
const SET_LOOKUP_TERM = 'fc/colleges/SET_LOOKUP_TERM';

type State = {
  results: Array<Object>,
  entities: Object,
  totalItems: number,
  page: number,
  filters: { [string]: Array<string> },
  options: Object,
  loading: boolean,
  optionsLoaded: boolean,
  pickedCollegeIds: Array<string>,
  headerSearchKeyword: string,
  contactEmailInfo: Object,
  navigatedToEmailFrom: string,
  contactEmail: {
    subject: string,
    text: string,
  },
  lookupTerm: LookupTerm,
};

export const lookupTypes = {
  keyword: 'Keyword',
  country: 'Country',
  state: 'State',
  group: 'College group',
  name: 'Name (A-Z)',
  quicklist: 'Quicklist',
};

const initialState: State = {
  results: [],
  entities: {},
  totalItems: 0,
  page: 1,
  filters: {
    /* state: ['CA'] */
  },
  options: {},
  loading: false,
  optionsLoaded: false,
  pickedCollegeIds: [],
  headerSearchKeyword: '',
  contactEmailInfo: {},
  navigatedToEmailFrom: '',
  contactEmail: {
    subject: '',
    text: '',
  },
  lookupTerm: {
    type: '',
    term: '',
  },
};

const normalizeItems = (res) =>
  res.data.reduce((items, item) => {
    if (item.id) {
      items[item.id] = {
        ...item,
        considering: item.prospectiveApplications && !!item.prospectiveApplications.length,
      };
    }
    return items;
  }, {});

/**
 * Create filter
 * For now we will only filter by location, so we overwrite what we just did above
 */
function doFilter(action) {
  const { location } = action.filter;
  const states = location.state;

  return states && states.length ? { state: states } : {};
}

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_COLLEGES:
    case GET_COLLEGE_OPTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLEGES_SUCCESS:
      return {
        ...state,
        page: 1,
        totalItems: action.result.totalItems,
        results: collectIds(expandObject(action, 'result.data')) || [],
        entities: { ...state.entities, ...normalizeItems(action.result) },
        loading: false,
      };
    case NEXT_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        page: expandObject(action, 'result.page') || 1,
        results: [...state.results.concat(collectIds(expandObject(action, 'result.data')) || [])],
        entities: union(state.entities, expandObject(action, 'result.data')),
      };
    case GET_COLLEGES_FAIL:
    case GET_COLLEGE_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_RESULT_FILTERS:
      return {
        ...state,
        filters: doFilter(action),
      };
    case GET_COLLEGE_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.result,
      };
    case CLEAR_COLLEGES:
      return {
        ...state,
        page: 1,
        totalItems: 0,
        results: [],
        entities: {},
        pickedCollegeIds: [],
      };
    case SET_CONTACT_EMAIL_INFO:
      return {
        ...state,
        contactEmailInfo: action.data || {},
        navigatedToEmailFrom: action.from,
      };
    case ON_EDIT_MAIL:
      return {
        ...state,
        contactEmail: {
          ...state.contactEmail,
          [action.field]: action.value,
        },
      };
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        contactEmail: { ...initialState.contactEmail },
        navigatedToEmailFrom: '',
        contactEmailInfo: {},
      };
    case SET_HEADER_SEARCH:
      return {
        ...state,
        headerSearchKeyword: action.keyword,
      };
    case SET_LOOKUP_TERM:
      return {
        ...state,
        lookupTerm: action.lookupTerm,
      };
    default:
      return state;
  }
}

export function clear() {
  return {
    type: CLEAR_COLLEGES,
  };
}

// cache previous query for pagination
let lastQuery: *;
let lastQuery2: *;

// remember last page we loaded. Set it to -1 so it's immediately invalidated
let lastPage: number = -1;

// how many pages per request?
const limit = Number(LAZY_PAGE_SIZE);

export function search(
  query: ?Object = lastQuery,
  query2: ?Object = lastQuery2,
  lastIndex: number = 0,
  includeSearchConsidering: boolean = true
) {
  return (dispatch: Function, getState: Function) => {
    const { totalItems } = getState().colleges.main;
    const totalResults = getState().colleges.main.results.length;

    // if last rendered index has passed half of a page, then increment page by one (round up)
    const nextPage = Math.ceil(lastIndex / limit) + 1;

    // however, if this is the last page we loaded, then ignore
    if (
      (totalResults === totalItems && query === lastQuery && query2 === lastQuery2) ||
      (lastPage >= nextPage && query === lastQuery && query2 === lastQuery2) ||
      (lastPage > 0 && query === lastQuery && query2 === lastQuery2 && totalItems === lastIndex)
    ) {
      // $FlowFixMe
      return Promise.resolve(true);
    }

    // set pagination filters
    const filters = [`limit=${limit}`, `page=${nextPage}`];

    // remember this page for next time
    lastPage = nextPage;

    // remember this query for future pagination
    lastQuery = query;
    lastQuery2 = query2;

    if (query && Object.keys(query).length) {
      filters.push(`filter=${encodeURIComponent(JSON.stringify(query))}`);
    }

    if (query2 && Object.keys(query2).length) {
      filters.push(`search=${encodeURIComponent(JSON.stringify(query2))}`);
    }

    const queryString = filters.join('&');

    const actions =
      nextPage > 1
        ? [NEXT_PAGE, NEXT_PAGE_SUCCESS, NEXT_PAGE_FAIL]
        : [GET_COLLEGES, GET_COLLEGES_SUCCESS, GET_COLLEGES_FAIL];

    if (includeSearchConsidering && nextPage === 1) {
      dispatch(searchConsidering());
    }
    return dispatch({
      types: actions,
      promise: (client: Object) => client.get(`${api.colleges}/search?${queryString}`),
    });
  };
}

export function searchById(id?: string = '') {
  const nameFilter = `{"college.id":"'${id}'"}`;

  return (dispatch: Function) =>
    dispatch({
      types: [GET_COLLEGES, GET_COLLEGES_SUCCESS, GET_COLLEGES_FAIL],
      promise: (client: Object) =>
        client.get(
          `${api.colleges}/search?filter=${encodeURIComponent(
            nameFilter
          )}&limit=${FETCH_MAX_RESULTS}`
        ),
    });
}

export function setFilters(filter: string) {
  return {
    type: SET_RESULT_FILTERS,
    filter,
  };
}

export function setContactEmailInfo(data?: Object, from: string) {
  return {
    type: SET_CONTACT_EMAIL_INFO,
    data,
    from,
  };
}

export function onEditMail(field: string, value: string) {
  return {
    type: ON_EDIT_MAIL,
    field,
    value,
  };
}

type MailData = { subject: string, text: string };

export function onSendMail(data: MailData, to: string) {
  return {
    types: [SEND_MAIL, SEND_MAIL_SUCCESS, SEND_MAIL_FAIL],
    promise: (client: Object) =>
      client.post(`${api.colleges}/contact`, {
        data: {
          subject: data.subject,
          text: data.text,
          to,
        },
      }),
  };
}

export const sendEmailWithValidation = (data: MailData, to: string) => (dispatch: Function) =>
  dispatch(onSendMail(data, to))
    .then(() =>
      dispatch(
        notify({
          type: 'success',
          title: 'Success',
          content: `Your e-mail has been successfully sent!`,
        })
      )
    )
    .catch((e) => {
      dispatch(
        notify({
          type: 'danger',
          title: `Error: ${expandObject(e, 'error.id') || ''}`,
          content: expandObject(e, 'error.error'),
        })
      );
      return Promise.reject();
    });

export function setHeaderSearch(keyword: string) {
  return {
    type: SET_HEADER_SEARCH,
    keyword,
  };
}

export const setLookupTerm = (lookupTerm: LookupTerm) => ({
  type: SET_LOOKUP_TERM,
  lookupTerm,
});
