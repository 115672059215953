// @flow
import noop from 'utils/noop';
import normalizeItems from 'utils/normalizeItems';
import expandObject from 'utils/expandObject';
import { CLEAR_COLLEGES } from './constants';
import { SET_PICKED_COLLEGES_SUCCESS } from './considering';

const SET_SELECTIONS = 'fc/lookup/SELECT_COLLEGE';
const DELETE_SELECTION = 'fc/lookup/DELETE_SELECTION';
const CLEAR_LOOKUP = 'fc/lookup/CLEAR_LOOKUP';
const SET_REDIRECT = 'fc/lookup/SET_REDIRECT';
const CLEAR_REDIRECT = 'fc/lookup/CLEAR_REDIRECT';
const ADD_TEMPORARY_APPLICATION = 'fc/lookup/ADD_TEMPORARY_APPLICATION';
const CLEAR_TEMPORARY_APPLICATIONS = 'fc/lookup/CLEAR_TEMPORARY_APPLICATIONS';
const SET_TEMPORARY_COUNTER = 'fc/lookup/SET_TEMPORARY_COUNTER';
const CHANGE_TEMPORARY_APPLICATION = 'fc/lookup/CHANGE_TEMPORARY_APPLICATION';

type State = {
  selectedColleges: Array<string>,
  results: Array<Object>,
  entities: Object,
  redirectURL: string,
  resultsAreTemporary: boolean,
  resultFollowup: Function,
  temporaryItems: Array<Object>,
  temporaryIdCounter: number,
};

const initialState: State = {
  selectedColleges: [],
  results: [],
  entities: {},
  temporaryItems: [],
  redirectURL: '',
  resultsAreTemporary: false,
  resultFollowup: noop,
  temporaryIdCounter: 0,
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case SET_SELECTIONS: {
      const data = action.payload;
      const results = [...state.results, ...data.ids].filter((el, i, a) => i === a.indexOf(el));
      return {
        ...state,
        results,
        entities: { ...data.selected, ...state.entities },
      };
    }
    case SET_PICKED_COLLEGES_SUCCESS: {
      const colleges = expandObject(action, 'result.data');
      return {
        ...state,
        entities: normalizeItems(Object.keys(colleges).map((key) => colleges[key])),
      };
    }
    case DELETE_SELECTION: {
      const data = action.payload;
      const entitiesS = state.entities;
      const results = state.results.filter((id) => id !== data.id);
      const entities = Object.keys(entitiesS).reduce(
        (acc, key) => (key !== data.id ? { ...acc, [key]: entitiesS[key] } : acc),
        {}
      );
      return {
        ...state,
        results,
        entities,
      };
    }
    case CLEAR_COLLEGES:
    case CLEAR_LOOKUP:
      return {
        ...state,
        results: [],
        entities: {},
      };
    case SET_REDIRECT:
      return {
        ...state,
        redirectURL: action.payload.url,
        resultsAreTemporary: action.payload.resultsAreTemporary,
        resultFollowup: action.payload.resultFollowup,
      };
    case CLEAR_REDIRECT:
      return {
        ...state,
        redirectURL: '',
        resultsAreTemporary: false,
        resultFollowup: noop,
      };
    case ADD_TEMPORARY_APPLICATION:
      return {
        ...state,
        temporaryItems: state.temporaryItems.concat(action.item),
      };
    case CHANGE_TEMPORARY_APPLICATION:
      return {
        ...state,
        temporaryItems: state.temporaryItems.map((item) =>
          item.applicationId === action.application.applicationId ? action.application : item
        ),
      };
    case CLEAR_TEMPORARY_APPLICATIONS:
      return {
        ...state,
        temporaryItems: [],
      };
    case SET_TEMPORARY_COUNTER:
      return {
        ...state,
        temporaryIdCounter: action.counter,
      };
    default:
      return state;
  }
}

export function setSelections(ids: Array<string>, selected: Object) {
  return {
    type: SET_SELECTIONS,
    payload: {
      ids,
      selected,
    },
  };
}
export function deleteSelection(id: string) {
  return {
    type: DELETE_SELECTION,
    payload: {
      id,
    },
  };
}

export function clearLookup() {
  return {
    type: CLEAR_LOOKUP,
  };
}

export function setRedirect(
  url: string,
  resultsAreTemporary?: boolean,
  resultFollowup?: (results: Array<Object>) => any
) {
  return {
    type: SET_REDIRECT,
    payload: {
      url,
      resultsAreTemporary,
      resultFollowup,
    },
  };
}

export function addTemporaryApplication(application: Object) {
  return {
    type: ADD_TEMPORARY_APPLICATION,
    item: application,
  };
}

export function clearTemporaryApplications() {
  return {
    type: CLEAR_TEMPORARY_APPLICATIONS,
  };
}

export function clearRedirect() {
  return {
    type: CLEAR_REDIRECT,
  };
}

export function setTemporaryApplicationCounter(counter: number) {
  return {
    type: SET_TEMPORARY_COUNTER,
    counter,
  };
}

export function changeTemporaryApplication(application: Object) {
  return {
    type: CHANGE_TEMPORARY_APPLICATION,
    application,
  };
}
