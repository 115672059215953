// @flow
/* eslint-disable import/prefer-default-export */

/**
 * Formats label to ID
 *
 * Baseball -> baseball
 * Canoe And Kayak => canoe_and_kayak
 *
 */
export function formatIds(entity: any) {
  const str = typeof entity !== 'string' ? String(entity) : entity;

  return str.replace(/\s+/g, '_').toLowerCase();
}
