// @flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';

import ls from 'utils/localStorage';

export default class RedirectToUrlForDeepLinking extends React.Component {
  componentDidMount() {
    ls.removeItem('loginRedirectUrlForDeepLinking');
  }

  render() {
    return <Redirect to={this.props.url || '/main'} />;
  }
}
