import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/Errors' /* webpackChunkName: "errors" */);  // eslint-disable-line prettier/prettier

export default class ErrorsRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
