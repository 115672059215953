// @flow
import expandObject from './expandObject';

type ObjectMap = {
  [any]: Object,
};

/**
 * Converts array items into hash-map structure
 * @example
 *   const items = [{ id: '1', name: 'My Item1' },{ id: '2', name: 'My Item2' }];
 *   normalizeItems(items);
 *   // => {'1': {id: '1', name: 'My Item1'}, '2': {id: '2', name: 'My Item2'}}
 * @param {Array<Object>} items - list of items
 * @param {string} idName - name of field which represent ID
 * @return {Object}
 */
export default function normalizeItems(items: Array<Object>, idName: string = 'id'): ObjectMap {
  return items.reduce((acc, item) => {
    acc[item[idName]] = item;

    return acc;
  }, {});
}

/**
 * Normalize items and add them to a previous set of entities
 */
export const union = (original: ObjectMap, toAdd: Object[], idName: string = 'id'): ObjectMap => ({
  ...original,
  ...normalizeItems(
    (toAdd || []).filter(
      (item: Object) => item[idName] !== expandObject(original, `${item[idName]}.${idName}`)
    )
  ),
});
