// @flow

// how fresh should data be by default, in ms
const freshness = 10 * 60 * 1000; // 10 minutes

// tell us if we timestamp is no longer fresh
const isOld = (timestamp: number, fresh: number = freshness): boolean =>
  Date.now() - timestamp > fresh;

export default isOld;
