// @flow
import * as React from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import noop from 'utils/noop';
import cx from 'classnames';
import s from './styles.scss';

type Props = {
  onDismiss: () => void,
};

export default class DownloadErrorModal extends React.PureComponent<Props> {
  static defaultProps = {
    onConfirm: noop,
    onCancel: noop,
  };

  handleDismiss = () => {
    this.props.onDismiss();
  };

  render() {
    return (
      <Modal isOpen onCancel={this.handleDismiss} variation={Modal.variation.white}>
        <header>
          <h3>Sorry, something went wrong.</h3>
          <span onClick={this.handleDismiss} className={cx(s.topright, s.closeButton)}>
            &times;
          </span>
        </header>
        <main>Please try after sometime.</main>
        <footer>
          <Button variation="primary" onClick={this.handleDismiss} className={s.dismiss}>
            Dismiss
          </Button>
        </footer>
      </Modal>
    );
  }
}
