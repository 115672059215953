import {
  DynamicComponent,
  addMfeEventListener,
  dispatchMfeEvent,
  setTranslations,
} from '@ps-refarch-ux/mfe-utils';
import { pbChatbotMFEUiUrl } from 'config/api';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withAmEventApplications } from 'containers/Colleges/ActiveMatchMicroHoc';
import ActiveMatchConnectDisconnectActions from 'routes/Colleges/Match/ActiveMatchNext/connectDisconnectActions';
import { getCurrentUser } from 'selectors/auth';
import { getHighSchool } from 'selectors/highschool';
import type { State as HighSchool } from 'modules/highschool';
import { usePowerBuddyListeners } from './usePowerBuddyListeners';
import {
  parseUserInformationForOneDs,
  getOneDsInitializationData,
} from 'components/PowerBuddy/helpers';
import { translations } from 'constants/pbChatBotMfe';
import {
  collegeProfilesPermissions,
  collegeFavoritingPermissions,
} from 'selectors/permissions';
import {
  HOST_EVENT_POWERBUDDY_INFORMATION_UPDATED,
  HOST_EVENT_POWERBUDDY_OPEN_REQUESTED,
  MFE_EVENT_CAREER_PATHWAY_GOALS,
  MFE_EVENT_POWERBUDDY_INITIALIZED,
  MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED,
  MFE_HOST_NAME,
  NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED,
} from 'constants/pbChatBotMfeEventConstants';
import s from './styles.scss';
import cx from 'classnames';
import { CustomPowerBuddyComponents } from './CustomPowerBuddyComponents';
import { getFeatureFlags } from 'selectors/featureFlags';
import { getUserInfo } from 'selectors/colleges/supermatchnext';
import { getFavorites } from 'selectors/powerbuddy';

export const PowerBuddyWrapper = ({ eventApplications }) => {
  // General handling of PowerBuddy events should be done in this hook
  usePowerBuddyListeners();
  const dispatch = useDispatch();

  const featureFlags = useSelector(getFeatureFlags) as Record<string, boolean>;
  const useExtensibleCollegeCard =
    featureFlags.releaseNavianceStudentPowerBuddyEnableExtensibilityCollegeCard;
  const powerBuddyFavoriteCollege =
    featureFlags.releaseNavianceStudentPowerBuddyFavoriteCollege;
  const powerBuddyConnectWithCollege =
    featureFlags.releaseNavianceStudentPowerBuddyEnableCollegeConnections;

  const [initializationData, setInitializationData] = useState(null);
  const initDataFetchedRef = useRef(false);

  const currentUser = useSelector(getCurrentUser);
  const highSchool: HighSchool = useSelector(getHighSchool);

  const dispatchSessionToken = (data) => {
    dispatchMfeEvent(
      'naviance_student',
      MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED,
      data.dataScienceSessionId
    );
  };

  const collegeProfiles = useSelector(collegeProfilesPermissions);

  const addOrEditProspectiveCollegesList = useSelector(collegeFavoritingPermissions);
  const superMatchUserInfo = useSelector(getUserInfo);
  const favorites = useSelector(getFavorites);

  const customPermissions: string[] = [];

  if (collegeProfiles) {
    if (!customPermissions.includes('college_profiles')) {
      customPermissions.push('college_profiles');
    }
  }
  if (addOrEditProspectiveCollegesList) {
    if (!customPermissions.includes('add_or_edit_prospective_colleges_list')) {
      customPermissions.push('add_or_edit_prospective_colleges_list');
    }
  }

  useEffect(() => {
    return addMfeEventListener(NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED, async () => {
      if (!currentUser || !highSchool) return;

      // If it's already initialized, just open the PowerBuddy MFE
      if (initDataFetchedRef.current) {
        dispatchMfeEvent('naviance_student', HOST_EVENT_POWERBUDDY_OPEN_REQUESTED, {});
        return;
      }
      // Initialize translations for PowerBuddy
      setTranslations(translations);
      const initializationDataPromise = getOneDsInitializationData(
        currentUser,
        highSchool,
        customPermissions,
        superMatchUserInfo,
        favorites
      );

      initializationDataPromise
        ?.then((data: any) => {
          setInitializationData(data);
          dispatchSessionToken(data);
        })
        .catch((error) => {
          // log error
        });

      initDataFetchedRef.current = true;
    });
  }, [currentUser, highSchool, superMatchUserInfo, favorites]);

  // When first initialized, request the PowerBuddy MFE to open
  useEffect(() => {
    return addMfeEventListener(MFE_EVENT_POWERBUDDY_INITIALIZED, (mfeEvent: any) => {
      if (mfeEvent.context.initializedCounter === 0)
        dispatchMfeEvent('naviance_student', HOST_EVENT_POWERBUDDY_OPEN_REQUESTED, {});
    });
  }, []);

  useEffect(() => {
    return addMfeEventListener(MFE_EVENT_CAREER_PATHWAY_GOALS, async (event) => {
      if (event?.context?.context) {
        const pathwayContext = { ...event.context.context };
        const careerContext = parseUserInformationForOneDs(
          superMatchUserInfo,
          currentUser
        );

        careerContext.primaryGoal = pathwayContext.primaryGoal;
        careerContext.secondaryGoal = pathwayContext.secondaryGoal;
        dispatchMfeEvent(MFE_HOST_NAME, HOST_EVENT_POWERBUDDY_INFORMATION_UPDATED, {
          context: { ...careerContext, favorites: favorites },
          skipResetChat: true,
        });
      }
    });
  }, [currentUser, superMatchUserInfo, favorites]);

  if (!initializationData) {
    return null;
  }

  return (
    // default in PowerBuddy is to show the "Add to list" button, therefore need to disable it if the Beta V2 feature flag is OFF
    <div
      id={s.boxSizing}
      className={cx({
        [s.disableAddToListButton]: !powerBuddyFavoriteCollege,
        [s.disableBuiltInCollegeCard]: useExtensibleCollegeCard,
      })}
    >
      <DynamicComponent
        remote="ps_mfe_ph_ai_chatbot"
        url={pbChatbotMFEUiUrl}
        module="./PhAiChatBot"
        locale="en-US"
        componentProps={initializationData}
      />
      <CustomPowerBuddyComponents />
      {powerBuddyConnectWithCollege && (
        <ActiveMatchConnectDisconnectActions
          hostApplication={eventApplications?.POWERBUDDY}
        />
      )}
    </div>
  );
};

export default withAmEventApplications(PowerBuddyWrapper);
