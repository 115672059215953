// @flow
import { get } from 'lodash';

export const getErrorMessage = (action: Object) =>
  get(action, 'error.errors.0') ||
  get(action, 'error.error.error') ||
  'An unexpected error occurred. Please try again.';

export const getScattergramUrl = (collegeData: Object) => {
  const uuid = get(collegeData, 'coreMapping.uuid');
  if (uuid) {
    return `/colleges/profile/${uuid}?landingPage=Admissions&anchor=scattergrams`;
  }

  return '';
};
