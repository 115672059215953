// @flow
import api from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import expandObject from 'utils/expandObject';
import { injectAsyncReducers } from 'store';
import localStorage from 'utils/localStorage';

const generateConstants = constantsGenerator('fc/highschool');

const [GET_HS_INFO, GET_HS_INFO_SUCCESS, GET_HS_INFO_FAIL]: string[] = generateConstants(
  'get_highschool_info'
);

export const englishId = 'en';
const [
  GET_HS_MILESTONES,
  GET_HS_MILESTONES_SUCCESS,
  GET_HS_MILESTONES_FAIL,
]: string[] = generateConstants('get_highschool_milestones');

const [
  GET_HS_LANGUAGES,
  GET_HS_LANGUAGES_SUCCESS,
  GET_HS_LANGUAGES_FAIL,
]: string[] = generateConstants('get_highschool_languages');

const [SET_HS_PREFERRED_LANGUAGE]: string[] = generateConstants('set_hs_preferred_language');

const SET_HSID = 'fc/highschool/set-hsid';

export type Language = {
  id: string,
  defaultDisplayName: string,
  languageCod: string,
  regionCode: string,
  isVisible: boolean,
  languageDisplayName: string,
};

export type LanguagesResult = {
  languageId: string,
  language: Language,
};

export type State = {
  hsid: ?string,
  nid: ?string,
  alias: ?string,
  name: ?string,
  addressLine1: ?string,
  addressLine2: ?string,
  city: ?string,
  state: ?string,
  country: ?string,
  zip: ?string,
  phone: ?string,
  fax: ?string,
  url: ?string,
  graphDisclaimer: ?string,
  loading: boolean,
  options: Object,
  milestones: ?(Object[]),
  preferredLanguageId: string,
  languages: Array<LanguagesResult>,
  highestGrade: number,
  lowestGrade: number,
  saml: Object,
};

type Action = {
  type: string,
  result: State | { options: { graphDisclaimer: string } },
  hsid: string,
  preferredLanguageId: string,
};

const initialState: State = {
  hsid: null,
  nid: null,
  alias: null,
  name: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  country: null,
  zip: null,
  phone: null,
  fax: null,
  url: null,
  graphDisclaimer: null,
  loading: false,
  options: {},
  milestones: null,
  languages: [],
  preferredLanguageId: englishId,
  highestGrade: 1,
  lowestGrade: 12,
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case GET_HS_INFO:
      return {
        ...state,
        loading: true,
      };
    case GET_HS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.result,
        graphDisclaimer: expandObject(action, 'result.options.graphDisclaimer'),
      };
    case GET_HS_MILESTONES_SUCCESS:
      return {
        ...state,
        milestones: action.result,
      };
    case SET_HSID:
      return {
        ...state,
        hsid: action.hsid,
        nid: null,
      };
    case GET_HS_INFO_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_HS_LANGUAGES:
      return {
        ...state,
        loading: true,
      };
    case GET_HS_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.result,
        loading: false,
      };
    case GET_HS_LANGUAGES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_HS_PREFERRED_LANGUAGE:
      return {
        ...state,
        preferredLanguageId: action.preferredLanguageId,
      };
    default:
      return state;
  }
}

export const load = (id: ?string) => (dispatch: Function, getState: Function): Promise<*> => {
  const state: State = getState().highschool;
  const hsid = id || 'me';

  if (state.nid) return Promise.resolve(state);

  return dispatch({
    types: [GET_HS_INFO, GET_HS_INFO_SUCCESS, GET_HS_INFO_FAIL],
    promise: (client: Object) => client.get(`${api.highschool}/${hsid}`),
  });
};

export const fetchHischoolMilestones = () => (
  dispatch: Function,
  getState: Function
): Promise<*> => {
  const state: State = getState().highschool;

  if (state.milestones) return Promise.resolve(state);

  return dispatch({
    types: [GET_HS_MILESTONES, GET_HS_MILESTONES_SUCCESS, GET_HS_MILESTONES_FAIL],
    promise: (client: Object) => client.get(`${api.highschool}/milestones`),
  });
};

export const setHsid = (hsid: string) => (dispatch: Function, getState: Function) => {
  if (getState().highschool.hsid !== hsid) {
    return dispatch({
      type: SET_HSID,
      hsid,
    });
  }

  return null;
};

export const fetchHighschoolLanguages = () => (
  dispatch: Function,
  getState: Function
): Promise<*> =>
  dispatch({
    types: [GET_HS_LANGUAGES, GET_HS_LANGUAGES_SUCCESS, GET_HS_LANGUAGES_FAIL],
    promise: (client: Object) => client.get(`${api.highschool}/me/languages`),
  }).then((result: LanguagesResult[]) => {
    if (!result.find((lang) => lang.languageId === getState().highschool.preferredLanguageId)) {
      localStorage.setItem(getState().auth.username, englishId);
      dispatch({
        type: SET_HS_PREFERRED_LANGUAGE,
        preferredLanguageId: englishId,
      });
    }
  });

export const changePreferredLanguage = (preferredLanguageId: string) => (
  dispatch: Function,
  getState: Function
) => {
  const currentUser = getState().auth.username;
  localStorage.setItem(currentUser, preferredLanguageId);
  return dispatch({
    type: SET_HS_PREFERRED_LANGUAGE,
    preferredLanguageId,
  });
};

export const getStoredPreferredLanguage = () => (dispatch: Function, getState: Function) => {
  const currentUser = getState().auth.username;
  const preferredLanguageId = localStorage.getItem(currentUser) || englishId;
  return dispatch({
    type: SET_HS_PREFERRED_LANGUAGE,
    preferredLanguageId,
  });
};

injectAsyncReducers({ highschool: reducer });
