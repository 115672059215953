// @flow
import * as React from 'react';
import IfFeatureAllowed from 'components/IfFeatureAllowed';
import Loadable from 'react-loadable';

const Placeholder = () => null;

// Legacy hamrburger menu
// prettier-ignore
const LegacyMenu = Loadable({
  loader: () => import('./HamburgerMenu' /* webpackChunkName: "hamburger-menu" */),  // eslint-disable-line
  loading: Placeholder,
});

// New BigMenu-based hamburger menu
// prettier-ignore
const BigBurgerMenu = Loadable({
  loader: () => import('./BigBurger' /* webpackChunkName: "big-burger-menu" */),  // eslint-disable-line
  loading: Placeholder,
});

// based on feature flags, we should show the legacy
const AsyncHamburgerMenuSwitcher = (props: Object) => (
  <IfFeatureAllowed target="bigMenu" whenDenied={<LegacyMenu {...props} />}>
    <BigBurgerMenu {...props} />
  </IfFeatureAllowed>
);

export default AsyncHamburgerMenuSwitcher;
