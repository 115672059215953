// @flow
import * as React from 'react';

import get from 'lodash/get';
import ScriptLoader from './ScriptLoader';

/**
 * This can create HOC which load ActiveMatch micro component and provide the resource as a property
 * @dependenciesDetails is an array of dependencies with the follow properties:
 * propName is the prop name used to pass the AM module depency to the wrapped component.
 * microComponentExportPath is the path used to find the dependency in the micro component.
 */
const AmHocCreater = (dependenciesDetails, options) => (Component) => {
  const CheckScriptLoadedComponent = (props) => {
    const { scriptsLoaded, scriptsLoadedSuccessfully, ...rest } = props;
    const newProps = { ...rest };
    const activeMatch = get(global, 'ActiveMatch.default', null);
    if (scriptsLoaded && scriptsLoadedSuccessfully && activeMatch) {
      dependenciesDetails.forEach(
        (dep) => (newProps[dep.propName] = get(activeMatch, dep.microComponentExportPath))
      );
    } else if (get(options, 'renderWait', false)) {
      // If the script is not yet loaded, return null.
      // without renderWait the component will render with props as undefined
      return null;
    }
    return <Component {...newProps} />;
  };

  return ScriptLoader(CheckScriptLoadedComponent);
};

export const withAmEventApplications = AmHocCreater([
  {
    propName: 'eventApplications',
    microComponentExportPath: 'constants.eventApplications',
  },
]);

export const withAwarenessUi = AmHocCreater(
  [
    {
      propName: 'AwarenessUi',
      microComponentExportPath: 'AwarenessUi',
    },
  ],
  {
    renderWait: true,
  }
);

export const withHubsUi = AmHocCreater(
  [
    {
      propName: 'HubsUi',
      microComponentExportPath: 'HubsUi',
    },
  ],
  {
    renderWait: true,
  }
);

export const withConnectionUi = AmHocCreater(
  [
    {
      propName: 'ConnectionUi',
      microComponentExportPath: 'ConnectionUi',
    },
  ],
  {
    renderWait: true,
  }
);

export const withDisconnectFromCollegeUi = AmHocCreater(
  [
    {
      propName: 'DisconnectFromCollegeUi',
      microComponentExportPath: 'DisconnectFromCollegeUi',
    },
  ],
  {
    renderWait: true,
  }
);

export const withSuperMatchPlugins = AmHocCreater([
  {
    propName: 'SuperMatchPlugins',
    microComponentExportPath: 'plugins',
  },
  {
    propName: 'AmContext',
    microComponentExportPath: 'ActiveMatchContext',
  },
]);

export const withAmConfigure = AmHocCreater(
  [
    {
      propName: 'configure',
      microComponentExportPath: 'configure',
    },
  ],
  {
    renderWait: true,
  }
);

export const withConnectionMatching = (WrappedComonent) => {
  // This component will get the withConnectionMatching from props and apply it to WrappedComonent
  const ApplyHocComponent = (props) => {
    const { withConnectionMatching: loadedHoc, ...rest } = props;
    const ComponentWithConnectionMatching = React.useMemo(() => loadedHoc(WrappedComonent), [
      loadedHoc,
    ]);
    return <ComponentWithConnectionMatching {...rest} />;
  };
  return AmHocCreater(
    [
      {
        propName: 'withConnectionMatching',
        microComponentExportPath: 'withConnectionMatching',
      },
    ],
    {
      renderWait: true,
    }
  )(ApplyHocComponent);
};

export const withAmApolloClient = AmHocCreater([
  {
    propName: 'createApolloClient',
    microComponentExportPath: 'createApolloClient',
  },
  {
    propName: 'awarenessCountData',
    microComponentExportPath: 'Awareness.awarenessCountData',
  },
  {
    propName: 'studentProfileData',
    microComponentExportPath: 'Awareness.studentProfileData',
  },
]);
