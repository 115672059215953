import api from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import { injectAsyncReducers } from 'store';
import { Favorites } from '../../types/pbChatbotMfe';

const generateConstants = constantsGenerator('fc/powebuddy/favorites');
const [
  FETCH_ALL_STUDENT_FAVORITES,
  FETCH_ALL_STUDENT_FAVORITES_SUCCESS,
  FETCH_ALL_STUDENT_FAVORITES_FAIL,
]: string[] = generateConstants('FETCH_ALL_STUDENT_FAVORITES');

type FavoritesState = {
  loading: boolean;
  favorites: Favorites;
};

const initialState: FavoritesState = {
  loading: false,
  favorites: {
    collegesImApplyingTo: [],
    collegesImThinkingAbout: [],
    careers: [],
    clusters: [],
    pathways: [],
    militaryBranches: [],
  },
};
export default function reducer(
  state: FavoritesState = initialState,
  action: { type: string; [key: string]: any }
) {
  switch (action.type) {
    case FETCH_ALL_STUDENT_FAVORITES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_STUDENT_FAVORITES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ALL_STUDENT_FAVORITES_SUCCESS:
      return {
        ...state,
        loading: false,
        favorites: action.result,
      };
    default: {
      return state;
    }
  }
}

export function fetchAllStudentFavoritedDataForPB() {
  return (dispatch: Function) => {
    return dispatch({
      types: [
        FETCH_ALL_STUDENT_FAVORITES,
        FETCH_ALL_STUDENT_FAVORITES_SUCCESS,
        FETCH_ALL_STUDENT_FAVORITES_FAIL,
      ],
      promise: (client) => client.get(`${api.powerBuddy}/career-colleges-favorites`),
    }).then((response: any) => response);
  };
}

injectAsyncReducers({ powebuddyFavorites: reducer });
