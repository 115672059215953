// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import noop from 'utils/noop';
import session from 'config/session';
import { getHighschoolLanguages, getPreferredLanguageId } from 'selectors/highschool';
import {
  fetchHighschoolLanguages,
  changePreferredLanguage,
  getStoredPreferredLanguage,
} from 'modules/highschool';
import Button from 'components/Button';
import ComboBox from 'components/ComboBox';
import type { Language } from 'modules/highschool';
import s from './styles.scss';

type Props = {
  languages: Array<Language>,
  preferredLanguageId: string,
  getStoredPreferredLanguage: Function,
};

type State = {
  selectedLanguage: string,
};

@connect(
  (state) => ({
    languages: getHighschoolLanguages(state),
    preferredLanguageId: getPreferredLanguageId(state),
  }),
  {
    fetchHighschoolLanguages,
    changePreferredLanguage,
    getStoredPreferredLanguage,
  }
)
export default class LanguagesDropdown extends React.Component<Props, State> {
  static defaultProps = {
    fetchHighschoolLanguages: noop,
    changePreferredLanguage: noop,
    getStoredPreferredLanguage: noop,
    preferredLanguageId: '',
  };

  state = {
    selectedLanguage: '',
  };

  UNSAFE_componentWillMount() {
    if (session.isValid()) {
      this.props.fetchHighschoolLanguages();
      this.props.getStoredPreferredLanguage();
    }
  }

  selectLanguage = (selectedLanguage) => {
    this.setState({ selectedLanguage });
  };

  selectedLanguage = () => {
    const { languages, preferredLanguageId } = this.props;

    if (languages.length === 0) {
      return '';
    }
    return preferredLanguageId;
  };

  render() {
    const { languages, preferredLanguageId } = this.props;
    const { selectedLanguage } = this.state;

    return (
      <div className={s.languagesDropdownContainer}>
        <ComboBox
          options={languages}
          onSelect={this.selectLanguage}
          value={selectedLanguage || preferredLanguageId}
          invertColor
          languageAttr
          inline
          valueProp="id"
          labelProp="languageDisplayName"
          selectClassName={s.languagesDropdown}
          title="Languages"
          noDefaultLabel="Select language"
          aria-label="Select Language"
          className={s.languagesComboBox}
        />
        <Button
          testId="languageSelector"
          onClick={() =>
            this.props.changePreferredLanguage(selectedLanguage || preferredLanguageId)
          }
          aria-label="Change language for school custom content"
          variation="noChrome"
          className={s.goButton}
        >
          Go
        </Button>
      </div>
    );
  }
}
