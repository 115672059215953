import Loadable from 'react-loadable';

const Placeholder = () => null;

// prettier-ignore
const AsyncBigMenu = Loadable({
  loader: () => import('./BigMenu' /* webpackChunkName: "big-menu" */),  // eslint-disable-line
  loading: Placeholder,
});

export default AsyncBigMenu;
