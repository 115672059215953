// @flow
import * as React from 'react';
import type { Location } from 'react-router';

/**
 * This flag tracks number of instances in one view
 * We don't want to scroll to too many elements so we scroll to the first one
 */
let queue = 0;
let timeoutId: TimeoutID;

function isScrolledIntoView(el, fullscreen: boolean = false) {
  if (!el) return true;

  const height = window.innerHeight;

  const rect = el.getBoundingClientRect();
  const minTop = fullscreen ? -1 : height * 0.02;
  const maxBottom = height * 0.7;

  queue += 1;

  return rect.top >= minTop && rect.bottom <= maxBottom;
}

type Props = {
  timeout?: number,
  location?: ?Location,
};

export default class ScrollHere extends React.Component<Props> {
  static defaultProps = {
    timeout: 10,
  };

  componentDidMount() {
    this.asyncScrollIntoView();
    window.addEventListener('scroll', () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    });
  }

  componentDidUpdate() {
    const { location } = this.props;

    // don't scroll if there is a hash fragment
    if ((location && location.hash) || window.location.hash) {
      return;
    }

    this.asyncScrollIntoView();
  }

  asyncScrollIntoView = () => setTimeout(() => this.scrollIntoView(), this.props.timeout);

  scrollIntoView = (lazyCheck: boolean = false) => {
    if (!lazyCheck) {
      queue = 0;
    }

    // el===element means we repeated this once so queue is ignored
    // this also means that queue was already determined
    if (this.el && !isScrolledIntoView(this.el) && (queue === 1 || lazyCheck)) {
      // only if first in the queue
      this.el.scrollIntoView({ behavior: 'smooth' });

      // if there's a lazy-rendered container, try to scroll again until lazy rendering is complete
      timeoutId = setTimeout(
        () => !isScrolledIntoView(this.el, true) && this.scrollIntoView(true),
        500
      );
    }
  };

  el: ?HTMLSpanElement;

  render() {
    return <div style={{ height: 0, width: 0 }} ref={(el) => (this.el = el)} />;
  }
}
