import * as React from 'react';
import cx from 'classnames';
import s from './Badge.scss';

interface Props {
  children: React.ReactElement;
  className?: string;
  transparent?: boolean;
}

export default class Badge extends React.PureComponent<Props> {
  static defaultProps = {
    transparent: false,
  };

  render() {
    const { children, className, transparent } = this.props;

    return (
      <div className={cx(s.badge, className, { [s.transparent]: transparent })}>
        {children}
      </div>
    );
  }
}
