export const requestType = 'FERPA';
export enum FerpaState {
  NOT_WAIVED = 2,
  WAIVED = 1,
}
export const FERPA_SIGN_DELAY_TIMEOUT = 30000;

export enum CurrentFerpaState {
  NOT_WAIVED = 'account_matched_with_ferpa_not_available',
}
