// @flow
import memoryStorage from './memoryStorage';

function returnStorageObject(): Object {
  try {
    const key: string = `ls-test-${String(Date.now())}`;
    localStorage.setItem(key, key);

    const value = localStorage.getItem(key);
    localStorage.removeItem(key);

    if (key !== value) {
      throw new Error();
    }

    return window.localStorage;
  } catch (e) {
    return memoryStorage;
  }
}

export default returnStorageObject();

export function clearLocalStorageOnLogin(): Object {
  const ls = returnStorageObject();
  ['amConnectBannerViewedList'].forEach((key) => ls.removeItem(key));
  return ls;
}
