// @noflow
import includes from 'lodash/includes';

import { AppDispatch } from 'store';
import * as config from 'config/rewritable-config';
import { translatePhrases } from 'modules/translationService';
import ls from 'utils/localStorage';
import { translationMessages } from 'constants/languageOptions';
import {
  ignoreTranslationPages,
  generalMessagePages,
} from '../config/ignore-translation-page';
import { divsToBeExcluded, excludeWords } from '../config/ignore-translation-divs';
import { TRADEMARK_SYMBOL } from 'constants/applicationWideUsedConstants';
import { convertPseudoElementToHTML } from 'utils/inlineManual';
interface ITranslationDetails {
  [source: string]: string;
}

interface ExtendedNode extends Node {
  translatedValue?: string;
  hasBeenTranslatedTo?: string;
  toBeUpdated?: string;
}

interface ExtendedElement extends Element {
  toBeUpdated?: string;
  hasBeenTranslatedTo?: string;
}

interface ExtendedAttr extends Attr {
  translatedValue?: string;
}

const wait = (time) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });

export const checkRegex = (paths, currentPath) => {
  const matchRegex = (path) => {
    const regexPath = `${path}(.*)$`; // ex - it will check for sub paths like - /careers/roadtripnation and /careers/roadtripnation/interests will return true
    return currentPath.match(regexPath);
  };

  return paths.some(matchRegex);
};

export const shouldIgnoreTranslationPage = (currentPath: string) => {
  return checkRegex(ignoreTranslationPages, currentPath);
};

export const showTranslationNotAvailableMessage = (currentPath: string) => {
  return checkRegex(generalMessagePages, currentPath);
};

export const getTranslationNotAvailableMessage = () => {
  return translationMessages.noTranslation;
};

export const shouldIgnoreDiv = (classname: string, id: string) =>
  includes(divsToBeExcluded, classname) || includes(divsToBeExcluded, id) || false;

export const checkPhrases = (text: string) => {
  const digitRegex = /[\d]+/g; // remove digits- 2022
  const symbolRegex = /[-!$%^&*()_+|~=`{}\[\]:";'<>?©,@.#…\/]/g; // ex- remove punctuations
  const spaceRegex = /\s/g; // remove spaces

  if (digitRegex.test(text)) {
    text = text.replace(digitRegex, '');
  }
  if (symbolRegex.test(text)) {
    text = text.replace(symbolRegex, '');
  }
  if (spaceRegex.test(text)) {
    text = text.replace(spaceRegex, '');
  }

  return !text;
};

const doNotTranslatePhrases = (text: string) => {
  const reg = new RegExp(`\\b${excludeWords.join('\\b|\\b')}\\b`, 'gi');
  return text.replace(reg, (matched) => {
    const index = text.indexOf(matched);
    const shouldGiveSpace =
      text.substring(matched.length) === TRADEMARK_SYMBOL ? '' : ' ';
    return index > 0
      ? `<span translate="no"> ${matched} </span>` // if matched text is in between sentence then need to give space in between
      : // if last charecter is ® then don't need space after text. ex - Intelligences®
        `<span translate="no">${matched}${shouldGiveSpace}</span>`;
  });
};

const textNodeType = 3;
const elementsToIgnore = ['STYLE'];
const elementsWithPlaceholder = [
  'TEXTAREA',
  'INPUT',
  'SEARCH',
  'URL',
  'TEL',
  'EMAIL',
  'PASSWORD',
];
const PHRASE_BATCH_LIMIT: number = +config.PHRASE_BATCH_LIMIT;

function renderTranslationToDOM(element: ExtendedNode, phrase: string) {
  switch (element.toBeUpdated) {
    case 'placeholder':
      const attributeNode: ExtendedAttr = document.createAttribute('placeholder');
      attributeNode.translatedValue = phrase;
      attributeNode.nodeValue = phrase;
      (element as HTMLInputElement).setAttributeNode(attributeNode);
      return;
    case 'tooltip':
      (element as HTMLAnchorElement).setAttribute('title', phrase);
      return;
    case 'data-label':
      (element as HTMLButtonElement).setAttribute('data-label', phrase);
      return;

    default:
      const translation = phrase.includes('<span translate="no">')
        ? phrase.replace(/<\/?span[^>]*>/g, '')
        : phrase;
      element.translatedValue = translation;
      element.nodeValue = translation;
      return;
  }
}

function renderAndSaveTranslations(
  dispatch,
  textPhrases: string[],
  nodes: ExtendedNode[],
  preferredLanguage: string,
  translatedPhrases: string[] = [],
  shouldRetry = false
) {
  const locallyStoredTranslations = ls.getItem(`${preferredLanguage}_TranslatedPhrase`);
  const locallyStoredTranslation: ITranslationDetails = locallyStoredTranslations
    ? JSON.parse(locallyStoredTranslations)
    : {};

  for (let i = 0; i < nodes.length; i++) {
    if (translatedPhrases[i]) {
      renderTranslationToDOM(nodes[i], translatedPhrases[i]);
      locallyStoredTranslation[textPhrases[i].trim()] = translatedPhrases[i];
    } else {
      if (shouldRetry) {
        retryTranslationWithDelay(
          dispatch,
          1,
          textPhrases[i],
          nodes[i],
          preferredLanguage
        );
      }
    }
  }
  ls.setItem(
    `${preferredLanguage}_TranslatedPhrase`,
    JSON.stringify(locallyStoredTranslation)
  );
}

async function retryTranslationWithDelay(
  dispatch,
  retryCount: number,
  textPhrase: string,
  node: ExtendedNode,
  preferredLanguage: string
) {
  try {
    if (retryCount > 3) {
      return;
    }

    await wait(retryCount * 2000);
    const res = await dispatch(
      translatePhrases(textPhrase.length, [textPhrase], preferredLanguage, true)
    ).catch((error: Error) => {
      //
    });
    if (res && res.translatedPhrases && res.translatedPhrases[0]) {
      renderAndSaveTranslations(
        dispatch,
        [textPhrase],
        [node],
        preferredLanguage,
        res.translatedPhrases,
        false
      );
    } else {
      return retryTranslationWithDelay(
        dispatch,
        retryCount + 1,
        textPhrase,
        node,
        preferredLanguage
      );
    }
  } catch (e) {
    console.log('Translation Retry Error', e);
  }
}

export const getTranslatedTextFromLocalStorage = (text: string, language: string) => {
  let locallySavedTranslatedPhrase = '';
  if (text.trim().length > 0) {
    const locallyStoredTranslation = JSON.parse(
      ls.getItem(`${language}_TranslatedPhrase`)
    );

    if (locallyStoredTranslation) {
      locallySavedTranslatedPhrase = locallyStoredTranslation[text]
        ? locallyStoredTranslation[text]
        : '';
    }
  }
  return locallySavedTranslatedPhrase;
};

export const saveTranslatedPhrases = (
  textPhrases: string[],
  translatedPhrases: string[],
  language: string
) => {
  const locallyStoredTranslations = ls.getItem(`${language}_TranslatedPhrase`);
  const locallyStoredTranslation: ITranslationDetails = locallyStoredTranslations
    ? JSON.parse(locallyStoredTranslations)
    : {};

  if (translatedPhrases) {
    for (let i = 0; i < translatedPhrases.length; i++) {
      locallyStoredTranslation[textPhrases[i].trim()] = translatedPhrases[i];
    }
    ls.setItem(`${language}_TranslatedPhrase`, JSON.stringify(locallyStoredTranslation));
  }
};

export async function getTranslatedPhrases(
  dispatch,
  noOfCharacters,
  chunk,
  preferredLanguage,
  nodes
) {
  const res = await dispatch(
    translatePhrases(noOfCharacters, chunk, preferredLanguage)
  ).catch((error: Error) => {
    console.log(error.message);
  });
  if (res && res.translatedPhrases && res.translatedPhrases.length) {
    renderAndSaveTranslations(
      dispatch,
      chunk,
      nodes,
      preferredLanguage,
      res.translatedPhrases,
      true
    );
  }
}

export async function checkAddedNodes(
  rootEl: HTMLElement,
  dispatch: AppDispatch,
  preferredLanguage: string,
  isInlineManualConvertPseudoElementEnabled: boolean
) {
  let noOfCharacters = 0;
  const nodes: ExtendedNode[] = [];
  const textPhrases: string[] = [];

  const treeWalker = document.createTreeWalker(rootEl, NodeFilter.SHOW_ELEMENT);

  let node: ExtendedNode = treeWalker.nextNode();

  function addPhrase(n: ExtendedNode) {
    if (n.hasBeenTranslatedTo !== preferredLanguage) {
      let nodeValue = '';

      switch (n.toBeUpdated) {
        case 'placeholder':
          nodeValue = (n as HTMLInputElement).placeholder;
          break;
        case 'tooltip':
          nodeValue = (n as HTMLAnchorElement).title;
          break;
        case 'data-label':
          nodeValue = (n as HTMLButtonElement).getAttribute('data-label');
          break;
        default:
          nodeValue = n.nodeValue;
          break;
      }
      if (nodeValue) {
        const shouldRemovePhrases = checkPhrases(nodeValue);

        if (!shouldRemovePhrases) {
          n.translatedValue = nodeValue;
          n.hasBeenTranslatedTo = preferredLanguage;

          const nodeValueWithDoNotTranslate = doNotTranslatePhrases(nodeValue);
          const translatedPhrase = getTranslatedTextFromLocalStorage(
            nodeValueWithDoNotTranslate.trim(),
            preferredLanguage
          );
          if (translatedPhrase.length > 0) {
            renderTranslationToDOM(n, translatedPhrase);
          } else {
            noOfCharacters += nodeValue.length;
            textPhrases.push(nodeValueWithDoNotTranslate);
            nodes.push(n);
          }
        }
      }
    }
  }

  function findNextSibling(n) {
    if (n === rootEl) {
      return null;
    }
    const next = treeWalker.nextSibling();
    if (next) {
      return next;
    }
    return findNextSibling(treeWalker.parentNode());
  }

  while (node) {
    if (elementsToIgnore.includes(node.nodeName)) {
      node = treeWalker.nextNode();
      // eslint-disable-next-line
      continue;
    }

    if (node.hasBeenTranslatedTo === preferredLanguage) {
      node = treeWalker.nextNode();
      // eslint-disable-next-line
      continue;
    }

    if ((node as HTMLElement).className === 'inmplayer-trigger') {
      if (isInlineManualConvertPseudoElementEnabled) {
        convertPseudoElementToHTML();
      }
    }

    if (
      (node as HTMLElement).getAttribute('data-navi-donottranslate') &&
      (node as HTMLElement).getAttribute('data-navi-donottranslate') !== 'false'
    ) {
      node = findNextSibling(node);
      // eslint-disable-next-line
      continue;
    }

    if (shouldIgnoreDiv((node as HTMLElement).className, (node as HTMLElement).id)) {
      node = findNextSibling(node);
      // eslint-disable-next-line
      continue;
    }

    // for tooltips
    if (
      (node.nodeName === 'A' && (node as HTMLAnchorElement).getAttribute('title')) ||
      (node.nodeName === 'TD' && (node as HTMLTableCellElement).getAttribute('title'))
    ) {
      node.toBeUpdated = 'tooltip';
      addPhrase(node);
    }
    if (
      node.nodeName === 'BUTTON' &&
      (node as HTMLButtonElement).getAttribute('data-label')
    ) {
      node.toBeUpdated = 'data-label';
      addPhrase(node);
    }

    if (!node.childNodes.length) {
      if (elementsWithPlaceholder.includes(node.nodeName)) {
        node.toBeUpdated = 'placeholder';
        addPhrase(node);
      }
      node = treeWalker.nextNode();
      // eslint-disable-next-line
      continue;
    }

    if (node.childNodes.length > 1) {
      const arrayOfChildNodes = Array.from(node.childNodes);
      if (arrayOfChildNodes.find((n) => n.nodeType === textNodeType)) {
        arrayOfChildNodes.forEach((n) => {
          if (n.nodeType === textNodeType) {
            addPhrase(n);
          }
        });
      }
      node = treeWalker.nextNode();
      // eslint-disable-next-line
      continue;
    }

    if (node.childNodes[0].nodeType === textNodeType) {
      const textNode = node.childNodes[0];
      addPhrase(textNode);
    }
    node = treeWalker.nextNode();
  }

  if (noOfCharacters && textPhrases.length && nodes.length) {
    const chunkSize = PHRASE_BATCH_LIMIT;
    const promises = [];
    for (let i = 0; i < textPhrases.length; i += chunkSize) {
      const chunk = textPhrases.slice(i, i + chunkSize);
      const nodeChunk = nodes.slice(i, i + chunkSize);
      promises.push(
        getTranslatedPhrases(
          dispatch,
          noOfCharacters,
          chunk,
          preferredLanguage,
          nodeChunk
        )
      );
    }
    await Promise.allSettled(promises);
  }
}

export async function checkCharacterDataNodes(
  dispatch: AppDispatch,
  el: ExtendedNode,
  preferredLanguage: string
) {
  let noOfCharacters = 0;
  const nodes: ExtendedNode[] = [];
  const textPhrases: string[] = [];

  function addPhrase(n: ExtendedNode) {
    const nodeValue = n.nodeValue;
    if (nodeValue) {
      const shouldRemovePhrases = checkPhrases(nodeValue);

      if (!shouldRemovePhrases) {
        const nodeValueWithDoNotTranslate = doNotTranslatePhrases(nodeValue);
        const translatedPhrase = getTranslatedTextFromLocalStorage(
          nodeValueWithDoNotTranslate.trim(),
          preferredLanguage
        );
        if (translatedPhrase.length > 0) {
          renderTranslationToDOM(n, translatedPhrase);
        } else {
          noOfCharacters += nodeValue.length;
          textPhrases.push(nodeValueWithDoNotTranslate);
          nodes.push(n);
        }
      }
    }
  }

  if (elementsToIgnore.includes(el.nodeName)) {
    return;
  }

  if (el.hasBeenTranslatedTo && el.translatedValue !== el.nodeValue) {
    addPhrase(el);
  }

  if (noOfCharacters && textPhrases.length && nodes.length) {
    await getTranslatedPhrases(
      dispatch,
      noOfCharacters,
      textPhrases,
      preferredLanguage,
      nodes
    );
  }
}

export async function checkAttributeNodes(
  dispatch: AppDispatch,
  el: ExtendedElement,
  preferredLanguage: string,
  attribute: string
) {
  let noOfCharacters = 0;
  const nodes: ExtendedNode[] = [];
  const textPhrases: string[] = [];

  function addPhrase(n: ExtendedElement) {
    const nodeValue = n.getAttribute(attribute);
    if (nodeValue.trim()) {
      const shouldRemovePhrases = checkPhrases(nodeValue);

      if (!shouldRemovePhrases) {
        const nodeValueWithDoNotTranslate = doNotTranslatePhrases(nodeValue);
        const translatedPhrase = getTranslatedTextFromLocalStorage(
          nodeValueWithDoNotTranslate.trim(),
          preferredLanguage
        );
        if (translatedPhrase.length > 0) {
          renderTranslationToDOM(n, translatedPhrase);
        } else {
          noOfCharacters += nodeValue.length;
          textPhrases.push(nodeValueWithDoNotTranslate);
          nodes.push(n);
        }
      }
    }
  }

  const attributeNode: ExtendedAttr = el.getAttributeNode(attribute);

  if (
    el.hasBeenTranslatedTo &&
    attributeNode.translatedValue !== attributeNode.nodeValue
  ) {
    el.toBeUpdated = attribute;
    addPhrase(el);
  }

  if (noOfCharacters && textPhrases.length && nodes.length) {
    await getTranslatedPhrases(
      dispatch,
      noOfCharacters,
      textPhrases,
      preferredLanguage,
      nodes
    );
  }
}

export const getRecentlyPrefferedlanguage = (): string[] => {
  const lsRecentlyPrefferedLanguage = JSON.parse(ls.getItem(`recent_languages`));
  return lsRecentlyPrefferedLanguage?.length > 0 ? lsRecentlyPrefferedLanguage : [];
};

const modifyLsRecentLanguagesArray = (array: string[], value: string): string[] => {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
    array.push(value);
  } else {
    array.push(value);
  }
  return array;
};

export const saveRecentlyPrefferedlanguage = (language: string) => {
  const lsRecentlyPrefferedLanguage = JSON.parse(ls.getItem(`recent_languages`));
  if (!lsRecentlyPrefferedLanguage) {
    const recentlangArray = [];
    recentlangArray.push(language);
    ls.setItem(`recent_languages`, JSON.stringify(recentlangArray));
  } else {
    if (lsRecentlyPrefferedLanguage.length < 3) {
      modifyLsRecentLanguagesArray(lsRecentlyPrefferedLanguage, language);
    } else {
      modifyLsRecentLanguagesArray(lsRecentlyPrefferedLanguage, language);
      if (lsRecentlyPrefferedLanguage.length > 3) {
        lsRecentlyPrefferedLanguage.shift();
      }
    }
    ls.setItem(`recent_languages`, JSON.stringify(lsRecentlyPrefferedLanguage));
  }
};

export function comboPreferredLanguageKey(districtId, highSchoolId, userId) {
  if (!districtId && !highSchoolId && !userId) {
    return 'NAVI__preferredLanguage';
  }
  if (districtId && !highSchoolId) {
    return `NAVI_${districtId}_${userId}_preferredLanguage`;
  }
  if (!districtId && highSchoolId) {
    return `NAVI_${highSchoolId}_${userId}_preferredLanguage`;
  }
  return `NAVI_${districtId}_${highSchoolId}_${userId}_preferredLanguage`;
}
