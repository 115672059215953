// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'components/Grid';
import SanitizedHtml, { singleLineHTMLConfig } from 'components/SanitizedHTML';
import Button from 'components/Button';
import navianceEmblem from 'theme/images/naviance-teal-and-gray.png';
import s from './styles.scss';

type Props = {
  errorTitle?: string,
  errorImage: string,
  errorMessage: string,
  errorDescription?: string,
  errorAction?: string,
  goHomeButton?: string,
};

export default class ErrorPageTemplate extends React.PureComponent<Props> {
  goHome = () => {
    window.location = '/';
  };

  render() {
    const {
      errorTitle,
      errorImage,
      errorMessage,
      errorDescription,
      errorAction,
      goHomeButton,
    } = this.props;
    return (
      <Row className={s.errorContainer}>
        <Col xs={12} md={12} offsetLg={1} lg={6} className={s.errorSection}>
          <NavLink to="/auth/fclookup">
            <img data-test-id="family_connection_emblem" src={navianceEmblem} alt="Naviance Logo" />
          </NavLink>
          {errorTitle && <p className={s.errorTitle}>{errorTitle}</p>}
          <p className={s.errorMessage}>{errorMessage}</p>
          <p className={s.errorDescription}>{errorDescription}</p>
          <p className={s.errorAction}>
            <SanitizedHtml sanitizerOptions={singleLineHTMLConfig} html={errorAction} />
          </p>
          {goHomeButton && (
            <p>
              <Button onClick={this.goHome} variation="primary">
                {goHomeButton}
              </Button>
            </p>
          )}
        </Col>
        <Col xs={12} md={12} lg={4} className={s.errorSectionImage}>
          <img
            data-test-id="error_image"
            src={errorImage}
            alt={errorTitle}
            className={s.errorImage}
          />
        </Col>
      </Row>
    );
  }
}
