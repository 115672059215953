// @flow

/**
 * Each Rule has Sequences, which in turn have Courses.
 *
 * There are 2 types of rules:
 * - Rules were the user selects a Sequence.
 * - Rules were the user selects Courses from the sequences.
 */
export const SelectSequencesRuleTypes = ['TYPE_MANDATED', 'TYPE_SEQUENCE'];
export const SelectCoursesRuleTypes = ['TYPE_LIST', 'TYPE_LISTS', 'TYPE_MULTI_GRADE_LEVEL'];

// these rule types are never optional
export const RequiredTypes = ['TYPE_MANDATED', 'TYPE_MULTI_GRADE_LEVEL', 'TYPE_SEQUENCE'];

// Each StudentPlanApprovalStatus can be of different types
export const InProgressStatus = ['APPROVAL_STATUS_NOT_SUBMITTED'];

export const RejectedStatus = ['APPROVAL_STATUS_REJECTED', 'APPROVAL_STATUS_SCHOOL_REJECTED'];
export const RejectedByParentStatus = ['APPROVAL_STATUS_PARENT_REJECTED'];

export const AwaitingApprovalStatus = ['APPROVAL_STATUS_SUBMITTED', 'APPROVAL_STATUS_PENDING'];
export const AwaitingParentApprovalStatus = ['APPROVAL_STATUS_AWAITING_PARENT'];

export const ApprovedStatus = [
  'APPROVAL_STATUS_APPROVED',
  'APPROVAL_STATUS_PARENT_APPROVED',
  'APPROVAL_STATUS_SCHOOL_APPROVED',
];

// Plan submit types
export const SubmitTypeApproval: 'APPROVAL' = 'APPROVAL';
export const SubmitTypeMakeCurrentPlan: 'MAKE_THIS_MY_CURRENT_PLAN' = 'MAKE_THIS_MY_CURRENT_PLAN';

// Student plan types
export const MiddleSchoolTypes = ['TYPE_MIDDLE_SCHOOL_DRAFT', 'TYPE_MIDDLE_SCHOOL_OFFICIAL'];
export const HighSchoolTypes = ['TYPE_HIGH_SCHOOL_DRAFT', 'TYPE_HIGH_SCHOOL_OFFICIAL'];

// Current or Draft plan types
export const CurrentPlanTypes = ['TYPE_MIDDLE_SCHOOL_OFFICIAL', 'TYPE_HIGH_SCHOOL_OFFICIAL'];
export const DraftPlanTypes = ['TYPE_MIDDLE_SCHOOL_DRAFT', 'TYPE_HIGH_SCHOOL_DRAFT'];

// Map approval status type to its label, as seen by the student
export const ApprovalStatusStudentLabels = {
  inProgress: 'In Progress',
  awaitingApproval: 'Awaiting School Approval',
  awaitingParentApproval: 'Awaiting Parent Approval',
  rejected: 'Not Approved',
  rejectedByParent: 'Not Approved By Parent',
  approved: 'Approved',
};

// Map approval status type to its label, as seen by the parent
export const ApprovalStatusParentLabels = {
  ...ApprovalStatusStudentLabels,
  awaitingParentApproval: 'Your Approval Is Needed',
};

/**
 * Each Plan allows students to select Courses in any of the following grade years.
 */
export const MiddleSchoolGrades = [6, 7, 8];
export const HighSchoolGrades = [9, 10, 11, 12];

// Types of report page
export const ReportPageTypeGrade: 'grade' = 'grade';
export const ReportPageTypeRequirement: 'requirement' = 'requirement';
export const ReportPageTypes = [ReportPageTypeGrade, ReportPageTypeRequirement];

// Course Record status type
export const RecordStatusCompleted: 'COMPLETED' = 'COMPLETED';
export const RecordStatusInProgress: 'IN_PROGRESS' = 'IN_PROGRESS';

export const PowerScoreSections = [
  { name: 'Career or 2-year College', min: 0, max: 27 },
  { name: '4-Year College', min: 28, max: 59 },
  { name: '4-Year Selective College', min: 60, max: 89 },
  { name: '4-Year Highly Selective College', min: 90, max: 100 },
];

// Types of institution used in Courses Lookup
export const InstitutionTypeAllSchools: 'ALL_SCHOOLS' = 'ALL_SCHOOLS';
export const InstitutionTypeHighSchools: 'HIGH_SCHOOLS' = 'HIGH_SCHOOLS';
export const InstitutionTypePlanSchools: 'PLAN_SCHOOLS' = 'PLAN_SCHOOLS';
