import Chunk from 'components/Chunk';
import * as React from 'react';

const loadCoursePlannerNext = () =>
  import(
    'containers/Courses/CoursePlannerNextRoutes' /* webpackChunkName: "course-planner-next" */
  );

export default class CoursePlannerNextRoute extends React.Component {
  render() {
    return <Chunk load={loadCoursePlannerNext} />;
  }
}
