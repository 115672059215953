// @flow

export default function getUrlParams(search: string): { [string]: string } {
  return search
    .slice(search.indexOf('?') + 1)
    .split('&')
    .reduce((acc, hash) => {
      const [key, val] = hash.split('=');
      acc[key] = decodeURIComponent(val);

      return acc;
    }, {});
}
