// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import persistedAction from '../../utils/temporaryPersist';

const generateConstants = constantsGenerator('fc/supermatchnext');
const [
  GET_SUPERMATCH_USER_INFO,
  GET_SUPERMATCH_USER_INFO_SUCCESS,
  GET_SUPERMATCH_USER_INFO_FAIL,
]: string[] = generateConstants('GET_SUPERMATCH_USER_INFO');

export type UserInfo = {
  userId: number,
  highSchoolNid: string,
  highSchoolState: string,
  highSchoolZip: string,
  academics: {
    gpa: number,
    psat: number,
    sat: number,
    act: number,
  },
  demographics: {
    classYear: number,
    ethnicity: string,
    gender: string,
  },
};
type State = {
  loading: boolean,
  loaded: boolean,
  userInfo?: UserInfo,
};

const initialState: State = {
  loading: false,
  loaded: false,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_SUPERMATCH_USER_INFO:
      return {
        ...state,
        userInfo: null,
        loading: true,
      };
    case GET_SUPERMATCH_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.result,
        loading: false,
        loaded: true,
      };
    case GET_SUPERMATCH_USER_INFO_FAIL:
      return {
        ...state,
        userInfo: null,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export const getSuperMatchUserInfo = persistedAction('supermatchnext.userinfo', {
  types: [
    GET_SUPERMATCH_USER_INFO,
    GET_SUPERMATCH_USER_INFO_SUCCESS,
    GET_SUPERMATCH_USER_INFO_FAIL,
  ],
  promise: (client: Object) => client.get(`${api.superMatch}/userinfo`),
});

export const loadSuperMatchUserInfo = () => (dispatch: Function) =>
  dispatch(getSuperMatchUserInfo());
