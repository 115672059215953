import React, { useEffect, useState } from 'react';
import Icon from 'components/Icon';
import Button from 'components/Button';
import cx from 'classnames';
import {
  saveTranslatedPhrases,
  getTranslatedTextFromLocalStorage,
  showTranslationNotAvailableMessage,
  getTranslationNotAvailableMessage,
} from 'utils/translation';
import { translatePhrases } from 'modules/translationService';
import { AppDispatch } from 'store';
import { translationMessages } from 'constants/languageOptions';
import { generalMessagePages } from '../../config/ignore-translation-page';
import { checkRegex } from '../../utils/translation';

import s from './styles.scss';

interface Notification {
  text?: string;
  currentPath: string;
  preferredLanguage: string;
  dispatch: AppDispatch;
  isSEIframeVisible: boolean;
}

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export const TranslationNotification = (props: Notification) => {
  const [isOpen, closeButtonHandler] = useState(false);
  const {
    text = translationMessages.noTranslation,
    currentPath,
    preferredLanguage,
    dispatch,
    isSEIframeVisible,
  } = props;
  const [message, setMessage] = useState(text);

  const close = () => {
    closeButtonHandler(false);
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  const translateMessage = async (value: string) => {
    const localMessage = getTranslatedTextFromLocalStorage(value, preferredLanguage);
    if (localMessage.length > 0) {
      setMessage(localMessage);
    } else {
      const res = await dispatch(
        translatePhrases(value.length, [value], preferredLanguage)
      )
        // eslint-disable-next-line flowtype/no-types-missing-file-annotation
        .catch((error: Error) => {
          console.log(error.message);
          return null;
        });
      if (res && res.translatedPhrases && res.translatedPhrases.length) {
        setMessage(res.translatedPhrases[0]);
        saveTranslatedPhrases([value], res.translatedPhrases, preferredLanguage);
      }
    }
  };

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  const callTranslateMessage = () => {
    translateMessage(getTranslationNotAvailableMessage());
    closeButtonHandler(undefined);
    const timer = setTimeout(() => {
      closeButtonHandler(true);
    }, 1);

    return timer;
  };

  useEffect(() => {
    let timer;
    if (showTranslationNotAvailableMessage(currentPath)) {
      if (checkRegex([generalMessagePages[0]], currentPath)) {
        if (isSEIframeVisible) {
          timer = callTranslateMessage();
        }
      } else {
        timer = callTranslateMessage();
      }
    } else {
      closeButtonHandler(false);
    }
    return () => clearTimeout(timer);
  }, [currentPath, isSEIframeVisible]);

  if (isOpen === undefined) return null;

  return (
    <div
      className={cx(
        s.container,
        isOpen ? s.rightToLeftAnimation : s.LeftToRightAnimation
      )}
      data-test-id="page-translation-notification"
      data-navi-donottranslate
    >
      <div className={s.wrapper}>
        <Icon icon="info-circle" className={s.infoIcon} />
        <h4 className={s.notificationText} data-test-id="notification-text">
          {message}
        </h4>
        <Button
          id="closeBtn"
          type="button"
          aria-label="Close"
          onClick={close}
          variation="noChrome"
        >
          <Icon icon="close" className={s.closeIcon} />
        </Button>
      </div>
    </div>
  );
};
