import get from 'lodash/get';
import { NeonButton } from '@ps-refarch-ux/neon';
import { addMfeEventListener, MfeEvent } from '@ps-refarch-ux/mfe-utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeDisplayAMConnectorPopup,
  changeAmMatchesInConnector,
} from 'modules/colleges/activeMatchConnectionMatching';
import {
  blacklistCollege,
  fetchBlacklistedColleges,
} from 'modules/colleges/lookingForStudentsLikeYou';
import { getAllAMConnectibleColleges } from 'selectors/colleges/activeMatchConnectionMatching';
import { getCurrentUser } from 'selectors/auth';
import {
  getUnfilteredMatchingColleges,
  getBlacklistedColleges,
} from 'selectors/colleges/lookingForStudentsLikeYou';
import { collegeConnectionPermissions } from 'selectors/permissions';
import { useId, pbTrackEvent } from './CollegeCard';
import styles from './CollegeCard.scss';
import type { LegacyMatch, AMMatch } from 'types/colleges';
import { CollegePlanningAction, EVENT_TYPE } from 'constants/pbChatBotMfeDsApi';
import { useCollegeCardConnectionContext } from './CollegeCardConnectionContext';
import { ACTIVEMATCH_EVENT_STUDENT_CONNECTED_WITH_COLLEGE } from 'constants/activematchMicroComponentEventConstants';

interface CollegeDetails {
  college_name: string;
  navianceId?: string;
  scid?: number;
}
interface CollegeData {
  actionDetails: CollegeDetails;
  actionName: CollegePlanningAction;
}

interface CollegeCardConnectionsProps {
  college: CollegeData;
  cardClass: string;
  sendPostActionMessage: (
    message: string,
    actionName: CollegePlanningAction,
    actionDetails: CollegeDetails
  ) => void;
}

enum Completion {
  SUCCESS = 'success',
  ERROR = 'error',
}

export function CollegeCardConnections({
  college,
  cardClass,
  sendPostActionMessage,
}: CollegeCardConnectionsProps): React.ReactElement {
  const collegeConnectionStatus = useCollegeCardConnectionContext();
  const uniqueId = useId();
  const dispatch = useDispatch();
  const connectibleLegacyColleges: LegacyMatch[] = useSelector(
    getUnfilteredMatchingColleges
  );
  const connectibleActiveMatchColleges: AMMatch[] = useSelector(
    getAllAMConnectibleColleges
  );
  const connectedActiveMatchColleges: AMMatch[] = connectibleActiveMatchColleges.filter(
    (college) => college.connectionStatus === 'CONNECTED'
  );
  const blacklistedColleges: number[] = useSelector(getBlacklistedColleges);
  // collegeConnectionPermissions is responsible for compliance that users under 13 years old cannot connect with colleges
  const mayConnectWithColleges = useSelector(collegeConnectionPermissions);
  const currentUser = useSelector(getCurrentUser);

  const suggestions = [
    `Connect with ${college?.actionDetails?.college_name}`,
    'Not interested',
  ];

  useEffect(() => {
    // event dispatched from match-ui-activematch repo https://github.com/Hobsons/match-ui-activematch/pull/530/files#diff-236c93b62b5f756421db1c9709b4fb16c0a7597fae6a00a637a30e99784ca6a0R179-R184
    return addMfeEventListener(
      ACTIVEMATCH_EVENT_STUDENT_CONNECTED_WITH_COLLEGE,
      (event: MfeEvent) => {
        if (event.context.status === Completion.SUCCESS) {
          collegeConnectionStatus.disable(college.actionDetails.scid);
          sendPostActionMessage(
            'yes i have added the college to my apply list',
            CollegePlanningAction.CONNECTION_COMPLETE,
            {
              scid: college.actionDetails.scid,
              navianceId: college.actionDetails.navianceId,
              college_name: college.actionDetails.college_name,
            }
          );
        }
        if (event.context.status === Completion.ERROR) {
          sendPostActionMessage(
            'there was an error in connecting with the college',
            CollegePlanningAction.REQUEST_ACTION_ERROR,
            {
              scid: college.actionDetails.scid,
              navianceId: college.actionDetails.navianceId,
              college_name: college.actionDetails.college_name,
            }
          );
        }
      }
    );
  }, []);

  const showConnectionAndNotInterestedButtons = () => {
    return (
      mayConnectWithColleges &&
      college.actionDetails.scid &&
      college.actionName === CollegePlanningAction.ADDED_TO_LIST_OFFER_CONNECTION &&
      !isCollegeConnected(college.actionDetails, connectedActiveMatchColleges) &&
      isConnectibleCollege(
        college.actionDetails,
        connectibleLegacyColleges,
        connectibleActiveMatchColleges
      ) &&
      !blacklistedCollege(college.actionDetails.scid, blacklistedColleges)
    );
  };

  const handleConnectionOfferClick = () => {
    const matchInConnector = {
      scid: get(college.actionDetails, 'scid', '').toString(),
      name: get(college.actionDetails, 'college_name', ''),
    };
    dispatch(changeAmMatchesInConnector([matchInConnector]));
    dispatch(changeDisplayAMConnectorPopup(true));
    // listen for MfeEvent ACTIVEMATCH_EVENT_STUDENT_CONNECTED_WITH_COLLEGE
  };

  const handleNotInterestedClick = () => {
    collegeConnectionStatus.disable(college.actionDetails.scid);
    dispatch(blacklistCollege(college.actionDetails.scid));
    dispatch(fetchBlacklistedColleges());
    sendPostActionMessage(
      'I am not interested in connecting with this college',
      CollegePlanningAction.NOT_INTERESTED_TO_CONNECT,
      {
        scid: college.actionDetails.scid,
        navianceId: college.actionDetails.navianceId,
        college_name: college.actionDetails.college_name,
      }
    );
    pbTrackEvent(dispatch, EVENT_TYPE.AM_NOT_INTERESTED, {
      connectibleActiveMatchColleges,
      currentUser,
      college,
    });
  };

  return (
    showConnectionAndNotInterestedButtons() && (
      <div className={styles.collegeConnectionButtons}>
        <NeonButton
          id={`pb-college-card-${uniqueId}-connection-btn`}
          disabled={collegeConnectionStatus.isDisabled(college.actionDetails.scid)}
          dataType="secondary"
          dataText={suggestions[0]}
          dataAriaLabel={suggestions[0]}
          data-testid="connection-btn"
          onClick={handleConnectionOfferClick}
        />
        <NeonButton
          id={`pb-college-card-${uniqueId}-not-interested-btn`}
          disabled={collegeConnectionStatus.isDisabled(college.actionDetails.scid)}
          dataType="secondary"
          dataText={suggestions[1]}
          dataAriaLabel={suggestions[1]}
          data-testid="not-interested-btn"
          onClick={handleNotInterestedClick}
        />
      </div>
    )
  );
}

function isConnectibleCollege(
  displayedCollege: CollegeDetails,
  connectibleLegacyColleges: LegacyMatch[],
  connectibleActiveMatchColleges: AMMatch[]
): boolean {
  // These results may not be correct in all cases, depending on
  // how robust the data from PowerBuddy is. This is a best effort
  // attempt to match against the connectible college's data.
  const connectibleLegacyCollege = connectibleLegacyColleges.some(
    (college) =>
      college.hobsonsId === displayedCollege.scid ||
      college.name === displayedCollege.college_name
  );
  const connectibleActiveMatchCollege = connectibleActiveMatchColleges.some(
    (college) =>
      college.scid === displayedCollege.scid.toString() ||
      college.name === displayedCollege.college_name
  );
  return connectibleLegacyCollege || connectibleActiveMatchCollege;
}

function isCollegeConnected(
  displayedCollege: CollegeDetails,
  connectedActiveMatchColleges: AMMatch[]
): boolean {
  // These results may not be correct in all cases, depending on
  // how robust the data from PowerBuddy is. This is a best effort
  // attempt to match against the connectible college's data.
  return connectedActiveMatchColleges.some(
    (college) =>
      college.scid === displayedCollege.scid.toString() ||
      college.name === displayedCollege.college_name
  );
}

function blacklistedCollege(
  displayedCollegeScid: number,
  blacklistedCollegeScids: number[]
): boolean {
  return blacklistedCollegeScids.includes(displayedCollegeScid);
}
