import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { languageOptions, defaultLanguage } from 'constants/languageOptions';
import Label from 'components/Label';
import Icon from 'components/Icon';
import Button from 'components/Button';
import cx from 'classnames';
import ls from 'utils/localStorage';
import noop from 'utils/noop';
import { LanguageOption } from 'types/translation';
import { FeatureFlag } from 'constants/featureFlags';
import s from './styles.scss';
import {
  comboPreferredLanguageKey,
  getRecentlyPrefferedlanguage,
  saveRecentlyPrefferedlanguage,
} from '../../utils/translation';
import { NeonIcon } from '@ps-refarch-ux/neon';

// Define props for option component
interface OptionProps {
  item: LanguageOption;
  onChange?: noop;
  className?: string;
  isSelected?: boolean;
}

/**
 * Option component used inside TranslateSwitcher component
 * @param props : OptionProps
 * @returns <Component>
 */

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export function Option(props: OptionProps) {
  const { item, onChange, className, isSelected } = props;
  return (
    <div
      key={item.value}
      className={className}
      onClick={() => {
        onChange(item.value);
      }}
      data-test-id={`language-${item.value}`}
    >
      <Label light className={s.option}>
        <Label className={isSelected ? s.checked : ''}>
          {item.label}
          <span data-test-id={`language-placeholder-${item.value}`}>
            {item.placeholder}
          </span>
        </Label>
      </Label>
    </div>
  );
}

// Define props for TranslateSwitcher component
interface Props {
  headerType?: string;
}

/**
 * TranslateSwitcher component used inside Header component
 * @returns <Component>
 */

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
function TranslateSwitcherNeon(props: Props) {
  const { headerType } = props;
  const auth = useSelector((state: any) => state.auth);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const userId = auth && auth.id;
  const districtId = auth && auth.districtId;
  const highSchoolId = auth && auth.highSchoolId;
  let preferredLanguageKey =
    userId && districtId ? `NAVI_${districtId}_${userId}_preferredLanguage` : null;
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const [recentlanguageDetails, setRecentLanguageDetails] = useState([]);

  const featureFlags = useSelector(
    // eslint-disable-next-line flowtype/no-types-missing-file-annotation
    (state: { featureFlags: { loaded: boolean; flags: { [key: string]: boolean } } }) =>
      state.featureFlags
  );

  const isComboPreferredLanguageKeyEnabled: boolean =
    featureFlags &&
    featureFlags.loaded &&
    featureFlags.flags[FeatureFlag.ComboPreferredLanguageKeyEnabled];

  if (isComboPreferredLanguageKeyEnabled && userId && highSchoolId) {
    preferredLanguageKey = comboPreferredLanguageKey(districtId, highSchoolId, userId);
  }

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  const saveRecentlanguage: boolean =
    featureFlags &&
    featureFlags.loaded &&
    featureFlags.flags[FeatureFlag.SaveRecentLanguage];

  useEffect(() => {
    if (preferredLanguageKey) {
      setSelectedLanguage(ls.getItem(preferredLanguageKey) || defaultLanguage);
    }
  }, [preferredLanguageKey]);

  useEffect(() => {
    const lsRecentlanguage = getRecentlyPrefferedlanguage();
    const languageDetails = lsRecentlanguage
      .reverse()
      .map((lang) => languageOptions.find((opt) => opt.value === lang));
    setRecentLanguageDetails(languageDetails);
  }, [preferredLanguageKey]);

  // function to open modal
  const setModalIsOpenToTrue = () => {
    document.body.style.overflow = 'hidden';
    setModalIsOpen(true);
  };

  // function to close modal
  const setModalIsOpenToFalse = () => {
    document.body.style.overflow = 'unset';
    setModalIsOpen(false);
  };

  // function to handle on select of language
  const handleChangeLanguage = (value) => {
    if (selectedLanguage !== value) {
      setModalIsOpen(false);
      setSelectedLanguage(value);
      saveRecentlyPrefferedlanguage(value);
      if (preferredLanguageKey) {
        ls.setItem(preferredLanguageKey, value);
      }
      window.location.reload();
    } else {
      setModalIsOpenToFalse();
    }
  };

  return (
    <>
      <div
        className={
          headerType === 'normal'
            ? cx(s.switcherButton, s.forNormalHeader)
            : s.switcherButton
        }
        data-test-id="translation-switcher"
      >
        <Button
          onClick={setModalIsOpenToTrue}
          aria-label="Change language for school custom content"
          name={selectedLanguage.toUpperCase()}
          variation="noChrome"
          className={cx(s.goButton, 'language-switch-button')}
          data-test-id="language-switch-button-header"
          data-navi-donottranslate
        >
          <span>{selectedLanguage.toUpperCase()}</span>
          <NeonIcon dataIcon={'web-language'} className={s.mailIcon} />
        </Button>
      </div>
      <div className={s.languageSupportedmodal} tabIndex={0}>
        <Modal
          isOpen={modalIsOpen}
          className={cx(s.modalWrapper, 'language-switcher-modal-parent-div')}
          ariaHideApp={false}
          id="language-switcher-modal-parent-div"
          overlayClassName={s.modalOverlay}
        >
          <div className={cx(s.modalHeader, 'model-header-class')}>
            <Label light>
              Choose a language
              <span
                className={s.modalClose}
                onClick={setModalIsOpenToFalse}
                data-test-id="translation-switcher-close"
              >
                <Icon icon="close" />
              </span>
            </Label>
          </div>
          <div className={s.containerOptions} tabIndex={0}>
            {saveRecentlanguage && recentlanguageDetails.length > 0 && (
              <>
                <div>
                  <div className={cx(s.containerOption)}>
                    <div className={s.popUpSubHeader}>
                      <span>Recent Languages</span>
                    </div>
                    <div
                      className={cx(s.containerOption)}
                      style={{ float: 'left', paddingLeft: '0px' }}
                      data-navi-donottranslate
                    >
                      {recentlanguageDetails.map((item) => (
                        <Option
                          key={item.value}
                          item={item}
                          onChange={handleChangeLanguage}
                          isSelected={selectedLanguage === item.value}
                          className={s.optionWrapper}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className={s.containerOption}>
                  <hr className={s.lineSeperator} />
                </div>
              </>
            )}
            <div className={s.containerOption} data-navi-donottranslate>
              {languageOptions.map((item) => (
                <Option
                  key={item.value}
                  item={item}
                  onChange={handleChangeLanguage}
                  isSelected={selectedLanguage === item.value}
                  className={s.optionWrapper}
                />
              ))}
            </div>
          </div>
          <div className={s.modalFooter}>
            <Label light>
              {`Naviance translation support may not be available in some areas, such as
            assessments and school or district-created content.`}
            </Label>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default TranslateSwitcherNeon;
