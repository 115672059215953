import React, { CSSProperties, useEffect, useState } from 'react';
import { WageMap } from 'components/WageMap';
import { fetchLatestWages } from './helpers';
import { Wage } from 'types/wages';
import { NeonCardStandard } from '@ps-refarch-ux/neon';

export interface WageCardProps {
  context: any;
  className: string;
}

// To merge the powerbuddy content and wages cards
const CardStyle: CSSProperties = {
  marginTop: '-22px',
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',
  paddingTop: '10px',
};

export function WageCard(props: WageCardProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const [wages, setWages] = useState<Wage[]>([]);

  useEffect(() => {
    let mounted = true;
    const fetchWages = async (occupationCodes: string[]) => {
      try {
        if (occupationCodes && occupationCodes.length > 0) {
          const latestWages = await fetchLatestWages(occupationCodes[0]);
          if (mounted) {
            setWages(latestWages);
          }
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };
    fetchWages(props.context?.occupationCodes);
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <NeonCardStandard
          className={props?.className}
          dataHeading={''}
          dataSkeleton="data-viz"
          style={CardStyle}
        />
      ) : (
        wages &&
        wages.length > 0 && (
          <WageMap wages={wages} className={props?.className} style={CardStyle} />
        )
      )}
    </>
  );
}
