export enum FeatureFlag {
  EdocsNext = 'releaseSucceedStudentFolderEdocsNext',
  CommonAppNextConnect = 'releaseSharedCaNextConnect',
  CommonAppAccountMatch = 'releaseStudentCommonappAccountMatch',
  PostEdocsNextConnectSameTab = 'releaseNavianceStudentPostEdocsNextConnectSameTab',
  TranslationService = 'releaseNavianceStudentTranslationClient',
  UpdateCollegeIMThinkingAboutDeadline = 'releaseUpdateCollegeIMThinkingAboutDeadline',
  UpdateCollgeDeadline = 'releaseUpdateCollgeDeadline',
  ReactList = 'releaseNavianceStudentReactList',
  Translate = 'releaseNavianceStudentTranslationClientDoTranslation',
  DeadlineAutoUpdate = 'releaseNavianceStudentDeadlineAutoUpdate',
  IgnoreRequestIfNoJwt = 'releaseNavianceStudentIgnoreRequestIfNoJwt',
  MatchAndFerpaAlert = 'releaseNavianceStudentMatchAndFerpaAlert',
  ConvertPseudoElement = 'featureNavianceStudentInlineManualConvertPseudoElement',
  SaveRecentLanguage = 'featureNavianceStudentTranslationSaveRecentLanguages',
  UnifiedUserLogin = 'featureNavianceStudentUnifiedUserLogin',
  DisableAboutMeEditForUU = 'featureNavianceStudentDisableAboutMeEditForUnifiedUser',
  ShowCAApplicantNameOnMatch = 'releaseNavianceStudentShowCaApplicantNameOnMatch',
  ComboPreferredLanguageKeyEnabled = 'releaseNavianceStudentComboPreferredLanguageKey',
  GoogleMapsKeyFromServiceEnabled = 'featureNavianceStudentGoogleMapsSecretKeyFromService',
  CaAccountDelete = 'releaseSharedCaMatchPermissionForCaAccountDelete',
}
