// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import { normalizeUrl } from 'utils/link';

const generateConstants = constantsGenerator('fc/applying');
const [
  GET_APPLICATION_MILESTONES,
  GET_APPLICATION_MILESTONES_SUCCESS,
  GET_APPLICATION_MILESTONES_FAIL,
]: string[] = generateConstants('GET_APPLICATION_MILESTONES');

type State = {
  results: Array<Object>,
  entities: Object,
  loading: boolean,
  loaded: boolean,
};

const initialState: State = {
  results: [],
  entities: {},
  loading: false,
  loaded: false,
};

const filterItems = (item) => !item.isDeleted;

const normalizeItems = (res) =>
  res.data.filter(filterItems).reduce((items, item) => {
    if (item.id) {
      items[item.id] = {
        ...item.college,
        ...item,
        url: normalizeUrl(item.college.url),
        applicationId: item.id,
        college: undefined,
      };
    }
    return items;
  }, {});

const collectIds = (res) => res.data.filter(filterItems).map((item) => item.id);

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_APPLICATION_MILESTONES:
      return {
        ...state,
        loading: true,
      };
    case GET_APPLICATION_MILESTONES_SUCCESS:
      return {
        ...state,
        results: collectIds(action.result),
        entities: normalizeItems(action.result),
        loading: false,
        loaded: true,
      };
    case GET_APPLICATION_MILESTONES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function fetchApplicationMilestones() {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        GET_APPLICATION_MILESTONES,
        GET_APPLICATION_MILESTONES_SUCCESS,
        GET_APPLICATION_MILESTONES_FAIL,
      ],
      promise: (client: Object) =>
        client.get(
          `${api.colleges}/colleges-im-applying-to/milestones?limit=${FETCH_MAX_RESULTS_LARGE}`
        ),
    });
}
