// @flow
import * as React from 'react';
import cx from 'classnames';
import Icon from 'components/Icon';
import s from './styles.scss';

type Props = {
  light: boolean,
  inline: boolean,
  className: string,
  required?: boolean,
  validationMsg?: ?string,
  children?: ?React.Node,
  isAdjacent?: boolean,
  text?: string,
  elementType?: string,
  isValid?: boolean,
};

export default class Label extends React.PureComponent<Props, void> {
  static defaultProps = {
    light: false,
    inline: false,
    className: '',
    required: false,
    validationMsg: null,
    isAdjacent: false,
    text: '',
    isValid: false,
  };

  render() {
    const {
      light,
      inline,
      className,
      required,
      children,
      validationMsg,
      isAdjacent,
      text,
      elementType,
      isValid,
      ...rest
    } = this.props;

    const validation =
      !this.props.max && !this.props.min && validationMsg && !isValid ? (
        <div className={s.validation}>
          {validationMsg && <Icon icon="alert" alt="warning" className={s.icon} />}
          {validationMsg}
        </div>
      ) : null;

    const cxClassName = cx(
      s.label,
      {
        [s.light]: light,
        [s.inline]: inline,
        [s.required]: required,
      },
      className
    );

    /* eslint-disable jsx-a11y/label-has-associated-control */
    /* eslint-disable jsx-a11y/label-has-for */
    if (isAdjacent) {
      return (
        <div className={cxClassName}>
          <label {...rest}>{text}</label>
          {children}
          {validation}
        </div>
      );
    }

    const Element = elementType || 'label';

    return (
      <Element className={cxClassName} {...rest}>
        {children}
        {validation}
      </Element>
    );
  }
}
