// @flow
import * as React from 'react';
import Loadable from 'react-loadable';
import s from './style.scss';

const Placeholder = () => <span className={s.icon}>◎</span>;

const AsyncIcon = Loadable({
  loader: () => import('./Icon' /* webpackChunkName: "icons" */),  // eslint-disable-line
  loading: Placeholder,
});

export default AsyncIcon;
