// @flow
import type { ComboBoxType } from 'types/inputs';

export const applicationTypeComboOptions: Array<ComboBoxType> = [
  {
    value: '0',
    code: 'RD',
    label: 'Regular Decision',
  },
  {
    value: '1',
    code: 'ED',
    label: 'Early Decision',
  },
  {
    value: '2',
    code: 'EA',
    label: 'Early Action',
  },
  {
    value: '3',
    code: 'EDII',
    label: 'Early Decision II',
  },
  {
    value: '4',
    code: 'ROLL',
    label: 'Rolling',
  },
  {
    value: '5',
    code: 'REA',
    label: 'Restrictive Early Action',
  },
  {
    value: '6',
    code: 'PRI',
    label: 'Priority',
  },
  {
    value: '7',
    code: 'OTHR',
    label: 'Other',
  },
  {
    value: '8',
    code: 'EAII',
    label: 'Early Action II',
  },
];

export const submitRadioOptions: Array<ComboBoxType> = [
  { value: '1', label: 'Via Common App' },
  { value: '2', label: 'Direct to the institution' },
  { value: '0', label: `I'm not sure yet` },
];

export const formatComboOptions: Array<ComboBoxType> = [
  { value: '0', label: 'N/A' },
  {
    value: '1',
    label: 'Paper',
  },
  {
    value: '2',
    label: 'Online',
  },
];

export const waitlistedDeferredOptions: Array<ComboBoxType> = [
  { value: '1', label: 'Waitlisted' },
  { value: '2', label: 'Deferred' },
];

export const checkboxGroupData: Array<ComboBoxType> = [
  { value: 'waitlisted', label: 'waitlisted' },
  { value: 'deferred', label: 'deferred' },
];

export const resultComboData: Array<string> = [
  'Unknown',
  'Accepted',
  'Denied',
  'Guaranteed Transfer',
  'Withdrawn',
  'Conditional Accept',
  'January Admit',
  'No decision',
  'Incomplete',
  'Summer Admit',
];

export const defaultApplicationTypeOptions: Array<ComboBoxType> = [
  {
    value: '0',
    code: 'RD',
    label: 'Regular Decision',
  },
];

export const rollingApplicationTypeOptions: Array<ComboBoxType> = [
  {
    id: 10,
    value: '4',
    code: 'ROLL',
    label: 'Rolling',
  },
];
