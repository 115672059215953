import * as React from 'react';
import cx from 'classnames';
import noop from 'utils/noop';
import ReactModal from 'react-modal';

import s from './style.scss';

export type Variations = 'default' | 'white';

export const variations = {
  default: 'default',
  white: 'white',
};

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onCancel: () => void;
  fullScreen: boolean;
  variation: Variations;
  className: string;
  overlayStyles: string;
}
export default class Modal extends React.PureComponent<Props, {}> {
  static defaultProps = {
    onCancel: noop,
    fullScreen: false,
    className: '',
    overlayStyles: '',
    variation: variations.default,
  };

  static variation = variations;

  render() {
    const {
      children,
      isOpen,
      onCancel,
      fullScreen,
      variation,
      overlayStyles,
      className: receivedCss,
    } = this.props;

    return (
      <ReactModal
        appElement={document.querySelector('#root')}
        className={cx(receivedCss, s[variation], { [s.fullScreen]: fullScreen })}
        overlayClassName={cx(s.overlay, overlayStyles)}
        onRequestClose={onCancel}
        isOpen={isOpen}
      >
        {children}
      </ReactModal>
    );
  }
}
