// @flow
import moment from 'moment';
import isObject from './isObject';

// test that string doesn't return non-latin characters
const NO_LOCALE = /^[\w-.\s,]*$/;

/**
 * String sorting
 * Use this inside Array.prototype.sort for arrays of strings or arrays of objects
 */
export function stringSort(field?: string) {
  return function (a: any, b: any): number {
    const strA: string = isObject(a) && field ? a[field] : a;
    const strB: string = isObject(b) && field ? b[field] : b;

    // it's faster to search latin characters this way
    if (NO_LOCALE.test(strA) && NO_LOCALE.test(strB)) {
      return strA > strB ? 1 : strA == strB ? 0 : -1; // eslint-disable-line
    }

    return strA.localeCompare(strB);
  };
}

export function stringSortNoCase(field?: string) {
  const stringSortFn = stringSort(field);

  return function (a: any, b: any): number {
    const strA: string = isObject(a) && field ? a[field] : a;
    const strB: string = isObject(b) && field ? b[field] : b;

    if (!strA || !strB) {
      return 0;
    }

    return stringSortFn(strA.toUpperCase(), strB.toUpperCase());
  };
}

export function dateSort(field: string, dir?: string = 'desc') {
  return function (a: any, b: any): number {
    if (!a[field] || a[field] === '') {
      return 1;
    }
    if (moment(a[field]).isSame(b[field])) {
      return 0;
    }
    return moment(a[field])[dir === 'desc' ? 'isAfter' : 'isBefore'](b[field]) ? 1 : -1;
  };
}

export function commentSort() {
  return (commentA: Object, commentB: Object) =>
    parseInt(commentA.id, 10) > parseInt(commentB.id, 10) ? -1 : 1;
}

export function favoriteSort(careerEntityA: Object, careerEntityB: Object) {
  const nameA =
    (careerEntityA.pathway && careerEntityA.pathway.pathway) ||
    (careerEntityA.career && careerEntityA.career.title) ||
    (careerEntityA.cluster && careerEntityA.cluster.name);
  const nameB =
    (careerEntityB.pathway && careerEntityB.pathway.pathway) ||
    (careerEntityB.career && careerEntityB.career.title) ||
    (careerEntityB.cluster && careerEntityB.cluster.name);

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;

  return 0;
}

export function byDueDateAndNameSort(taskA: Object, taskB: Object) {
  if (!taskA.dueDate) {
    return 1;
  }

  if (moment(taskA.dueDate) > moment(taskB.dueDate)) return 1;
  if (moment(taskA.dueDate) < moment(taskB.dueDate)) return -1;
  if (moment(taskA.dueDate).isSame(taskB.dueDate)) {
    // for custom task name comes at a parent level, where as built-in task takes the name of activity
    const taskAName = taskA.name || taskA.activity.name;
    const taskBName = taskB.name || taskB.activity.name;
    if (taskAName > taskBName) return 1;
    if (taskAName < taskBName) return -1;
  }
  return -1;
}
