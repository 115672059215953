// @flow
import unique from 'lodash/uniq';
import type { InputGroup } from 'containers/Colleges/Search/inputGroups';

/**
 * Extract list of ID's from objects array
 * @example
 *   const items = [{ value: '1', label: 'My Item1' }, { value: '2', label: 'My Item2' }];
 *   collectIds(items, 'value'); //=> ['1', '2'];
 * @param {Array<Object>} items - list of raw items
 * @param {string} idName - name of object field which represent ID
 */
export default function collectIds(items: Array<Object>, idName: string = 'id'): Array<string> {
  return unique(items.map((item: InputGroup): string => `${item[idName]}`));
}
