import { combineReducers } from 'redux';
import { injectAsyncReducers } from 'store';
import main from './main';
import considering from './considering';
import compare from './compare';
import applying from './applying';
import applicationMilestones from './applicationMilestones';
import addApplication from './addApplication';
import groups from './groups';
import scholarships from './scholarships';
import lookup from './lookup';
import scattergrams from './scattergrams';
import acceptance from './acceptance';
import match from './match';
import activematch from './activematch';
import supermatch from './supermatch';
import supermatchnext from './supermatchnext';
import events from './events';
import visits from './visits';
import otherStudentsLike from './otherStudentsLike';
import acceptedStudentsLikeYou from './acceptedStudentsLikeYou';
import hubs from './hubs';
import profiles from './profiles';
import commonApp from './commonApp';
import lookingForStudentsLikeYou from './lookingForStudentsLikeYou';
import activeMatchConnectionMatching from './activeMatchConnectionMatching';
import savedCollegeSearch from './savedCollegeSearch';

const colleges = combineReducers({
  main,
  compare,
  considering,
  groups,
  applying,
  applicationMilestones,
  addApplication,
  scholarships,
  lookup,
  scattergrams,
  acceptance,
  match,
  activematch,
  supermatch,
  supermatchnext,
  events,
  visits,
  otherStudentsLike,
  acceptedStudentsLikeYou,
  hubs,
  profiles,
  commonApp,
  lookingForStudentsLikeYou,
  activeMatchConnectionMatching,
  savedCollegeSearch,
});

export const SET_SAVED_SEARCH_ID = 'fc/colleges/search/SET_SAVED_SEARCH_ID';

export function setSavedSearch(id) {
  return (dispatch) => {
    dispatch({
      type: SET_SAVED_SEARCH_ID,
      id,
    });
  };
}

export const CHANGE_SELECTED_SEARCH = 'fc/colleges/search/CHANGE_SELECTED_SEARCH';

export function changeSelectedSearch(id) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_SEARCH,
      id,
    });
  };
}

export const CLEAR_SELECTED_SEARCH = 'fc/colleges/search/CHANGE_SELECTED_SEARCH';

export function clearSelectedSearch() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_SEARCH,
    });
  };
}

injectAsyncReducers({ colleges });
