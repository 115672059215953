// @flow
import { Component } from 'react';

type Props = {
  smooth?: boolean,
};

export default class ScrollToTopOnMount extends Component<Props> {
  static defaultProps = {
    smooth: true,
  };

  componentDidMount() {
    const topMostElement =
      window.document.querySelector('#header') || window.document.querySelector('#root');
    const options = this.props.smooth ? { behavior: 'smooth' } : undefined;

    if (topMostElement) topMostElement.scrollIntoView(options);
  }

  render() {
    return null;
  }
}
