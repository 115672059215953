import Loadable from 'react-loadable';

const Placeholder = () => null;

// prettier-ignore
const AsyncProfileMenu = Loadable({
  loader: () => import('./Careers2ProfileMenu' /* webpackChunkName: "c2-profile-menu" */),
  loading: Placeholder,
});

export default AsyncProfileMenu;
