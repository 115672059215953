// @flow
import React, { useEffect, useState } from 'react';
import type { RtnVideo as RtnVideoType } from 'types/rtn';
import Video from 'components/Video';
import VTTConverter from 'srt-webvtt';

declare type Props = {
  nowPlaying: RtnVideoType,
  autoPlay?: String,
};

const RtnVideo = ({
  nowPlaying: { thumb, url, captionsUrl, interests },
  autoPlay = true,
}: Props) => {
  const [captionsData, setCaptionsData] = useState({});

  useEffect(() => {
    // Due to CORS restrictions, FireFox requires this workaround to display RTN captions properly
    fetch(captionsUrl)
      .then((response) => response.blob())
      .then(async (blob) => {
        if (captionsUrl.endsWith('.vtt')) {
          setCaptionsData({ captionsUrl, objectUrl: URL.createObjectURL(blob) });
        } else if (captionsUrl.endsWith('.srt')) {
          // RTN serves some (not all!) of their captions in the SRT format which is non-standard for HTML5 Video
          const converter = await VTTConverter(blob);
          setCaptionsData({ captionsUrl, objectUrl: converter });
        } else {
          throw new Error(
            `Unsupported media extension for Road Trip Nation video caption at: ${captionsUrl}`
          );
        }
      });
  }, [captionsUrl, thumb, url]);

  if (!captionsData.objectUrl || captionsUrl !== captionsData.captionsUrl) return null;

  return (
    <Video
      description="video"
      preload
      autoPlay={autoPlay}
      poster={thumb}
      src={url}
      crossOrigin="anonymous"
      interests={interests}
    >
      <track src={captionsData.objectUrl} label="English" kind="captions" srcLang="en-us" />
    </Video>
  );
};

RtnVideo.defaultProps = {
  showRelatedVideos: true,
};

export default RtnVideo;
