// @flow
const EXTERNAL_ADDRESS = /^(https?:\/\/|#)/;

// eslint-disable-next-line
const VALID_ADDRESS = RegExp('^(https?://)(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$');

// update this when static files with other extensions are added
const FILE = /\.(pdf)$/;

/**
 * Detect if link is external
 * External link will start with http or https
 *
 * @param {String} link URL you want to test
 * @return {Boolean} true if link is external
 */
export function isLinkExternal(link: string | Object) {
  return typeof link === 'string' && !!EXTERNAL_ADDRESS.test(link);
}

/**
 * https://jira.hobsons.com/browse/CH-1450
 * To prevent cross origin attacks, browsers now implicitly set `rel=noopener` for any `target=_blank` link.
 * This will break the authentication in Naviance Student since the cookies won't carry over to the new tab.
 * We need to add `rel=opener` to all links that require authentication.
 * Previously the `Link` component was using the `isLinkExternal` function.
 * But that function decides that a link is external if it starts with `http`.
 * Since you can have an internal link starting with `http`, it doesnt solve the problem.
 * To keep backwards compatibility, this function will be used in the `Link` component instead.
 * It compares the link domain with the current page domain.
 */
export function isLinkSameDomain(link: string | Object) {
  const isLinkString = typeof link === 'string';
  if (isLinkString && link.startsWith('/')) return true;
  const hostWithProtocol = RegExp(`^((f|ht)tps?:)?//(${location.host})`);
  return isLinkString && hostWithProtocol.test(link);
}

/**
 * Check if is mailto link
 * Mailto url starts with `mailto:`
 *
 * @param {String} link URL you want to test
 * @return {Boolean} true if is mail link
 */
export function isMailLink(link: string | Object) {
  return typeof link === 'string' && link.trim().indexOf('mailto:') === 0;
}

/**
 * Detect if link is a file
 *
 * @param {String} link URL you want to test
 * @return {Boolean} true if link is file
 */
export function isFileLink(link: string | Object) {
  return typeof link === 'string' && FILE.test(link);
}

/**
 * Detect if link is valid
 *
 * @param {String} link URL you want to test
 * @return {Boolean} true if link is valid
 */
export function isLinkValid(link: string) {
  return typeof link === 'string' && VALID_ADDRESS.test(link);
}

/**
 * Normalize url
 *
 * @param {String} link URL you want to normalize
 * @return {String} normalized url
 */
export function normalizeUrl(link: string) {
  return typeof link === 'string' && isLinkExternal(link) ? link : `http://${link}`;
}
