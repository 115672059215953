export enum CollegePlanningAction {
  ADDED_TO_LIST_OFFER_CONNECTION = 'added_to_list_offer_connection',
  ADDED_TO_LIST_NO_CONNECTION = 'added_to_list_no_connection',
  NOT_INTERESTED_TO_CONNECT = 'not_interested_to_connect',
  CONNECTION_COMPLETE = 'connection_complete',
  REQUEST_ACTION_ERROR = 'request_action_error',
}

export const POST_FAV_ACTION_MESSAGE = 'yes i have added the college to my fav list';

export enum EVENT_TYPE {
  AM_FAVORITED = 'am_favorited',
  AM_NOT_INTERESTED = 'am_not_interested',
}

export const EVENT_APPLICATION_NAME = 'naviance_powerbuddy';
