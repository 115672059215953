import withGlobals from 'components/Globals';
import PageTemplate from 'components/PageTemplate';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ScriptLoader from 'react-script-loader-hoc';
import { connect } from 'react-redux';
import { getFeatureFlags } from 'selectors/featureFlags';
import { getPermissions } from 'selectors/permissions';
import api, { headed2UiUrl } from 'config/api';
import session from '../../config/session';
import { FeatureFlags } from 'types/featureFlags';
import { HSPermissions } from 'types/permissions';
import Icon from 'components/Icon';

interface Headed2Props extends RouteComponentProps {
  scriptsLoaded: boolean;
  currentUser: {};
  featureFlags: FeatureFlags;
  permissions: HSPermissions;
}

const Headed2UIWrapper: React.FunctionComponent<Headed2Props> = ({
  history,
  scriptsLoaded,
  currentUser,
  featureFlags,
  permissions,
}) => {
  const divId = 'naviance-headed2';
  const inputRef = React.useRef();
  useEffect(() => {
    setTimeout(() => {
      if (scriptsLoaded && (window as any).Headed2) {
        (window as any).Headed2(divId);
      }
    }, 1000);
  }, [scriptsLoaded, (window as any).Headed2, document.getElementById(divId)]);

  const { data, isValid } = session;
  const userSession = { userSession: data, isValid, currentUser };

  return scriptsLoaded ? (
    <PageTemplate title="Search Job Opportunities">
      <div
        id={divId}
        ref={inputRef}
        x-naviance-headed2-api-url={api.headed2}
        x-ns-jwt={session.jwt}
        x-user-session={JSON.stringify(userSession)}
        x-fcui-router-history={JSON.stringify(history)}
        x-icon={Icon}
        x-feature-flags={JSON.stringify(featureFlags)}
        x-permissions={JSON.stringify(permissions)}
      />
    </PageTemplate>
  ) : null;
};

export default withGlobals(
  connect((state) => ({
    auth: (state as any).auth,
    featureFlags: getFeatureFlags(state),
    permissions: getPermissions(state),
  }))(ScriptLoader(`${headed2UiUrl}`)(withRouter(Headed2UIWrapper)))
);
