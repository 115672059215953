// @noflow
import * as React from 'react';
import { useMemo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { defaultLanguage } from 'constants/languageOptions';
import ls from 'utils/localStorage';
import { FeatureFlag } from 'constants/featureFlags';
import { TranslationNotification } from 'components/TranslationNotification';

import { useMutationObserver } from 'utils/useMutationObserver';
import {
  shouldIgnoreTranslationPage,
  checkAddedNodes,
  checkCharacterDataNodes,
  checkAttributeNodes,
  comboPreferredLanguageKey,
} from 'utils/translation';

const TranslateAndReplace = () => {
  const dispatch = useDispatch();
  const [
    isStrengthExplorersShowQuestions,
    setStrengthsExplorerShowQuestionFlag,
  ] = useState(false);
  const auth = useSelector((state: any) => state.auth);
  const featureFlags = useSelector(
    (state: { featureFlags: { loaded: boolean; flags: { [key: string]: boolean } } }) =>
      state.featureFlags
  );

  const userId = auth && auth.id;
  const districtId = auth && auth.districtId;
  const highSchoolId = auth && auth.highSchoolId;
  const rootEl = useMemo(() => document.body, []);
  let preferredLanguageKey =
    userId && districtId
      ? `NAVI_${districtId}_${userId}_preferredLanguage`
      : 'NAVI__preferredLanguage';

  const isComboPreferredLanguageKeyEnabled: boolean =
    featureFlags &&
    featureFlags.loaded &&
    featureFlags.flags[FeatureFlag.ComboPreferredLanguageKeyEnabled];

  if (isComboPreferredLanguageKeyEnabled && userId && highSchoolId) {
    preferredLanguageKey = comboPreferredLanguageKey(districtId, highSchoolId, userId);
  }

  const preferredLanguage = ls.getItem(preferredLanguageKey) || defaultLanguage;

  // Declare current path
  const currentPath = window.location.pathname;
  const ignoreTranslation = shouldIgnoreTranslationPage(currentPath);
  const shouldTranslate = preferredLanguage !== defaultLanguage;

  const handleIframeMessage = (event: any) => {
    const eventData = event.data;
    const isRedirectMessage = eventData.type && eventData.type === 'strengthsExplorer';
    if (isRedirectMessage && eventData.data === 'showQuestions') {
      setStrengthsExplorerShowQuestionFlag(true);
    } else {
      setStrengthsExplorerShowQuestionFlag(false);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  if (!shouldTranslate) {
    return null;
  }

  const isInlineManualConvertPseudoElementEnabled: boolean =
    featureFlags &&
    featureFlags.loaded &&
    featureFlags.flags[FeatureFlag.ConvertPseudoElement];

  const debouncedCallback = useMemo(
    () => (mutations) => {
      let isAddedNodesRunning = false;
      mutations.forEach((mutation) => {
        if (mutation.type === 'characterData') {
          checkCharacterDataNodes(dispatch, mutation.target, preferredLanguage);
        } else if (mutation.type === 'attributes') {
          checkAttributeNodes(
            dispatch,
            mutation.target,
            preferredLanguage,
            mutation.attributeName
          );
        } else if (!isAddedNodesRunning) {
          isAddedNodesRunning = true;
          checkAddedNodes(
            rootEl,
            dispatch,
            preferredLanguage,
            isInlineManualConvertPseudoElementEnabled
          );
        }
      });
    },
    [rootEl, dispatch, preferredLanguage]
  );

  useMutationObserver(rootEl, debouncedCallback, ignoreTranslation);
  return shouldTranslate ? (
    <TranslationNotification
      currentPath={currentPath}
      preferredLanguage={preferredLanguage}
      dispatch={dispatch}
      isSEIframeVisible={isStrengthExplorersShowQuestions}
    />
  ) : null;
};

export default TranslateAndReplace;
