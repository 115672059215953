import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/Home/Routes' /* webpackChunkName: "home" */); // eslint-disable-line prettier/prettier

export default class HomeRoute extends React.Component {
  render() {
    return <Chunk load={load} {...this.props} />;
  }
}
