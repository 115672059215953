import * as React from 'react';

import Chunk from 'components/Chunk';

const loadContainer = () => import('containers/MyResume');

export default class MyResumeRoute extends React.Component {
  render() {
    return <Chunk load={loadContainer} />;
  }
}
