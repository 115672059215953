import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/Documents' /* webpackChunkName: "docs" */);  // eslint-disable-line prettier/prettier

export default class DocumentsRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
