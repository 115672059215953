// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { Location } from 'react-router';
import { areFeatureFlagsLoaded } from 'selectors/featureFlags';
import { fetchFeatureFlags } from 'modules/featureFlags';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import ScrollHere from 'components/ScrollHere';
import { relative, setHistory } from 'utils/url';
import LoginRoute from 'routes/Login';
import { disableInlineManual } from 'utils/inlineManual';
import RegisterRoute, { RegisterCode } from 'routes/Register';
import ForgotPasswordRoute from 'routes/ForgotPassword';
import noop from 'utils/noop';
import Privacy from 'routes/Privacy';
import GuestLogin from 'routes/Guest';
import SchoolLookup from 'routes/SchoolLookup';
import SchoolLookupElementary from 'routes/SchoolLookupElementary';
import AdditionalHelpRoute from 'routes/AdditionalHelp';
import SchoolUpdates from 'routes/SchoolUpdates';
import ChangesFromMySchool from 'routes/ChangesFromMySchool';
import SchoolCustomPage from 'routes/SchoolCustomPage';
import ErrorRoute from 'routes/Errors';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { isLoggingIn } from 'selectors/auth';

import RedirectComponent from './RedirectComponent';

const AnonymousHome = () => <Redirect to={relative('../')} />;

type Props = {
  fetchFeatureFlags: Function,
  featureFlagsLoaded: boolean,
  location: Location,
  isLogging: boolean,
};

@withRouter
@connect(
  (state) => ({
    featureFlagsLoaded: areFeatureFlagsLoaded(state),
    isLogging: isLoggingIn(state),
  }),
  {
    fetchFeatureFlags,
  }
)
export default class Anonymous extends React.Component<Props> {
  static defaultProps = {
    fetchFeatureFlags: noop,
    featureFlagsLoaded: false,
    location: {
      key: '',
      hash: '',
      search: '',
      pathname: '',
    },
    isLogging: false,
  };

  UNSAFE_componentWillMount() {
    this.props.fetchFeatureFlags();
  }

  componentDidMount() {
    disableInlineManual();
    setHistory(this.props.history);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.location.key !== nextProps.location.key) {
      if (!nextProps.featureFlagsLoaded) {
        nextProps.fetchFeatureFlags();
      }
    }
  }

  render() {
    if (this.props.isLogging) {
      return <LoadingPlaceholder />;
    }
    return (
      <div>
        <ScrollHere key="scrollHere" />
        <Switch key="route">
          <Redirect exact from="/bad-hsid" to="/auth/fclookup?bad-hsid=true" />

          <Route path="/:hsid/login" component={LoginRoute} />
          <Route exact path="/:hsid/newaccount" component={RegisterRoute} />
          <Route exact path="/:hsid/register" component={RegisterCode} />
          <Route path="/:hsid/forgot-password" component={ForgotPasswordRoute} />
          <Route path="/:hsid/guest" component={GuestLogin} />
          <Route path="/login" component={LoginRoute} />

          {/* This should contain all routes defined in Authorized */}
          <Route path="/main" component={LoginRoute} />
          <Route path="/colleges" component={LoginRoute} />
          <Route path="/careers" component={LoginRoute} />
          <Route path="/careers2" component={LoginRoute} />
          <Route path="/about-me" component={LoginRoute} />
          <Route path="/inbox" component={LoginRoute} />
          <Route path="/courses" component={LoginRoute} />
          <Route path="/closed-beta" component={LoginRoute} />
          <Route path="/my-planner" component={LoginRoute} />
          <Route path="/documents" component={LoginRoute} />
          <Route path="/student-readiness-results" component={LoginRoute} />
          <Route path="/contact-counselor" component={LoginRoute} />
          <Route path="/auth/fclookup" component={SchoolLookup} />
          <Route path="/fclookup/:districtId" component={SchoolLookupElementary} />
          <Route path="/privacy-statement" component={Privacy} />
          <Route path="/terms-of-service" component={Privacy} />
          <Route path="/additional-help" component={AdditionalHelpRoute} />
          <Route path="/select-child" component={LoginRoute} />
          <Route path="/update-alumni" component={LoginRoute} />
          <Route path="/demo-user" component={LoginRoute} />
          <Route path="/default" component={LoginRoute} />
          <Route path="/error" component={ErrorRoute} />
          <Route path="/my-school/changes" component={ChangesFromMySchool} />
          <Route path="/family-connection/auth/logout" component={LoginRoute} />
          <Route path="/family-connection/auth/login" component={RedirectComponent} />
          <Route path="/success-planner" component={RedirectComponent} />
          <Route path="/school-updates/:id" component={SchoolUpdates} />
          <Route path="/view-as-student/:studentId" component={LoginRoute} />
          <Route path="/view-as-parent/:parentId/:schoolId" component={LoginRoute} />
          <Route path="/demo-user/:schoolId" component={LoginRoute} />
          <Route path="/custom-page/:id" component={SchoolCustomPage} />
          <Route path="/:hsid/reset-password" component={LoginRoute} />
          <Route path="/:hsid/:unknown" component={AnonymousHome} />
          <Route exact path="/:hsid" component={LoginRoute} />
          <Redirect to="/auth/fclookup" />
        </Switch>
      </div>
    );
  }
}
