// @flow
import * as React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import type { Location } from 'react-router';
import getUrlParams from 'utils/getUrlParams';

type Props = {
  location: Location,
};

const mapping = {
  '/colleges/application/consideration': '/colleges/application/considering',
  '/colleges/application': '/colleges/applying-to',
  '/colleges/visit': '/colleges/visits',
  '/careers/roadtrip-nation': '/careers/roadtripnation',
  '/success-planner': '/my-planner',
  '/success-planner/': '/my-planner',
  '/auth/register/privacy-statement': '/privacy-statement',
  // support for /family-connection/ path
  '/family-connection/main/': '/main',
  '/family-connection/colleges/': '/colleges',
  '/family-connection/careers/': '/careers',
  '/family-connection/careers/explore/': '/careers/explore',
  '/family-connection/careers/explore': '/careers/explore',
  '/family-connection/careers/career-cluster-finder': '/careers/cluster-finder',
  '/family-connection/careers/career-cluster-finder/': '/careers/cluster-finder',
  '/family-connection/main/about-me/': '/about-me',
  '/family-connection/courses/': '/courses',
  '/family-connection/success-planner/': '/my-planner',
  '/family-connection/auth/register/privacy-statement/': '/privacy-statement',
  '/family-connection/auth/register/privacy-statement': '/privacy-statement',
  '/family-connection/auth/register/terms-of-service/': '/terms-of-service',
  '/family-connection/auth/register/terms-of-service': '/terms-of-service',
  '/family-connection/careers/roadtrip-nation/': '/careers/roadtripnation',
  '/family-connection/careers/roadtrip-nation': '/careers/roadtripnation',
  '/family-connection/colleges/application/consideration': '/colleges/application/considering',
  '/family-connection/colleges/application': '/colleges/applying-to',
  '/family-connection/colleges/visit': '/colleges/visits',
  '/family-connection/main/account/manage': '/about-me/profile/general',
  '/family-connection/resume/': '/about-me/resume/build',
  '/family-connection/resume': '/about-me/resume/build',
  '/family-connection/colleges/national-scholarship': '/colleges/scholarships/national',
  '/family-connection/colleges/national-scholarship/': '/colleges/scholarships/national',
  '/family-connection/strengthsexplorer': '/about-me/strengths-explorer',
  '/family-connection/strengthsexplorer/': '/about-me/strengths-explorer',
  '/family-connection/colleges/search': '/colleges/search',
  '/family-connection/colleges/search/': '/colleges/search',
};

@withRouter
export default class RedirectComponent extends React.Component<Props> {
  /**
   * redirect to the correct place when routes come in the format of
   *  /family-connection/
   *  /route?path=/colleges/search/123
   *  /route?uuid=8a2b939d-c4cd-4bba-acfd-3d06e8d470a4
   * https://jira.hobsons.com/browse/NTU-1879
   */
  getRedirectPath() {
    const { location } = this.props;
    const { pathname } = location;
    const urlParams = getUrlParams(location.search) || {};

    // if we come from /family-connection/ starting path
    if (mapping[pathname]) {
      return mapping[pathname];
    }

    // if we come from /route?path=/colleges/search/123
    if (urlParams.path) {
      return mapping[urlParams.path];
    }

    // support for college applying link
    // if we come from /family-connection/colleges/application/edit?cid=5006#5006
    if (pathname.includes('application/edit') && urlParams.cid) {
      return `/colleges/application/edit/${urlParams.cid}`;
    }
    // support for advance college search for majors
    // if we come from /family-connection/colleges/lookup/?byMajor=520301&cip=50127
    if (pathname.includes('colleges/lookup') && urlParams.cip) {
      return `/colleges/search/majors?majorId=${urlParams.cip}`;
    }

    // support for college overlaps
    // if we come from /route?uuid=8a2b939d-c4cd-4bba-acfd-3d06e8d470a4
    if (pathname.includes('view-overlaps') && urlParams.uuid) {
      return `/colleges/overlaps/uuid/${urlParams.uuid}`;
    }

    // support for college super match
    // /family-connection/colleges/college/view/?smscid=1100983
    if (pathname.includes('/college/view/') && urlParams.smscid) {
      return `/colleges/hubs/${urlParams.smscid}`;
    }

    // support redirect to login screen from succeed
    // /family-connection/auth/login?hsid=blue1hs
    if (pathname.includes('/auth/login') && urlParams.hsid) {
      return `/${urlParams.hsid}`;
    }

    return '/main';
  }

  render() {
    const redirectPath = this.getRedirectPath();
    return <Redirect to={redirectPath} />;
  }
}
