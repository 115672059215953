import { createStore as reduxCreateStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import clientMiddleware from 'utils/clientMiddleware';
import ApiClient from 'utils/ApiClient';
import { logger } from 'redux-logger';
import appReducer, { rootReducer } from '../modules/rootReducer';

export const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createStore = (client) => {
  const middlewares = [clientMiddleware(client), thunk];
  // If we are running in Cypress, add the logger middleware for easier debugging
  if (window.Cypress) {
    // Remove the group methods from the console, as Cypress does not record them correctly
    // and redux-logger will use them if available.
    delete console.group;
    delete console.groupCollapsed;
    delete console.groupEnd;

    middlewares.push(logger);
  }

  return reduxCreateStore(
    rootReducer(combineReducers(appReducer)),
    composeEnhancers(applyMiddleware(...middlewares))
  );
};

const store = createStore(new ApiClient());

/**
 * Register async loaded reducers in store and replace
 * current state-reducer with the a new reducer
 *
 * @export
 * @param {Object} store - the store object
 * @param {Object} asyncReducer - async reducer modules
 */
store.asyncReducers = {};

function replaceReducers(defaultReducers) {
  const merged = Object.assign({}, defaultReducers, store.asyncReducers);
  const combined = rootReducer(combineReducers(merged));
  store.replaceReducer(combined);
}

export function injectAsyncReducers(asyncReducers) {
  const injectReducers = Object.keys(asyncReducers).reduce((toAdd, reducer) => {
    // do not replace already existing reducer unless it was (potentially) hot-reloaded
    if (store.asyncReducers[reducer] && !module.hot) {
      delete toAdd[reducer];
    }

    return toAdd;
  }, asyncReducers);

  store.asyncReducers = Object.assign({}, store.asyncReducers, injectReducers);
  replaceReducers(appReducer);
}

/* istanbul ignore next */
if (module.hot) {
  module.hot.accept('../modules/rootReducer', () => {
    const nextReducer = require('../modules/rootReducer').default; // eslint-disable-line global-require

    store.replaceReducer(nextReducer);
  });
}

export const AppDispatch = typeof store.dispatch;

export default store;
