// @flow
import * as React from 'react';

type Props = {
  load: Function,
};

type State = {
  mod: ?Function,
};

export default class Chunk extends React.Component<Props, State> {
  state = {
    mod: null,
  };

  componentDidMount() {
    this.load(this.props);
  }

  load(props: Props) {
    props.load().then((mod: Object) => {
      this.setState({
        mod: mod.default,
      });
    });
  }

  render() {
    const { mod: Cmp } = this.state;
    return Cmp ? <Cmp {...this.props} /> : null;
  }
}
