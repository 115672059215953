import React, { createContext, useContext, useState } from 'react';

interface CollegeCardConnectionContextType {
  isDisabled(uuid: number): boolean;
  disable(uuid: number): void;
}

const CollegeCardConnectionContext = createContext<CollegeCardConnectionContextType | null>(
  null
);

export const useCollegeCardConnectionContext = () => {
  return useContext(CollegeCardConnectionContext);
};

// This is a workaround for the fact that the extensibility API is currently re-creating
// custom components when it shouldn't. As a consequence, we need to lift the state out
// of the custom component into a React context to ensure that the state is preserved
// even if the custom component is re-created from scratch.
// Once the extensibility API is fixed, we can remove this context and use local state.
export const CollegeCardConnectionProvider: React.FC = ({ children }) => {
  const [disabledScids, setDisabledScids] = useState<number[]>([]);

  const isDisabled = (scid: number) => disabledScids.includes(scid);
  const disable = (scid: number) => setDisabledScids([...disabledScids, scid]);

  return (
    <CollegeCardConnectionContext.Provider value={{ isDisabled, disable }}>
      {children}
    </CollegeCardConnectionContext.Provider>
  );
};
