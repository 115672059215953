/* eslint-disable */
const dataLayerName = "dataLayer";

export const initAnalytics = () => {
  const d = document;
  const s = "script";
  const g = d.querySelector('script[data-goptimize]');
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);

  j.async = true;
  j.src = "//www.googletagmanager.com/gtm.js?id=" + GTM_CODE;

  (g || g != null) ?
    g.parentNode.insertBefore(j, g.nextSibling) :
    f.parentNode.insertBefore(j, f);
};

const initDataLayer = () => {
  window[dataLayerName] = window[dataLayerName] || [];
}

export const getDataLayer = () => {
  initDataLayer();
  return window[dataLayerName];
}

// push a single value/object onto the data layer
export const addDataLayerValue = (value) => {
  const dl = getDataLayer();
  dl.push(value);
  return dl;
}

/**
 * Optional redux middleware can push every action onto the data layer
 *
 * @export
 * @param {Object} config
 * @returns
 */
export const gtmMiddleware = (config = {}) => () => next => action => {
  const { blacklist = [], whitelist = [] } = config;
  const { type, ...actionData } = action;
  const dataLayerValue = {
    event: type,
    payload: actionData
  };

  // no whitelist or blacklist
  if (whitelist.length === 0 && blacklist.length === 0) {
    addDataLayerValue(dataLayerValue);

    // whitelisting has precedence
  } else if (whitelist.length > 0) {
    if (whitelist.indexOf(type) > -1) addDataLayerValue(dataLayerValue);

    // blacklisting
  } else if (blacklist.length > 0) {
    if (blacklist.indexOf(type) === -1) addDataLayerValue(dataLayerValue);
  }

  return next(action);
};
