import Loadable from 'react-loadable';

const Placeholder = () => null;

// prettier-ignore
const AsyncFavoritesMenu = Loadable({
  loader: () => import('./Careers2FavoritesMenu' /* webpackChunkName: "c2-favorites-menu" */),
  loading: Placeholder,
});

export default AsyncFavoritesMenu;
