// @flow
import api from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import { stringSort } from 'utils/sorters';
import session from 'config/session';
import { injectAsyncReducers } from 'store';

const generateConstants = constantsGenerator('fc/parent');

const [
  GET_PARENT_STUDENTS,
  GET_PARENT_STUDENTS_SUCCESS,
  GET_PARENT_STUDENTS_FAIL,
]: string[] = generateConstants('GET_PARENT_STUDENTS');

export const [IMPERSONATE, IMPERSONATE_SUCCESS, IMPERSONATE_FAIL]: string[] = generateConstants(
  'impersonate'
);

export type State = {
  students: Object[],
  loaded: boolean,
};

const initialState: State = {
  students: [],
  loaded: false,
};

function normalizeStudents(students: Object[]) {
  return students.map((s) => ({ ...s.student })).sort(stringSort('firstName'));
}

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case IMPERSONATE:
    case GET_PARENT_STUDENTS:
      return {
        ...state,
        loaded: false,
      };
    case GET_PARENT_STUDENTS_SUCCESS:
      return {
        ...state,
        loaded: true,
        students: normalizeStudents(action.result),
      };
    case IMPERSONATE_SUCCESS:
      return {
        ...state,
        loaded: true,
      };
    case IMPERSONATE_FAIL:
    case GET_PARENT_STUDENTS_FAIL:
      return {
        ...state,
        loaded: true,
      };
    default:
      return state;
  }
}

export function fetchParentStudents(parentId: number) {
  return (dispatch: Function) => {
    // if there is no parentId don't do the call to the server
    if (!parentId) {
      return dispatch({ type: GET_PARENT_STUDENTS_SUCCESS, result: [] });
    }
    return dispatch({
      types: [GET_PARENT_STUDENTS, GET_PARENT_STUDENTS_SUCCESS, GET_PARENT_STUDENTS_FAIL],
      promise: (client: Object) => client.get(`${api.users}/students/${parentId}`),
    });
  };
}

export function impersonateStudent(
  studentId: number,
  parentUrlDeepLinkUrl?: string,
  studentLength?: number
) {
  return {
    types: [IMPERSONATE, IMPERSONATE_SUCCESS, IMPERSONATE_FAIL],
    promise: (client: Object) =>
      client
        .post(`${api.security}/impersonate`, {
          data: {
            value: Number(studentId), // weird issue with api endpoint that detects this as a string
          },
          params: {
            credentials: 'include',
          },
        })
        .then((token) => {
          // Decode jwt token and check if elementary student and redirect
          // If not then register token and redirect to main page
          const jwtDecode = session.decodeJwt(token);
          // Open new tab if student impersonated user session has redirect URL for Naviance for Elementary
          // If parent has only one child and is elementary redirect to N4E
          if (jwtDecode && jwtDecode.elementryLoginRedirectURL && token) {
            if (studentLength === 1) {
              session.register(token);
              window.location.href = jwtDecode.elementryLoginRedirectURL;
            } else {
              window.open(jwtDecode.elementryLoginRedirectURL, '_blank').focus();
            }
            return;
          }
          session.register(token);
          if (parentUrlDeepLinkUrl) {
            window.location = parentUrlDeepLinkUrl;
            return;
          }
          /* TODO reload all user data instead of reloading window */
          // Only reload user data to main for Non-elementary/ Big Naviance students
          window.location = '/main';
        }),
  };
}

injectAsyncReducers({ parent: reducer });
