import { formatIds } from 'utils/formatIds';
import React, { createRef } from 'react';
import cx from 'classnames';
import uniqueId from 'utils/uniqueId';
import noop from 'utils/noop';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Item from './Item';
import { ComboItemProps } from './Item';
import s from './styles.scss';

interface ComboBoxProps {
  id?: string;
  label?: string;
  value?: ComboItemProps['value'];
  options: ComboItemOption[];
  onSelect?: (value: ComboItemProps['value']) => void;
  className?: string;
  selectClassName?: string;
  labelClassName?: string;
  inline?: boolean;

  // which field from the dataset represents dropdown item's value
  // default "value"
  valueProp?: string;

  // which field from the dataset represents dropdown item's label
  // default "label"
  labelProp?: string;

  // true to remove the default N/A
  noDefault?: boolean;
  noDefaultLabel?: string;

  // color suitable for dark backgrounds
  invertColor?: boolean;

  // id to assign to data-test-id
  testId?: string;
  languageAttr?: boolean;
  disabled?: boolean;
  doNotTranslate?: boolean;
  labelInlineExtras?: JSX.Element;
}

interface State {
  value?: number | string | null;
}

interface ComboItemOption {
  id: number;
  valueProp: any;
  labelProp: string | number;
}

class ComboBox extends React.Component<ComboBoxProps, State> {
  static defaultProps: ComboBoxProps = {
    get id() {
      return uniqueId('checkbox_');
    },
    className: '',
    value: '',
    onSelect: noop,
    valueProp: 'value',
    labelProp: 'label',
    noDefaultLabel: 'N/A',
    options: [],
    languageAttr: false,
    disabled: false,
  };

  state = {
    value: '',
  };

  private comboElRef = createRef<HTMLSelectElement>();

  // set value from props to state
  UNSAFE_componentWillMount() {
    this.updateValueFromProps();
  }

  // update value if parent changed it
  UNSAFE_componentWillReceiveProps(nextProps: ComboBoxProps) {
    if (nextProps.value !== undefined && nextProps.value !== this.state.value) {
      this.updateValueFromProps(nextProps);
    }
  }

  // universal value-from-props updater
  updateValueFromProps = (props: ComboBoxProps = this.props) =>
    this.setState({ value: props.value });

  // update value in state based on element's value property
  onChange = () => {
    const newVal = this.comboElRef.current && this.comboElRef.current.value;

    this.setState(
      (state: State): State => {
        if (state.value !== newVal) {
          if (this.props.onSelect) {
            this.props.onSelect(newVal);
          }
          return { value: newVal };
        }
        return {};
      }
    );
  };

  render() {
    const {
      id,
      className,
      options,
      label,
      selectClassName,
      labelClassName,
      onSelect,
      value: originalValue,
      valueProp,
      labelProp,
      noDefault,
      noDefaultLabel,
      inline,
      invertColor,
      testId,
      languageAttr,
      doNotTranslate,
      labelInlineExtras,
      ...rest
    } = this.props;
    const { value } = this.state;

    const MaybeLabel = label ? Label : 'div';

    const dataTestId = testId || formatIds(label || '');

    return (
      <div
        className={cx(className, { [s.inline]: inline, [s.invertColor]: invertColor })}
      >
        <MaybeLabel htmlFor={id} className={cx(labelClassName, s.maybeLabel)}>
          {label ? (
            <span className={s.label}>
              {label}
              {labelInlineExtras}
            </span>
          ) : null}
          <div
            className={cx(selectClassName, s.selectWrapper)}
            data-navi-donottranslate={doNotTranslate}
          >
            <select
              data-test-id={dataTestId}
              id={id}
              onChange={this.onChange}
              ref={this.comboElRef}
              value={value}
              className={s.select}
              {...rest}
            >
              {noDefault ? null : <Item key="default" label={noDefaultLabel} value="" />}
              {options.map((cfg) => {
                return languageAttr ? (
                  <Item
                    key={cfg.id || cfg[valueProp]}
                    value={cfg.id || cfg[valueProp]}
                    label={cfg[labelProp]}
                    lang={cfg[valueProp]}
                  />
                ) : (
                  <Item
                    key={cfg.id || cfg[valueProp]}
                    value={cfg.id || cfg[valueProp]}
                    label={cfg[labelProp]}
                  />
                );
              })}
            </select>
            {!this.props.disabled && <Icon icon="chevron-down" className={s.icon} />}
          </div>
        </MaybeLabel>
      </div>
    );
  }
}

export default ComboBox;
