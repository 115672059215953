// @flow
import * as React from 'react';
import cx from 'classnames';
import Link from 'components/Link';
import noop from 'utils/noop';
import Icon from 'components/Icon';
import s from './styles.scss';

interface MenuItemProps {
  label: string;
  to?: string;
  onNavigate?: () => void;
  icon?: string;
  target?: string;
  openInNewTab?: boolean;
  setModal?: (label: string) => void;
}

const isSeparatedByDottedLine = (label: string) =>
  ['College Home', 'About Me Home'].includes(label);

const MenuItem = ({
  label,
  to = '/',
  onNavigate = noop,
  icon,
  target,
  openInNewTab = false,
  setModal = noop,
}: MenuItemProps) => (
  <Link
    className={cx(s.menuItem, isSeparatedByDottedLine(label) && s.dottedWrapper, s.link)}
    to={to}
    onClick={onNavigate}
    target={target || (openInNewTab && '_blank')}
    rel={openInNewTab && 'opener'}
    role="menuitem"
  >
    {icon && <Icon icon={icon} className={s.icon} />}
    {label}
  </Link>
);

export default MenuItem;
