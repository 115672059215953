// @flow
import { createSelector } from 'reselect';
import type { College } from 'types/colleges';
import type { ComboItemProps } from 'components/ComboBox/Item';

const getScattergramsState = (state: Object): Object => state.colleges.scattergrams;

const getScattergramColleges: (state: Object) => College[] = createSelector(
  [getScattergramsState],
  (state: Object): College[] => state.colleges
);

const getCollegeLabel = (college: College) => {
  let label;

  if (!college.totalApplying) {
    label = college.name;
    return label;
  }
  const appCount = `${college.totalApplying} app${college.totalApplying === 1 ? '' : 's'}`;

  label = `${college.name} (${appCount})`;

  return label;
};

export const shouldLoad: (Function) => boolean = createSelector(
  [getScattergramsState],
  (state: Object) => !state.loaded
);

export const getCollegesForCombobox: (state: Object) => ComboItemProps[] = createSelector(
  [getScattergramColleges],
  (colleges: College[]): ComboItemProps[] =>
    colleges.map((college: College) => ({
      coreMapping: college.coreMapping,
      value: college.id,
      label: getCollegeLabel(college),
    }))
);
