// @flow
/**
 * Card UI
 * https://github.com/naviance/family-connection-ui/pull/534
 *
 * Header and footer are components that span full width so padding doesn't influence them
 *
 * Examples:
 *    <Card title="What's new" shadow tBar="Subtitle">Content</Card>
 *    <Card title="News" shadow tBar="Secondary title" type="green">Content</Card>
 *    <Card title="Warning" shadow tBar="...or toolbar" type="warning">Content</Card>
 *    <Card
 *      title="Alert"
 *      shadow
 *      tBar={[
 *         { label: 'Foo', onClick: () => console.log('Foo') },
 *         { label: 'Bar', onClick: () => {}, selected: true },
 *      ]}
 *      type="smalldark"
 *      onMore={() => console.log('on more')}
 *      icon="experiment"
 *    >Content</Card>
 *
 */
import * as React from 'react';
import cx from 'classnames';
import noop from 'utils/noop';
import CommonHeader from './CommonHeader';
import Toolbar from './Toolbar';
import type { HeaderType } from './CommonHeader';
import type { Props as ToolbarProps } from './Toolbar';
import s from './styles.scss';

type Props = {
  children: React.Node,

  // Card type. Supported types are 'dark' | 'green' | 'warning' | 'alert' | 'smalldark' etc...
  // See CommonHeader component for the full list.
  // All but smalldark have predefined colors and title icon
  // Smalldark is a special type that changes colors of the toolbars and allows custom icon
  type: HeaderType,

  // custom header component that renders instead of title
  header?: React.Node,

  // custom footer component
  footer?: React.Node,

  // in some cases we need to disable default padding inside the card
  noPadding?: boolean,

  // in some cases we want to apply a default margin-top to the card.
  withMargin?: boolean,

  // when true, card will show shadow when hovered over. Shadow prop must be off
  hoverable?: boolean,

  // show shadow under the card
  shadow?: boolean,

  // icon used only with card type "smalldark". Placed right before the title
  icon?: ?string,

  // className to be applied to children
  childrenClass?: ?string,

  // if title is present then _header_ is ignored and CommonHeader is used
  title: ?React.Node,

  // top toolbar. Can be a string or array of button configs
  tBar: $PropertyType<ToolbarProps, 'bar'>,

  // bottom toolbar (special styling applies)
  bBar: $PropertyType<ToolbarProps, 'bar'>,

  // If onMore is a function then More button is shown with this callback
  // only applies to smalldark type
  onMore?: ?Function,

  className?: string,

  headerLevel?: string,

  nodeRef: (node: ?HTMLElement) => void,
};

export default class Card extends React.Component<Props, void> {
  static defaultProps = {
    header: null,
    tBar: null,
    bBar: null,
    footer: null,
    noPadding: false,
    withMargin: false,
    hoverable: false,
    shadow: false,
    title: null,
    type: window.location.pathname === '/student-readiness-results' ? 'purple' : 'dark',
    icon: null,
    children: null,
    childrenClass: null,
    onMore: null,
    nodeRef: noop,
    headerLevel: null,
  };

  render() {
    const {
      children,
      header,
      footer,
      noPadding,
      withMargin,
      hoverable,
      shadow,
      title,
      type,
      tBar,
      bBar,
      icon,
      onMore,
      nodeRef,
      childrenClass,
      headerLevel,
      ...rest
    } = this.props;

    return (
      <section
        {...rest}
        className={cx('card', s.card, rest.className, {
          [s.hoverable]: hoverable,
          [s.shadow]: shadow,
          [s.margin]: withMargin,
        })}
        ref={nodeRef}
      >
        {title ? <CommonHeader {...{ title, type, icon, onMore, headerLevel }} /> : header}
        <Toolbar top bar={tBar} small={type === 'smalldark'} />
        <div className={cx({ [s.padding]: !noPadding }, childrenClass)}>{children}</div>
        <Toolbar bottom bar={bBar} small={type === 'smalldark'} />
        {footer}
      </section>
    );
  }
}
