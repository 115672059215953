// @flow
import * as React from 'react';
import s from './styles.scss';

export const LoadingModalHeader = () => (
  <div className={s.headerContainer}>
    <div className={s.button} />
    <div className={s.title} />
  </div>
);

export default class LoadingPlaceholder extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className={s.container}>
        <div className={s.title} />
        <div className={s.subTitle} />
        <div className={s.textLine1} />
        <div className={s.textLine2} />
        <div className={s.textLine3} />
      </div>
    );
  }
}
