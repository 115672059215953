// eslint-disable-next-line max-classes-per-file
import config from 'config/rewritable-config';
import api, { host as apiHost } from 'config/api';
import session from 'config/session';
import expandObject from 'utils/expandObject';
import { push } from 'utils/url';
import { FeatureFlag } from 'constants/featureFlags.ts';
import ls from 'utils/localStorage';

/* istanbul ignore next */
function getFetchUrl(path) {
  return path;
}

const methods = ['get', 'post', 'put', 'patch', 'delete', 'file', 'getFile'];

const publicEndPoints = [
  `${apiHost}/featureFlags`,
  `${apiHost}/auth0migration/lock`,
  `${apiHost}/security/school`,
  `${apiHost}/highschool`,
  `${apiHost}/security/login`,
  `${apiHost}/security/session`,
  `${apiHost}/security/resetPassword`,
  `${api.users}/register/verify-access-code`,
  `${api.users}/register`,
];

export class ApiClientError extends Error {
  constructor(message, data) {
    super(message);

    // This is to avoid breaking any current rejection handling code.
    Object.assign(this, data);
  }
}

export default class ApiClient {
  headers = {
    'Content-Type': 'application/json',
    // cache buster for IE11 which otherwise serves GET requests from cache
    Pragma: 'no-cache',
  };

  constructor(resolveUrl = getFetchUrl) {
    /* istanbul ignore next */
    methods.forEach((method) => {
      this[method] = (
        path,
        { headers, data, timeout, params, flagsForApiClient = {} } = {},
        host = 'local'
      ) => {
        const isIgnoreRequestIfNoJwtEnabled =
          flagsForApiClient &&
          flagsForApiClient.loaded &&
          flagsForApiClient.flags &&
          flagsForApiClient.flags[FeatureFlag.IgnoreRequestIfNoJwt];
        let jwt = session.isValid() ? session.jwt : null;
        if (!jwt && ls.getItem('deepLinkingAuthorizedToken')) {
          jwt = ls.getItem('deepLinkingAuthorizedToken');
        }
        // Try to avoid making calls to the API which will result in 401s
        if (path.startsWith(apiHost)) {
          const notPublicPath = publicEndPoints.every((endPoint) => !path.startsWith(endPoint));

          if (notPublicPath && !session.isValid()) {
            session.clear();
            push('/main');
            return Promise.reject('invalid session');
          }

          if (isIgnoreRequestIfNoJwtEnabled) {
            if (notPublicPath && !jwt) {
              session.clear();
              push('/main');
              return Promise.reject('Authorization Token not found.');
            }
          }
        }

        let timerId;
        let abortController;
        if (timeout) {
          abortController = new AbortController();
          timerId = setTimeout(() => {
            abortController.abort();
          }, timeout);
        }

        return fetch(resolveUrl(path, host, config), {
          method: ApiClient.getMethod(method),
          headers: this.getHeaders(headers, method, jwt),
          ...(timeout && { signal: abortController.signal }),
          body:
            data &&
            JSON.stringify({
              // form data will need to be serialized later
              ...data,
            }),
          ...params,
        })
          .then((response) => {
            // we need to redirect to login on 401
            if (response.status === 401) {
              session.clear();
            }

            if (isIgnoreRequestIfNoJwtEnabled) {
              if (response.status >= 500) {
                return Promise.reject(response);
              }
            } else {
              /* eslint-disable no-lonely-if */
              if (response.status > 500) {
                return Promise.reject(response);
              }
              /* eslint-enable */
            }

            if (response.status === 406) {
              return Promise.reject(response);
            }

            // todo: check for 300-400 range too for redirects

            // return empty json object in no-content responses
            if (response.status === 204) {
              return {};
            }

            // here we assume it's json for all methods but succesfull getFile
            return method === 'getFile' && response.status === 200
              ? response.blob()
              : response.json();
          })
          .then((json) => {
            // we need to redirect to login on 401
            if (json.message && json.message.code === 401) {
              session.clear();
            }

            if (Array.isArray(json.errors)) {
              return Promise.reject({
                ...json,
                requestData: { ...data },
                requestParams: { ...params },
              });
            }
            if (json.message && json.message.code && json.message.code !== 200) {
              // if there is no message.code we take the response as a successful one
              return Promise.reject({
                error: json.message,
                requestData: { ...data },
                requestParams: { ...params },
              });
            }

            return Promise.resolve(json);
          })
          .catch((response) => {
            // we have two ways of getting code error from api
            // error object or message object
            const code =
              expandObject(response, 'error.code') || expandObject(response, 'message.code');
            // we need to redirect to login on 401
            if (code === 401) {
              session.clear();
              push('/main');
            }

            if (response.status === 406) {
              return push('/error/waf-blocking');
            }

            return Promise.reject(new ApiClientError(code, response));
          })
          .finally(() => {
            clearTimeout(timerId);
          });
      };
    });
  }

  /* istanbul ignore next */
  getHeaders(additionalHeaders = {}, method, jwt) {
    const authHeaders = jwt ? { authorization: jwt } : {};
    const dynamicHeaders = { ...authHeaders, ...additionalHeaders };
    return ['file', 'getFile'].includes(method)
      ? dynamicHeaders
      : { ...this.headers, ...dynamicHeaders };
  }

  /* istanbul ignore next */
  static getMethod(method) {
    if (method === 'file') {
      return 'POST';
    }
    if (method === 'getFile') {
      return 'GET';
    }
    return method.toUpperCase();
  }
}
