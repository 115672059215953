// @flow

// an error looks like this: "averageHours must be a positive number"
// we want validations object to look like this: {averageHours: 'must be a positive number'}
export function parseValidationErrors(action: Object) {
  const errors = (action.error && action.error.errors) || [];

  return errors.reduce((acc: Object, err: string) => {
    const parts = err.match(/^(\w*)(.*)$/);

    // we should always find something, but let's keep other finds intact in this unlikely situation
    if (!parts) {
      return acc;
    }

    acc[parts[1]] = parts[2] ? parts[2].trim() : '';

    return acc;
  }, {});
}
