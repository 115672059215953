// @flow

export type PaginatedSearchParams = {
  // Base url to construct request URL. It'll normally come from `config/api`
  baseUrl: string,
  // Last index passed in by `react-list`.
  lastIndex: number,
  // Last page that was loaded.
  lastPage: number,
  // Limit per page to be sent to backend for pagination
  limit?: number,
  // Total amount of results already loaded in the state
  totalResults: number,
  // Total number of items in database (normally comes with the GET request)
  totalItems: number,
  // Array of types for the first page load
  firstPageTypes: string[],
  // Array of types for any pages other than the first
  nextPageTypes: string[],
  // Any custom validations to decide wether or not load another page. If true, it won't load a next page
  customValidation?: boolean,
  // Total pages returned by the backend, in case the endpoint does return them.
  totalPages?: number,
};

export type PaginatedSearchResult = {
  // Resulting array of types to be dispatched
  types?: string[],
  // URL to make the request along with  its query string
  requestUrl?: string,
  // Next page number, used to save "lastPage" within the module.
  nextPage?: number,
  // Wether or not load a next page. If true, it'll proceed and load a next page.
  proceed: boolean,
};

export const paginatedSearch = ({
  baseUrl,
  lastIndex,
  lastPage,
  limit = 25,
  totalResults,
  totalItems,
  firstPageTypes,
  nextPageTypes,
  customValidation,
  totalPages,
}: PaginatedSearchParams): PaginatedSearchResult => {
  const nextPage = Math.ceil(lastIndex / limit) + 1;
  // however, if this is the last page we loaded, then ignore
  if (
    (totalResults === totalItems && lastPage > 0) ||
    lastPage >= nextPage ||
    (lastPage > 0 && totalItems === lastIndex) ||
    (totalPages && nextPage > totalPages && nextPage > 1) ||
    customValidation
  ) {
    return { proceed: false };
  }

  const types = nextPage > 1 ? nextPageTypes : firstPageTypes;

  lastPage = nextPage;
  return {
    types,
    requestUrl: `${baseUrl}?page=${nextPage}&limit=${limit}`,
    nextPage,
    proceed: true,
  };
};
