// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import cx from 'classnames';
import { logout, getCurrentUserData } from 'modules/auth';
import { fetchUnreadCount } from 'modules/mail';
import { fetchSurveys } from 'modules/surveys';
import IfFeatureAllowed from 'components/IfFeatureAllowed';
import { load as fetchHighSchoolInfo, setHsid } from 'modules/highschool';
import {
  getPermittedLinks,
  getPermittedSecondaryLinks,
  getNewNavigationFlag,
} from 'selectors/header';
import { getFeatureFlags, areFeatureFlagsLoaded } from 'selectors/featureFlags';
import {
  getCurrentUser,
  getParentId,
  isDemoUser,
  getLogoutText,
  getEditableUser,
  getH2Status,
  getH2AccountLink,
  isStaffOrParentUser,
} from 'selectors/auth';
import { getUnreadCount } from 'selectors/mail';
import { getHsid, getState } from 'selectors/highschool';
import type { Language } from 'modules/highschool';
import type { NavLink as NavLinkType } from 'selectors/header';
import Button from 'components/Button';
import Link from 'components/Link';
import Badge from 'components/Badge';
import Icon from 'components/Icon';
import ScreenReader from 'components/ScreenReader';
import TranslateSwitcher from 'components/TranslateSwitcher';
import noop from 'utils/noop';
import navianceEmblem from 'theme/images/naviance-emblem.svg';
import powerschoolStudentLogo from 'theme/images/powerschool-logo-white.png';
import sHamburgerBtn from 'components/HamburgerMenu/button.scss';
import {
  Careers2FavoritesMenu,
  Careers2ProfileMenu,
  Careers2MobileMenu,
  Careers2DesktopNavBar,
} from 'components/Careers2Header';
import type { FeatureFlags } from 'types/featureFlags';
import GoToHeaded2Modal from 'components/GoToHeaded2Modal';
import { h2Status, h2AccountLink } from 'modules/H2Auth';
import { parentView, studentPendingView, studentUnlinkView } from 'constants/h2Modal';
import SwitchGradeDropdown from './Dropdown/DemoUser';
import StudentsDropdown from './Dropdown/Student';
import LanguagesDropdown from './Dropdown/Language';
import s from './styles.scss';

type Props = {
  permittedLinks: Array<NavLinkType>,
  permittedSecondaryLinks: Array<NavLinkType>,
  currentUser: ?Object,
  logout: Function,
  history: Object,
  getCurrentUserData: Function,
  h2Status: Function,
  fetchUnreadCount: Function,
  unreadCount: number,
  anonymous: boolean,
  fetchHighSchoolInfo: Function,
  fetchSurveys: Function,
  setHsid: Function,
  hsid: string,
  parentId: number,
  isDemo: boolean,
  students: Object[],
  languages: Array<Language>,
  preferredLanguageId: string,
  logoutText: string,
  featureFlags: FeatureFlags,
  featureFlagsLoaded: boolean,
  editableUser: ?Object,
};

type State = {
  showMenu: boolean,
  bigMenuX: number,
  bigMenuVisible: ?NavLink,
  bigMenuIndex: ?number,
  modal: ?string,
};

type UnreadMessagesProps = {
  count: number,
  minimal: boolean,
};
type FeedbackProps = {
  minimal: boolean,
};

const UnreadMessages = ({ count, minimal }: UnreadMessagesProps) => {
  const label = `${count === 0 ? 'No' : ''} new message${count === 1 ? '' : 's'}`;
  const visualLabel = minimal ? '' : label;
  const title = minimal ? label : '';

  return (
    <NavLink to="/inbox" className={s.messages} title={title}>
      <Icon icon="mail" className={s.mailIcon} />
      {count > 0 && <Badge className={s.badge}>{count}</Badge>}
      <span>{visualLabel}</span>
    </NavLink>
  );
};

UnreadMessages.defaultProps = {
  minimal: false,
};

const FeedbackLink = ({ minimal }: FeedbackProps) => (
  <Link
    to="https://www.questionpro.com/t/ANu73Za61T"
    className={s.messages}
    title={minimal ? 'Give feedback' : ''}
  >
    <Icon icon="favorite-messages" className={s.mailIcon} />
    {!minimal && <span>Give feedback</span>}
  </Link>
);

FeedbackLink.defaultProps = {
  minimal: false,
};

@withRouter
@connect(
  (state) => ({
    permittedLinks: getPermittedLinks(state),
    permittedSecondaryLinks: getPermittedSecondaryLinks(state),
    currentUser: getCurrentUser(state),
    unreadCount: getUnreadCount(state),
    hsid: getHsid(state),
    parentId: getParentId(state),
    isDemo: isDemoUser(state),
    logoutText: getLogoutText(state),
    newNavigationEnabled: getNewNavigationFlag(state),
    featureFlags: getFeatureFlags(state),
    featureFlagsLoaded: areFeatureFlagsLoaded(state),
    editableUser: getEditableUser(state),
    linkStatus: getH2Status(state),
    headed2LinkPath: getH2AccountLink(state),
    isStaffOrParent: isStaffOrParentUser(state),
    stateCode: getState(state),
  }),
  {
    logout,
    getCurrentUserData,
    fetchUnreadCount,
    fetchHighSchoolInfo,
    setHsid,
    fetchSurveys,
    h2Status,
    h2AccountLink,
  }
)
export default class HeaderV2 extends React.Component<Props, State> {
  static defaultProps = {
    permittedLinks: [],
    permittedSecondaryLinks: [],
    students: [],
    logout: noop,
    history: {},
    currentUser: null,
    getCurrentUserData: noop,
    h2Status: noop,
    fetchUnreadCount: noop,
    fetchHighSchoolInfo: noop,
    setHsid: noop,
    fetchSurveys: noop,
    unreadCount: 0,
    anonymous: false,
    hsid: '',
    parentId: 0,
    isDemo: false,
    preferredLanguageId: '',
    logoutText: 'LOG OUT',
    featureFlags: {},
    featureFlagsLoaded: false,
    editableUser: null,
  };

  state = {
    showMenu: false,
    bigMenuX: 0,
    bigMenuVisible: null,
    bigMenuIndex: null,
    modal: null,
    gotoH2Popup: false,
  };

  async componentDidMount() {
    this.activateListener();
    if (!this.props.anonymous) {
      this.props.getCurrentUserData();
      this.props.fetchUnreadCount();
      this.props.fetchSurveys();
      await this.props.fetchHighSchoolInfo();
      const highschoolState = this.props.stateCode;
      const isAccountLinkingDisabled =
        this.props.featureFlagsLoaded &&
        this.props.featureFlags.releaseNavianceStudentHeaded2RemoveAccountLinking;

      if (highschoolState || isAccountLinkingDisabled) {
        const response = await this.props.h2Status();
        // Handle the API response data
        if (response) this.setState({ gotoH2Popup: true });
      }
    }
  }

  componentDidUpdate() {
    this.activateListener();
  }

  componentWillUnmount() {
    this.deActivateListener();
  }

  activateListener = () => {
    this.deActivateListener();
    document.addEventListener('click', this.hideBigMenuOnOutsideClick);
  };

  deActivateListener = () => {
    document.removeEventListener('click', this.hideBigMenuOnOutsideClick);
  };

  hideBigMenuOnOutsideClick = (e: Event) => {
    const target: any = e.target; // eslint-disable-line prefer-destructuring

    const { bigMenuIndex } = this.state;
    const isMatchingMenu = !!bigMenuIndex && target.dataset.index === bigMenuIndex.toString();
    const bigMenu = document.getElementsByClassName(s.bigMenu)[0];
    const inBigMenu = bigMenu && bigMenu.contains(target);

    if (!isMatchingMenu && !inBigMenu) {
      this.closeMenu();
    }
  };

  onLinkClick = () => {
    this.setState({ showMenu: false });
  };

  toggleMenu = () => {
    this.setState((prevState) => ({ showMenu: !prevState.showMenu }));
  };

  logout = () => {
    const { history } = this.props;

    this.props.logout().then(() => {
      this.props.setHsid(this.props.hsid);
      history.push(`/${this.props.hsid}`);
    });
  };

  showMenu = (link: Object, index: number) => (e: SyntheticEvent<*>) => {
    e.stopPropagation();

    const noItems =
      (!Array.isArray(link.menu) || !link.menu.length) &&
      (!Array.isArray(link.favorites) || !link.favorites.length) &&
      (!Array.isArray(link.lists) || !link.lists.length) &&
      (!Array.isArray(link.menus) || !link.menus.length);

    if (!noItems) e.preventDefault();

    // Allow toggling bigMenu closed if the same index is currently open
    if (this.state.bigMenuVisible && this.state.bigMenuIndex === index) {
      this.closeMenu();
      return;
    }
    // $FlowFixMe - getBoundingClientRect is supported in our target browsers https://caniuse.com/#feat=getboundingclientrect
    const elementBox = e.target.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const rightEdge = elementBox.x + elementBox.width;
    const overflow = windowWidth - rightEdge;
    const defaultOffset = 120;
    const bigMenuX = overflow > defaultOffset ? overflow - defaultOffset : 10;

    this.setState({ bigMenuX, bigMenuVisible: link, bigMenuIndex: index });
  };

  closeMenu = () => {
    this.setState({ bigMenuVisible: null });
  };

  setModal = (label: string) => {
    this.setState({ modal: label });
  };

  returnToHomePage = () => {
    window.location.assign('/');
  };

  generateHeaded2RedirectURL = async () => {
    try {
      // In this function, we are calling post method when gotoheaded2 is clicked
      this.setState({ disablePrimaryButton: true });
      await this.props.h2AccountLink();

      const { headed2LinkPath, error } = this.props;
      if (error || !headed2LinkPath) {
        throw new Error(this.state.errorMessage);
      }

      this.setState({
        headed2URL: headed2LinkPath,
      });

      location.href = headed2LinkPath;
    } catch (err) {
      this.setState({
        error: true,
        errorMessage: err.message,
      });
    } finally {
      this.setState({
        showDialog: false,
        disablePrimaryButton: false,
      });
    }
  };

  renderModal = () => {
    if (
      this.state.gotoH2Popup &&
      this.props.location.pathname &&
      this.props.location.pathname === '/job-search' &&
      this.props.linkStatus !== 'linked'
    ) {
      const { isStaffOrParent } = this.props;
      let {
        primaryButtonText,
        primaryButtonFunction,
        showSecondaryButton,
        showGotoHomePageLink,
      } = this.props;
      let { largeText, boldText, firstParagraph, secondParagraph, showFooterButton } = '';
      primaryButtonFunction = this.generateHeaded2RedirectURL;
      if (isStaffOrParent) {
        ({
          largeText,
          boldText,
          firstParagraph,
          secondParagraph,
          showFooterButton,
          showSecondaryButton,
          showGotoHomePageLink,
        } = parentView);
        primaryButtonText = 'Back to Homepage';
        primaryButtonFunction = this.returnToHomePage;
      } else if (this.props.linkStatus === 'pending') {
        ({
          largeText,
          boldText,
          firstParagraph,
          secondParagraph,
          showFooterButton,
          showSecondaryButton,
          showGotoHomePageLink,
        } = studentPendingView);
      } else {
        ({
          largeText,
          boldText,
          firstParagraph,
          secondParagraph,
          showFooterButton,
          showSecondaryButton,
          showGotoHomePageLink,
        } = studentUnlinkView);
      }
      return (
        <GoToHeaded2Modal
          handleModalState={() => this.setState({ modal: null })}
          largeText={largeText}
          boldText={boldText}
          firstParagraph={firstParagraph}
          secondParagraph={secondParagraph}
          primaryButtonText={primaryButtonText}
          primaryButtonFunction={primaryButtonFunction}
          showSecondaryButton={showSecondaryButton}
          showFooterButton={showGotoHomePageLink || showFooterButton}
          showGotoHomePageLink={showGotoHomePageLink}
          handleClose={this.returnToHomePage}
        />
      );
    }
    return null;
  };

  render() {
    const {
      anonymous,
      permittedLinks,
      permittedSecondaryLinks,
      currentUser,
      unreadCount,
      parentId,
      isDemo,
      logoutText,
      featureFlags,
      featureFlagsLoaded,
      editableUser,
    } = this.props;
    const { showMenu, bigMenuVisible } = this.state;
    const showLanguageDropdown =
      featureFlagsLoaded && !featureFlags.featureNavianceStudentNavigation;

    const isAccountLinkingDisabled =
      featureFlagsLoaded && featureFlags.releaseNavianceStudentHeaded2RemoveAccountLinking;

    return (
      <>
        <a className={s.skipMain} href="#main-container">
          Skip to main content
        </a>
        <header id="header" className={s.header} role="banner">
          <nav className={cx(s.c2Nav)} data-test-id="nav">
            <div className={s.c2NavContainer}>
              <div className={s.c2FlexContainer}>
                {currentUser && !anonymous && (
                  <div className={cx(sHamburgerBtn.navMobileMenu, s.hamburger)}>
                    <button
                      className={sHamburgerBtn.navMobileMenuButton}
                      onClick={this.toggleMenu}
                      aria-label="Open navigation menu"
                      type="button"
                    >
                      <Icon icon="menu" className={sHamburgerBtn.navMobileMenuIcon} />
                    </button>
                  </div>
                )}
                <NavLink to="/main" className={s.c2LogoWrapper}>
                  <img // eslint-disable-line
                    className={cx(s.logo, 'nophone')}
                    data-test-id="family_connection_header"
                    src={powerschoolStudentLogo}
                    alt="Naviance Student"
                    role="img"
                  />
                  <img
                    className={cx(s.emblem, s.c2Emblem)}
                    data-test-id="family_connection_emblem"
                    src={navianceEmblem}
                    alt="Logo"
                    role="presentation"
                  />
                </NavLink>
              </div>
              {currentUser && !anonymous && (
                <Careers2DesktopNavBar
                  showMenu={this.showMenu}
                  closeMenu={this.closeMenu}
                  bigMenuVisible={bigMenuVisible}
                  setModal={this.setModal}
                />
              )}
              {currentUser && !anonymous && (
                <div className={cx(s.c2FlexContainer, s.withSeparator, s.noMobile)}>
                  <Careers2FavoritesMenu />
                  {parentId && (
                    <span className={s.selectUserCombo}>
                      <StudentsDropdown selection={currentUser.id} />
                    </span>
                  )}
                  {isDemo && (
                    <span className={s.selectUserCombo}>
                      <SwitchGradeDropdown />
                    </span>
                  )}
                  {showLanguageDropdown && (
                    <IfFeatureAllowed target="languageBar">
                      <LanguagesDropdown />
                    </IfFeatureAllowed>
                  )}
                  <FeedbackLink minimal />
                  <UnreadMessages count={unreadCount} minimal />
                  <ScreenReader read="Menu" />
                  <Careers2ProfileMenu />
                  <TranslateSwitcher />
                  <Button
                    uppercase
                    onClick={this.logout}
                    variation="primary"
                    size="small"
                    className={s.c2LogoutBtn}
                    testId="logout-button"
                  >
                    {logoutText}
                  </Button>
                </div>
              )}
              {currentUser && !anonymous && (
                <div className={cx(s.c2FlexContainer, s.noDesktop)}>
                  <UnreadMessages count={unreadCount} minimal />
                  <TranslateSwitcher />
                </div>
              )}
            </div>
          </nav>
          {currentUser && !anonymous && (
            <Careers2MobileMenu
              show={showMenu}
              user={editableUser}
              onNavigate={this.onLinkClick}
              onClose={this.toggleMenu}
              onLogout={this.logout}
              permittedLinks={permittedLinks}
              permittedSecondaryLinks={permittedSecondaryLinks}
            />
          )}
        </header>
        {!isAccountLinkingDisabled && this.renderModal()}
      </>
    );
  }
}
