// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import { SAVE_COLLEGES_IAM_THINKING_SUCCESS } from 'modules/colleges/considering';

const generateConstants = constantsGenerator('fc/colleges/match/otherStudentsLike');
const [
  GET_COLLEGES_OTHER_STUDENTS_LIKE,
  GET_COLLEGES_OTHER_STUDENTS_LIKE_SUCCESS,
  GET_COLLEGES_OTHER_STUDENTS_LIKE_FAIL,
]: string[] = generateConstants('GET_COLLEGES_OTHER_STUDENTS_LIKE');

type State = {
  colleges: Object[],
  loading: boolean,
  loaded: boolean,
};

const initialState: State = {
  colleges: [],
  loading: false,
  loaded: false,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_COLLEGES_OTHER_STUDENTS_LIKE:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLEGES_OTHER_STUDENTS_LIKE_SUCCESS:
      return {
        ...state,
        colleges: action.result.colleges,
        loading: false,
        loaded: true,
      };
    case GET_COLLEGES_OTHER_STUDENTS_LIKE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    case SAVE_COLLEGES_IAM_THINKING_SUCCESS: {
      const newFavorites = action.result.map((favorite) => {
        const { college: { id } = { id: '' } } = favorite;
        return id;
      });
      const colleges = state.colleges.map((college) => ({
        ...college,
        matches: college.matches.map((match) => ({
          ...match,
          favorite: match.favorite || newFavorites.includes(match.id),
        })),
      }));
      return {
        ...state,
        colleges,
      };
    }
    default:
      return state;
  }
}

export const fetchCollegesOtherStudentsLike = () => ({
  types: [
    GET_COLLEGES_OTHER_STUDENTS_LIKE,
    GET_COLLEGES_OTHER_STUDENTS_LIKE_SUCCESS,
    GET_COLLEGES_OTHER_STUDENTS_LIKE_FAIL,
  ],
  promise: (client: Object) => client.get(`${api.colleges}/matches/other-students-like`),
});
