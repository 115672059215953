import * as React from 'react';
import { connect } from 'react-redux';
import { isParentUser, isViewAsStudent, isGuestUser } from 'selectors/auth';

interface Props {
  target: string | string[];
  isParent: boolean;
  children: React.ReactNode;
  isCounselor: boolean;
  isGuest: boolean;
  whenDenied?: () => React.ReactNode | null;
}

/**
 * Wrapper that renders component only if target role is true for current user
 * student | parent | counselor.
 */
export class IfUserRole extends React.Component<Props> {
  static defaultProps = {
    isParent: false,
    isCounselor: false,
    isGuest: false,
    target: [],
    whenDenied: () => null,
  };

  render() {
    const { target, isParent, children, isCounselor, whenDenied, isGuest } = this.props;
    const targets = Array.isArray(target) ? target : [target];

    if (!targets.length) {
      return children;
    }

    if (isParent && targets.includes('parent')) {
      return children;
    }

    if (isGuest && targets.includes('guest')) {
      return children;
    }

    if (isCounselor && targets.includes('counselor')) {
      return children;
    }

    if (targets.includes('student') && !isParent && !isCounselor && !isGuest) {
      return children;
    }

    return whenDenied();
  }
}

export default connect(
  (state) => ({
    isParent: isParentUser(state),
    isCounselor: isViewAsStudent(state),
    isGuest: isGuestUser(state),
  }),
  {}
)(IfUserRole);
