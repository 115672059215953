// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import collectIds from 'utils/collectIds';
import normalizeItems from 'utils/normalizeItems';
import {
  SET_SAVED_SEARCH_ID,
  CHANGE_SELECTED_SEARCH,
  CLEAR_SELECTED_SEARCH,
} from 'modules/colleges/index';

const generateConstants = constantsGenerator('fc/colleges/search');

const [
  GET_SAVED_COLLEGE_SEARCHES,
  GET_SAVED_COLLEGE_SEARCHES_SUCCESS,
  GET_SAVED_COLLEGE_SEARCHES_FAIL,
]: string[] = generateConstants('GET_SAVED_COLLEGE_SEARCHES');

const [
  SAVE_NEW_COLLEGE_SEARCH,
  SAVE_NEW_COLLEGE_SEARCH_SUCCESS,
  SAVE_NEW_COLLEGE_SEARCH_FAIL,
]: string[] = generateConstants('SAVE_NEW_COLLEGE_SEARCH');

const [
  DELETE_COLLEGE_SEARCH,
  DELETE_COLLEGE_SEARCH_SUCCESS,
  DELETE_COLLEGE_SEARCH_FAIL,
]: string[] = generateConstants('DELETE_COLLEGE_SEARCH');

type State = {
  loading: boolean,
  results: Object,
  entities: [],
  initialSelect: string,
  currentSelect: string,
};

const initialState: State = {
  loading: false,
  results: {},
  entities: [],
  initialSelect: '',
  currentSelect: '',
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_SAVED_COLLEGE_SEARCHES:
      return {
        ...state,
        loading: true,
      };
    case GET_SAVED_COLLEGE_SEARCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        results: normalizeItems(action.result),
        entities: collectIds(action.result) || [],
      };
    case GET_SAVED_COLLEGE_SEARCHES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SAVE_NEW_COLLEGE_SEARCH:
      return {
        ...state,
        loading: true,
      };
    case SAVE_NEW_COLLEGE_SEARCH_SUCCESS: {
      const newCollegeSearch = { [action.result.id]: action.result };
      return {
        ...state,
        results: { ...state.results, ...newCollegeSearch },
        entities: [...state.entities, action.result.id.toString()] || [],
        loading: false,
      };
    }
    case SAVE_NEW_COLLEGE_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_COLLEGE_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_SAVED_SEARCH_ID: {
      return {
        ...state,
        initialSelect: action.id.toString() || '',
        currentSelect: action.id.toString() || '',
      };
    }
    case CHANGE_SELECTED_SEARCH: {
      return {
        ...state,
        initialSelect: '',
        currentSelect: action.id || '',
      };
    }
    case CLEAR_SELECTED_SEARCH: {
      return {
        ...state,
        initialSelect: '',
        currentSelect: '',
      };
    }
    default:
      return state;
  }
}

export function fetchSavedCollegeSearches() {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        GET_SAVED_COLLEGE_SEARCHES,
        GET_SAVED_COLLEGE_SEARCHES_SUCCESS,
        GET_SAVED_COLLEGE_SEARCHES_FAIL,
      ],
      promise: (client: Object) => client.get(`${api.host}/saved-college-searches`),
    });
}

export function saveNewCollegeSearch(name: string, filters: Object) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        SAVE_NEW_COLLEGE_SEARCH,
        SAVE_NEW_COLLEGE_SEARCH_SUCCESS,
        SAVE_NEW_COLLEGE_SEARCH_FAIL,
      ],
      promise: (client: Object) =>
        client.post(`${api.host}/saved-college-searches`, {
          data: {
            name,
            searchFilter: filters,
          },
        }),
    });
}

export function deleteSavedCollegeSearch(id: string | number) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [DELETE_COLLEGE_SEARCH, DELETE_COLLEGE_SEARCH_SUCCESS, DELETE_COLLEGE_SEARCH_FAIL],
      promise: (client: Object) => client.delete(`${api.host}/saved-college-searches/${id}`),
    });
}
