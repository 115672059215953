import * as React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedComponent } from 'react-redux';
import * as ReactRouter from 'react-router';
import * as ReactRouterDOM from 'react-router-dom';
import { withReactCompatibilityShim } from './react-compatibility-shim';

export const withGlobals = (
  WrappedComponent: ConnectedComponent<React.ComponentType, any> | React.ComponentType
) => {
  return class extends React.Component {
    constructor(props: any) {
      super(props);
      global.React = React;
      global.ReactDOM = ReactDOM;
      global.ReactRouter = ReactRouter;
      global.ReactRouterDOM = ReactRouterDOM;
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

/**
 * A hook-like function that sets globals for React, ReactDOM, ReactRouter, and ReactRouterDOM
 * to be consumed by MFEs that want to share these libraries with the NavStudent host application
 * via the window object (a concept known as "externals" in webpack and vite).
 *
 * The exposed React module will be patched to shim the `useSyncExternalStore` hook. The latter
 * was added in React 18, and some newer libraries such as react-query may depend on it.
 */
export const useGlobals = () => {
  global.React = withReactCompatibilityShim(React);
  global.ReactDOM = ReactDOM;
  global.ReactRouter = ReactRouter;
  global.ReactRouterDOM = ReactRouterDOM;
};

export default withGlobals;
