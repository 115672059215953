import * as React from 'react';
import cx from 'classnames';
import ClickHOC, { variations, sizes } from 'components/ClickHOC';
import Icon from 'components/Icon';
import s from './styles.scss';

interface CustomProps {
  // HOC props
  children: any;
  className?: string;
  disabled: boolean;
  // own props
  onClick?: any;
  icon?: string | null;
  uppercase?: boolean;
  name?: string;
  testId?: string;
}

type Props = CustomProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export class Button extends React.PureComponent<Props> {
  static defaultProps: Props = {
    children: null,
    className: '',
    disabled: false,
    type: 'button',
    icon: null,
    testId: '',
  };

  static variation = variations;

  static size = sizes;

  handleOnClick = (event: React.SyntheticEvent) => {
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick();
    }
  };

  render() {
    const {
      className,
      disabled,
      children,
      type,
      icon,
      uppercase,
      name,
      testId,
      ...rest
    } = this.props;

    return (
      <button
        className={cx(
          s.pointer,
          { [s.disabled]: disabled, [s.uppercase]: uppercase },
          className
        )}
        disabled={disabled}
        onClick={this.handleOnClick}
        type={type}
        name={name}
        role="button"
        data-test-id={testId}
        {...rest}
      >
        {icon ? <Icon icon={icon} /> : null}
        {children}
      </button>
    );
  }
}

export default ClickHOC(Button);
