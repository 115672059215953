import * as React from 'react';
import Modal from 'components/Modal';
import s from './ExtendSessionModal.scss';
import Button from 'components/Button';

export interface ExtendSessionModalProps {
  onLogOut: () => any;
  onExtendSession: () => {};
  secondsRemaining: number;
  showModal: boolean;
}

export const getFormattedTimeRemaining = (secondsRemaining: number) => {
  if (secondsRemaining < 0) {
    return '0:00';
  }

  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining - minutes * 60;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${minutes}:${formattedSeconds}`;
};

function ExtendSessionModal({
  showModal,
  secondsRemaining,
  onExtendSession,
  onLogOut,
}: ExtendSessionModalProps) {
  return (
    <Modal isOpen={showModal} variation="white" overlayStyles={s.modalWrapper}>
      <section>
        <header className={s.header}>
          <h1 className="h4">Hey there!</h1>
        </header>

        <h1 className="h3">
          Your session is going to time out in{' '}
          {getFormattedTimeRemaining(secondsRemaining)} minutes.
        </h1>

        <p>
          Do you want to extend your session (that means you'll stay logged in to
          Naviance)?
        </p>

        <footer className={s.footer}>
          <Button variation="default" className={s.logOut} onClick={onLogOut}>
            Log Out
          </Button>
          <Button
            variation="primary"
            className={s.extendSession}
            onClick={onExtendSession}
            autoFocus
          >
            Extend Your Session
          </Button>
        </footer>
      </section>
    </Modal>
  );
}

export default ExtendSessionModal;
