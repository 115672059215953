import React, { useRef, useEffect } from 'react';
import Plyr from 'plyr/dist/plyr.polyfilled.js';
import './styles.scss';
import blankVideo from 'plyr/dist/blank.mp4';
import iconUrl from 'plyr/dist/plyr.svg';
import captions from 'static/test.vtt';
import { useDispatch, useSelector } from 'react-redux';
import { sendCtaTrigger } from 'modules/ctaTrigger';
import { areFeatureFlagsLoaded, getFeatureFlags } from 'selectors/featureFlags';
import { getPermissions } from 'selectors/permissions';
import { isFeatureFlagAllowed, isAllowed } from 'utils/permissions';
import {
  MfeEvent,
  addMfeEventListener,
  dispatchMfeEvent,
} from '@ps-refarch-ux/mfe-utils';
import {
  MFE_HOST_NAME,
  VIDEO_PAUSE_EVENT_NAME,
} from 'constants/pbChatBotMfeEventConstants';

interface CustomProps {
  src: string;
  description: string;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  interests?: string[];
}

type Props = CustomProps & React.VideoHTMLAttributes<HTMLVideoElement>;

const plyrOptions = {
  blankVideo,
  iconUrl,
  // Omitting the 'pip' (player in player) control and 'settings' control
  controls: [
    'play-large',
    'play',
    'progress',
    'current-time',
    'mute',
    'volume',
    'captions',
    'airplay',
    'fullscreen',
  ],
  settings: ['speed'],
};

const RoadTripEvents: { [key in string]: string } = {
  roadtrip_nation: 'WATCHES_ONE_INTERVIEW_IN_ROADTRIP_NATION',
  armed_nation: 'WATCHES_INTERVIEW_WITH_ARMED_SERVICE_LEADERS_IN_ROADTRIP_NATION',
};

const Video = (props: Props) => {
  const { src, className, description, children, interests = [], ...rest } = props;
  const videoRef = useRef();
  const dispatch = useDispatch();
  const isSendCtaTriggerEvents = useSelector((state) => {
    return (
      areFeatureFlagsLoaded(state) &&
      isFeatureFlagAllowed('featureSharedCta', getFeatureFlags(state)) &&
      isAllowed({
        acl: getPermissions(state),
        group: 'home',
        target: 'call_to_actions',
      })
    );
  });

  useEffect(() => {
    const player = new Plyr(videoRef.current, plyrOptions);

    if (player.elements.container) {
      player.elements.container.tabIndex = '-1';
    }

    player.on('exitfullscreen', () => {
      player.toggleControls(true);
      const fullscreenButton = player.elements.buttons.fullscreen;
      fullscreenButton.focus();
      fullscreenButton.addClass('plyr__tab-focus');
    });

    player.on('play', async () => {
      if (isSendCtaTriggerEvents) {
        dispatch(
          sendCtaTrigger({
            source: 'nav.student.frontend',
            detailType: RoadTripEvents.roadtrip_nation,
            eventData: {
              videoLink: props.src,
              interests,
            },
          })
        );
        // send cta trigger for interview with armed service leaders in roadtrip nation
        if (Array.isArray(interests) && interests.includes('Armed Services')) {
          dispatch(
            sendCtaTrigger({
              source: 'nav.student.frontend',
              detailType: RoadTripEvents.armed_nation,
              eventData: {
                videoLink: props.src,
                interests,
              },
            })
          );
        }
      }
    });

    player.on('playing', () => {
      player.elements.buttons.play.forEach((button) =>
        button.setAttribute('aria-label', 'Pause')
      );
      dispatchMfeEvent(MFE_HOST_NAME, VIDEO_PAUSE_EVENT_NAME, { videoUrl: props.src });
    });

    player.on('pause', () => {
      player.elements.buttons.play.forEach((button) =>
        button.setAttribute('aria-label', 'Play')
      );
    });

    const removePauseListener = addMfeEventListener(
      VIDEO_PAUSE_EVENT_NAME,
      (event: MfeEvent) => {
        if (event.context?.videoUrl !== props.src) {
          player.pause();
        }
      }
    );

    return () => {
      player.destroy();
      removePauseListener();
    };
  }, []);

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <div className={className}>
      <video
        ref={videoRef}
        controls
        controlsList="nodownload"
        src={src}
        {...rest}
        aria-label={description}
        preload="true"
        crossOrigin="anonymous"
      >
        {children}
      </video>
    </div>
  );
};

Video.defaultProps = {
  className: '',
  description: 'Play video',
};

export default Video;
