// @flow
import type { CollegeVisit } from 'types/colleges';
import * as React from 'react';
import { notify } from 'modules/notification';
import constantsGenerator from 'utils/constantsGenerator';
import moment from 'moment';
import api from 'config/api';
import { paginatedSearch } from 'utils/pagination';
import { getErrorMessage } from 'utils/api';

const generateConstants = constantsGenerator('fc/visits');

// Left here for future reference, currently not used
const registerErrors = {
  0: 'NONE', // generic
  1: 'INVALID_EVENT', // event is not valid
  2: 'DEADLINE_PASSED', // event has deadline passed
  3: 'EVENT_FULL', // event was already fully booked
  4: 'MAX_REACHED', // user already had maximum number of regs reached
  5: 'ALREADY_REGISTERED', // user was already registered
};

const [
  GET_COLLEGE_VISITS,
  GET_COLLEGE_VISITS_SUCCESS,
  GET_COLLEGE_VISITS_FAIL,
]: string[] = generateConstants('GET_COLLEGE_VISITS');

const [
  NEXT_PAGE_COLLEGE_VISITS,
  NEXT_PAGE_COLLEGE_VISITS_SUCCESS,
  NEXT_PAGE_COLLEGE_VISITS_FAIL,
]: string[] = generateConstants('NEXT_PAGE_COLLEGE_VISITS');

const [
  GET_COLLEGE_VISIT,
  GET_COLLEGE_VISIT_SUCCESS,
  GET_COLLEGE_VISIT_FAIL,
]: string[] = generateConstants('GET_COLLEGE_VISIT');

const [
  GET_LANDING_PAGE_VISITS,
  GET_LANDING_PAGE_VISITS_SUCCESS,
  GET_LANDING_PAGE_VISITS_FAIL,
]: string[] = generateConstants('GET_LANDING_PAGE_VISITS');

const [
  GET_REGISTRATION_STATUS,
  GET_REGISTRATION_STATUS_SUCCESS,
  GET_REGISTRATION_STATUS_FAIL,
]: string[] = generateConstants('GET_REGISTRATION_STATUS');

const [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL]: string[] = generateConstants('REGISTER');

const [
  REGISTER_CANCEL,
  REGISTER_CANCEL_SUCCESS,
  REGISTER_CANCEL_FAIL,
]: string[] = generateConstants('REGISTER_CANCEL');

type State = {
  items: Array<CollegeVisit>,
  landingPageVisits: Array<CollegeVisit>,
  entities: { [number]: CollegeVisit },
  results: Array<number>,
  registrationStatus: Object,
  loaded: boolean,
  loading: boolean,
  page: number,
  totalItems: number,
  loadingNewPage: boolean,
  collegeVisitRegistrationError: string,
};

const initialState: State = {
  items: [],
  entities: {},
  results: [],
  registrationStatus: {},
  landingPageVisits: [],
  loaded: true,
  loading: false,
  page: 1,
  totalItems: 0,
  loadingNewPage: false,
  collegeVisitRegistrationError: '',
};

function getMessageFromReasonCode(action: Object) {
  const serverErrorMessage = getErrorMessage(action);
  const reasonCode: number = serverErrorMessage?.match(/\d+/)?.pop();

  if (registerErrors[reasonCode] === 'EVENT_FULL') {
    return 'This college visit has reached the maximum number of attendees.';
  }

  return 'Something went wrong. Please try again later!';
}

function formatVisit(visit: CollegeVisit) {
  const visitTimeMoment = moment(visit.visitDate, 'YYYY-MM-DD hh:mm:ss');

  return {
    ...visit,
    date: {
      hour: visitTimeMoment.format('hh:mmA'),
      month: visitTimeMoment.format('MMMM'),
      date: visitTimeMoment.format('DD'),
      day: visitTimeMoment.format('ddd'),
    },
  };
}

function formatVisits(visits: Array<CollegeVisit>) {
  return visits.map(formatVisit);
}

function registerFollowUp(action: Object, state: Object, isCancel: boolean) {
  const currentRegistrations = state.registrationStatus.studentCurrent;

  return {
    ...state,
    entities: {
      ...state.entities,
      [action.id]: formatVisit(action.result),
    },
    items: (state.items || []).map((visit) =>
      visit.id === action.id ? formatVisit(action.result) : visit
    ),
    registrationStatus: {
      ...state.registrationStatus,
      studentCurrent: isCancel ? currentRegistrations - 1 : currentRegistrations + 1,
    },
  };
}

export function getVisitData(visit: CollegeVisit) {
  const registered = visit.student && visit.student.studentId;
  const showSeating = visit.totalRegistrations >= 0 && visit.maxAttendees > 0 && !registered;
  const deadlinePassed = visit.passedDaysCutoff || visit.passedHoursCutoff;
  const eventFull = visit.passedMaxAttendees;

  return {
    registered,
    showSeating,
    deadlinePassed,
    eventFull,
  };
}

export function shouldShowRegistration(visit: CollegeVisit, canAttendMoreVisits: boolean): boolean {
  const visitData = getVisitData(visit);

  return canAttendMoreVisits && !visitData.deadlinePassed && !visitData.eventFull;
}

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case NEXT_PAGE_COLLEGE_VISITS:
      return {
        ...state,
        loadingNewPage: true,
      };
    case GET_REGISTRATION_STATUS:
    case GET_COLLEGE_VISITS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_LANDING_PAGE_VISITS_SUCCESS: {
      return {
        ...state,
        landingPageVisits: formatVisits(action.result),
      };
    }
    case GET_REGISTRATION_STATUS_SUCCESS: {
      return {
        ...state,
        registrationStatus: action.result,
      };
    }
    case GET_COLLEGE_VISITS_SUCCESS: {
      return {
        ...state,
        items: formatVisits(action.result.data),
        loading: false,
        loaded: true,
        page: action.result.page,
        totalItems: action.result.totalItems,
      };
    }
    case REGISTER_CANCEL_SUCCESS:
      return registerFollowUp(action, state, true);
    case REGISTER_SUCCESS:
      return registerFollowUp(action, state, false);
    case REGISTER_FAIL:
      return {
        ...state,
        collegeVisitRegistrationError: getMessageFromReasonCode(action),
      };
    case GET_COLLEGE_VISIT_SUCCESS: {
      return {
        ...state,
        entities: {
          [action.id]: formatVisit(action.result),
        },
        results: state.results.find((result) => result === parseInt(action.id, 10))
          ? state.results
          : state.results.concat(action.id),
      };
    }
    case NEXT_PAGE_COLLEGE_VISITS_SUCCESS: {
      return {
        ...state,
        items: [...state.items, ...formatVisits(action.result.data)],
        loading: false,
        loaded: true,
        page: action.result.page,
        totalItems: action.result.totalItems,
        loadingNewPage: false,
      };
    }
    case NEXT_PAGE_COLLEGE_VISITS_FAIL:
    case GET_REGISTRATION_STATUS_FAIL:
    case GET_COLLEGE_VISITS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: true,
        loadingNewPage: false,
      };
    }
    default:
      return state;
  }
}

let lastPage: number = -1;
const limit = 10;
export const fetchCollegeVisits = (lastIndex: number) => (
  dispatch: Function,
  getState: Function
) => {
  const totalResults = getState().colleges.visits.items.length;
  const { totalItems } = getState().colleges.visits;
  if (!lastIndex && lastIndex !== 0) {
    lastIndex = 0;
    // if nothing is sent, last page is reset to -1
    lastPage = -1;
  }
  const { requestUrl, types, nextPage, proceed } = paginatedSearch({
    baseUrl: `${api.collegeVisits}/search`,
    lastIndex,
    lastPage,
    limit,
    totalResults,
    totalItems,
    firstPageTypes: [GET_COLLEGE_VISITS, GET_COLLEGE_VISITS_SUCCESS, GET_COLLEGE_VISITS_FAIL],
    nextPageTypes: [
      NEXT_PAGE_COLLEGE_VISITS,
      NEXT_PAGE_COLLEGE_VISITS_SUCCESS,
      NEXT_PAGE_COLLEGE_VISITS_FAIL,
    ],
  });

  if (!proceed) {
    // $FlowFixMe
    return Promise.resolve(true);
  }

  lastPage = nextPage || -1;
  return dispatch({
    types,
    promise: (client: Object) => client.get(requestUrl),
  });
};

export function fetchCollegeVisit(id: number) {
  return {
    types: [GET_COLLEGE_VISIT, GET_COLLEGE_VISIT_SUCCESS, GET_COLLEGE_VISIT_FAIL],
    promise: (client: Object) => client.get(`${api.collegeVisits}/${id}`),
    id,
  };
}

export function fetchStudentVisitRegistrationStatus() {
  return {
    types: [GET_REGISTRATION_STATUS, GET_REGISTRATION_STATUS_SUCCESS, GET_REGISTRATION_STATUS_FAIL],
    promise: (client: Object) => client.get(`${api.collegeVisits}/registration-status`),
  };
}

export function fetchLandingPageVisits() {
  return {
    types: [GET_LANDING_PAGE_VISITS, GET_LANDING_PAGE_VISITS_SUCCESS, GET_LANDING_PAGE_VISITS_FAIL],
    promise: (client: Object) => client.get(`${api.collegeVisits}/landing-page`),
  };
}

export function register(id: number, cb: Function) {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
      promise: (client: Object) => client.put(`${api.collegeVisits}/${id}`),
      id,
    })
      .then(() => {
        if (cb) cb();
        dispatch(
          notify({
            type: 'success',
            content: (
              <div>
                <b>See you there!</b> &nbsp;
                {`You're on the list to attend this college visit. Don't worry, if your plans change just let us know by cancelling your registration!`}
              </div>
            ),
          })
        );
      })
      .catch(() => {
        if (cb) cb();
        const { collegeVisitRegistrationError } = getState().colleges.visits;

        dispatch(
          notify({
            type: 'danger',
            content: collegeVisitRegistrationError,
          })
        );
      });
}

export function cancelRegister(id: number) {
  return (dispatch: Function) =>
    dispatch({
      types: [REGISTER_CANCEL, REGISTER_CANCEL_SUCCESS, REGISTER_CANCEL_FAIL],
      promise: (client: Object) => client.delete(`${api.collegeVisits}/${id}`),
      id,
    })
      .then(() =>
        dispatch(
          notify({
            type: 'success',
            content: (
              <div>
                <b>Sorry we'll miss you!</b> &nbsp;
                {`You've been removed from the list to attend this college visit. Remember, you can sign back up as long as there are open seats!`}
              </div>
            ),
          })
        )
      )
      .catch(() => {
        dispatch(
          notify({
            type: 'danger',
            content: 'Something went wrong. Please try again later!',
          })
        );
      });
}
