import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isAllowed } from 'utils/permissions';
import { getPermissions } from 'selectors/permissions';

interface Props {
  acl?: object;
  group?: string;
  target?: string | string[];
  requireAll: boolean;
  children?: React.ReactElement;
  whenDenied?: () => React.ReactNode | null;
}

/**
 * Wrapper that renders component only if it's permitted
 * If group is not supplied then ACL is ignored
 */
export class IfAllowed extends Component<Props> {
  static defaultProps: Partial<Props> = {
    whenDenied: () => null,
    requireAll: true,
    children: null,
  };

  render() {
    const { acl, group, target, requireAll, children, whenDenied } = this.props;

    if (!acl) {
      return null;
    }

    return isAllowed({ acl, group, target, checkFor: requireAll ? 'all' : 'any' })
      ? children
      : whenDenied();
  }
}

export default connect(
  (state) => ({
    acl: getPermissions(state),
  }),
  {}
)(IfAllowed);
