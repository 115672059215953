import { LanguageOption } from 'types/translation';

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export const languageOptions: LanguageOption[] = [
  { label: 'Afrikaans', value: 'af', placeholder: 'Afrikaans' },
  { label: 'Albanian', value: 'sq', placeholder: 'shqiptare' },
  { label: 'Amharic', value: 'am', placeholder: 'አማርኛ' },
  { label: 'Arabic', value: 'ar', placeholder: 'عربي' },
  { label: 'Armenian', value: 'hy', placeholder: 'հայերեն' },
  { label: 'Azerbaijani', value: 'az', placeholder: 'Azərbaycan' },
  { label: 'Bengali', value: 'bn', placeholder: 'বাংলা' },
  { label: 'Bosnian', value: 'bs', placeholder: 'bosanski' },
  { label: 'Bulgarian', value: 'bg', placeholder: 'български' },
  { label: 'Catalan', value: 'ca', placeholder: 'català' },
  { label: 'Chinese (Simplified)', value: 'zh', placeholder: '简体中文）' },
  { label: 'Chinese (Traditional)', value: 'zh-TW', placeholder: '中國傳統的）' },
  { label: 'Croatian', value: 'hr', placeholder: 'Hrvatski' },
  { label: 'Czech', value: 'cs', placeholder: 'čeština' },
  { label: 'Danish', value: 'da', placeholder: 'dansk' },
  { label: 'Dari', value: 'fa-AF', placeholder: 'درى' },
  { label: 'Dutch', value: 'nl', placeholder: 'Nederlands' },
  { label: 'English', value: 'en', placeholder: 'English' },
  { label: 'Estonian', value: 'et', placeholder: 'eesti keel' },
  { label: 'Farsi (Persian)', value: 'fa', placeholder: 'فارسی' },
  { label: 'Filipino, Tagalog', value: 'tl', placeholder: 'Filipino, Tagalog' },
  { label: 'Finnish', value: 'fi', placeholder: 'Suomalainen' },
  { label: 'French', value: 'fr', placeholder: 'Français' },
  { label: 'French (Canada)', value: 'fr-CA', placeholder: 'Français (Canada)' },
  { label: 'Georgian', value: 'ka', placeholder: 'ქართული' },
  { label: 'German', value: 'de', placeholder: 'Deutsch' },
  { label: 'Greek', value: 'el', placeholder: 'Ελληνικά' },
  { label: 'Gujarati', value: 'gu', placeholder: 'ગુજરાતી' },
  { label: 'Haitian Creole', value: 'ht', placeholder: 'Kreyòl ayisyen' },
  { label: 'Hausa', value: 'ha', placeholder: 'Hausa' },
  { label: 'Hebrew', value: 'he', placeholder: 'עִברִית' },
  { label: 'Hindi', value: 'hi', placeholder: 'हिन्दी' },
  { label: 'Hungarian', value: 'hu', placeholder: 'Magyar' },
  { label: 'Icelandic', value: 'is', placeholder: 'íslenskur' },
  { label: 'Indonesian', value: 'id', placeholder: 'bahasa Indonesia' },
  { label: 'Irish', value: 'ga', placeholder: 'Gaeilge' },
  { label: 'Italian', value: 'it', placeholder: 'Italiano' },
  { label: 'Japanese', value: 'ja', placeholder: '日本' },
  { label: 'Kannada', value: 'kn', placeholder: 'ಕನ್ನಡ' },
  { label: 'Kazakh', value: 'kk', placeholder: 'қазақ' },
  { label: 'Korean', value: 'ko', placeholder: '한국어' },
  { label: 'Latvian', value: 'lv', placeholder: 'latviski' },
  { label: 'Lithuanian', value: 'lt', placeholder: 'lietuvių' },
  { label: 'Macedonian', value: 'mk', placeholder: 'македонски' },
  { label: 'Malay', value: 'ms', placeholder: 'Melayu' },
  { label: 'Malayalam', value: 'ml', placeholder: 'മലയാളം' },
  { label: 'Maltese', value: 'mt', placeholder: 'Malti' },
  { label: 'Marathi', value: 'mr', placeholder: 'मराठी' },
  { label: 'Mongolian', value: 'mn', placeholder: 'Монгол' },
  { label: 'Norwegian', value: 'no', placeholder: 'norsk' },
  { label: 'Pashto', value: 'ps', placeholder: 'پښتو' },
  { label: 'Polish', value: 'pl', placeholder: 'Polski' },
  { label: 'Portuguese (Brazil)', value: 'pt', placeholder: 'Português (Brasil)' },
  { label: 'Portuguese (Portugal)', value: 'pt-PT', placeholder: 'Português (Portugal)' },
  { label: 'Punjabi', value: 'pa', placeholder: 'ਪੰਜਾਬੀ' },
  { label: 'Romanian', value: 'ro', placeholder: 'Română' },
  { label: 'Russian', value: 'ru', placeholder: 'Pусский' },
  { label: 'Serbian', value: 'sr', placeholder: 'Српски' },
  { label: 'Sinhala', value: 'si', placeholder: 'සිංහල' },
  { label: 'Slovak', value: 'sk', placeholder: 'slovenský' },
  { label: 'Slovenian', value: 'sl', placeholder: 'Slovenščina' },
  { label: 'Somali', value: 'so', placeholder: 'Soomaali' },
  { label: 'Spanish', value: 'es', placeholder: 'Español' },
  { label: 'Spanish (Mexico)', value: 'es-MX', placeholder: 'Español (mexico)' },
  { label: 'Swahili', value: 'sw', placeholder: 'kiswahili' },
  { label: 'Swedish', value: 'sv', placeholder: 'svenska' },
  { label: 'Tamil', value: 'ta', placeholder: 'தமிழ்' },
  { label: 'Telugu', value: 'te', placeholder: 'తెలుగు' },
  { label: 'Thai', value: 'th', placeholder: 'ไทย' },
  { label: 'Turkish', value: 'tr', placeholder: 'Türkçe' },
  { label: 'Ukrainian', value: 'uk', placeholder: 'український' },
  { label: 'Urdu', value: 'ur', placeholder: 'اردو' },
  { label: 'Uzbek', value: 'uz', placeholder: "o'zbek" },
  { label: 'Vietnamese', value: 'vi', placeholder: 'Tiếng Việt' },
  { label: 'Welsh', value: 'cy', placeholder: 'Cymraeg' },
];

export const defaultLanguage = `en`;

export const translationMessages = {
  noTranslation: `Naviance Translation is not available.`,
};
