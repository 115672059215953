// @flow
import { shouldLoad } from 'selectors/scattergrams';
import constantsGenerator from 'utils/constantsGenerator';
import type { College } from 'types/colleges';
import api from 'config/api';

const generateConstants = constantsGenerator('fc/colleges/scattergram');

const [
  GET_SCATTERGRAM_COLLEGES,
  GET_SCATTERGRAM_COLLEGES_SUCCESS,
  GET_SCATTERGRAM_COLLEGES_FAIL,
]: string[] = generateConstants('GET_COLLEGES');

type State = {
  loaded: boolean,
  colleges: College[],
};

const initialState: State = {
  loaded: false,
  colleges: [],
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_SCATTERGRAM_COLLEGES_SUCCESS:
      return {
        loaded: true,
        colleges: action.result,
      };
    default:
      return state;
  }
}

export function load() {
  return (dispatch: Function, getState: Function): Function | Promise<any> => {
    if (shouldLoad(getState())) {
      return dispatch({
        types: [
          GET_SCATTERGRAM_COLLEGES,
          GET_SCATTERGRAM_COLLEGES_SUCCESS,
          GET_SCATTERGRAM_COLLEGES_FAIL,
        ],
        promise: (client: Object): Promise<*> => client.get(`${api.colleges}/scattergram`),
      });
    }

    return Promise.resolve();
  };
}
