// @flow
import { createSelector } from 'reselect';
import expandObject from 'utils/expandObject';
import type { State as HighSchoolData, Language } from 'modules/highschool';
import { host as apiHost } from 'config/api';

export type NavLink = {
  +to: string,
  +children: mixed,
  'data-test-id': string,
  neededPermission?: string,
  icon?: string,
};

export const isHighSchoolLoaded = (state: Object) => !!expandObject(state, 'highschool.nid');

export const getHighSchool = (state: Object) => expandObject(state, 'highschool') || {};

export const getHighSchoolZipCode: (Object) => string = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.zip
);

export const isLoadingHighSchoolData: (Object) => boolean = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.loading
);

export const getCountry: (Object) => string = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.country
);

export const isAustralia: (Object) => boolean = createSelector(
  [getCountry],
  (country: string) => country === 'AU'
);

export const getState: (Object) => string = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.state
);

export const getOptions: (Object) => Object = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.options || {}
);

export const getMilestones: (Object) => Object = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.milestones || []
);

export const isUsernameLoginAllowed: (Object) => boolean = createSelector(
  [getOptions],
  (hsOptions: Object) => hsOptions.allowNoEmail === 1
);

export const getHsid: (Object) => string = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.hsid || hsData.alias
);

export const logoPlaceholder =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const getLogo: (Object) => string = createSelector(
  [getOptions, getHsid],
  (options: HighSchoolData, hsid: string) =>
    options.logoFilename ? `${apiHost}/highschool/${hsid}/logo` : logoPlaceholder
);

export const getWebsite: (Object) => string = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.url
);

export const getWebsiteHref: (Object) => string = createSelector(
  [getWebsite],
  // ensure URL is prefixed with http
  (site: ?string) => site && site.replace(/(^(?!https?))/, 'http://$1')
);

export const getHighschoolLanguages: (Object) => Array<Language> = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) =>
    hsData.languages ? hsData.languages.map((lang) => lang.language) : []
);

export const getPreferredLanguageId: (Object) => string = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.preferredLanguageId
);

export const isFamilyConnectionOn: (Object) => string = createSelector(
  [getHighSchool, isLoadingHighSchoolData],
  (hsData: HighSchoolData, loading: boolean) => {
    const isOn = expandObject(hsData, 'options.fcOn');
    if (loading) {
      return true;
    }
    return isOn !== 0;
  }
);

export const getSaml: (Object) => Object = createSelector(
  [getHighSchool],
  (hsData: HighSchoolData) => hsData.saml
);
