import * as React from 'react';
import sanitizeHTML from 'sanitize-html';
import noop from 'utils/noop';

export const noHTMLConfig: sanitizeHTML.IOptions = {
  allowedTags: [],
  allowedAttributes: {},
};
export const singleLineHTMLConfig: sanitizeHTML.IOptions = {
  allowedTags: ['b', 'strong', 'i', 'em', 'u', 'strike', 'sup'],
  allowedAttributes: {},
};

export function decodeHTMLEntities(text) {
  const entities = [
    ['amp', '&'],
    ['apos', "'"],
    ['#x27', "'"],
    ['#x2F', '/'],
    ['#39', "'"],
    ['#47', '/'],
    ['lt', '<'],
    ['gt', '>'],
    ['nbsp', ' '],
    ['quot', '"'],
    ['copy', '©'],
    ['reg', '®'],
    ['pound', '£'],
    ['yen', '¥'],
    ['euro', '€'],
  ];

  if (text === undefined || text === null) {
    return '';
  }

  for (let i = 0, max = entities.length; i < max; ++i)
    text = String(text).replace(
      new RegExp('&' + entities[i][0] + ';', 'g'),
      entities[i][1]
    );

  return text;
}

export const safeHTMLConfig: sanitizeHTML.IOptions = {
  allowedTags: [
    ...(singleLineHTMLConfig.allowedTags as string[]),
    'a',
    'ul',
    'ol',
    'nl',
    'li',
    'br',
    'hr',
    'p',
  ],
  allowedAttributes: {
    a: ['href', 'target'],
  },
};
export const complexHTMLConfig: sanitizeHTML.IOptions = {
  allowedTags: [
    ...(safeHTMLConfig.allowedTags as string[]),
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'code',
    'div',
    'span',
    'table',
    'thead',
    'caption',
    'tbody',
    'tr',
    'th',
    'td',
    'pre',
    'video',
    'source',
    'iframe',
    'img',
  ],
  allowedAttributes: {
    ...(safeHTMLConfig.allowedAttributes as { [index: string]: string[] }),
    iframe: ['frameBorder', 'width', 'height', 'allow', 'src'],
    video: ['width', 'height', 'allow', 'src', 'controls'],
    img: ['src', 'width', 'height', 'alt'],
    source: ['src', 'type'],
    table: ['align', 'border', 'cellpadding', 'cellspacing'],
    '*': ['style'],
  },
  allowedSchemesByTag: {
    img: ['http', 'https', 'data'],
    iframe: ['https'],
    video: ['https'],
    source: ['https'],
  },
  allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com', 'www.screencast.com'],
};

interface BaseProps {
  // HTML string to sanitize
  html: string;

  // Custom options to pass to 'sanitize-html'.
  // The defaults are here: https://github.com/punkave/sanitize-html#what-are-the-default-options
  sanitizerOptions: sanitizeHTML.IOptions;

  // optional component (custom or built-in) in which to set inner HTML
  component: React.ElementType<any>;

  // Callback ref
  containerRef: (ref: HTMLElement) => void;
}

type Props = BaseProps & React.HTMLAttributes<HTMLElement>;

/**
 * Sanitizes and renders HTML within a div.
 * You can pass extra props to customize the container div.
 */
export default class SanitizedHTML extends React.Component<Props> {
  static defaultProps = {
    html: '',
    sanitizerOptions: {},
    component: 'span',
    containerRef: noop,
  };

  render() {
    const {
      sanitizerOptions,
      html,
      component: Component,
      containerRef,
      ...otherProps
    } = this.props;

    const sanitizedHTML = sanitizeHTML(html, sanitizerOptions);
    return (
      <Component
        ref={containerRef}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        {...otherProps}
      />
    );
  }
}
