// @flow
import api from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import session from 'config/session';
import { injectAsyncReducers } from 'store';

const generateConstants = constantsGenerator('fc/counselor');

const [
  GET_DEMO_STUDENT,
  GET_DEMO_STUDENT_SUCCESS,
  GET_DEMO_STUDENT_FAIL,
]: string[] = generateConstants('GET_DEMO_STUDENTS');

const [
  GET_VIEW_AS_STUDENT,
  GET_VIEW_AS_STUDENT_SUCCESS,
  GET_VIEW_AS_STUDENT_FAIL,
]: string[] = generateConstants('GET_DEMO_STUDENTS');

const [
  GET_VIEW_AS_PARENT,
  GET_VIEW_AS_PARENT_SUCCESS,
  GET_VIEW_AS_PARENT_FAIL,
]: string[] = generateConstants('GET_DEMO_STUDENTS');

export type State = {
  demoStudent: boolean,
  viewAsStudent: boolean,
  loaded: boolean,
  status: string,
};

const initialState: State = {
  demoStudent: false,
  loaded: false,
  viewAsStudent: false,
  viewAsParent: false,
  status: '',
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_DEMO_STUDENT:
      return {
        ...state,
        loaded: false,
      };
    case GET_DEMO_STUDENT_SUCCESS:
      return {
        loaded: true,
        demoStudent: true,
      };
    case GET_DEMO_STUDENT_FAIL:
      return {
        ...state,
        loaded: true,
        status: 'error',
      };
    case GET_VIEW_AS_STUDENT:
    case GET_VIEW_AS_PARENT:
      return {
        ...state,
        loaded: false,
      };
    case GET_VIEW_AS_STUDENT_SUCCESS:
      return {
        loaded: true,
        viewAsStudent: true,
      };
    case GET_VIEW_AS_PARENT_SUCCESS:
      return {
        loaded: true,
        viewAsParent: true,
      };
    case GET_VIEW_AS_STUDENT_FAIL:
    case GET_VIEW_AS_PARENT_FAIL:
      return {
        ...state,
        loaded: true,
        status: 'error',
      };
    default:
      return state;
  }
}

export function fetchDemoStudent(
  grade: number = 0,
  showLoadingScreen: boolean = false,
  schoolId: string = ''
) {
  let done = false;
  /**
   * This way we show the loading screen for while,
   * so the user has feedback that he is demoing stunden user
   * https://jira.hobsons.com/browse/NTU-3032
   */
  if (showLoadingScreen) {
    const sec = 1000;
    let counter = 0;

    const interval = setInterval(() => {
      counter += sec;
      if (counter >= DEMO_SCREEN_DURATION && done) {
        clearInterval(interval);
        // we reload window to load all user session data again
        window.location = '/main';
      }
    }, sec);
  }
  return (dispatch: Function) =>
    // do the call to load student data
    dispatch({
      types: [GET_DEMO_STUDENT, GET_DEMO_STUDENT_SUCCESS, GET_DEMO_STUDENT_FAIL],
      promise: (client: Object) =>
        client
          .post(`${api.security}/demo-user`, {
            data: {
              value: Number(grade),
              schoolId: schoolId,
            },
            params: {
              credentials: 'include',
            },
          })
          .then((token) => {
            session.register(token);
            if (!showLoadingScreen) {
              /* TODO reload all user data instead of reloading window */
              window.location = '/main';
            } else {
              done = true;
            }
          }),
    });
}

export function viewAsStudent(studentId: number = 0, redirectUrl?: string) {
  return (dispatch: Function) =>
    // do the call to load student data
    dispatch({
      types: [GET_VIEW_AS_STUDENT, GET_VIEW_AS_STUDENT_SUCCESS, GET_VIEW_AS_STUDENT_FAIL],
      promise: (client: Object) =>
        client
          .post(`${api.security}/view-as-student`, {
            data: {
              value: Number(studentId),
            },
            params: {
              credentials: 'include',
            },
          })
          .then((token) => {
            session.register(token);
            /* TODO reload all user data instead of reloading window */
            window.location = redirectUrl || '/main';
          }),
    });
}

export function viewAsParent(parentId: number, schoolId: string) {
  return (dispatch: Function) =>
    // do the call to load student data
    dispatch({
      types: [GET_VIEW_AS_PARENT, GET_VIEW_AS_PARENT_SUCCESS, GET_VIEW_AS_PARENT_FAIL],
      promise: (client: Object) =>
        client
          .post(`${api.security}/view-as-parent`, {
            data: {
              value: Number(parentId),
              schoolId,
            },
            params: {
              credentials: 'include',
            },
          })
          .then((token) => {
            session.register(token);
            /* TODO reload all user data instead of reloading window */
            window.location = '/main';
          }),
    });
}

injectAsyncReducers({ counselor: reducer });
