import Loadable from 'react-loadable';

const Placeholder = () => null;

// prettier-ignore
const AsyncHeaderItem = Loadable({
  loader: () => import('./Careers2HeaderItem' /* webpackChunkName: "c2-header-item" */),
  loading: Placeholder,
});

export default AsyncHeaderItem;
