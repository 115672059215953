import gql from 'graphql-tag';
import get from 'lodash/get';

export const query = gql`
  query CollegeEvents(
    $studentProfile: StudentProfile
    $filter: EventSearchFilter
    $first: Int
    $after: String
  ) {
    searchCollegeEvents(
      studentProfile: $studentProfile
      filter: $filter
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          collegeEvent {
            id
            name
            timezone
            eventDateTime
            startTime
            eventEndDateTime
            endTime
            eventDescription
            registrationUrl
            registrationDeadline
            registrationDeadlineTime
            virtual
            collegeEventLocation {
              name
              street
              street2
              city
              state
              zipCode {
                zipCode
              }
            }
          }
          college {
            scid
            name
          }
          recommended
          recommendedAudienceProfile {
            id
          }
        }
      }
    }
  }
`;

export default function fetchCollegeEvents(client, filter, studentProfile, pagination) {
  const variables = {};
  const { first, after } = pagination || {};
  if (filter) variables.filter = filter;
  if (studentProfile) variables.studentProfile = studentProfile;
  if (first) variables.first = first;
  if (after) variables.after = after;
  return client.query({ query, variables }).then((response) => ({
    events: get(response, 'data.searchCollegeEvents.edges', []),
    pagination: {
      totalCount: get(response, 'data.searchCollegeEvents.totalCount', 0),
      ...get(response, 'data.searchCollegeEvents.pageInfo', {}),
    },
  }));
}
