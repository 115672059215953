// @noflow
export const divsToBeExcluded: string[] = [
  'donottranslate',
  'components-Icon-style-icon',
  // 'components-LandingHeader-styles-title',
  // 'components-PageTitle-styles-pageTitle',
  // 'h1 components-PageTitle-styles-pageTitle components-LandingHeader-styles-title',
  'result-row-decription-label',
  'college-name-profile-label',
];

export const excludeWords: string[] = [
  'PSAT',
  'SAT',
  'ACT Aspire',
  'ACT',
  'PreACT',
  'PRE ACT',
  'Achieveworks Assessments',
  'AchieveWorks Intelligences',
  'AchieveWorks Learning and Productivity',
  'AchieveWorks Personality',
  'X2Vol',
  'RoadTrip Nation',
  'Career Key',
  'AchieveWorks',
  'Gallup',
  'Strengths Explorer',
  'RoadTrip',
  'roadtrips',
  'Learning and Productivity',
  'StrengthsExplorer',
  'Intelligences',
  'Personality',
  'FAFSA',
  'SuperMatch',
  'Common Application',
  'Common App',
  'Coalition Application',
  'Coalition App',
];
