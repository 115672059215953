// @flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';

import ls from 'utils/localStorage';

export default class RedirectToUrlParam extends React.Component {
  url: string;

  constructor() {
    super();
    this.url = ls.getItem('loginRedirectUrl');
  }

  componentDidMount() {
    ls.removeItem('loginRedirectUrl');
  }

  render() {
    return <Redirect to={this.url} />;
  }
}
