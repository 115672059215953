// @flow
import { createSelector } from 'reselect';
import isOld from 'utils/isOld';
import type { LinkType, PagesListItem, UpdatesListItem, ResourcesLink } from 'types/home';
import type { Language } from 'modules/highschool';
import { englishId } from 'modules/highschool';
import { arePermissionsLoaded, getPermissions } from './permissions';
import { getPreferredLanguageId, getHighschoolLanguages } from './highschool';
import { getHeaderPermissions } from './header';
/**
 * Permissions
 */
const validateLanguageAndFilter = (
  collection: Object[],
  languageId: string,
  languages: Language[]
): Object[] => {
  collection = collection.map((item) => {
    if (!item.displayTo || item.displayTo.length === 0) {
      item.displayTo = ['en'];
    }
    return item;
  });
  return languageId && languages.length > 0
    ? collection.filter((item) => item.displayTo.find((lang) => lang === languageId))
    : collection.filter((item) => item.displayTo.find((lang) => lang === englishId));
};

const getHomePermissions = createSelector(
  [arePermissionsLoaded, getPermissions],
  (loaded: boolean, acl: Object): Array<string> =>
    loaded && acl.resources.home ? acl.resources.home : []
);

export const isAllowed = (state: Object): Function => {
  const permissions = getHomePermissions(state);
  return (feature: string): boolean => permissions.includes(feature);
};

// if we have any home permissions show default data
const hasHomePermissions = (state: Object): Function => {
  const permissions = getHomePermissions(state);
  const headerPermissions = getHeaderPermissions(state);
  return (): boolean => !!permissions.length || headerPermissions.includes('home');
};

/**
 * Welcome message
 */
export const getWelcome = (state: Object): $PropertyType<FC$PageHome, 'welcome'> =>
  state.home.welcome;

export const isWelcomeLoaded = createSelector(
  [getWelcome],
  (welcome: $PropertyType<FC$PageHome, 'welcome'>): any => welcome.loaded
);

export const getWelcomeTranslation = createSelector(
  [getWelcome, getPreferredLanguageId],
  (welcome: $PropertyType<FC$PageHome, 'welcome'>, preferredLanguageId: string): Object =>
    (welcome.translations || []).find(({ languageId }) => languageId === preferredLanguageId) ||
    welcome
);

export const getTitle = createSelector(
  [getWelcomeTranslation],
  (welcomeTranslation: FC$PageHomeWelcomeTranslation): string => welcomeTranslation.title
);

export const getMessage = createSelector(
  [getWelcomeTranslation],
  (welcomeTranslation: FC$PageHomeWelcomeTranslation): string =>
    welcomeTranslation.message || 'No Welcome Message is available at this time.'
);

/**
 * News
 */

export const getNews = (state: Object): Array<Object> => state.home.news;

const getNewsTs = (state: Object): number => state.home.newsTs;

export const shouldNewsRefresh = createSelector(
  [hasHomePermissions, getNewsTs],
  (allowed: Function, ts: number): boolean => allowed() && isOld(ts)
);

/**
 * Updates
 */
const getUpdatesTs = (state: Object): number => state.home.updatesTs;

export const getUpdates = (state: Object): Array<UpdatesListItem> => state.home.updates;

export const getUpdatesFilteredByLang = createSelector(
  [getUpdates, getPreferredLanguageId, getHighschoolLanguages],
  (
    updates: Array<UpdatesListItem>,
    preferredLangId: string,
    languages: Array<Language>
  ): Array<UpdatesListItem> => validateLanguageAndFilter(updates, preferredLangId, languages)
);

export const getUpdatesForHome = createSelector(
  [getUpdatesFilteredByLang],
  (updates: Array<UpdatesListItem>): Array<UpdatesListItem> => updates.slice(0, 3)
);

export const shouldUpdatesRefresh = createSelector(
  [hasHomePermissions, getUpdatesTs],
  (allowed: Function, ts: number): boolean => allowed() && isOld(ts)
);

/**
 * Resources
 */

export const getResources = (state: Object): Array<ResourcesLink> =>
  (state.home && state.home.resources) || [];

export const getTestPrepLink = createSelector(
  [getResources],
  (resources: Function): ResourcesLink =>
    resources && resources.filter((r) => r.type === 'navianceTestPrep')[0]
);

export const getCurriculumLink = createSelector(
  [getResources],
  (resources: Function): ResourcesLink =>
    resources && resources.filter((r) => r.type === 'curriculumLink')[0]
);
export const getX2VolLink = createSelector(
  [getResources],
  (resources: Function): ResourcesLink =>
    resources && resources.filter((r) => r.type === 'x2Vol')[0]
);

export const shouldResourcesRefresh = createSelector(
  [getResources],
  (resources: Function): boolean => !resources.length
);

/**
 * Quick links
 */

const getLinks = (state: Object): Array<Object> => state.home.links;
export const getPages = (state: Object): Array<PagesListItem> => state.home.pages;

export const getFilteredLinks = createSelector(
  [getLinks, getPreferredLanguageId, getHighschoolLanguages],
  (links: Array<LinkType>, languageId: string, languages: Array<Language>): Array<LinkType> =>
    validateLanguageAndFilter(links, languageId, languages)
);

export const getFirstThreeFilteredLinks = createSelector(
  [getFilteredLinks],
  (links: Array<LinkType>): Array<LinkType> => links.slice(0, 4)
);

export const getFilteredPages = createSelector(
  [getPages, getPreferredLanguageId, getHighschoolLanguages],
  (
    pages: Array<PagesListItem>,
    languageId: string,
    languages: Array<Language>
  ): Array<PagesListItem> => validateLanguageAndFilter(pages, languageId, languages)
);

export const getFirstThreeFilteredPages = createSelector(
  [getFilteredPages],
  (pages: Array<PagesListItem>): Array<PagesListItem> => pages.slice(0, 4)
);

const getPagesQuickLinks = createSelector(
  [hasHomePermissions, getPages],
  (allowed: Function, pages: Array<FC$HomePage>): Object | boolean =>
    allowed() && {
      title: 'Pages',
      links: pages.map((page) => ({ to: ``, label: page.name })),
    }
);

const getLinksQuickLinks = createSelector(
  [hasHomePermissions, getLinks],
  (allowed: Function, links: Array<FC$HomeLink>): Object | boolean =>
    allowed() && {
      title: 'Links',
      links: links.map((link) => ({ to: `//${link.url}`, label: link.name, target: '_blank' })),
    }
);

const getResourcesQuickLinks = createSelector([getResources], (resources: Array<FC$HomeResource>):
  | Object
  | boolean => {
  const links = resources.map((resource) => ({
    to: resource.url,
    label: resource.type === 'curriculumLink' ? 'Curriculum' : 'Naviance Test Prep',
  }));

  /* eslint-disable indent */
  return links.length === 0
    ? false
    : {
        title: 'Resources',
        links,
      };
});

const getCollegesQuickLinks = createSelector(
  [isAllowed],
  (allowed: Function): Object | boolean =>
    allowed('colleges') && {
      title: 'My Colleges',
      links: [
        {
          to: '/colleges/application/considering',
          label: `Colleges I'm thinking about`,
        },
        {
          to: '/colleges/applying-to',
          label: `Colleges I'm applying to`,
          aclGroup: 'edit',
          aclTarget: 'add_active_applications',
        },
      ],
    }
);

export const getQuickLinks = createSelector(
  [getCollegesQuickLinks, getPagesQuickLinks, getResourcesQuickLinks, getLinksQuickLinks],
  (...sections) => sections.filter(Boolean)
);
