import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { connect } from 'utils/connect';
import { getCurrentUserData } from 'modules/auth';
import { load as loadHighschool } from 'modules/highschool';
import { initHeapAnalytics } from 'utils/heapAnalytics';
import { isFeatureFlagAllowed } from 'utils/permissions';
import { getFeatureFlags } from 'selectors/featureFlags';
import { getAuth, userLoaded } from 'selectors/auth';
import { getHighSchool, isHighSchoolLoaded } from 'selectors/highschool';

function tryInitHeapAnalytics(...args) {
  try {
    initHeapAnalytics(...args);
  } catch (err) {
    console.error(`Heap initialization failed: ${err.message}`);
  }
}

export default function HeapAnalytics({ authenticated }) {
  const dispatch = useDispatch();
  const store = useStore();
  const loaded = useSelector((state) => userLoaded(state) && isHighSchoolLoaded(state));
  const enableSimplifiedConfig = useSelector((state) =>
    isFeatureFlagAllowed('devNavianceStudentHeapAnalyticsEnvConfiguration', getFeatureFlags(state))
  );

  React.useEffect(() => {
    if (!authenticated) {
      if (enableSimplifiedConfig) {
        tryInitHeapAnalytics(null, null, true);
      } else {
        tryInitHeapAnalytics();
      }
    } else {
      dispatch(getCurrentUserData());
      dispatch(loadHighschool());
    }
  }, [authenticated]);

  React.useEffect(() => {
    if (authenticated && loaded) {
      const state = store.getState();
      if (enableSimplifiedConfig) {
        tryInitHeapAnalytics(getAuth(state), getHighSchool(state), true);
      } else {
        tryInitHeapAnalytics(getAuth(state), getHighSchool(state));
      }
    }
  }, [authenticated, loaded, store]);

  return null;
}

@connect(() => ({}), {
  getCurrentUserData,
  loadHighschool,
})
export class LegacyHeapAnalytics extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      Promise.all([this.props.getCurrentUserData(), this.props.loadHighschool()]).then(
        ([currentUser, highschool]) => {
          initHeapAnalytics(currentUser, highschool);
        }
      );
    }, 1);
  }

  render() {
    return null;
  }
}
