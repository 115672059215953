// @flow
import type { ControlsItemsById } from 'utils/normalizeControlsItems';
import { needLoading } from 'selectors/colleges';
import constantsGenerator from 'utils/constantsGenerator';
import normalizeControlsItems from 'utils/normalizeControlsItems';
import collectIds from 'utils/collectIds';
import api from 'config/api';

const generateConstants = constantsGenerator('fc/colleges');

const [GET_GROUPS, GET_GROUPS_SUCCESS, GET_GROUPS_FAIL]: string[] = generateConstants('GET_GROUPS');

type State = {
  loaded: boolean,
  entities: ControlsItemsById,
  results: number[],
};

const initialState: State = {
  loaded: false,
  entities: {},
  results: [],
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_GROUPS_SUCCESS:
      return {
        loaded: true,
        entities: normalizeControlsItems(action.result.data, 'id', 'name', (item) => ({
          ...item,
          value: String(item.id),
        })),
        results: collectIds(action.result.data, 'id'),
      };
    default:
      return state;
  }
}

export function load() {
  return (dispatch: Function, getState: Function): Function | Promise<any> => {
    if (needLoading(getState())) {
      return dispatch({
        types: [GET_GROUPS, GET_GROUPS_SUCCESS, GET_GROUPS_FAIL],
        promise: (client: Object): Promise<*> =>
          client.get(`${api.colleges}/group-search?limit=${FETCH_MAX_RESULTS_LARGE}`),
      });
    }

    return Promise.resolve();
  };
}
