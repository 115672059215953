const planIdToName = new Map([
  [[0], 'Unknown'],
  [[1], "College: Associate's Degree"],
  [[2], "College: Bachelor's Degree"],
  [[3], 'Career Education or Trade School'],
  [[4], 'Military Service'],
  [[5], 'Employment'],
  [[6], 'Undecided'],
  [[7, 14], 'Apprenticeship Program'],
  [[9], 'Gap or Service Year'],
  [[15], 'Not Answered'],
]);

export const getNameForPlanId = (planId: number): string => {
  const foundEntry = Array.from(planIdToName.entries()).find(([key]) =>
    key.includes(planId)
  );

  if (foundEntry) {
    return foundEntry[1];
  }
  return 'Unknown';
};
