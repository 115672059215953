// @flow
import * as React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import noop from 'utils/noop';
import { push } from 'utils/url';
import { Row, Col } from 'components/Grid';
import ComboBox from 'components/ComboBox';
import PageTitle from 'components/PageTitle';
import Link from 'components/Link';
import IconInput from 'components/IconInput';
import Button from 'components/Button';
import Icon from 'components/Icon';
import IfUserRole from 'components/IfUserRole';
import IfAllowed from 'components/IfAllowed';
import { isFeatureFlagAllowed, isAllowed } from 'utils/permissions';
import {
  search,
  setHeaderSearch,
  setLookupTerm as setCollegeLookupTerm,
  lookupTypes,
} from 'modules/colleges/main';
import { isAustralia } from 'selectors/highschool';
import { getPermissions } from 'selectors/permissions';
import { getFeatureFlags } from 'selectors/featureFlags';
import { getNewNavigationFlag } from 'selectors/header';
import type { FeatureFlags } from 'types/featureFlags';

import 'modules/colleges';

import s from './styles.scss';

export type SearchScope = 'colleges' | 'careers' | 'courses' | '';

type SearchOptions = {
  value: SearchScope,
  label: string,
  placeholder: string,
  more: string,
  moreTo: string,
  aclGroup?: string,
  neededPermission?: string,
  featureFlag: ?string | string[],
};

type Props = {
  headingTitle: string,
  title?: string,
  showSearch?: boolean,

  // set this to hide the combobox and show additional search options
  fixedScope?: boolean,

  // default search target
  searchScope?: SearchScope,

  // from redux
  searchColleges: Function,
  setHeaderSearch: Function,
  history: Object,
  inAustralia: boolean,
  permissions: Object,
  featureFlags: FeatureFlags,
  setCollegeLookupTerm: Function,
};

type State = {
  query: string,
  searchOptions: ?SearchOptions,
  searchScope: SearchScope,
};

export const getSearchTargets = (inAustralia: boolean): Array<SearchOptions | Object> => [
  {
    value: 'colleges',
    label: `Search for ${inAustralia ? 'Higher Ed' : 'Colleges'}`,
    placeholder: `Type a ${inAustralia ? 'higher ed' : 'college'} name`,
    more: `MORE ${inAustralia ? 'HIGHER ED' : 'COLLEGE'} SEARCH OPTIONS`,
    moreTo: '/colleges/search',
    aclGroup: 'colleges',
    neededPermission: 'college_search',
  },
  {
    value: 'careers',
    label: 'Search for Careers',
    placeholder: 'Type a career',
    more: 'MORE CAREER SEARCH OPTIONS',
    moreTo: '/careers/explore',
    aclGroup: 'careers',
    neededPermission: 'career_search',
  },
  {
    value: 'courses',
    label: 'Search for Courses',
    placeholder: 'Type a course name',
    more: 'BROWSE COURSE CATALOG',
    moreTo: '/course-planner-next/catalog',
    aclGroup: 'header',
    neededPermission: 'courses',
    featureFlag: 'releaseCoursePlannerCoursesFindNext',
  },
];

const getAllowedSearchTargets = (
  inAustralia: boolean,
  permissions: Object,
  featureFlags: FeatureFlags
) => {
  const targets = [];
  getSearchTargets(inAustralia).forEach((t) => {
    if (
      // we check for feature flags
      isFeatureFlagAllowed(t.featureFlag, featureFlags) &&
      // Check specific to skip careers if explore careers is enabled
      !(t.value === 'careers' && isFeatureFlagAllowed('featureCareers2Explore', featureFlags)) &&
      // we check for permissions
      isAllowed({
        acl: permissions,
        group: t.aclGroup,
        target: t.neededPermission,
      })
    ) {
      targets.push(t);
    }
  });
  return targets;
};

const showSearchInput = (
  showSearch?: boolean,
  fixedScope?: boolean,
  searchScope: string,
  searchTargets: Array<SearchOptions | Object>
) => {
  if (searchTargets.length <= 0) {
    return false;
  }

  if (showSearch) {
    return true;
  }
  if (!fixedScope && searchTargets.length) {
    showSearch = true;
  }
  if (fixedScope && searchTargets.find((i) => i.value === searchScope)) {
    showSearch = true;
  }
  return showSearch;
};

@withRouter
@connect(
  (state) => ({
    inAustralia: isAustralia(state),
    permissions: getPermissions(state),
    featureFlags: getFeatureFlags(state),
    newNavigationEnabled: getNewNavigationFlag(state),
  }),
  {
    searchColleges: search,
    setHeaderSearch: setHeaderSearch,
    setCollegeLookupTerm,
  }
)
export default class LandingHeader extends React.Component<Props, State> {
  static defaultProps = {
    headingTitle: '',
    title: '',
    showSearch: true,
    fixedScope: false,
    searchScope: '',
    searchColleges: noop,
    setHeaderSearch: noop,
    history: {},
    inAustralia: false,
    permissions: {},
    featureFlags: {},
    setCollegeLookupTerm: noop,
  };

  state = {
    searchScope: '',
    query: '',
    searchOptions: null,
  };

  targets: Array<SearchOptions | Object> = [];

  UNSAFE_componentWillMount() {
    const { showSearch, fixedScope } = this.props;
    if (!showSearch && !fixedScope) {
      return;
    }
    this.targets = getAllowedSearchTargets(
      this.props.inAustralia,
      this.props.permissions,
      this.props.featureFlags
    );
    const initialSearchValue = this.targets.length && this.targets[0].value;
    this.updateScope(this.props.searchScope || initialSearchValue || '');
  }

  updateScope = (searchScope: SearchScope) => {
    const searchOptions = this.targets.find((item: SearchOptions) => item.value === searchScope);

    if (searchScope) {
      this.setState({ searchScope, searchOptions });
    }
  };

  updateQuery = (event: SyntheticInputEvent<*>) => this.setState({ query: event.target.value });

  search = (event: SyntheticEvent<*>) => {
    const { searchScope, query } = this.state;

    event.preventDefault();
    event.stopPropagation();

    switch (searchScope) {
      case 'colleges':
        if (query !== '') {
          this.props.searchColleges(
            { 'college.name': `LIKE '${escape(`%${query.trim()}%`)}'` },
            {}
          );
        }
        this.props.setHeaderSearch(query);
        this.props.setCollegeLookupTerm({
          type: lookupTypes.keyword,
          term: query,
        });
        push(`/colleges/college-lookup`);
        break;
      case 'careers':
        push(`/careers/explore/${query}`);
        break;
      case 'courses':
        push(`/course-planner-next/catalog/${query}`);
        break;
      default:
    }
  };

  render() {
    const { title, headingTitle, fixedScope, showSearch, newNavigationEnabled } = this.props;
    const { searchScope, query, searchOptions } = this.state;

    const searchTargets = this.targets;
    const reallyShowSearch = showSearchInput(showSearch, fixedScope, searchScope, searchTargets);

    return (
      <section className={cx(s.header, { [s.careers2Bg]: newNavigationEnabled })}>
        <Row>
          <Col
            xs={12}
            md={fixedScope ? 12 : 9}
            lg={fixedScope ? 10 : 8}
            offsetLg={fixedScope ? 1 : null}
          >
            <PageTitle className={s.title} title={headingTitle} size="1" documentTitle={title} />
          </Col>
        </Row>
        {reallyShowSearch ? (
          <IfAllowed group="colleges" target="college_profiles">
            <form onSubmit={this.search}>
              <Row>
                {fixedScope ? null : (
                  <Col xs={12} md={3} lg={2} offsetLg={1} className={s.comboWrap}>
                    <ComboBox
                      testId="search_scope"
                      options={searchTargets}
                      noDefault
                      invertColor
                      className={s.combo}
                      onSelect={this.updateScope}
                      value={searchScope}
                      aria-label="Select Search Type"
                    />
                  </Col>
                )}
                <Col
                  xs={12}
                  md={fixedScope ? 12 : 9}
                  lg={fixedScope ? 10 : 8}
                  offsetLg={fixedScope ? 1 : null}
                  className={cx(s.searchForm, { [s.fixedScope]: fixedScope })}
                >
                  <IconInput
                    icon="search"
                    testId="search_box"
                    iconClassName="nophone"
                    bare
                    placeholder={searchOptions ? searchOptions.placeholder : ''}
                    name="query"
                    type="search"
                    maxLength="2048"
                    autoComplete="off"
                    title="Search"
                    value={query}
                    aria-label={searchOptions ? searchOptions.label : 'Search'}
                    aria-haspopup="false"
                    aria-autocomplete="both"
                    dir="ltr"
                    spellCheck="false"
                    onChange={this.updateQuery}
                  />
                  <Button
                    variation="light"
                    size="medium"
                    onClick={this.search}
                    testId="search_submit_button"
                  >
                    <Icon icon="search" className="no-gt-phone" />
                    <span className="nophone">SEARCH</span>
                  </Button>
                </Col>
              </Row>

              <IfUserRole target={['student', 'parent', 'counselor']}>
                {fixedScope && searchOptions ? (
                  <Row>
                    <Col
                      xs={12}
                      md={fixedScope ? 12 : 9}
                      lg={fixedScope ? 10 : 8}
                      offsetLg={fixedScope ? 1 : null}
                    >
                      <Link to={searchOptions.moreTo}>{searchOptions.more}</Link>
                    </Col>
                  </Row>
                ) : (
                  <div />
                )}
              </IfUserRole>
            </form>
          </IfAllowed>
        ) : (
          <div />
        )}
      </section>
    );
  }
}
