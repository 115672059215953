import * as React from 'react';
import cx from 'classnames';
import Icon from 'components/Icon';
import Button from 'components/Button';
import s from './styles.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  label?: string;
  handleClick?: () => void;
  icon: string;
  hideLabelOnLowerRes?: boolean;
}

const IconButton = ({
  icon,
  label,
  hideLabelOnLowerRes,
  handleClick,
  className,
  ...rest
}: Props) => (
  <Button
    variation="link"
    className={cx(s.iconButton, className)}
    onClick={handleClick}
    {...rest}
  >
    <Icon icon={icon} />
    <span className={cx(hideLabelOnLowerRes && s.buttonLabel)}>{label}</span>
  </Button>
);

IconButton.defaultProps = {
  icon: 'plus',
  label: null,
  hideLabelOnLowerRes: false,
};

export default IconButton;
