// sent by PowerBuddy to indicate when all React initialization has completed and the chat window is ready to be opened:
// payload: {}
export const MFE_EVENT_POWERBUDDY_INITIALIZED = 'ph-ai-chatbot.initialized';
// sent by the host application to open the PowerBuddy chat window
// payload: {}
export const HOST_EVENT_POWERBUDDY_OPEN_REQUESTED = '!host.chatbot-open-requested';
// sent by PowerBuddy to notify the host application when the chat window is closed
// payload: {}
export const HOST_EVENT_POWERBUDDY_CLOSE_REQUESTED = '!host.chatbot-close-requested';
// sent by PowerBuddy to notify the host application when the chat window was closed
// ONLY USED WHEN RENDERED WITHOUT DRAWER
export const MFE_EVENT_POWERBUDDY_CLOSE_REQUESTED = '!ph-ai-chatbot.close-requested';
// sent by PowerBuddy to notify the host application of an error
// payload: { error: new Error('MESSAGE HERE') }
export const MFE_EVENT_POWERBUDDY_ERROR = '!ph-ai-chatbot.capture-exception';
// sent by the host application to share an API session token with PowerBuddy
// payload: 'NEW AUTH TOKEN'
export const MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED =
  'ph-ai-chatbot.session-token-issued';
// sent by the host application to notify that the session/user chat context has been reset and chat should be started over
export const HOST_EVENT_POWERBUDDY_INFORMATION_UPDATED =
  'host.chatbot-information-updated';
// Sent by PowerBuddy to notify the host application that the user has requested to open a college profile
export const MFE_EVENT_POWERBUDDY_OPEN_COLLEGE_PROFILE_REQUESTED =
  '!ph-ai-chatbot.open-college-profile-requested';
// sent by PowerBuddy to notify the host application that the user has favorited a college
export const MFE_EVENT_POWERBUDDY_COLLEGE_FAVORITED = '!ph-ai-chatbot.college-favorited';
// sent by PowerBuddy to notify the host that PowerBuddy is about to display a college card and is requesting the college status
// for now, this is only the favorited status, but will be expanded with the connection status
export const MFE_EVENT_COLLEGE_STATUS_REQUESTED =
  '!ph-ai-chatbot.college-status-requested';

// Used by the PowerBuddy button to let the Naviance Student app's PowerBuddy
// component know that it should initialize and open the PowerBuddy chat window
export const NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED =
  '!naviance-student.open-powerbuddy';

// sent by the HOST to PB MFE that the user has favorited a college
export const NAV_STUDENT_EVENT_COLLEGE_FAVORITED = '!naviance-student.college-favorited';

// sent by the HOST to PB MFE that the user has unfavorited a college
export const NAV_STUDENT_EVENT_COLLEGE_UNFAVORITED =
  '!naviance-student.college-unfavorited';

// sent by the PowerBuddy MFE to HOST that the user has clicked on send Button
export const MFE_EVENT_USER_MESSAGE_SENT = '!ph-ai-chatbot.user-message-sent';

// sent by the Pathway MFE to HOST that the user has updated the pathway goals.
export const MFE_EVENT_CAREER_PATHWAY_GOALS = '!naviance-student.pathway-goals';
export const MFE_HOST_NAME = 'naviance_student';
export const MEDIA_PLAYER_EVENT_NAME = '!host.media-player-event';

// sent by the video player to another video player to pause video.
export const VIDEO_PAUSE_EVENT_NAME = '!host.video-pause-event';
