// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import type { College } from 'types/colleges';

const generateConstants = constantsGenerator('fc/colleges/addApplication');
const [
  GET_COLLEGES_AUTOCOMPLETE_RESULTS,
  GET_COLLEGES_AUTOCOMPLETE_RESULTS_SUCCESS,
  GET_COLLEGES_AUTOCOMPLETE_RESULTS_FAIL,
]: string[] = generateConstants('GET_COLLEGES_AUTOCOMPLETE_RESULTS');

type State = {
  colleges: Array<College>,
  loading: boolean,
  loaded: boolean,
};

const initialState: State = {
  colleges: [],
  loading: false,
  loaded: false,
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_COLLEGES_AUTOCOMPLETE_RESULTS_SUCCESS:
      return {
        ...state,
        colleges: action.result.data,
      };
    default:
      return state;
  }
}

export function search(name?: string = '') {
  const nameFilter = `{"college.name": "LIKE '${escape(`%${name}%`)}'"}`;

  return (dispatch: Function) =>
    dispatch({
      types: [
        GET_COLLEGES_AUTOCOMPLETE_RESULTS,
        GET_COLLEGES_AUTOCOMPLETE_RESULTS_SUCCESS,
        GET_COLLEGES_AUTOCOMPLETE_RESULTS_FAIL,
      ],
      promise: (client: Object) =>
        client.get(
          `${api.colleges}/search?filter=${encodeURIComponent(
            nameFilter
          )}&limit=${FETCH_MAX_RESULTS}`
        ),
    });
}
