// @flow

import type { Notification } from 'types/notification';
import { injectAsyncReducers } from 'store';
import expandObject from 'utils/expandObject';

type Action = {
  +type: string,
  notification?: Notification,
};

type ErrorObject = {
  id: string,
  error: string,
  code: number,
};

type ErrorMessage = {
  error: ErrorObject,
  requestData: Object,
  requestParams: Object,
};

const NOTIFICATION_ON = 'fc/notification/show';
const NOTIFICATION_OFF = 'fc/notification/hide';

const initialState: Notification = {
  type: 'success',
  content: '',
  title: '',
};

/**
 * Reducer
 */
export default function reducer(state: Notification = initialState, action: Action) {
  switch (action.type) {
    case NOTIFICATION_ON:
      return { ...action.notification };
    case NOTIFICATION_OFF:
      return {};
    default:
      return state;
  }
}

export const notify = (cfg: ?Notification) => (dispatch: Function) =>
  dispatch(cfg ? { type: NOTIFICATION_ON, notification: { ...cfg } } : { type: NOTIFICATION_OFF });

export const notifyError = (error: ErrorMessage) => (dispatch: Function) =>
  dispatch(
    notify({
      type: 'danger',
      title: `${expandObject(error, 'error.id') || ''}`,
      content: `${expandObject(error, 'error.error') || ''}`,
    })
  );

injectAsyncReducers({ notification: reducer });
