import * as React from 'react';

import Chunk from 'components/Chunk';

const loadMyPlanner = () => import('containers/MyPlanner' /* webpackChunkName: "my-planner" */); // eslint-disable-line prettier/prettier

export default class MyPlannerRoute extends React.Component {
  render() {
    return <Chunk load={loadMyPlanner} />;
  }
}
