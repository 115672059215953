import constantsGenerator from 'utils/constantsGenerator';
import api, { host } from 'config/api';
import get from 'lodash/get';
import { getErrorMessage } from 'utils/api';

const generateConstants = constantsGenerator('fc/colleges/profiles');
// @flow
export const [
  GET_ALL_COLLEGE_VISITS,
  GET_ALL_COLLEGE_VISITS_SUCCESS,
  GET_ALL_COLLEGE_VISITS_FAIL,
]: string[] = generateConstants('GET_ALL_COLLEGE_VISITS');

export const [
  GET_COLLEGE_VISITS,
  GET_COLLEGE_VISITS_SUCCESS,
  GET_COLLEGE_VISITS_FAIL,
]: string[] = generateConstants('GET_COLLEGE_VISITS');

export const [
  GET_COLLEGE_CONTACT,
  GET_COLLEGE_CONTACT_SUCCESS,
  GET_COLLEGE_CONTACT_FAIL,
]: string[] = generateConstants('GET_COLLEGE_CONTACT');

export const [
  GET_DELIVERY_TYPE,
  GET_DELIVERY_TYPE_SUCCESS,
  GET_DELIVERY_TYPE_FAIL,
]: string[] = generateConstants('GET_DELIVERY_TYPE');

export const [GET_OVERLAPS, GET_OVERLAPS_SUCCESS, GET_OVERLAPS_FAIL]: string[] = generateConstants(
  'GET_OVERLAPS'
);

export const [RESET_COLLEGE_PROFILE]: string[] = generateConstants('RESET_COLLEGE_PROFILE');

export const [
  HAS_ENHANCED_PROFILE,
  HAS_ENHANCED_PROFILE_SUCCESS,
  HAS_ENHANCED_PROFILE_FAIL,
]: string[] = generateConstants('HAS_ENHANCED_PROFILE');

const [
  FETCH_COUNSELOR_COMMENTS,
  FETCH_COUNSELOR_COMMENTS_SUCCESS,
  FETCH_COUNSELOR_COMMENTS_FAILURE,
]: string[] = generateConstants('FETCH_COUNSELOR_COMMENTS');

const initialState: State = {
  enhanced: [],
  collegeContact: null,
  deliveryType: null,
  overlaps: null,
  visits: null,
  loading: false,
  loaded: false,
  error: null,
  counselorComments: null,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case RESET_COLLEGE_PROFILE:
      return {
        ...initialState,
      };
    case GET_ALL_COLLEGE_VISITS:
    case GET_COLLEGE_VISITS:
    case GET_COLLEGE_CONTACT:
    case GET_DELIVERY_TYPE:
    case GET_OVERLAPS:
    case HAS_ENHANCED_PROFILE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_COLLEGE_VISITS_SUCCESS:
    case GET_COLLEGE_VISITS_SUCCESS:
      return {
        ...state,
        visits: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case GET_COLLEGE_CONTACT_SUCCESS:
      return {
        ...state,
        collegeContact: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case GET_DELIVERY_TYPE_SUCCESS:
      return {
        ...state,
        deliveryType: get(action.result, 'deliveryType', null),
        loading: false,
        loaded: true,
        error: null,
      };
    case HAS_ENHANCED_PROFILE_SUCCESS: {
      const enhanced = [...state.enhanced];

      if ('enhanced' in action.result && Array.isArray(action.result.enhanced)) {
        action.result.enhanced.forEach((scid) => {
          if (!enhanced.includes(scid)) {
            enhanced.push(scid);
          }
        });
      }

      return {
        ...state,
        enhanced,
        loading: false,
        loaded: true,
        error: null,
      };
    }
    case GET_OVERLAPS_SUCCESS:
      return {
        ...state,
        overlaps: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case FETCH_COUNSELOR_COMMENTS_SUCCESS:
      return {
        ...state,
        counselorComments: action.result,
        error: null,
      };
    case GET_ALL_COLLEGE_VISITS_FAIL:
    case GET_COLLEGE_VISITS_FAIL:
    case GET_COLLEGE_CONTACT_FAIL:
    case GET_DELIVERY_TYPE_FAIL:
    case GET_OVERLAPS_FAIL:
    case HAS_ENHANCED_PROFILE_FAIL:
    case FETCH_COUNSELOR_COMMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: getErrorMessage(action),
      };
    default:
      return state;
  }
}

export const fetchAllCollegeVisits = () => (dispatch: Function) =>
  dispatch({
    types: [GET_ALL_COLLEGE_VISITS, GET_ALL_COLLEGE_VISITS_SUCCESS, GET_ALL_COLLEGE_VISITS_FAIL],
    promise: (client: Object) =>
      client.get(`${api.collegeVisits}/search?limit=${FETCH_MAX_RESULTS_LARGE}`),
  });

export const fetchCollegeVisits = (scid: string) => (dispatch: Function): Promise<*> =>
  dispatch({
    types: [GET_COLLEGE_VISITS, GET_COLLEGE_VISITS_SUCCESS, GET_COLLEGE_VISITS_FAIL],
    promise: (client: Object) => {
      const filter = encodeURI(`{"college.hobsons_id":"= ${scid}"}`);
      return client.get(
        `${api.collegeVisits}/search?filter=${filter}&limit=${FETCH_MAX_RESULTS_LARGE}`
      );
    },
  });

export const hasEnhancedProfile = (scids: string[]) => (dispatch: Function): Promise<*> =>
  dispatch({
    types: [HAS_ENHANCED_PROFILE, HAS_ENHANCED_PROFILE_SUCCESS, HAS_ENHANCED_PROFILE_FAIL],
    promise: (client: Object) => {
      const validScids = scids
        ? scids.filter((scid) => scid && !Number.isNaN(Number(scid)) && Number(scid) > 0)
        : [];
      if (validScids.length > 0) {
        const scidsParam = encodeURI(validScids.join(','));
        return client.get(`${api.collegesProfilesNext}/has-enhanced?scids=${scidsParam}`);
      }
      return Promise.reject({ errors: [`Invalid or Empty scids provided: ${scids}`] });
    },
  });

export const fetchCollegeOverlaps = (uuid: string) => (dispatch: Function) =>
  dispatch({
    types: [GET_OVERLAPS, GET_OVERLAPS_SUCCESS, GET_OVERLAPS_FAIL],
    promise: (client: Object) => client.get(`${api.colleges}/overlaps?uuid=${uuid}`),
  });
export const fetchCollegeOverlapsByScid = (scid: number) => (dispatch: Function) =>
  dispatch({
    types: [GET_OVERLAPS, GET_OVERLAPS_SUCCESS, GET_OVERLAPS_FAIL],
    promise: (client: Object) => client.get(`${api.colleges}/overlaps?scid=${scid}`),
  });

export const fetchCounselorComments = (uuid: string) => (dispatch: Function) =>
  dispatch({
    types: [
      FETCH_COUNSELOR_COMMENTS,
      FETCH_COUNSELOR_COMMENTS_SUCCESS,
      FETCH_COUNSELOR_COMMENTS_FAILURE,
    ],
    promise: (client: Object) => client.get(`${host}/college-comments/uuid/${uuid}`),
  });
export const fetchCounselorCommentsByScid = (scid: number) => (dispatch: Function) =>
  dispatch({
    types: [
      FETCH_COUNSELOR_COMMENTS,
      FETCH_COUNSELOR_COMMENTS_SUCCESS,
      FETCH_COUNSELOR_COMMENTS_FAILURE,
    ],
    promise: (client: Object) => client.get(`${host}/college-comments/scid/${scid}`),
  });

export const requestDeliveryType = (uuid: string) => (dispatch: Function): Promise<*> =>
  dispatch({
    types: [GET_DELIVERY_TYPE, GET_DELIVERY_TYPE_SUCCESS, GET_DELIVERY_TYPE_FAIL],
    promise: (client: Object) =>
      client.get(`${api.host}/college/college-delivery-type?uuid=${uuid}`),
  });

export const requestDeliveryTypeByScid = (scid: number) => (dispatch: Function): Promise<*> =>
  dispatch({
    types: [GET_DELIVERY_TYPE, GET_DELIVERY_TYPE_SUCCESS, GET_DELIVERY_TYPE_FAIL],
    promise: (client: Object) =>
      client.get(`${api.host}/college/college-delivery-type?scid=${scid}`),
  });

export const requestCollegeContact = (uuid: string) => (dispatch: Function): Promise<*> =>
  dispatch({
    types: [GET_COLLEGE_CONTACT, GET_COLLEGE_CONTACT_SUCCESS, GET_COLLEGE_CONTACT_FAIL],
    promise: (client: Object) => client.get(`${api.host}/college/contact/uuid/${uuid}`),
  });

export const requestCollegeContactByScid = (scid: number) => (dispatch: Function): Promise<*> =>
  dispatch({
    types: [GET_COLLEGE_CONTACT, GET_COLLEGE_CONTACT_SUCCESS, GET_COLLEGE_CONTACT_FAIL],
    promise: (client: Object) => client.get(`${api.host}/college/contact/scid/${scid}`),
  });

export const resetCollegeProfile = () => (dispatch: Function): Promise<*> =>
  dispatch({ type: RESET_COLLEGE_PROFILE });
