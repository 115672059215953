// @flow
/**
 * Constants set generator factory
 * @example
 *   const generateConstants = constantsGenerator('fc/some/path');
 *   const [GET, GET_SUCCESS, GET_FAIL] = generateConstants('GET');
 *   //=> ["fc/some/path/GET...", "fc/some/path/GET_√", "fc/some/path/GET_X"]
 *
 * @param {string} base
 */
export default function constantsGenerator(base: string): Function {
  /**
   * Constants set generator
   * @param {string} name - constant base name
   * @returns {Object<string, string>}
   */
  return (name: string): Array<string> => [
    `${base}/${name}...`,
    `${base}/${name}_√`,
    `${base}/${name}_X`,
  ];
}
