import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/ContactCounselor' /* webpackChunkName: "contact-counselor" */);  // eslint-disable-line prettier/prettier

export default class ContactCounselorRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
