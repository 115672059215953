// @flow
import moment from 'moment';

/**
 * IMPORTANT - Only moment should be used to convert date-only strings to dates.
 * Never use javaScript "new Date(myDate)" unless you have both date and time.
 *
 * For example:
 * Using moment('2017-12-25') will always return Mon Dec 25 2017 00:00:00 GMT-0500
 * The timezone will vary but the date will always be Dec 25.
 *
 * Using new Date('2017-12-25') may return Sun Dec 24 2017 19:00:00 GMT-0500
 * Depending on your browser and timezone, you may end up with Dec 24, the day before.
 */

/**
 * Add @days to @date that is passed in
 * @param date
 * @param days
 * @returns {Date}
 */
export const addDays = (date: string | Date, days: number): Date => moment(date).add(days, 'days');

/**
 * Standardizes a date part (day or month) - Numbers below 10 receive a 0 prefix
 *
 * '1' becomes '01', '6' becomes '06'
 * @param datePart
 * @returns {*}
 */
export function standardizeDatePart(datePart: number) {
  return datePart < 10 ? `0${datePart}` : datePart;
}

/**
 * Returns today's date in the standard UI date format '2017-05-29'
 * @returns {string}
 */
export function getStandardizedTodayDate() {
  const date = new Date();
  const day = standardizeDatePart(date.getDate());
  const month = standardizeDatePart(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export const getCurrentYear = () => new Date().getFullYear();

export function isLeapYear(year: number) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * @param year
 * @param month
 * @returns {Array} - Array of standard formatted dates for said month and year
 *
 * For year 2017 and month 0, it will return:
 * ['2017-01-01', '2017-01-02', .... , '2017-01-31']
 */
export function getDateStringArrayForMonth(year: number, month: number) {
  const daysArray = [];
  let numberOfDaysInMonth = daysInMonth[month] + 1;

  if (isLeapYear(year) && month === 1) {
    numberOfDaysInMonth += 1;
  }

  for (let i = 1; i < numberOfDaysInMonth; i++) {
    daysArray.push(`${year}-${standardizeDatePart(month + 1)}-${standardizeDatePart(i)}`);
  }

  return daysArray;
}

// Returns month number [0, 11]
export const getMonth = (date: string | moment): number => moment(date).get('month');

export const getYear = (date: string | moment): number => moment(date).get('year');

export const getDay = (date: string | moment): number => moment(date).date();

/**
 * Check if the date is today, only works with standard string format YYYY-MM-DD
 * @param {Date} date
 * @return {boolean}
 */
export function isToday(date: string): boolean {
  return getStandardizedTodayDate() === date;
}

/**
 * Check if the past date
 * @param {Date} date
 * @return {boolean}
 */
export const isPastDate = (date: string | moment): boolean =>
  moment(date).isBefore(moment(), 'day');

export const isValidDate = (date: string | moment): boolean =>
  !!date && date !== '0000-00-00' && moment(date, 'YYYY-M-DD').isValid();

export const formatDate = (date: string | moment): string => moment(date).format('MMMM, YYYY');

export const formatFullDate = (date: string | moment): string =>
  moment(date).format('dddd MMM DD, YYYY');

export const formatYMDDate = (date: string | moment): string =>
  date && date !== 'null' ? moment(date).format('YYYY-MM-DD') : '';

export const formatMDYDate = (date: string | moment) => moment(date).format('MM/DD/YYYY');

// 'yyyy-MM-dd' with dashes and leading zero date is not support in Safari Version 11.0 (13604.1.38.1.6) without specifying format.
export const incomingYMD = (date: string | moment) => moment(date, 'YYYY-MM-DD');

export const fullMonthAndDay = (date: string | moment) =>
  moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('MMMM D');

/**
 * Formats date to three letter month and day. eg. Jan 15
 * @param date : '2022-03-02 01:28:56'
 * @returns {string}
 */
export const shortMonthAndDay = (date: string | moment) =>
  moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('MMM D');

export const formatCollegeVisitDate = (visit: Object, useRegistrationDeadline: boolean = false) =>
  visit.showTime
    ? `${moment(
        useRegistrationDeadline ? visit.registrationDeadline : visit.visitDate,
        'YYYY-MM-DD HH:mm:ss'
      ).format('dddd, MMM D YYYY, hh:mmA')}`
    : `${moment(
        useRegistrationDeadline ? visit.registrationDeadline : visit.visitDate,
        'YYYY-MM-DD HH:mm:ss'
      ).format('dddd, MMM D YYYY')}`;

export const formatCommentDate = (date: string | moment) =>
  moment(date).format('MMM DD, YYYY h:mm A');

export const formatDateRange = (startDate: string | moment, endDate: string | moment): string =>
  `${formatDate(startDate)} - ${endDate ? formatDate(endDate) : 'Present'}`;

export const areStartEndDatesValid = (startDate: string | moment, endDate: string | moment) => {
  if (startDate && endDate) {
    return (
      startDate < endDate ||
      (moment(startDate).get('month') === moment(endDate).get('month') &&
        moment(startDate).get('year') === moment(endDate).get('year'))
    );
  }
  if (startDate && !endDate) return true;
  if (!startDate && endDate) return false;
  return !startDate;
};

export const getOnlyDateAndMonth = (date: string | moment) => moment(date).format('DD MMMM');

/**
 * Returns month (Only first three characters of month) and day specific format - 'Mar 10'
 * @param date
 * @returns {string}
 */
export const shortMonthsAndDay = (date: string | moment) =>
  moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('MMM D');
