import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/Accounts/SelectChild' /* webpackChunkName: "select-child" */);  // eslint-disable-line prettier/prettier

export default class SelectChildRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
