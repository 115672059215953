// @flow
import api from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';

const generateConstants = constantsGenerator('fc/permissions');

const [
  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
]: string[] = generateConstants('get_permissions');

const [MERGE_PERMISSIONS]: string[] = generateConstants('merge_permissions');

// cannot import from authInfo at this moment, so action types are duplicated here
const [GET_AUTH_INFO, GET_AUTH_INFO_SUCCESS, GET_AUTH_INFO_FAIL]: string[] = constantsGenerator(
  'fc/authInfo'
)('get_auth_info');

export type Acl = {
  role: string,
  resources: {
    [name: string]: Array<string>,
  },
};
export type State = {
  authPermissionsLoaded: boolean,
  mainPermissionsLoaded: boolean,
  loading: boolean,
  acl: Acl,
};

const initialState = {
  authPermissionsLoaded: false,
  mainPermissionsLoaded: false,
  loading: false,
  acl: {
    role: '',
    resources: {},
  },
};

function mergePermissions(permissions: Object, acl: Acl) {
  // we always show colleges search and lookup for GUESTs users
  if (acl.role === 'GUEST') {
    return {
      ...permissions,
      colleges: permissions.colleges ? [...permissions.colleges, 'college_search'] : [],
    };
  }

  // if it is a student user we return all his permissions
  if (!acl.parentId) {
    return permissions;
  }

  // for parent user we override edit permissions
  const parentPermissions = permissions['parent-edit'] || [];
  const editPermissions = permissions.edit || [];
  const parentEditPermissions = [];
  // if parent can add to colleges I`m thinking about
  if (parentPermissions.includes('add_to_students_prospective_colleges_list')) {
    parentEditPermissions.push('add_to_students_prospective_colleges_list');
  }
  // if student can edit email then parent can
  if (editPermissions.includes('edit_email_address')) {
    parentEditPermissions.push('edit_email_address');
  }
  // parent can't see events
  const collegePermissions = permissions.colleges || [];

  return {
    ...permissions,
    colleges: [...collegePermissions.filter((i) => i !== 'active_match_events')],
    edit: [...parentEditPermissions],
  };
}

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_PERMISSIONS:
    case GET_AUTH_INFO:
      return {
        ...state,
        loading: true,
      };
    case GET_PERMISSIONS_SUCCESS: {
      const oldAcl = state.acl;
      const newResources = {
        ...oldAcl.resources,
        ...action.result.resources,
      };
      return {
        loading: false,
        mainPermissionsLoaded: true,
        acl: {
          ...action.result,
          resources: mergePermissions(newResources, action.result),
        },
      };
    }
    case MERGE_PERMISSIONS:
      return {
        ...state,
        acl: {
          ...state.acl,
          resources: mergePermissions(state.acl.resources, state.acl),
        },
      };
    case GET_AUTH_INFO_SUCCESS: {
      const oldAcl = state.acl;
      const newResources = {
        ...oldAcl.resources,
        auth: action.result.permissions,
      };

      return {
        ...state,
        loading: false,
        authPermissionsLoaded: true,
        acl: {
          ...oldAcl,
          resources: mergePermissions(newResources, state.acl),
        },
      };
    }
    case GET_PERMISSIONS_FAIL:
      console.log('Failed to load permissions');
      return {
        ...state,
        loading: false,
      };
    case GET_AUTH_INFO_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function fetchPermissions(parentId: number) {
  return (dispatch: Function) =>
    dispatch({
      types: [GET_PERMISSIONS, GET_PERMISSIONS_SUCCESS, GET_PERMISSIONS_FAIL],
      promise: (client: Object) =>
        client.get(`${api.security}/permissions`).then((result) => ({
          ...result,
          parentId,
        })),
    });
}
