// @noflow
import { Action } from 'redux';

import api from 'config/api';
import { injectAsyncReducers } from 'store';
import { IApiClient, ApiDispatch } from 'types/api';
import constantsGenerator from 'utils/constantsGenerator';
import { defaultLanguage } from 'constants/languageOptions';

const generateConstants = constantsGenerator('fc/translationService');

export const [
  TRANSLATE_PHRASES,
  TRANSLATE_PHRASES_SUCCESS,
  TRANSLATE_PHRASES_FAIL,
]: string[] = generateConstants('TRANSLATE_PHRASES');

interface State {
  submitting: boolean;
}

const initialState: State = {
  submitting: false,
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case TRANSLATE_PHRASES:
      return {
        ...state,
        submitting: true,
      };
    case TRANSLATE_PHRASES_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case TRANSLATE_PHRASES_FAIL:
      return {
        ...state,
        submitting: false,
      };

    default:
      return state;
  }
}

export function translatePhrases(
  characterCount: number,
  textPhrases: string[],
  preferredLanguage: string,
  isRetry = false
) {
  return (dispatch: ApiDispatch, getState): Promise<void | {}> =>
    dispatch<{}>({
      types: [TRANSLATE_PHRASES, TRANSLATE_PHRASES_SUCCESS, TRANSLATE_PHRASES_FAIL],
      promise: (client: IApiClient) =>
        client.post(api.translationService, {
          data: {
            isRetry,
            characterCount,
            sourceLanguage: defaultLanguage,
            destinationLanguage: preferredLanguage,
            inputPhrases: textPhrases,
            pageURL: window.location.href,
          },
          flagsForApiClient: getState().featureFlags,
        }),
    });
}

injectAsyncReducers({ translationService: reducer });
