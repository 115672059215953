import * as React from 'react';

import Chunk from 'components/Chunk';

const loadAboutMe = () => import('containers/AboutMe' /* webpackChunkName: "about-me" */); // eslint-disable-line prettier/prettier

export default class AboutMeRoute extends React.Component {
  render() {
    return <Chunk load={loadAboutMe} {...this.props} />;
  }
}
