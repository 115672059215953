// @flow
import { injectAsyncReducers } from 'store';
import { ingestRawEventCtaApiBaseUrl } from 'config/api';
import session from 'config/session';
import constantsGenerator from 'utils/constantsGenerator';
import noop from 'utils/noop';

const generateConstants = constantsGenerator('fc/cta');

const [
  SEND_CTA_TRIGGER,
  SEND_CTA_TRIGGER_SUCCESS,
  SEND_CTA_TRIGGER_FAIL,
]: string[] = generateConstants('send_cta_trigger');

export type CtaResponseProps = {
  id: string,
};

export type State = {
  ctaResponse: CtaResponseProps,
  loading: boolean,
};

type Action = {
  type: string,
  result: Object,
};

const initialState: State = {
  ctaResponse: {
    id: noop,
  },
  loading: false,
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case SEND_CTA_TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case SEND_CTA_TRIGGER_SUCCESS:
      return {
        ...state,
        loading: false,
        ctaResponse: action.result,
      };
    case SEND_CTA_TRIGGER_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export async function sendCtaTriggerEvents(ctaBody) {
  const jwt = session.isValid() ? session.jwt : null;
  if (!jwt) {
    throw new Error('Invalid jwt');
  }

  try {
    await fetch(`${ingestRawEventCtaApiBaseUrl}/save-raw-cta-trigger-event`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ctaBody),
    });
  } catch (err) {
    throw new Error('Failed to send cta trigger event', err);
  }
}

export const sendCtaTrigger = (ctaBody: Object) => (dispatch: Function): Promise<*> =>
  dispatch({
    types: [SEND_CTA_TRIGGER, SEND_CTA_TRIGGER_SUCCESS, SEND_CTA_TRIGGER_FAIL],
    promise: () => sendCtaTriggerEvents(ctaBody),
  });

injectAsyncReducers({ ctaTrigger: reducer });
