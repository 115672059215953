import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Icon from 'components/Icon';
import { getNewNavigationFlag } from 'selectors/header';
import noop from 'utils/noop';
import { FC } from 'react';
import Link from '../../Link';
import s from './styles.scss';
import { NeonIcon } from '@ps-refarch-ux/neon';

export interface AccordionSectionData {
  icon?: string;
  headerLink?: {
    text: string;
    url: string;
  };
  childLinks?: {
    text: string;
    url: string;
  }[];
  header?: React.ReactNode;
  childItems?: React.ReactNode[];
  expanded?: boolean;
  onToggle?: (state: boolean) => void;
  newNavigationEnabled?: boolean;
  expandedCss?: string;
  sectionCss?: string;
}

interface State {
  expanded: boolean;
  childrenHeight?: number;
}

const AccordionSectionButton: FC<{
  className?: string;
  label?: string;
  handleClick?: () => void;
  icon: string;
  hideLabelOnLowerRes?: boolean;
  testId?: string;
}> = ({ icon, label, hideLabelOnLowerRes, handleClick, className, testId, ...rest }) => (
  <div
    className={cx(s.iconButton, className)}
    onClick={handleClick}
    data-test-id={testId}
    {...rest}
  >
    <NeonIcon dataIcon={icon} />
    <span className={cx(hideLabelOnLowerRes && s.buttonLabel)}>{label}</span>
  </div>
);

export class AccordionSectionNeon extends React.Component<AccordionSectionData, State> {
  static defaultProps = {
    onToggle: noop,
    expandedCss: '',
  };

  state = {
    expanded: !!this.props.expanded,
    childrenHeight: 0,
  };

  childrenWrapper: HTMLElement;

  handleIconClick = () => {
    if (this.hasChildren()) {
      const childrenHeight = this.childrenWrapper && this.childrenWrapper.clientHeight;
      this.setState(
        (state) => ({ expanded: !state.expanded, childrenHeight }),
        () => {
          // tell someone that item is expanded
          if (this.props.onToggle) {
            this.props.onToggle(this.state.expanded);
          }
        }
      );
    }
  };

  getChildrenRef = (div: HTMLElement) => (this.childrenWrapper = div);

  updateHeight = () => {
    if (this.state.expanded && this.childrenWrapper) {
      this.setState({ childrenHeight: this.childrenWrapper.clientHeight });
    }
  };

  componentDidMount() {
    // setting state to update client height if this section is meant to be expanded by default
    this.updateHeight();
  }

  hasChildren = () => {
    const { childItems, childLinks } = this.props;

    return (
      (Array.isArray(childItems) && childItems.length > 0) ||
      (Array.isArray(childLinks) && childLinks.length > 0)
    );
  };

  getChildItems = () =>
    this.props.childItems &&
    this.props.childItems.map((item: React.ReactElement, idx) => (
      <li key={idx}>
        {React.cloneElement(item, {
          onDomUpdated: this.updateHeight,
          closeAccordion: this.handleIconClick,
        })}
      </li>
    ));

  render() {
    const { expanded, childrenHeight } = this.state;
    const {
      icon,
      headerLink,
      header,
      childLinks,
      childItems,
      expandedCss,
      sectionCss,
      newNavigationEnabled,
      ...otherProps
    } = this.props;
    const hasChildren = this.hasChildren();

    return (
      <div
        className={cx(s.section, sectionCss, {
          [expandedCss || '']: expandedCss && expanded,
        })}
        {...otherProps}
      >
        <div className={cx(s.sectionHeader, { [s.linkedHeader]: headerLink })}>
          {icon && headerLink ? (
            <Link to={headerLink.url} className={s.actionableHeader}>
              {icon && <Icon icon={icon} className={s.icon} />} {headerLink.text}
            </Link>
          ) : null}
          {header ? (
            <div onClick={this.handleIconClick} className={s.customHeaderContainer}>
              {header}
            </div>
          ) : null}
          {hasChildren && newNavigationEnabled && (
            <AccordionSectionButton
              className={cx(s.caret, s['caretButton--big'], {
                [s.expandedCaret]: expanded,
              })}
              handleClick={this.handleIconClick}
              icon="chevron-down-sm"
              aria-haspopup={hasChildren}
              aria-expanded={expanded}
              aria-controls={headerLink ? headerLink.url : ''}
              aria-label={`${expanded ? 'Collapse' : 'Expand'} ${
                headerLink ? headerLink.text : ''
              }`}
            />
          )}
          {hasChildren && !newNavigationEnabled && (
            <AccordionSectionButton
              className={cx(s.caret, s.caretButton, { [s.expandedCaret]: expanded })}
              handleClick={this.handleIconClick}
              icon="chevron-down-sm"
              aria-haspopup={hasChildren}
              aria-expanded={expanded}
              aria-controls={headerLink ? headerLink.url : ''}
              aria-label={`${expanded ? 'Collapse' : 'Expand'} ${
                headerLink ? headerLink.text : ''
              }`}
            />
          )}
        </div>
        {hasChildren && (
          <div
            className={s.sectionChildren}
            style={
              newNavigationEnabled
                ? { maxHeight: expanded ? 'unset' : 0 }
                : { height: expanded ? childrenHeight : 0 }
            }
            role="menu"
            aria-hidden={!expanded}
          >
            <ul className={s.sectionChildrenInner} ref={this.getChildrenRef}>
              {childLinks &&
                childLinks.map((l, i) => (
                  <li key={i}>
                    <Link
                      to={l.url}
                      className={s.pathway}
                      key={l.url}
                      tabIndex={expanded ? 0 : -1}
                    >
                      {l.text}
                    </Link>
                  </li>
                ))}
              {this.getChildItems()}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    newNavigationEnabled: getNewNavigationFlag(state),
  }),
  {}
)(AccordionSectionNeon);
