// @flow
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import cx from 'classnames';
import { Row, Col } from 'components/Grid';
import LandingHeader from 'components/LandingHeader';
import Card from 'components/Card';
import type { SearchScope } from 'components/LandingHeader';
import PageTitle from 'components/PageTitle';
import Notify from './Notification';
import { getDocumentTitle } from '../../utils/documentTitle';

import s from './styles.scss';

type Props = {
  children?: React.Node,
  left?: React.Node,
  right?: React.Node,
  className?: string,
  contentWrapperClassName?: string,
  contentClassName?: string,
  heading?: string,
  hasQuickLinks?: boolean,
  headingTitle?: string,
  title?: string,
  showSearch?: boolean,
  headingScope?: SearchScope,
  footer?: ?React.Node,
  customHeader?: React.Node,
};

/**
 * Template component place aside components:
 * left aside component from the left of main content
 * right aside component from the right of main content
 */
export default class PageTemplate extends React.PureComponent<Props, void> {
  static defaultProps = {
    className: '',
    contentWrapperClassName: '',
    contentClassName: '',
  };

  UNSAFE_componentWillMount() {
    const { left, right, hasQuickLinks } = this.props;

    if (left && right) {
      this.contentSize.left = {
        xs: 12,
        sm: 4,
        md: 3,
      };
      this.contentSize.center = {
        xs: 12,
        sm: 4,
        md: 6,
      };
      this.contentSize.right = {
        xs: 12,
        sm: 4,
        md: 3,
      };
    } else if (!left && !right) {
      this.contentSize.center = {
        xs: 12,
      };
    } else {
      this.contentSize.left = {
        xs: 12,
        sm: hasQuickLinks ? 12 : 4,
        md: 3,
      };
      this.contentSize.center = {
        xs: 12,
        sm: hasQuickLinks ? 12 : 8,
        md: 9,
      };
      this.contentSize.right = {
        xs: 12,
        sm: 4,
        md: 3,
      };
    }
  }

  contentSize = {
    left: {},
    center: {},
    right: {},
  };

  render() {
    const {
      children,
      left,
      right,
      className,
      contentWrapperClassName,
      contentClassName,
      heading,
      hasQuickLinks,
      headingTitle,
      title,
      headingScope,
      footer,
      showSearch,
      customHeader,
      ...rest
    } = this.props;

    const headerColor =
      window.location.pathname === '/student-readiness-results' ? 'purple' : 'dark';
    return (
      <main id="main-container" className={cx(s.pageContent, className)} role="main">
        {headingTitle ? (
          <LandingHeader
            title={title}
            headingTitle={headingTitle}
            showSearch={showSearch}
            fixedScope={!!headingScope}
            searchScope={headingScope}
          />
        ) : null}
        {!headingTitle && title ? <DocumentTitle title={getDocumentTitle(title)} /> : null}
        {customHeader}
        <article
          className={cx(s.contentWrapper, { [s.pullUp]: headingTitle }, contentWrapperClassName)}
        >
          <Row className={cx({ [s.hasQuickLinks]: hasQuickLinks }, contentClassName)}>
            {left && <Col {...this.contentSize.left}>{left}</Col>}
            <Col {...this.contentSize.center}>
              {heading ? (
                <Card {...rest} type={headerColor} shadow title={<PageTitle title={heading} />}>
                  <Notify />
                  {children}
                </Card>
              ) : (
                <div>
                  <Notify />
                  {children}
                </div>
              )}
            </Col>
            {right && <Col {...this.contentSize.right}>{right}</Col>}
          </Row>
        </article>
        {footer || null}
      </main>
    );
  }
}
