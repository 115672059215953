// @flow
import api from 'config/api';
import session from 'config/session';
import ss from 'utils/sessionStorage';
import GenericError from 'containers/Errors/generic';
import { checkLogin, portalSessionLogin, logout } from 'modules/auth';
import { parse, stringify } from 'query-string';
import Raven from 'raven-js';
import * as React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isAuthenticated } from 'selectors/auth';
import { getHighSchool, getHsid } from 'selectors/highschool';

import noop from 'utils/noop';
import initSentry from 'utils/Sentry';
import { setHistory, validateUrl } from 'utils/url';
import ls from 'utils/localStorage';

import type { History } from 'utils/url';
import IfFeatureAllowed from 'components/IfFeatureAllowed';
import HeapAnalytics from 'components/HeapAnalytics';
import GainsightAnalytics from 'components/GainsightAnalytics';

import LegacyExtendSessionModal from 'containers/Accounts/ActivityWatcher/LegacyExtendSessionModal';
import { areFeatureFlagsLoaded, getFeatureFlags } from 'selectors/featureFlags';
import type { FeatureFlags } from 'types/featureFlags';
import GoogleTagManager from '../GoogleTagManager';
import GoogleOptimize from '../GoogleOptimize';
import Anonymous from './Anonymous';
import Authorized from './Authorized';
import RedirectToUrlParam from './RedirectToUrlParam';
import RedirectToUrlForDeepLinking from './RedirectToUrlForDeepLinking';
import { ConsoleLogger } from '../ConsoleLogger';

import s from './style.scss';

type Props = {
  checkLogin: Function,
  portalSessionLogin: Function,
  authenticated: boolean,
  showAdminToast: boolean,
  featureFlags?: FeatureFlags,
  hsid: string,
  history: History,
  location: {
    pathname: string,
    search: string,
  },
  highSchool: Object,
}; // here just to trigger re-render on login

type State = {
  errorId?: string,
};

const routeIncludesHsid = (route: string, hsid: string) =>
  Boolean(route.split('/').filter((path) => path === hsid).length);

const isAnonymousRoute = (route: string, hsid: string) =>
  routeIncludesHsid(route, hsid) || route.endsWith(`/login`) || route.endsWith('/additional-help');

@withRouter
@connect(
  (state) => ({
    authenticated: isAuthenticated(state), // prop here just to trigger re-render
    loggingIn: state.auth.loggingIn, // prop here just to trigger re-render
    showAdminToast: false,
    hsid: getHsid(state),
    highSchool: getHighSchool(state),
    featureFlags: getFeatureFlags(state),
    featureFlagsLoaded: areFeatureFlagsLoaded(state),
  }),
  {
    logout,
    checkLogin,
    portalSessionLogin,
  }
)
export default class App extends React.Component<Props, State> {
  static defaultProps = {
    logout: noop,
    checkLogin: noop,
    portalSessionLogin: noop,
    authenticated: false,
    showAdminToast: false,
    hsid: '',
    history: {},
    location: {
      pathname: '',
    },
    highSchool: null,
  };

  state = {};

  previous: ?string;

  removeHistoryListener: ?Function;

  observer = null;

  UNSAFE_componentWillMount() {
    this.checkLogin();

    if (this.removeHistoryListener) {
      this.removeHistoryListener();
    }
  }

  async checkLogin() {
    const { location } = this.props;
    if (
      session.getSessionCookie() ||
      session.getSessionCookie('STUDENT_IDENTITY') ||
      session.getSessionCookie('PARENT_IDENTITY')
    ) {
      await this.props.checkLogin();
    } else if (location.pathname && validateUrl(location.pathname)) {
      const loginRedirectUrlForDeepLinking = location.pathname + location.search;
      /** Deep-linking unauthorized user redirect specific page requirement, we have stored that value in local storage */
      const [url, queryString = ''] = loginRedirectUrlForDeepLinking.split('?');
      const params = queryString.split('&');
      const queryParams = {};
      params.forEach((param) => {
        const [key, value] = param.split('=');
        if (value) queryParams[key] = value;
      });
      let fromPortal = false;
      if ('hssignin' in queryParams) {
        ls.setItem('hsid', queryParams.hssignin);
        ls.setItem('isUnifiedUser', true);
        if (queryParams.fromportal === 'true') {
          fromPortal = true;
          delete queryParams.fromportal;
        }
        if (queryParams.studentid) {
          ls.setItem('userType', 'parent');
          ls.setItem('studentId', queryParams.studentid);
          delete queryParams.studentid;
        } else {
          ls.setItem('userType', 'student');
        }
        delete queryParams.hssignin;
      }
      const modifiedQueryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join('&');
      const modifiedURL = modifiedQueryString ? `${url}?${modifiedQueryString}` : url;

      ls.setItem('loginRedirectUrlForDeepLinking', modifiedURL.trim());
      if (ls.getItem('studentId')) {
        ls.setItem('loginRedirectUrlForParentDeepLinking', modifiedURL.trim());
      }
      if (fromPortal) {
        ls.removeItem('isUnifiedUser');
        ls.removeItem('loginRedirectUrlForDeepLinking');
        // Redirect to a BRAPI on the PowerSchool Domain, so it can capture the
        // portal sessontoken from the PowerSchool Domain cookie.
        // It will redirect back to FCUI at the redirectPath, with the sessiontoken in the URL
        window.location.assign(
          `${api.portalApiHost}/users/me/portalsessiontoken?redirectUrl=${modifiedURL.trim()}`
        );
      }
    }
    setHistory(this.props.history);
    const urlSearchParams = new URLSearchParams(location.search);
    if (urlSearchParams.has('sessiontoken')) {
      ls.removeItem('loginRedirectUrlForDeepLinking');
      ls.removeItem('loginRedirectUrlForParentDeepLinking');
      await this.props.portalSessionLogin();
    }
  }

  componentDidMount() {
    initSentry();

    if (ls.getItem('loginRedirectUrl')) {
      this.removeHistoryListener = this.props.history.listen(() => {
        ls.removeItem('loginRedirectUrl');
      });
    }
  }

  cleanLocationSearch = (search: string): string => {
    const parsedSearch = parse(search);
    parsedSearch.hsid = undefined;
    const stringifiedSearch = stringify(parsedSearch);
    return stringifiedSearch ? `?${stringifiedSearch}` : '';
  };

  /*
   * In some cases we need to re-calculate the previous path.
   * ref https://jira.hobsons.com/browse/NTU-2054
   */
  getPreviousPath = (previous: string) => {
    if (previous.endsWith('cluster-finder/questions')) {
      return previous.replace('cluster-finder/questions', 'cluster-finder/');
    }
    // when we logout from LOR, EVENTS
    // https://jira.hobsons.com/browse/NTU-4656
    if (previous.includes('/auth/logout')) {
      return null;
    }
    return previous;
  };

  UNSAFE_componentWillReceiveProps({ location: { pathname }, hsid }: Props) {
    const {
      location: { pathname: currentPathname, search: currentSearch },
    } = this.props;
    const previous = `${currentPathname}${this.cleanLocationSearch(currentSearch)}`;

    if (isAnonymousRoute(pathname, hsid) && !isAnonymousRoute(previous, hsid)) {
      this.previous = this.getPreviousPath(previous);
    } else if (!isAnonymousRoute(previous, hsid)) {
      this.previous = null;
    }
  }

  componentDidCatch(error: Error, errorInfo: Object) {
    Raven.captureException(error, {
      extra: {
        ...errorInfo,
      },
    });

    this.setState({ errorId: Raven.lastEventId() || 'N/A' });
  }

  logoutN4E = (jwtDecode) => {
    this.props.logout().then(() => {
      window.location.href = jwtDecode.elementryLoginRedirectURL;
    });
  };

  render() {
    const {
      showAdminToast,
      featureFlags,
      featureFlagsLoaded,
      loggingIn,
      authenticated,
    } = this.props;

    // LMAO-668: user segmentation by local storage
    let component = <Anonymous />;

    const sessionIsValid = session.isValid();
    if (sessionIsValid) {
      const redirectUrl = ls.getItem('loginRedirectUrl');
      const redirectionUrl = ls.getItem('loginRedirectUrlForDeepLinking');
      const jwtToken = ss.getItem('jwt');
      const jwtDecode = session.register(jwtToken);
      if (featureFlags.releaseNavianceStudentAuthorisedDeeplinking && jwtToken) {
        if (jwtDecode && jwtDecode.role === 'STUDENT') {
          ls.setItem('deepLinkingAuthorizedToken', jwtToken);
          ss.removeItem('jwt');
        }
      }
      // Redirect if user session has redirect URL for Naviance for Elementary and jwt token is set
      // If login user is parent and is impersonating Student then use impersonateStudent method to open new tab/redirect
      if (
        jwtDecode &&
        jwtDecode.elementryLoginRedirectURL &&
        jwtToken &&
        !jwtDecode.renewPwd &&
        !(jwtDecode.loginUser?.role === 'PARENT' || jwtDecode.parent)
      ) {
        this.logoutN4E(jwtDecode);
      }

      if (redirectUrl) {
        component = <RedirectToUrlParam />;
      } else if (featureFlags.releaseNavianceStudentDeepLinkingUnAuthorised && redirectionUrl) {
        component = <RedirectToUrlForDeepLinking url={redirectionUrl} />;
      } else {
        component = <Authorized showAdminToast={showAdminToast} previous={this.previous} />;
      }
    }

    return (
      <>
        {!loggingIn || (featureFlagsLoaded && authenticated) ? (
          <div className={s.app}>
            <IfFeatureAllowed target="releaseBlueridgeGoogleOptimize">
              <GoogleOptimize />
            </IfFeatureAllowed>
            <IfFeatureAllowed target="releaseStudentGainsightAnalytics">
              <GainsightAnalytics
                authenticated={sessionIsValid}
                featureFlags={featureFlags}
                featureFlagsLoaded={featureFlagsLoaded}
              />
            </IfFeatureAllowed>
            <IfFeatureAllowed target="releaseBlueridgeHeapAnalytics">
              <IfFeatureAllowed target="releaseNavianceStudentAnonHeapAnalytics">
                <HeapAnalytics authenticated={sessionIsValid} />
              </IfFeatureAllowed>
            </IfFeatureAllowed>
            <GoogleTagManager />
            <IfFeatureAllowed
              whenDenied={<LegacyExtendSessionModal />}
              target="releaseNavianceStudentNewActivityWatcher"
            >
              {null}
            </IfFeatureAllowed>
            {this.state.errorId ? <GenericError id={this.state.errorId} /> : component}
          </div>
        ) : (
          <ConsoleLogger
            contextName="App component"
            logValue={{ loggingIn, featureFlagsLoaded, authenticated }}
          />
        )}
      </>
    );
  }
}
