// @flow
import * as React from 'react';
import cx from 'classnames';
import Label from 'components/Label';
import Icon from 'components/Icon';
import styles from './styles.scss';

type Props = {
  type: string,
  className: string,
  labelClassName?: string,
  inline: boolean,
  label?: React.Node,
  required?: boolean,
  validationMsg?: ?string,
  bare?: boolean,
  /**
   * is used for setting custom validation error message
   */
  customValidity?: string,
  testId?: string,
  min?: number,
  max?: number,
  value?: any,
  showValidationEvenWithoutLabel?: boolean,
};

export default class Input extends React.PureComponent<Props, void> {
  static defaultProps = {
    type: 'text',
    className: '',
    labelClassName: '',
    inline: false,
    label: null,
    required: false,
    customValidity: '',
    testId: '',
    isValid: false,
    showValidationEvenWithoutLabel: false,
  };

  focus = () => {
    if (this.inputEl) this.inputEl.focus();
  };

  inputEl: ?HTMLInputElement;

  textareaEl: ?HTMLTextAreaElement;

  componentDidMount() {
    if (this.inputEl && this.inputEl.setCustomValidity) {
      this.inputEl.setCustomValidity(this.props.customValidity || '');
    }
    if (this.textareaEl && this.textareaEl.setCustomValidity) {
      this.textareaEl.setCustomValidity(this.props.customValidity || '');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.customValidity !== nextProps.customValidity) {
      if (this.inputEl && this.inputEl.setCustomValidity) {
        this.inputEl.setCustomValidity(nextProps.customValidity || '');
      }
      if (this.textareaEl && this.textareaEl.setCustomValidity) {
        this.textareaEl.setCustomValidity(nextProps.customValidity || '');
      }
    }
  }

  render() {
    const {
      type,
      className,
      labelClassName,
      inline,
      label,
      required,
      validationMsg,
      bare,
      customValidity,
      testId,
      isValid,
      showValidationEvenWithoutLabel,
      ...rest
    } = this.props;

    if (label && showValidationEvenWithoutLabel) {
      throw new Error(
        'Incompatible props passed: label and showValidationEvenWithoutLabel are mutually exclusive'
      );
    }

    const minMaxValidationWarning =
      this.props.value < this.props.min ||
      this.props.value > this.props.max ||
      (!this.props.value && this.props.value !== '' && !this.props.min) ? (
        <div className={styles.validationWarning}>
          {validationMsg && <Icon icon="alert" alt="warning" className={styles.icon} />}
          {validationMsg}
        </div>
      ) : null;
    const validationWithoutLabel = this.props.showValidationEvenWithoutLabel ? (
      <div className={styles.validationWarning}>
        {validationMsg && <Icon icon="alert" alt="warning" className={styles.icon} />}
        {validationMsg}
      </div>
    ) : null;
    const labelProps =
      (this.props.min || this.props.min === 0) && this.props.max
        ? { ...{ required, validationMsg, isValid, min: this.props.min, max: this.props.max } }
        : { ...{ required, validationMsg, isValid } };

    const field =
      type === 'textarea' ? (
        <div>
          <textarea
            ref={(elem) => (this.textareaEl = elem)}
            required={required}
            {...rest}
            className={cx(styles.textarea, className)}
          />
          {validationWithoutLabel}
        </div>
      ) : (
        <>
          <input
            ref={(elem) => (this.inputEl = elem)}
            type={type}
            required={required}
            data-test-id={testId}
            {...rest}
            className={cx(
              styles.input,
              {
                [styles.inline]: inline,
                [styles.bare]: bare,
              },
              className
            )}
          />
          {minMaxValidationWarning}
        </>
      );

    if (label) {
      return (
        <Label className={labelClassName} {...labelProps}>
          {label}
          {field}
        </Label>
      );
    }

    return field;
  }
}
