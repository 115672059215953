import * as React from 'react';

import Chunk from 'components/Chunk';

const closedBeta = () => import('containers/ClosedBeta' /* webpackChunkName: "closed-beta" */);  // eslint-disable-line prettier/prettier

export default class ClosedBeta extends React.Component {
  render() {
    return <Chunk load={closedBeta} />;
  }
}
