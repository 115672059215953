/* eslint-disable */

const initGoogleOptimize = (_dataLayer = [], enablePageHiding = false) => {
  window.googleOptimize = window.googleOptimize || {};

  // define base tracking method
  googleOptimize.initTracking = googleOptimize.initTracking || function (i, s, o, g, r, a, m) {
    let h;
    i['GoogleAnalyticsObject'] = r;
    i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      };
    i[r].l = 1 * new Date();

    // force the script to render in <head>
    h = s.getElementsByTagName('head')[0];
    a = s.createElement(o);
    m = h.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    a.setAttribute('data-goptimize', '');
    m && m.parentNode.insertBefore(a, m);
  };

  // define page hiding snippet
  googleOptimize.initPageHiding = googleOptimize.initPageHiding || function (a, s, y, n, c, h, i, d, e) {
    s.className += ' ' + y;
    h.start = 1 * new Date();
    h.end = i = function() {
      s.className = s.className.replace(RegExp(' ?' + y), '');
    };
    (a[n] = a[n] || []).hide = h;
    setTimeout(function() {
      i();
      h.end = null;
    }, c);
    h.timeout = c;
  }

  // initialize dataLayer Object
  window.dataLayer = _dataLayer;

  // initialize page hiding snippet
  if (enablePageHiding && window.dataLayer) {
    googleOptimize.initPageHiding(
      window,
      document.documentElement,
      'async-hide',
      'dataLayer',
      4000, // default wait time for Optimize is 4000ms
      { [GOOGLE_OPTIMIZE_PAGE_HIDING_CODE]: true }
    );
  }

  // finally initialize
  googleOptimize.initTracking(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  window.ga('create', GOOGLE_OPTIMIZE_GA_CODE, 'auto'); // GA Property ID
  window.ga('require', GOOGLE_OPTIMIZE_GTM_CODE); // Optimize Container ID
  // Pageview call has been removed as pageview tag is implemented in GTM
}

export default initGoogleOptimize;
