import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/Accounts/SchoolLookupElementary' /* webpackChunkName: "school-lookup-elementary" */);  // eslint-disable-line prettier/prettier

export default class SchoolLookupElementaryRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
