import React, { FC } from 'react';

import { singleLineHTMLConfig } from 'components/SanitizedHTML';
import sanitizeHTML from 'sanitize-html';

interface Props {
  value: any;
  label: any;
  name?: string;
  lang?: string;
  id?: number;
  searchFilter?: object;
}

export type ComboItemNeonProps = Props & React.OptionHTMLAttributes<HTMLOptionElement>;

const ComboItemNeon: FC<ComboItemNeonProps> = ({ value, label, ...rest }) => (
  <option
    value={value}
    {...rest}
    dangerouslySetInnerHTML={{ __html: sanitizeHTML(label, singleLineHTMLConfig) }}
  />
);

export default ComboItemNeon;
