// @flow
import React from 'react';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isObject from 'utils/isObject';
import { isAllowed, isFeatureFlagAllowed } from 'utils/permissions';
import { getCurrentUser } from 'selectors/auth';
import { getSuperMatchNextLabel } from './colleges/supermatchnext';
import { arePermissionsLoaded, getPermissions, getEditPermissions } from './permissions';
import { getFeatureFlags, areFeatureFlagsLoaded } from './featureFlags';
import { isAustralia } from './highschool';

export type NavLink = {
  +to: string,
  +children: any,
  'data-test-id': string,
  neededPermission?: string,
  icon?: string,
  menu?: Object[],
  favorites?: Object[],
};

export const getNewNavigationFlag = createSelector(
  [getFeatureFlags, areFeatureFlagsLoaded],
  (featureFlags, featureFlagsLoaded) =>
    featureFlagsLoaded && featureFlags.featureNavianceStudentNavigation
);

export const getExploreCareersFlag = createSelector(
  [getFeatureFlags, areFeatureFlagsLoaded],
  (featureFlags, featureFlagsLoaded) => featureFlagsLoaded && featureFlags.featureCareers2Explore
);

export const hasCPNext = createSelector(
  [arePermissionsLoaded, getPermissions],
  (permissionsLoaded, permissions) =>
    permissionsLoaded &&
    permissions.resources.courses &&
    permissions.resources.courses.includes('course_planner_next')
);

const getSiteMap: (Object) => NavLink[] = createSelector(
  [
    getFeatureFlags,
    getPermissions,
    isAustralia,
    getSuperMatchNextLabel,
    getCurrentUser,
    getNewNavigationFlag,
  ],
  (
    featureFlags,
    permissions,
    whenInAustralia: boolean,
    superMatchNextLabel,
    profile,
    newNavigationEnabled
  ) => {
    const scholarshipsListAllowed = featureFlags.scholarshipList;
    const releaseSharedScholarshipsAllowed = featureFlags.releaseSharedScholarships;
    const showNewNavianceScholarship =
      !featureFlags.releaseSharedScholarshipDisableForMaintenance &&
      featureFlags.featureNavianceStudentScholarshipsMicroApp;
    const scholarshipsPermission = get(permissions, 'resources.colleges', []).includes(
      'scholarships'
    );
    const scholarshipMatchPermission = get(permissions, 'resources.colleges', []).includes(
      'scholarship_match'
    );
    const showScholarshipsListOption =
      scholarshipsListAllowed && (scholarshipsPermission || scholarshipMatchPermission);
    let scholarshipsRouteSuffix = 'colleges';
    if (
      releaseSharedScholarshipsAllowed &&
      scholarshipsListAllowed &&
      !scholarshipMatchPermission &&
      scholarshipsPermission
    ) {
      scholarshipsRouteSuffix = 'national-and-local';
    }

    if (!profile) {
      profile = { firstName: '' };
    }

    return newNavigationEnabled
      ? [] // Careers2 links are handled separately
      : [
          { to: '/main', children: 'Home', icon: 'weekend-house', 'data-test-id': 'navbar_home' },
          {
            to: '/course-planner-next',
            children: <>Courses</>,
            title: 'Courses',
            'data-test-id': 'navbar_course_planner_next',
            neededPermission: 'courses',
            aclGroup: 'courses',
            aclTarget: 'course_planner_next',
            menu: [
              {
                title: 'Course Catalog',
                to: '/course-planner-next/catalog',
              },
              {
                title: "Courses I'm thinking About",
                to: '/course-planner-next/favorites',
              },
              {
                title: 'Manage Course Plans',
                to: '/course-planner-next/plans',
                aclGroup: 'courses',
                aclTarget: 'view_and_edit_course_plans',
              },
            ],
          },
          {
            to: '/colleges',
            children: whenInAustralia ? 'Higher Ed' : 'Colleges',
            neededPermission: 'colleges',
            'data-test-id': 'navbar_colleges',
            favorites: [
              {
                id: 'bm-colleges-home',
                to: '/colleges',
                icon: 'weekend-house',
                title: whenInAustralia ? 'Higher Ed' : 'Colleges',
                caption: 'Home',
                aclGroup: 'header',
                aclTarget: 'colleges',
              },
              {
                id: 'bm-colleges-thinking',
                to: '/colleges/application/considering',
                icon: 'heart-full',
                title: 'Colleges',
                notAllowedFor: 'GUEST',
                caption: "I'm thinking about",
              },
              {
                id: 'bm-colleges-applying',
                to: '/colleges/applying-to',
                icon: 'graduation-hat',
                title: 'Colleges',
                notAllowedFor: 'GUEST',
                caption: "I'm applying to",
              },
            ],
            menu: [
              {
                title: 'Find Your Fit',
                items: [
                  {
                    title: 'SuperMatch',
                    to: '/colleges/supermatch',
                    aclGroup: 'colleges',
                    aclTarget: 'super_match_college_search',
                    featureFlag: 'superMatch',
                  },
                  {
                    title: superMatchNextLabel,
                    to: '/colleges/supermatch-next',
                    aclGroup: 'colleges',
                    aclTarget: 'super_match_college_search',
                    featureFlag: 'superMatchNext',
                  },
                  {
                    title: 'College Match',
                    to: '/colleges/match',
                    aclGroup: 'colleges',
                    aclTarget: [
                      'college_match_active_match',
                      'college_match_admission_history',
                      'college_match_universal_overlaps',
                    ],
                    aclTargetType: 'any',
                    featureFlag: 'collegeMatch',
                  },
                  {
                    title: 'College Events',
                    to: '/colleges/events',
                    aclGroup: 'colleges',
                    aclTarget: 'active_match_events',
                    featureFlag: 'collegeEvents',
                  },
                  {
                    title: 'Scattergrams',
                    to: '/colleges/scattergram',
                    aclGroup: 'colleges',
                    aclTarget: 'scattergrams',
                    notAllowedFor: 'GUEST',
                  },
                  {
                    title: 'Advanced College Search',
                    to: '/colleges/search',
                    aclGroup: 'colleges',
                    aclTarget: 'college_search',
                    notAllowedFor: 'GUEST',
                  },
                  {
                    title: 'College Lookup',
                    to: '/colleges/college-lookup',
                    aclGroup: 'colleges',
                    aclTarget: 'college_search',
                    notAllowedFor: 'GUEST',
                  },
                ],
              },
              {
                title: 'Research Colleges',
                items: [
                  {
                    title: `I'm Thinking About`,
                    to: '/colleges/application/considering',
                    aclGroup: 'colleges',
                    aclTarget: 'college_search',
                    notAllowedFor: 'GUEST',
                  },
                  {
                    title: 'College Compare',
                    to: '/colleges/compare',
                    aclGroup: 'colleges',
                    aclTarget: ['college_statistics', 'college_compare'],
                    aclTargetType: 'all',
                    featureFlag: 'collegeCompare',
                  },
                  {
                    title: 'College Resources',
                    to: '/colleges/resources',
                    aclGroup: 'colleges',
                    aclTarget: 'resources',
                    featureFlag: 'collegesResources',
                  },
                  {
                    title: 'Acceptance History',
                    to: '/colleges/acceptance-history',
                    aclGroup: 'colleges',
                    aclTarget: 'acceptance_history',
                  },
                  {
                    title: 'Enrichment Programs',
                    to: '/colleges/enrichment-programs',
                    aclGroup: 'colleges',
                    aclTarget: 'enrichment_programs',
                    featureFlag: 'enrichmentPrograms',
                  },
                  {
                    title: 'College Maps',
                    to: '/colleges/maps',
                    aclGroup: 'colleges',
                    aclTarget: 'college_maps',
                  },
                  {
                    title: 'College Visits',
                    to: '/colleges/visits',
                    aclGroup: 'colleges',
                    aclTarget: 'college_visits',
                    featureFlag: 'collegeVisits',
                  },
                ],
              },
              {
                title: 'Apply to College',
                items: [
                  {
                    title: 'Letters of Recommendation',
                    to: LOR_APP_URL,
                    aclGroup: 'colleges',
                    aclTarget: 'request_letters_of_recommendation',
                    featureFlag: 'lettersOfRecommendation',
                  },

                  {
                    title: 'Manage Transcripts',
                    to: '/colleges/transcripts',
                    aclGroup: 'transcripts',
                    aclTarget: 'display',
                    featureFlag: 'transcripts',
                  },
                  {
                    title: 'Test Scores',
                    to: '/about-me/test-scores',
                    featureFlag: 'testScores',
                    notAllowedFor: 'GUEST',
                  },
                ],
              },
              {
                title: 'Scholarships and Money',
                items: [
                  ...(showScholarshipsListOption
                    ? [
                        {
                          title: 'Scholarship List',
                          to: `/colleges/scholarships/${scholarshipsRouteSuffix}`,
                          aclGroup: 'colleges',
                          aclTarget: ['scholarships', 'scholarship_match'],
                          aclTargetType: 'any',
                          featureFlag: 'scholarshipList',
                        },
                      ]
                    : []),
                  ...[
                    {
                      title: 'Scholarship Applications',
                      to: '/colleges/scholarships/applications',
                      aclGroup: 'colleges',
                      aclTarget: 'scholarships',
                      featureFlag: 'scholarships',
                      notAllowedFor: 'GUEST',
                    },
                    {
                      title: 'National Scholarship Search',
                      to: '/colleges/scholarships/national',
                      target: '_blank',
                      aclGroup: 'colleges',
                      aclTarget: 'national_scholarship_search',
                      featureFlag: 'nationalScholarship',
                    },
                  ],
                  ...(showNewNavianceScholarship
                    ? [
                        {
                          title: 'Scholarship New Application',
                          to: '/colleges/scholarships/new/applications',
                          aclGroup: 'colleges',
                          aclTarget: 'scholarships',
                          featureFlag: showNewNavianceScholarship,
                          notAllowedFor: 'GUEST',
                        },
                      ]
                    : []),
                ],
              },
            ],
          },
          {
            to: '/careers',
            children: 'Careers',
            neededPermission: 'careers',
            'data-test-id': 'navbar_careers',
            favorites: [
              {
                id: 'bm-careers-home',
                to: '/careers',
                icon: 'weekend-house',
                title: 'Careers',
                caption: 'Home',
                aclGroup: 'header',
                aclTarget: 'careers',
              },
              {
                id: 'bm-careers-thinking',
                to: '/careers/favorites',
                icon: 'heart-full',
                title: 'Careers',
                caption: "I'm thinking about",
                aclGroup: 'careers',
                aclTarget: 'career_search',
              },
              {
                id: 'bm-explore-careers',
                to: '/careers/explore',
                icon: 'graduation-hat',
                title: 'Explore',
                caption: 'Careers, Clusters and Pathways',
                aclGroup: 'careers',
                aclTarget: 'career_search',
              },
              {
                id: 'bm-careers-rtn',
                to: '/careers/roadtripnation',
                icon: 'graduation-hat',
                title: 'Roadtrip Nation',
                caption: 'Interview archive',
                aclGroup: 'careers',
                aclTarget: 'roadtrip_nation_interview_archive',
              },
            ],
            menu: [
              {
                title: 'Explore Careers and Interests',
                items: [
                  {
                    title: 'Career Cluster Finder',
                    to: '/careers/cluster-finder',
                    aclGroup: 'careers',
                    aclTarget: 'career_search',
                  },
                  {
                    title: 'Career Interest Profiler',
                    to: '/careers/interest-profiler',
                    aclGroup: 'careers',
                    aclTarget: 'career_interest_profiles',
                    featureFlag: 'careerInterestProfiler',
                  },
                  {
                    title: 'Career Key',
                    to: '/careers/career-key',
                    aclGroup: 'careers',
                    aclTarget: 'career_key',
                    featureFlag: 'careerKey',
                  },
                  {
                    title: 'Do What You Are™',
                    to: '/about-me/achieveworks/DWYA',
                    aclGroup: 'about-me',
                    aclTarget: 'do_what_you_want_2',
                    featureFlag: 'doWhatYouAre',
                  },
                  {
                    title: 'YouScience',
                    to: '/careers/you-science',
                    aclGroup: 'careers',
                    aclTarget: ['youscience_ms', 'youscience_hs'],
                    aclTargetType: 'any',
                    featureFlag: 'youScienceAssessments',
                  },
                ],
              },
            ],
          },
          {
            to: '/about-me',
            children: 'About Me',
            neededPermission: 'about_me',
            'data-test-id': 'navbar_aboutme',
            favorites: [
              {
                id: 'bm-aboutme-home',
                to: '/about-me',
                icon: 'weekend-house',
                title: 'About Me',
                aclGroup: 'header',
                aclTarget: 'about_me',
                caption: 'Home',
              },
              'user',
            ],
            menu: [
              {
                title: 'My Stuff',
                notAllowedFor: 'PARENT',
                items: [
                  {
                    title: 'My Surveys',
                    to: '/about-me/surveys',
                  },
                  {
                    title: 'Resume',
                    to: '/about-me/resume',
                  },
                  {
                    title: 'Test Scores',
                    to: '/about-me/test-scores',
                    featureFlag: 'testScores',
                  },
                  {
                    title: 'Portfolio',
                    to: '/about-me/portfolio',
                    featureFlag: 'portfolio',
                  },
                  {
                    title: 'Documents Shared With Me',
                    to: '/documents',
                    featureFlags: 'documents',
                  },
                  {
                    title: 'Journal',
                    to: '/about-me/journal',
                    aclGroup: 'about-me',
                    aclTarget: 'journal',
                  },
                ],
              },
              {
                title: `${profile.firstName}'s Stuff`,
                notAllowedFor: 'STUDENT',
                items: [
                  {
                    title: `Surveys`,
                    to: '/about-me/student/surveys',
                  },
                  {
                    title: 'Resume',
                    to: '/about-me/resume',
                  },
                  {
                    title: 'Test Scores',
                    to: '/about-me/test-scores',
                    featureFlag: 'testScores',
                  },
                  {
                    title: 'Portfolio',
                    to: '/about-me/portfolio',
                    featureFlag: 'portfolio',
                  },
                  {
                    title: 'My Student Readiness Reports',
                    to: '/student-readiness-results',
                    featureFlag: 'releaseSharedStudentReadinessReport',
                  },
                  {
                    title: 'Documents Shared With Me',
                    to: '/documents',
                    featureFlags: 'documents',
                  },
                  {
                    title: 'Journal',
                    to: '/about-me/journal',
                    aclGroup: 'about-me',
                    aclTarget: 'journal',
                  },
                ],
              },
              {
                title: `${profile.firstName}'s Assessments`,
                notAllowedFor: 'STUDENT',
                items: [
                  {
                    title: 'Do What You Are',
                    to: '/about-me/achieveworks/DWYA',
                    aclGroup: 'about-me',
                    aclTarget: 'do_what_you_want_2',
                    featureFlag: 'doWhatYouAre',
                  },
                  {
                    title: 'MI Advantage',
                    to: '/about-me/achieveworks/MI',
                    aclGroup: 'about-me',
                    aclTarget: 'mi_advantage_2',
                  },
                  {
                    title: 'Learning Styles Inventory',
                    to: '/about-me/achieveworks/LSI',
                    aclGroup: 'about-me',
                    aclTarget: 'learning_style_2',
                  },
                  {
                    title: 'Strengths Explorer',
                    to: '/about-me/strengths-explorer',
                    aclGroup: 'about-me',
                    aclTarget: 'strengths_explorer',
                  },
                  {
                    title: 'Career Key',
                    to: '/careers/career-key',
                    aclGroup: 'careers',
                    aclTarget: 'career_key',
                    featureFlag: 'careerKey',
                  },
                  {
                    title: 'Career Interest Profiler',
                    to: '/careers/interest-profiler',
                    aclGroup: 'careers',
                    aclTarget: 'career_interest_profiles',
                  },
                  {
                    title: 'Career Cluster Finder',
                    to: '/careers/cluster-finder',
                    aclGroup: 'careers',
                    aclTarget: 'career_search',
                  },
                ],
              },
              {
                title: 'My Assessments',
                notAllowedFor: 'PARENT',
                items: [
                  {
                    title: 'Do What You Are',
                    to: '/about-me/achieveworks/DWYA',
                    aclGroup: 'about-me',
                    aclTarget: 'do_what_you_want_2',
                    featureFlag: 'doWhatYouAre',
                  },
                  {
                    title: 'MI Advantage',
                    to: '/about-me/achieveworks/MI',
                    aclGroup: 'about-me',
                    aclTarget: 'mi_advantage_2',
                  },
                  {
                    title: 'Learning Styles Inventory',
                    to: '/about-me/achieveworks/LSI',
                    aclGroup: 'about-me',
                    aclTarget: 'learning_style_2',
                  },
                  {
                    title: 'Strengths Explorer',
                    to: '/about-me/strengths-explorer',
                    aclGroup: 'about-me',
                    aclTarget: 'strengths_explorer',
                  },
                  {
                    title: 'Career Key',
                    to: '/careers/career-key',
                    aclGroup: 'careers',
                    aclTarget: 'career_key',
                    featureFlag: 'careerKey',
                  },
                  {
                    title: 'Career Interest Profiler',
                    to: '/careers/interest-profiler',
                    aclGroup: 'careers',
                    aclTarget: 'career_interest_profiles',
                  },
                  {
                    title: 'Career Cluster Finder',
                    to: '/careers/cluster-finder',
                    aclGroup: 'careers',
                    aclTarget: 'career_search',
                  },
                  {
                    title: 'YouScience',
                    to: '/careers/you-science',
                    aclGroup: 'careers',
                    aclTarget: ['youscience_hs', 'youscience_ms'],
                    aclTargetType: 'any',
                    featureFlag: 'youScienceAssessments',
                  },
                ],
              },
              {
                title: 'My Surveys',
                items: [
                  {
                    title: 'Surveys In progress',
                    to: '/about-me/surveys/in-progress',
                  },
                  {
                    title: 'Surveys Completed',
                    to: '/about-me/surveys/done',
                  },
                  {
                    title: 'Surveys Not Started',
                    to: '/about-me/surveys/not-started',
                  },
                ],
              },
              {
                title: 'Postsecondary Plans',
                items: [
                  {
                    title: 'Game Plan',
                    to: '/about-me/game-plan',
                    aclGroup: 'about-me',
                    aclTarget: 'game_plan_survey',
                    featureFlag: 'gameplanSurvey',
                  },
                ],
              },
            ],
          },
          {
            to: '/my-planner',
            children: 'My Planner',
            neededPermission: 'success_planner',
            'data-test-id': 'navbar_planner',
            favorites: [
              {
                id: 'bm-planner-home',
                to: '/my-planner',
                icon: 'weekend-house',
                title: 'My Planner',
                caption: 'Overview',
                aclGroup: 'header',
                aclTarget: 'success_planner',
              },
            ],
            menu: [
              {
                title: 'Goals',
                to: '/my-planner/goals',
              },
              {
                title: 'To-dos',
                to: '/my-planner/to-do-list',
              },
              {
                title: 'Tasks',
                to: '/my-planner/tasks-assigned-to-me',
              },
              {
                title: 'Student Readiness Indicators',
                to: '/my-planner/student-readiness-indicators',
                featureFlag: ['releaseSucceedSrt', 'releaseSharedStudentReadinessIndicators'],
                aclGroup: 'success-planner',
                aclTarget: 'view_student_readiness_indicators',
              },
              {
                title: 'Calendar View',
                to: '/my-planner/overview',
                featureFlag: ['featureNavianceStudentMyPlannerCalendarView'],
              },
            ],
          },
        ];
  }
);

const isPermitted = (link, permissions) => {
  // some links share permissions but guest users can not see them.
  if (permissions.role === 'STUDENT' && permissions.parentId) {
    return link.notAllowedFor !== 'PARENT';
  }
  return link.notAllowedFor !== permissions.role;
};

const filterPermittedLinks = (link, permissions, featureFlags) => {
  // only process if object. Otherwise, it's a special use case such as 'user'
  if (!isObject(link)) {
    return link;
  }

  // the link is permitted only if aclTarget value is in the permissions group
  if (
    !isAllowed({
      acl: permissions,
      group: link.aclGroup,
      target: link.aclTarget,
      checkFor: link.aclTargetType,
    })
  ) {
    return false;
  }

  // then, the link is permitted if it is available for the role
  if (!isPermitted(link, permissions)) {
    return false;
  }

  // finally, the link is permitted if the feature flag is allowed
  const featureFlagAllowed =
    featureFlags.bigMenu !== false && isFeatureFlagAllowed(link.featureFlag, featureFlags);
  if (!featureFlagAllowed) {
    return false;
  }

  let filteredItems = [];

  if (Array.isArray(link.items)) {
    filteredItems = link.items
      .map((subLink) => filterPermittedLinks(subLink, permissions, featureFlags))
      .filter(Boolean);
    // if no sublinks are allowed then the parent link should go away
    if (filteredItems.length < 1) return false;
  }
  return { ...link, items: filteredItems };
};

export const getMainNavLinks: (Object) => NavLink[] = createSelector(
  [getSiteMap, getPermissions, getFeatureFlags],
  (sitemap, permissions, featureFlags) =>
    sitemap
      .map((link) => filterPermittedLinks(link, permissions, featureFlags))
      .filter(Boolean)
      .map((link) => ({
        ...link,
        menu: Array.isArray(link.menu)
          ? link.menu
              .map((menu) => filterPermittedLinks(menu, permissions, featureFlags))
              .filter(Boolean)
          : null,
        favorites: Array.isArray(link.favorites)
          ? link.favorites
              .map((fav) => filterPermittedLinks(fav, permissions, featureFlags))
              .filter(Boolean)
          : null,
        lists: Array.isArray(link.lists)
          ? link.lists
              .map((column) => filterPermittedLinks(column, permissions, featureFlags))
              .filter(Boolean)
          : null,
      }))
);

const secondaryNavLinks: Array<NavLink> = [
  {
    to: '/account',
    children: 'Manage my account',
    neededPermission: 'edit_email_address',
    icon: 'gear2',
    'data-test-id': 'navbar_account',
  },
];

export const getHeaderPermissions = createSelector(
  [getPermissions, arePermissionsLoaded],
  (permissions, permissionsLoaded) => (permissionsLoaded && permissions.resources.header) || []
);

export const getPermittedLinks = createSelector(
  [getHeaderPermissions, getMainNavLinks],
  (permissions, mainNavLinks): Array<NavLink> =>
    mainNavLinks.filter(
      (link) =>
        !link.neededPermission ||
        (link.neededPermission && permissions.includes(link.neededPermission))
    )
);

export const getPermittedSecondaryLinks = createSelector(
  [getEditPermissions],
  (permissions): Array<NavLink> =>
    secondaryNavLinks.filter(
      (link) =>
        !link.neededPermission ||
        (link.neededPermission && permissions.includes(link.neededPermission))
    )
);
