// @flow
import { headed2TokenExchangeServiceUrl, headed2AppUrl } from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import store, { injectAsyncReducers } from 'store';
import jwtDecode from 'jwt-decode';
import session from 'config/session';
import ls from 'utils/localStorage';
import { getFeatureFlags } from 'selectors/featureFlags';

const generateConstants = constantsGenerator('fc/h2Auth');

const [ACCOUNTLINK_STATUS, GET_H2_AUTH_SUCCESS, GET_H2_AUTH_FAIL]: string[] = generateConstants(
  'ACCOUNTLINK_STATUS'
);

const [
  GET_H2_AUTH,
  ACCOUNTLINK_STATUS_SUCCESS,
  ACCOUNTLINK_STATUS_FAIL,
]: string[] = generateConstants('GET_H2_AUTH');

const [LINK_ACCOUNT, LINK_ACCOUNT_SUCCESS, LINK_ACCOUNT_FAIL]: string[] = generateConstants(
  'LINK_ACCOUNT'
);

const [UNLINK_ACCOUNT, UNLINK_ACCOUNT_SUCCESS, UNLINK_ACCOUNT_FAIL]: string[] = generateConstants(
  'UNLINK_ACCOUNT'
);

export type State = {
  loading: boolean,
  errorFlag: boolean,
  authorized: boolean,
  linkStatus: string,
  headed2Endpoint: string,
};

const initialState = {
  loading: false,
  authorized: false,
  linkStatus: 'unlinked',
  errorFlag: false,
  headed2Endpoint: null,
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_H2_AUTH:
      return {
        ...state,
        loading: true,
      };
    case GET_H2_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        authorized: action.result,
      };
    }
    case GET_H2_AUTH_FAIL: {
      return {
        ...state,
        loading: false,
        authorized: false,
      };
    }
    case ACCOUNTLINK_STATUS:
      return {
        ...state,
      };
    case ACCOUNTLINK_STATUS_SUCCESS: {
      if (action.result === 'approved') {
        return {
          ...state,
          errorFlag: false,
          linkStatus: 'linked',
        };
      }
      if (action.result === 'awaiting_parent') {
        return {
          ...state,
          errorFlag: false,
          linkStatus: 'pending',
        };
      }
      return {
        ...state,
        errorFlag: false,
        linkStatus: 'unlinked',
      };
    }
    case ACCOUNTLINK_STATUS_FAIL: {
      return {
        ...state,
        linkStatus: 'unlinked',
        errorFlag: true,
      };
    }
    case LINK_ACCOUNT:
      return {
        ...state,
        errorFlag: false,
      };
    case LINK_ACCOUNT_SUCCESS:
      return {
        ...state,
        errorFlag: false,
        headed2Endpoint: action.result,
      };
    case LINK_ACCOUNT_FAIL:
      return {
        ...state,
        errorFlag: true,
      };
    case UNLINK_ACCOUNT:
      return {
        ...state,
        errorFlag: false,
      };
    case UNLINK_ACCOUNT_SUCCESS:
      return {
        ...state,
        linkStatus: 'unlinked',
        errorFlag: false,
      };
    case UNLINK_ACCOUNT_FAIL:
      return {
        ...state,
        errorFlag: true,
      };
    default:
      return state;
  }
}

async function getHeaded2Token() {
  const jwt = session.isValid() ? ls.getItem('deepLinkingAuthorizedToken') : null;
  if (!jwt) {
    throw new Error('Invalid jwt');
  }

  try {
    const response = await fetch(`${headed2TokenExchangeServiceUrl}/h2token`, {
      method: 'GET',
      headers: {
        'brapi-token': jwt,
      },
    });

    const body = await response.json();

    if (body.h2Token) {
      sessionStorage.setItem('headed2Token', body.h2Token);
      return body.h2Token;
    }
    throw new Error('Invalid headed2 Token');
  } catch (err) {
    throw new Error('Failed to generate headed2 Token');
  }
}

function shouldRefreshToken(sessionToken) {
  const { exp, externalId } = jwtDecode(sessionToken);
  const { id } = jwtDecode(ls.getItem('deepLinkingAuthorizedToken'));
  const expirationTime = exp * 1000 - 60000;

  return !sessionToken || Date.now() >= expirationTime || externalId !== id;
}

async function generateH2Token() {
  let sessionToken = sessionStorage.getItem('headed2Token');

  if (!sessionToken || shouldRefreshToken(sessionToken)) {
    await getHeaded2Token();
    sessionToken = sessionStorage.getItem('headed2Token');
  }

  return sessionToken;
}

function getStateBaseURL(getState) {
  const stateCode = getState().highschool.state;
  sessionStorage.setItem('stateCode', stateCode);
  const stateBaseURL = headed2AppUrl.replace(/app/, stateCode);
  return stateBaseURL;
}

async function getOptions(methodType) {
  const h2Token = await generateH2Token();
  const commonOptions = {
    method: methodType,
    headers: {
      'Content-Type': 'application/json',
      authorization: h2Token,
    },
  };

  if (methodType === 'POST') {
    return {
      ...commonOptions,
      body: JSON.stringify({ backToOriginUrl: window.location.href }),
    };
  }

  return commonOptions;
}

async function h2API(retryCount = 0, methodType, getState) {
  const state = store.getState();
  const featureFlags = getFeatureFlags(state);
  if (!featureFlags.featureNavianceStudentHeaded2JobSearch) {
    return false;
  }

  if (!ls.getItem('deepLinkingAuthorizedToken')) {
    return false;
  }

  const stateBaseURL = getStateBaseURL(getState);
  const options = await getOptions(methodType);

  const response = await fetch(`${stateBaseURL}/authorization`, options);
  if (response.status !== 200) {
    if (response.status === 422 && retryCount < 3) {
      return h2API(retryCount + 1, methodType, getState);
    }
    if (response.status === 401 && methodType === 'GET') {
      const data = await response.json();
      return data.status || 'unlinked';
    }
    throw new Error(
      'We’ve encountered an error checking your account status. Try checking the status again later.'
    );
  }

  const data = await response.json();
  if (methodType === 'GET') {
    return data.status || 'unlinked';
  }
  if (methodType === 'POST') {
    return data.headed2LinkPath;
  }
  return true;
}

export function h2Status() {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [ACCOUNTLINK_STATUS, ACCOUNTLINK_STATUS_SUCCESS, ACCOUNTLINK_STATUS_FAIL],
      promise: () => h2API(0, 'GET', getState),
    });
}

export function h2AccountLink() {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [LINK_ACCOUNT, LINK_ACCOUNT_SUCCESS, LINK_ACCOUNT_FAIL],
      promise: () => h2API(0, 'POST', getState),
    });
}

export function unlinkH2AccountLink() {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [UNLINK_ACCOUNT, UNLINK_ACCOUNT_SUCCESS, UNLINK_ACCOUNT_FAIL],
      promise: () => h2API(0, 'DELETE', getState),
    });
}

injectAsyncReducers({ h2Auth: reducer });
