import Loadable from 'react-loadable';

const Placeholder = () => null;

// prettier-ignore
const AsyncNavMenu = Loadable({
  loader: () => import('./Careers2DesktopMenu' /* webpackChunkName: "c2-desktop-menu" */),
  loading: Placeholder,
});

export default AsyncNavMenu;
