// @flow
import * as React from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import { disconnectTypes } from 'modules/colleges/lookingForStudentsLikeYou';
import { createSelector } from 'reselect';
import { getUnfilteredMatchingColleges } from 'selectors/colleges/lookingForStudentsLikeYou';
import expandObject from 'utils/expandObject';
import { FeatureFlag } from 'constants/featureFlags';
import mapEntitiesToResults from 'utils/mapEntitiesToResults';
import type { College, LookupTerm, LegacyAMMatch } from 'types/colleges';
import { normalizeUrl } from 'utils/link';
import { fullMonthAndDay, shortMonthAndDay } from 'utils/date';
import { getFeatureFlags } from './featureFlags';

import { submitRadioOptions } from '../containers/Colleges/dataOptions';
import { arePermissionsLoaded, getPermissions } from './permissions';
import { nonAttendingCollegeResultCodes } from '../constants/college';

export type Group = {
  id: number,
  name: string,
};

export const areAcceptanceCollegesLoaded = (state: Object) => state.colleges.acceptance.loaded;
export const areAcceptanceCollegesLoading = (state: Object) => state.colleges.acceptance.loading;
export const getAcceptanceStartYear = (state: Object) => state.colleges.acceptance.startYear;
export const getAcceptanceEndYear = (state: Object) => state.colleges.acceptance.endYear;
export const getAcceptanceEntities = (state: Object) => state.colleges.acceptance.entities;
export const getAcceptanceResults = (state: Object) => state.colleges.acceptance.results;

export const getContactEmailInfo = (state: Object) => state.colleges.main.contactEmailInfo;

export const getContactEmail = (state: Object) => state.colleges.main.contactEmail;

export const getLookupTerm = (state: Object): LookupTerm => state.colleges.main.lookupTerm;

export const getContactEmailNavigatedFrom = (state: Object) =>
  (state.colleges.main.navigatedToEmailFrom || '').includes('considering')
    ? 'thinking-about'
    : 'applying-to';

export const getSavedSearches = (state: Object) => [
  ...Object.values(state.colleges.savedCollegeSearch.results),
];

export const getSelectedSavedSearch = (state: Object) =>
  state.colleges.savedCollegeSearch.initialSelect;

export const getCurrentSelectedSearch = (state: Object) =>
  state.colleges.savedCollegeSearch.currentSelect;

export const getFilters = (state: Object) => state.colleges.main.filters;

export const getEntities = (state: Object) => state.colleges.main.entities;

export const getResults = (state: Object) => state.colleges.main.results;

export const getEntitiesAsArray = createSelector([getResults, getEntities], mapEntitiesToResults);

export const getResultCount = (state: Object) => state.colleges.main.totalItems;

export const getConsideringResults = (state: Object) => state.colleges.considering.results;
export const getConsideringAllResults = (state: Object) => state.colleges.considering.allResults;

export const getConsideringEntities = (state: Object) => state.colleges.considering.entities;
export const getConsideringAllEntities = (state: Object) => state.colleges.considering.allEntities;

export const getAllCollegeVisits = (state: Object) => state.colleges.profiles.visits;
export const getEnhancedCollegeProfiles = (state: Object) => state.colleges.profiles.enhanced;
export const getDeliveryType = (state: Object) => state.colleges.profiles.deliveryType;
export const getCollegeContact = (state: Object) => state.colleges.profiles.collegeContact;
export const getCollegeOverlaps = (state: Object) => state.colleges.profiles.overlaps;

export const getWereConsideringLegacyMatchesLoadedState = (state: Object) =>
  state.colleges.considering.wereLegacyMatchesLoaded;

export const getWereApplyingLegacyMatchesLoadedState = (state: Object) =>
  state.colleges.applying.wereLegacyMatchesLoaded;

export const getCollegeDeadlineTypes = (state: Object) =>
  state.colleges.considering.collegeDeadlineTypes;

export const getCollegeDeadlineTypesWithJustTypeAndLabel = createSelector(
  [getCollegeDeadlineTypes, getFeatureFlags],
  (
    deadlineArrayData: Array<Object>,
    { releaseNavianceStudentCiatAppTypeDisplayValues, releaseNavianceStudentDeadlineDisplayValues }
  ) =>
    deadlineArrayData.reduce((arr, deadlineType) => {
      let label = '';
      if (
        releaseNavianceStudentCiatAppTypeDisplayValues &&
        releaseNavianceStudentDeadlineDisplayValues
      ) {
        label = [
          `${deadlineType.label} ${
            deadlineType.deadlineTermDescription
              ? `- ${deadlineType.deadlineTermDescription} term `
              : ''
          }${deadlineType.deadlineLabel ? `- ${deadlineType.deadlineLabel}` : ''}`,
          ' - ',
          <b>{`Deadline ${shortMonthAndDay(deadlineType.deadlineDate)}`}</b>,
        ];
      } else if (
        releaseNavianceStudentCiatAppTypeDisplayValues &&
        !releaseNavianceStudentDeadlineDisplayValues
      ) {
        label = `${deadlineType.label} ${
          deadlineType.deadlineTermDescription
            ? `- ${deadlineType.deadlineTermDescription} term `
            : ''
        }${
          deadlineType.deadlineLabel ? `- ${deadlineType.deadlineLabel}` : ''
        } - Deadline ${shortMonthAndDay(deadlineType.deadlineDate)}`;
      } else {
        label = `${deadlineType.label} ${
          deadlineType.deadlineLabel ? `(${deadlineType.deadlineLabel})` : ''
        } ${fullMonthAndDay(deadlineType.deadlineDate)}`;
      }
      arr.push({
        id: deadlineType.id,
        collegeId: deadlineType.collegeId,
        value: deadlineType.deadlineTypeId,
        label,
      });
      return arr;
    }, [])
);

export const getConsideringEntitiesAsArray = createSelector(
  [getConsideringResults, getConsideringEntities],
  mapEntitiesToResults
);

export const getConsideringAllEntitiesAsArray = createSelector(
  [getConsideringAllResults, getConsideringAllEntities],
  mapEntitiesToResults
);

export const getConsideringCollegeIds = createSelector(
  [getConsideringEntitiesAsArray],
  (consideringEntitiesArray: Object[]) =>
    consideringEntitiesArray.map((item) => item && item.collegeId)
);

export const getLookupEntities = (state: Object) => state.colleges.lookup.entities;

export const getApplyingResults = (state: Object) => state.colleges.applying.results;
export const getApplyingAllResults = (state: Object) => state.colleges.applying.allResults;

export const getApplyingEntities = (state: Object) => state.colleges.applying.entities;
export const getApplyingAllEntities = (state: Object) => state.colleges.applying.allEntities;

export const getApplyingResultsWithDeleted = (state: Object) =>
  state.colleges.applying.resultsWithDeleted;

export const getApplyingEntitiesWithDeleted = (state: Object) =>
  state.colleges.applying.entitiesWithDeleted;

export const getApplyingEntitiesAsArray = createSelector(
  [getApplyingResults, getApplyingEntities],
  mapEntitiesToResults
);

export const getApplyingAllEntitiesAsArray = createSelector(
  [getApplyingAllResults, getApplyingAllEntities],
  mapEntitiesToResults
);

/* enhanced profile entities: start */
const updateFeaturedEntity = (featureFlags: Array, entities: Array, profiles: Array) => {
  if (!featureFlags.releaseSharedEnhancedCollegeProfiles) return entities;

  const enhancedEntities = {};
  Object.keys(entities).forEach((key) => {
    const entity = entities[key];
    enhancedEntities[key] = {
      ...entity,
      featured: profiles.includes(entity.hobsonsId),
    };
  });

  return enhancedEntities;
};

export const getEnhancedProfileEntities = createSelector(
  [getFeatureFlags, getEntities, getEnhancedCollegeProfiles],
  updateFeaturedEntity
);

export const getEnhancedProfileConsideringEntities = createSelector(
  [getFeatureFlags, getConsideringEntities, getEnhancedCollegeProfiles],
  updateFeaturedEntity
);

export const getEnhancedProfileApplyingEntities = createSelector(
  [getFeatureFlags, getApplyingEntities, getEnhancedCollegeProfiles],
  updateFeaturedEntity
);
/* enhanced profile entities: end */

const filterCollegesThatNeedPopup = (colleges) =>
  colleges.filter((college) => college.connectorId && college.disconnect === null);

const filterConnectedColleges = (colleges) =>
  colleges.filter(
    (college) =>
      college.connectorId &&
      (college.disconnect === disconnectTypes.CONNECTED ||
        college.disconnect === disconnectTypes.PROCESSING)
  );

export const getConsideringConnectedColleges = createSelector(
  [getConsideringEntitiesAsArray],
  (consideringEntities) => filterConnectedColleges(consideringEntities)
);

export const getApplyingConnectedColleges = createSelector(
  [getApplyingEntitiesAsArray],
  (applyingEntities) => filterConnectedColleges(applyingEntities)
);

export const getConsideringCollegesWithConnector = createSelector(
  [getConsideringEntitiesAsArray],
  (consideringEntities) => filterCollegesThatNeedPopup(consideringEntities)
);

export const getConsideringCollegesThatNeedPopup = createSelector(
  [getConsideringAllEntitiesAsArray],
  filterCollegesThatNeedPopup
);

export const getApplyingEntitiesWithConnector = createSelector(
  [getApplyingEntitiesAsArray],
  (applyingEntities) => filterCollegesThatNeedPopup(applyingEntities)
);

export const getApplyingCollegesThatNeedPopup = createSelector(
  [getApplyingAllEntitiesAsArray],
  filterCollegesThatNeedPopup
);

export const getApplyingCollegeIds = createSelector(
  [getApplyingEntitiesAsArray],
  (applyingEntitiesArray: Object[]) => applyingEntitiesArray.map((item) => item && item.collegeId)
);

export const getSimplifiedApplyingEntities = createSelector(
  [getApplyingEntitiesAsArray, (state, allowedKeys = ['name', 'id']) => allowedKeys],
  (applyingEntitiesArray: Object[], allowedKeys) => {
    const filteredApplyingEntitiesArray = applyingEntitiesArray.map((applyingEntity) =>
      Object.keys(applyingEntity)
        .filter((key) => allowedKeys.includes(key))
        .reduce((object, key) => {
          object[key] = applyingEntity[key];
          return object;
        }, {})
    );
    return filteredApplyingEntitiesArray;
  }
);

export const isApplyingLoading = (state: Object) => state.colleges.applying.loading;

export const isConsideringLoading = (state: Object) => state.colleges.considering.loading;

export const isApplyingSubmitting = (state: Object) => state.colleges.applying.submitting;

export const getApplicationMilestonesEntities = (state: Object) =>
  state.colleges.applicationMilestones.entities;

export const getApplicationMilestonesResults = (state: Object) =>
  state.colleges.applicationMilestones.results;

export const getPickedColleges = (state: Object) => state.colleges.considering.pickedCollegeIds;

export const getPickedRemovableCollegesIds = createSelector(
  [getPickedColleges, getApplyingEntitiesAsArray],
  (pickedCollegesIds, applyingEntities) =>
    applyingEntities
      .filter(
        (applyingEntity) =>
          applyingEntity.removable && pickedCollegesIds.includes(applyingEntity.applicationId)
      )
      .map((applyingEntity) => applyingEntity.applicationId)
);

export const getCheckedCollegeIds = (state: Object) =>
  state.colleges.considering.collegeIdSelections;

export const getStudentScholarshipsEntities = (state: Object) =>
  state.colleges.scholarships.studentScholarships.entities;

export const getStudentScholarshipsResults = (state: Object) =>
  state.colleges.scholarships.studentScholarships.results;

export const getStudentScholarshipsEntitiesAsArray = createSelector(
  [getStudentScholarshipsResults, getStudentScholarshipsEntities],
  mapEntitiesToResults
);

export const getStudentScholarshipsAdhocEntities = (state: Object) =>
  state.colleges.scholarships.studentScholarshipsAdhoc.entities;

export const getStudentScholarshipsAdhocResults = (state: Object) =>
  state.colleges.scholarships.studentScholarshipsAdhoc.results;

export const getStudentScholarshipsAdhocEntitiesAsArray = createSelector(
  [getStudentScholarshipsAdhocResults, getStudentScholarshipsAdhocEntities],
  mapEntitiesToResults
);

export const getScholarshipsEntities = (state: Object) =>
  state.colleges.scholarships.scholarships.entities;

export const getScholarshipsResults = (state: Object) =>
  state.colleges.scholarships.scholarships.results;

export const getScholarshipsCollection = createSelector(
  [getScholarshipsResults, getScholarshipsEntities],
  (results, entities) => mapEntitiesToResults(results, entities)
);

export const getStudentScholarshipsCollection = createSelector(
  [getStudentScholarshipsResults, getStudentScholarshipsEntities],
  (results, entities) => mapEntitiesToResults(results, entities)
);

export const getStudentScholarshipsAdHocCollection = createSelector(
  [getStudentScholarshipsAdhocResults, getStudentScholarshipsAdhocEntities],
  (results, entities) => mapEntitiesToResults(results, entities)
);

export const getCandidates = (state: Object) => state.colleges.considering.application;

export const getCandidatesEntities = (state: Object) =>
  state.colleges.considering.application.entities;

export const getCandidatesResults = (state: Object) =>
  state.colleges.considering.application.results;

/**
 * Returns the url of the Request Info product if it exists.
 * Dependency on mapping of legacy products to NextGen result type in `getCollegeProducts`.
 * @return {Array} Array of products
 */
export const getCollegeProductsURL = (state: Object) => {
  let url = '';
  if (Array.isArray(state.colleges.applying.products)) {
    const communicate = state.colleges.applying.products.find(
      ({ category }) => category === 'COMMUNICATE'
    );
    if (communicate && communicate.url) {
      url = normalizeUrl(communicate.url);
    }
  }
  return url;
};

/**
 * Returns the college product id of the Request Info product if it exists.
 * Dependency on mapping of legacy products to NextGen result type in `getCollegeProducts`.
 * @return {Array} Array of products
 */
export const getPremiumProductId = (state: Object) => {
  if (state.colleges.applying.products && Array.isArray(state.colleges.applying.products)) {
    const communicate = state.colleges.applying.products.find(
      ({ category }) => category === 'COMMUNICATE'
    );
    return get(communicate, 'product_id') || '';
  }
  return '';
};

export const getCandidatesEntitiesAsArray = createSelector(
  [getCandidatesResults, getCandidatesEntities],
  mapEntitiesToResults
);

export const getApplicationUpdates = (state: Object) => state.colleges.applying.edits.entities;

/**
 * Filter colleges by set filters.
 * Filters object contains key-value pairs where
 * value can be a specific match or array of possible matches
 * @return {Array} Array of result set IDs
 */
export const filter = createSelector(
  [getFilters, getEntities, getResults],
  (filters, entities, results) => {
    const filterKeys = Object.keys(filters);

    return results
      .map((key) => entities[key])
      .filter((item: any) =>
        filterKeys.every((filterKey) =>
          Array.isArray(filters[filterKey])
            ? filters[filterKey].find((filterVal) => filterVal === item[filterKey])
            : item[filterKey] === filters[filterKey]
        )
      )
      .map((item: any) => item.id);
  }
);

export const getCollegePermissions = createSelector(
  [getPermissions, arePermissionsLoaded],
  (permissions, permissionsLoaded) => (permissionsLoaded && permissions.resources.colleges) || []
);

export const isCollegeCoreIdDisabled = (state) =>
  (state.featureFlags.loaded || state.featureFlags.featureFlagsLoaded) &&
  !!state.featureFlags.flags.featureCollegeProfileCollegeCoreIdDisabled;

const getGroups = (state: Object) => state.colleges.groups;

export const needLoading: (Object) => boolean = createSelector(
  [getGroups],
  (groups) => !groups.loaded
);

// plain array of college groups (as retrieved form server)
export const getCollegeGroupsPlain: (Object) => Group = createSelector(
  [getGroups],
  (groups): Group[] => mapEntitiesToResults(groups.results, groups.entities)
);

function typeToTitle(type) {
  switch (type) {
    case 'top20':
      return 'Top 20 most popular colleges where our students applied';
    case 'attending':
      return 'Colleges where our students are attending';
    case 'accepted':
      return 'Colleges that accepted our students';
    case 'considering':
      return "Colleges I'm thinking about";
    default:
      return type;
  }
}

export const getGroupName = (state: Object, props: Object): string => {
  const id = props.match.params.type;
  const match = getGroups(state).entities[id] || {};

  return typeToTitle(match.name || id);
};

export const getApplicationById = createSelector(
  [getApplyingEntities, (state, applicationId) => applicationId],
  (applicationEntities, applicationId) => applicationEntities[`${applicationId}`]
);

export const getFlatCollegesApplyingToWithHobsonsId = createSelector(
  [getApplyingEntities, getApplyingResults],
  (entities, results) =>
    sortedUniqBy(
      results.reduce((arr, result) => {
        const item = entities[result];
        if (item.hobsonsId && !arr.includes((a) => a.id)) {
          arr.push({
            id: item.id,
            hobsonsId: item.hobsonsId,
            name: item.name,
            alphaName: item.alphaName,
          });
        }
        return arr;
      }, []),
      (college) => college.alphaName
    )
);
export const getFlatCollegesApplyingTo = createSelector(
  [getApplyingEntities, getApplyingResults],
  (entities, results) =>
    results.map((item) => ({
      name: entities[item].name,
      alphaName: entities[item].alphaName,
      id: entities[item].id,
      applicationId: entities[item].applicationId,
      resultCode: entities[item].resultCode,
    }))
);

export const getFlatSortedCollegesApplyingTo = createSelector(
  [getFlatCollegesApplyingTo],
  (colleges) => {
    // remove all non-attending applications
    colleges = colleges.filter(
      (college) => !nonAttendingCollegeResultCodes.includes(Number(college.resultCode))
    );
    // sort by alphaName and remove duplicates
    const sortedColleges = sortBy(colleges, 'alphaName');
    return sortedUniqBy(sortedColleges, (college) => college.alphaName);
  }
);

export const getCollegeAttending = (state: Object) => {
  const entities = getApplyingEntities(state);

  return getApplyingResults(state).reduce((acc, item) => {
    if (acc) return acc;

    const application = entities[item];
    return application.attending === true ? application.applicationId : acc;
  }, null);
};

// this checks if a college has exclusive app to sumbit application to
export const isExclusive = (college: College) =>
  college.edocsCollege && college.edocsCollege.commonappIsExclusive;

export const hasAppId = (college: College) =>
  college.edocsCollege && college.edocsCollege.commonappId;

export const getCollegeFromAny = (state: Object, id: string) =>
  getLookupEntities(state)[id] ||
  getConsideringEntities(state)[id] ||
  getApplyingEntities(state)[id];

// support for showing diferent submit options for colleges, depending on colleges properties
export const getApplicationSubmissionOptions = (college: College, flags = {}) => {
  const options = [submitRadioOptions[0], submitRadioOptions[1]];
  const isEdocsNextEnabled = flags[FeatureFlag.EdocsNext];

  if (!isEdocsNextEnabled) {
    options.push(submitRadioOptions[2]);
  }

  if (isExclusive(college)) {
    return [options[0]];
  }
  if (hasAppId(college)) {
    return options;
  }
  return [options[1]];
};

export const getApplicationOptions = (state: Object, props: Object) => {
  const college = getCollegeFromAny(state, props.id);
  return getApplicationSubmissionOptions(college, state.featureFlags.flags);
};

// returns the default value for submit options of a college
export const getApplicationSubmissionDefaultValue = (college: College, flags = {}) => {
  const isEdocsNextEnabled = flags[FeatureFlag.EdocsNext];

  if (isExclusive(college)) {
    return '1';
  }
  if (hasAppId(college)) {
    return isEdocsNextEnabled ? '1' : '0';
  }
  return '2';
};

export const getInitialValue = (state: Object, props: Object) => {
  const college = getCollegeFromAny(state, props.id);
  return getApplicationSubmissionDefaultValue(college, state.featureFlags.flags);
};

/**
 * Get college id (used in LikeRenderer)
 */
const getCollegeId = (_, collegeData) => expandObject(collegeData, 'id');

/**
 * Give it a college and it returns info whether it's considered or not
 */
export const isCollegeConsidered = createSelector(
  [getConsideringResults, getCollegeId],
  (results, id) => results.includes(id)
);

/**
 * Get application if from college
 */
export const getApplicationId = createSelector(
  [getConsideringEntities, getCollegeId],
  (entities, id) => expandObject(entities[id], 'prospectiveApplicationId')
);

export const getSupermatchUrl = (state: Object) => expandObject(state, 'colleges.supermatch.url');

export const getTemporaryScholarships = (state: Object) =>
  state.colleges.scholarships.temporaryScholarships;

export const getTemporaryEntities = createSelector([(state, values = {}) => values], (values) =>
  Object.keys(values)
    .filter((key) => key.includes('temporary'))
    .map((key) => values[key])
);

export const getFlatAllAmConsideringMatches = createSelector(
  [getConsideringAllEntities, getConsideringAllResults, getUnfilteredMatchingColleges],
  (entities, results, unfilteredMatchingColleges) =>
    results.reduce((arr, result) => {
      const item = entities[result];
      const legacyMatch = unfilteredMatchingColleges.find(
        (match) => match.hobsonsId === item.hobsonsId && !match.isExpired
      );
      if (item.hobsonsId && !legacyMatch) {
        return arr.concat({
          id: item.id,
          scid: item.hobsonsId.toString(),
          name: item.name,
        });
      }
      return arr;
    }, [])
);

export const getFlatAllAmApplyingToMatches = createSelector(
  [getApplyingAllEntities, getApplyingAllResults, getUnfilteredMatchingColleges],
  (entities, results, unfilteredMatchingColleges) =>
    results.reduce((arr, result) => {
      const item = entities[result];
      const legacyMatch = unfilteredMatchingColleges.find(
        (match) => match.hobsonsId === item.hobsonsId && !match.isExpired
      );
      if (item.hobsonsId && !legacyMatch) {
        return arr.concat({
          id: item.id,
          scid: item.hobsonsId.toString(),
          name: item.name,
        });
      }
      return arr;
    }, [])
);

export const getWereConsideringLegacyMatchesLoaded = createSelector(
  [getWereConsideringLegacyMatchesLoadedState],
  (wereLegacyMatchesLoaded: boolean): boolean => wereLegacyMatchesLoaded
);

export const getWereApplyingLegacyMatchesLoaded = createSelector(
  [getWereApplyingLegacyMatchesLoadedState],
  (wereLegacyMatchesLoaded: boolean): boolean => wereLegacyMatchesLoaded
);

const getFlatLegacyMatches = (results, entities, unfilteredMatchingColleges): LegacyAMMatch =>
  results.reduce((arr, result) => {
    const { hobsonsId, name, id } = entities[result];
    const legacyMatch = unfilteredMatchingColleges.find((match) => match.hobsonsId === hobsonsId);
    if (hobsonsId && legacyMatch) {
      return arr.concat({
        scid: hobsonsId.toString(),
        id,
        name,
        disconnect: legacyMatch.disconnect,
        connectorId: parseInt(legacyMatch.connectorId, 10),
        orderFilterId: parseInt(legacyMatch.orderFilterId, 10),
      });
    }
    return arr;
  }, []);

export const getFlatAllAmConsideringLegacyMatches = createSelector(
  [getConsideringAllEntities, getConsideringAllResults, getUnfilteredMatchingColleges],
  (entities, results, unfilteredMatchingColleges) =>
    getFlatLegacyMatches(results, entities, unfilteredMatchingColleges)
);

export const getAllConsideringPickedConnectedLegacyMatches = createSelector(
  [getPickedColleges, getFlatAllAmConsideringLegacyMatches],
  (pickedCollegesIds, consideringLegacyMatches) => {
    const connectedColleges = filterConnectedColleges(consideringLegacyMatches);
    const result = connectedColleges.filter((match) =>
      pickedCollegesIds.find((id) => id === match.id)
    );

    return result;
  }
);

export const getFlatAllAmApplyingLegacyMatches = createSelector(
  [getApplyingAllEntities, getApplyingAllResults, getUnfilteredMatchingColleges],
  (entities, results, unfilteredMatchingColleges) =>
    getFlatLegacyMatches(results, entities, unfilteredMatchingColleges)
);

export const getAllApplyingPickedConnectedLegacyMatches = createSelector(
  [getPickedColleges, getFlatAllAmApplyingLegacyMatches],
  (pickedCollegesIds, applyingLegacyMatches) => {
    const connectedColleges = filterConnectedColleges(applyingLegacyMatches);
    const result = connectedColleges.filter((match) =>
      pickedCollegesIds.find((id) => id === match.id)
    );

    return result;
  }
);

export const getRankDisplayPermissions = createSelector(
  [getPermissions, arePermissionsLoaded],
  (permissions, permissionsLoaded) =>
    (permissionsLoaded && permissions.resources['rank-display']) || []
);
