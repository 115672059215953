import { useEffect, useState } from 'react';

type ScriptState = 'idle' | 'loading' | 'ready' | 'error';

type props = {
  url: string;
  moduleType?: boolean;
};
export const useExternalScript = ({ url, moduleType }: props): ScriptState => {
  const [state, setState] = useState<ScriptState>(url ? 'loading' : 'idle');

  useEffect(() => {
    if (!url) {
      setState('idle');
      return undefined;
    }
    let script: HTMLScriptElement | null = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e: Event) => {
      setState(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script) {
      script = document.createElement('script');
      script.src = url;
      if (moduleType) {
        script.type = 'module';
        script.crossOrigin = 'anonymous';
      }
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    } else {
      setState('ready');
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    return () => {
      if (script) {
        script.removeEventListener('load', handleScript);
        script.removeEventListener('error', handleScript);
      }
    };
  }, [url]);

  return state;
};
