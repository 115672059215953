import * as React from 'react';

import Chunk from 'components/Chunk';

const load = () => import('containers/ChangesFromMySchool' /* webpackChunkName: "changes-from-my-school" */);  // eslint-disable-line prettier/prettier

export default class ChangesFromMySchoolRoute extends React.Component {
  render() {
    return <Chunk load={load} />;
  }
}
