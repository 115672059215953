import * as React from 'react';
import { connect } from 'react-redux';
import { getFeatureFlags, areFeatureFlagsLoaded } from 'selectors/featureFlags';
import { FeatureFlags } from 'types/featureFlags';
import { isFeatureFlagAllowed } from 'utils/permissions';

interface Props {
  featureFlags?: FeatureFlags;
  featureFlagsLoaded?: boolean;
  target?: string | string[];
  unless?: string | string[];
  children: React.ReactNode;
  whenDenied?: React.ReactNode;
}

/**
 * Wrapper that renders component only if it's allowed
 * by a feature flag.
 */
export class IfFeatureAllowed extends React.Component<Props> {
  static defaultProps = {
    whenDenied: null,
  };

  render() {
    const {
      featureFlags,
      featureFlagsLoaded,
      target,
      unless,
      children,
      whenDenied,
    } = this.props;
    if (!featureFlagsLoaded) {
      return whenDenied;
    }

    const allowed = isFeatureFlagAllowed(target, featureFlags);
    const disallowed = unless && !isFeatureFlagAllowed(unless, featureFlags);

    if (allowed && disallowed !== false) {
      return children;
    }

    return whenDenied;
  }
}

export default connect(
  (state) => ({
    featureFlags: getFeatureFlags(state),
    featureFlagsLoaded: areFeatureFlagsLoaded(state),
  }),
  {}
)(IfFeatureAllowed);
