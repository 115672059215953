// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';

const generateConstants = constantsGenerator('fc/supermatch');
const [
  GET_SUPERMATCH_URL,
  GET_SUPERMATCH_URL_SUCCESS,
  GET_SUPERMATCH_URL_FAIL,
]: string[] = generateConstants('GET_SUPERMATCH_URL');

type State = {
  url: string | null,
  loading: boolean,
  loaded: boolean,
};

const initialState: State = {
  url: null,
  loading: false,
  loaded: false,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_SUPERMATCH_URL:
      return {
        ...state,
        loading: true,
      };
    case GET_SUPERMATCH_URL_SUCCESS:
      return {
        ...state,
        url: action.result,
        loading: false,
        loaded: true,
      };
    case GET_SUPERMATCH_URL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export const fetchSupermatchUrl = () => ({
  types: [GET_SUPERMATCH_URL, GET_SUPERMATCH_URL_SUCCESS, GET_SUPERMATCH_URL_FAIL],
  promise: (client: Object) =>
    client
      .get(`${api.host}/super-match`)
      .then((response) => `${response.host}/${response.path}?${response.params}`),
});
