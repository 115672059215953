// @flow
import isObject from './isObject';

/**
 * Programatically get nested objects and/or array items
 *
 * Usage
 *
 *    expandObject({ a: { b: { c: 'foo' } } }, 'a.b.c') // returns 'foo'
 *
 *    expandObject({ a: { b: { c: 'foo' } } }, ['a', 'b', 'c']) // returns 'foo'
 *
 *    expandObject({ a: { b: { c: 'foo' } } }, 'a.b') // returns { c: 'foo' }
 *
 *    expandObject({ a: [ 'foo' ]}, 'a.0') // returns 'foo'
 *
 * @export
 * @param {Object} obj
 * @param {(string | string[])} keys
 * @returns {mixed} nested object value
 */
export default function expandObject(obj: Object, keys: string | string[]): any {
  const arrKeys = typeof keys === 'string' ? keys.split('.') : Array.from(keys);

  if (!isObject(obj)) {
    return obj;
  }

  return arrKeys.reduce(
    (tree, key) =>
      tree &&
      ((isObject(tree) && Object.prototype.hasOwnProperty.call(tree, key)) || Array.isArray(tree))
        ? tree[key]
        : undefined,
    obj
  );
}
