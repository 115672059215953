import * as React from 'react';
import AccordionSection from './Section';
import { AccordionSectionData } from './Section';

export type AccordionMenuSections = AccordionSectionData[];

interface Props {
  sections?: AccordionMenuSections;
}

export default class AccordionMenu extends React.Component<Props> {
  render() {
    const { sections, ...otherProps } = this.props;
    return (
      <div {...otherProps}>
        {sections &&
          sections.map((section, index) => (
            <AccordionSection
              key={section.headerLink ? section.headerLink.url : index}
              {...section}
            />
          ))}
      </div>
    );
  }
}
