/* eslint-disable global-require */

if (typeof Promise === 'undefined') {
  require('promise/lib/rejection-tracking').enable();

  // maybe use core-js instead?
  window.Promise = require('promise/lib/es6-extensions.js');
}

require('whatwg-fetch');

// core-js ES.next polyfills
require('babel-polyfill');

// async polyfill
require('regenerator-runtime/runtime');

// URL polyfill for IE 11
require('url-polyfill');
