// @flow
import expandObject from './expandObject';
import isOld from './isOld';

/**
 * This function creates action creator that will fire action only if data is stale
 * Useful for when we want to cache data from the API based on one of the following criteria:
 * 1. Data is loading
 * 2. Data is loaded
 * 3. Data is old
 * 4. If data array is empty
 *
 * All these are optional.
 * If redux tree doesn't have some of the keys or if keys are null then they are ignored
 *
 * Example:
      const searchConsidering = persistedAction('colleges.considering', {
        types: [
          GET_COLLEGES_IAM_THINKING,
          GET_COLLEGES_IAM_THINKING_SUCCESS,
          GET_COLLEGES_IAM_THINKING_FAIL,
        ],
        promise: (client: Object) =>
          client.get(`${api.colleges}/colleges-im-thinking-about?limit=${FETCH_MAX_RESULTS}`),
      });
 */

type Config = {
  // timestamp tells us when data was last loaded
  timestampKey?: string,
  // redux key that tells us if data has been loaded
  loadedKey?: string,
  // redux key that tells us if data is currently loading.
  // We don't want to load twice at the same time.
  loadingKey?: string,
  // location of data for length comparison
  dataKey?: string,
  // how long to persist data
  retentionPeriod?: number,
};

export default function actionCreatorGenerator(
  // where in the redux tree are we looking at?
  stateModulePath: string,
  // action to dispatch when ready
  action: Object,
  // optional config
  {
    timestampKey = 'timestamp',
    loadedKey = 'loaded',
    loadingKey = 'loading',
    dataKey = 'results',
    retentionPeriod = 60000,
  }: Config = {}
) {
  if (process.env.NODE_ENV === 'development') {
    if (!action) {
      throw new Error(`You should provide with action for this to work`);
    }

    if (!stateModulePath) {
      throw new Error(`You should provide with root path of module's state tree.`);
    }
  }

  return (force?: boolean) => (dispatch: Function, getState: Function): Function | Promise<any> => {
    const state = getState();
    const root = expandObject(state, stateModulePath) || {};

    // get real values or act as if they are passing validation
    // that means we need to have at least one piece of data that will trigger reload
    const loading = loadingKey ? !!root[loadingKey] : false;
    const loaded = loadedKey ? !!root[loadedKey] : false;
    const timestamp = (timestampKey && root[timestampKey]) || Date.now();
    const data = dataKey ? root[dataKey] : Array(1);

    // there is data if it's loaded, not old, and array is not empty
    const thereIsData =
      loaded === true &&
      !isOld(timestamp, retentionPeriod) &&
      Array.isArray(data) &&
      data.length > 0;

    // this boolean indicated that it's possible to use cache
    // at least one condition should be true
    // of course, if these keys are not found then they are automatically false
    const couldSkipLoading = loading === true || thereIsData;

    // but this one tells us that we should definitely load
    const shouldNeverSkip = force === true;

    if (!shouldNeverSkip && couldSkipLoading) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`Data cached, skipping action ${expandObject(action, 'types.0')}`);
      }
      return Promise.resolve(state);
    }

    return dispatch(action);
  };
}
