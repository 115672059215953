// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { isGhostingParentUser, getParent } from 'selectors/auth';
import s from './styles.scss';
import { isGhostingParentOnlyEnabled } from '../../../selectors/banner';

type Props = {
  parent: Object,
  isGhostingParent: boolean,
  fullName: string,
  isGhostingParentOnlyEnabled: boolean,
};

@connect(
  (state) => ({
    isGhostingParent: isGhostingParentUser(state),
    parent: getParent(state),
    fullName: state.auth.fullName || '',
    isGhostingParentOnly: isGhostingParentOnlyEnabled(state),
  }),
  null
)
export default class ViewAsStudentBanner extends React.Component<Props> {
  static defaultProps = {
    fullName: '',
    parent: {
      fullName: '',
    },
    isGhostingParent: false,
  };

  render() {
    const { isGhostingParent, parent, fullName, isGhostingParentOnly } = this.props;
    let name = isGhostingParent ? parent.fullName : fullName;
    if (isGhostingParent && isGhostingParentOnly) {
      name = this.getGhostingParentDisplayName(parent);
    }

    return (
      <div className={s.banner}>
        You are viewing Naviance Student as <b>{name || fullName}</b>
      </div>
    );
  }

  getGhostingParentDisplayName = (parent) => {
    let ghostingParentName = `${parent.id}`;
    if (parent.fullName) {
      ghostingParentName = parent.fullName;
    } else if (parent.firstName && parent.lastName) {
      ghostingParentName = `${parent.firstName} ${parent.lastName}`;
    } else if (parent.firstName) {
      ghostingParentName = `${parent.firstName} (${parent.id})`;
    }
    return ghostingParentName;
  };
}
