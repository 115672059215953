// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import get from 'lodash/get';
import { getErrorMessage } from 'utils/api';
import type { College } from 'types/colleges';
import { RESET_COLLEGE_PROFILE } from 'modules/colleges/profiles';

const generateConstants = constantsGenerator('fc/colleges/hubs');
const [
  GET_HUBS_COLLEGE,
  GET_HUBS_COLLEGE_SUCCESS,
  GET_HUBS_COLLEGE_FAIL,
]: string[] = generateConstants('GET_HUBS_COLLEGE');

const [
  GET_SCID_BY_UUID,
  GET_SCID_BY_UUID_SUCCESS,
  GET_SCID_BY_UUID_FAIL,
]: string[] = generateConstants('GET_SCID_BY_UUID');

const [
  SEND_HUBS_EMAIL,
  SEND_HUBS_EMAIL_SUCCESS,
  SEND_HUBS_EMAIL_FAIL,
]: string[] = generateConstants('SEND_HUBS_EMAIL');

const [
  GET_COLLEGE_APPLICATION_STATISTICS,
  GET_COLLEGE_APPLICATION_STATISTICS_SUCCESS,
  GET_COLLEGE_APPLICATION_STATISTICS_FAIL,
]: string[] = generateConstants('GET_COLLEGE_APPLICATION_STATISTICS');

export type State = {
  college: ?College,
  applicationStatistics: ?Object,
  loading: boolean,
  loaded: boolean,
  error: ?string,
};

const initialState: State = {
  college: null,
  applicationStatistics: null,
  scid: null,
  loading: false,
  loaded: false,
  error: null,
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case RESET_COLLEGE_PROFILE:
      return {
        ...initialState,
      };
    case GET_HUBS_COLLEGE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_HUBS_COLLEGE_SUCCESS: {
      const { result } = action;
      return {
        ...state,
        college: result,
        loading: false,
        loaded: true,
        error: get(result, 'uuid')
          ? null
          : `No college found with Hobsons ID ${get(result, 'hobsonsId')}`,
      };
    }

    case GET_HUBS_COLLEGE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: getErrorMessage(action),
      };
    case GET_SCID_BY_UUID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SCID_BY_UUID_SUCCESS:
      return {
        ...state,
        scid: get(action, 'result.hobsonsId', 0),
        loading: false,
        loaded: true,
        error: null,
      };
    case GET_SCID_BY_UUID_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: getErrorMessage(action),
      };
    case GET_COLLEGE_APPLICATION_STATISTICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_COLLEGE_APPLICATION_STATISTICS_SUCCESS:
      return {
        ...state,
        applicationStatistics: get(action, 'result', null),
        loading: false,
        loaded: true,
        error: null,
      };
    case GET_COLLEGE_APPLICATION_STATISTICS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: getErrorMessage(action),
      };
    default:
      return state;
  }
}

export const fetchCollegeUuid = (hobsonsId: number | string) => {
  if (!hobsonsId) {
    throw new Error(`Parameter HobsonsId is required: ${hobsonsId}`);
  }
  return {
    types: [GET_HUBS_COLLEGE, GET_HUBS_COLLEGE_SUCCESS, GET_HUBS_COLLEGE_FAIL],
    promise: (client: Object) => client.get(`${api.colleges}/hid/${hobsonsId}`),
  };
};

export function fetchScidByCollegeUuid(uuid: string) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [GET_SCID_BY_UUID, GET_SCID_BY_UUID_SUCCESS, GET_SCID_BY_UUID_FAIL],
      promise: (client: Object) => client.get(`${api.colleges}/uuid/${uuid}`),
    });
}

export function fetchCollegeByScid(scid: number) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [GET_SCID_BY_UUID, GET_SCID_BY_UUID_SUCCESS, GET_SCID_BY_UUID_FAIL],
      promise: (client: Object) => client.get(`${api.colleges}/scid/${scid}`),
    });
}

export const sendEmail = (hobsonsId: number | string, payload: Object) => ({
  types: [SEND_HUBS_EMAIL, SEND_HUBS_EMAIL_SUCCESS, SEND_HUBS_EMAIL_FAIL],
  promise: (client: Object) =>
    client.post(`${api.collegesProfilesNext}/scid/${hobsonsId}/contact`, {
      data: payload,
    }),
});

export const fetchCollegeApplicationStatistics = (uuid: string) => ({
  types: [
    GET_COLLEGE_APPLICATION_STATISTICS,
    GET_COLLEGE_APPLICATION_STATISTICS_SUCCESS,
    GET_COLLEGE_APPLICATION_STATISTICS_FAIL,
  ],
  promise: (client: Object) => client.get(`${api.host}/application-statistics/uuid/${uuid}`),
});

export const fetchCollegeApplicationStatisticsByScid = (scid: number) => ({
  types: [
    GET_COLLEGE_APPLICATION_STATISTICS,
    GET_COLLEGE_APPLICATION_STATISTICS_SUCCESS,
    GET_COLLEGE_APPLICATION_STATISTICS_FAIL,
  ],
  promise: (client: Object) => client.get(`${api.host}/application-statistics/scid/${scid}`),
});
