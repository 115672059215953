import * as React from 'react';
// We do not use a dynamic import here for code splitting because the Careers2 component immediately loads another script
// which contains the Careers 2 micro-frontend. Doing so would just introduce an unnecessary network round trip for the user.
import Careers2 from 'containers/Careers2';

export default class Careers2Route extends React.Component {
  render() {
    return <Careers2 />;
  }
}
