// @flow

/**
 * Prepare map items structure for using in form controls
 * @example
 *   const results = ['1', '2'];
 *   const entities = {'1': {value: '1', 'label': 'My Item1'}, '2': {value: '2', 'label': 'My Item2'}}
 *   mapEntitiesToResults(results, entities);
 *   // => [{ value: '1', label: 'My Item1' },{ value: '2', label: 'My Item2' }];
 * @param {Array<string>} results - list of ID's
 * @param {Object} entities
 * @return {Array<Object>}
 */

export default function mapEntitiesToResults<T, K>(
  results: Array<K>,
  entities: { [K]: T }
): Array<T> {
  return results.map((m) => entities[m]);
}
