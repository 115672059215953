export default function getCollegesProfileGQLQuery(scid) {
  return {
    query: `
    query FindCollegeDetails($scid: String!) {
      findCollegeDetails(scid: $scid) {
        hubsProfile {
          custom_profiles {
            pop_areas_of_study
            career_aspirations
            fav_activities
            five_fav_things
            img
            imgDisplayUrl
            img_thumb_large
            type
            body
            headline
            logo
            logoDisplayUrl
            quote
            best_place_to_eat
            class
            department
            desc
            fav_event
            fav_hangout
            fav_tradition
            hometown
            major
            persons_name
            program_name
            travel
            add_facts
            product_id
          }
        }
        hubsWebLinks {
          premium_links {
            active
            url
            info
            topic
            category
            order
            begin_date
            end_date
            product_id
          }
        }
        hubsWebTour {
          campus_media {
            active
            date_from
            date_to
            product_id
            assets {
              title
              caption
              media_type
              order
              media_url
              mediaDisplayUrl
            }
          }
        }
        collegelogo
      }
    }
    `,
    variables: {
      scid: String(scid),
    },
  };
}
