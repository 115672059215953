// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getFeatureFlags, areFeatureFlagsLoaded } from 'selectors/featureFlags';
import type { FeatureFlags } from 'types/featureFlags';

type Props = {
  featureFlags: FeatureFlags,
  featureFlagsLoaded: boolean,
};

/**
 * Hoc that renders component only if it's allowed by a feature flag
 * @param featureFlag: feature flag needed to render.
 *                     In advanced scenarios, this can be a function called with props.
 * @param redirectUrl: Optional URL to redirect to when not allowed to render
 * @param flagBehavior: Optionally specify if the flag should 'forbid' instead of allow (the default)
 */
const withFeatureFlag = (
  featureFlag: string | ((props: Object) => string),
  redirectUrl?: string | ((props: Object) => string),
  flagBehavior?: string
) => (WrappedComponent: React.ComponentType<*>) =>
  connect((state) => ({
    featureFlags: getFeatureFlags(state),
    featureFlagsLoaded: areFeatureFlagsLoaded(state),
  }))(
    class AllowedContainer extends React.Component<Props> {
      allowed: boolean = false;

      UNSAFE_componentWillMount() {
        this.getAllowed();
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (
          this.props.featureFlags !== nextProps.featureFlags ||
          this.props.featureFlagsLoaded !== nextProps.featureFlagsLoaded
        ) {
          this.getAllowed(nextProps);
        }
      }

      getAllowed = (props = this.props) => {
        let featureFlagString = '';
        if (typeof featureFlag === 'string') {
          featureFlagString = featureFlag;
        } else if (typeof featureFlag === 'function') {
          featureFlagString = featureFlag(props);
        }

        this.allowed =
          props.featureFlagsLoaded &&
          (props.featureFlags[featureFlagString] === undefined ||
            props.featureFlags[featureFlagString] === true);

        if (flagBehavior === 'forbid') {
          this.allowed =
            props.featureFlagsLoaded && props.featureFlags[featureFlagString] === false;
        }
      };

      getRedirectUrl() {
        if (typeof redirectUrl === 'function') {
          return redirectUrl(this.props);
        }

        return redirectUrl;
      }

      render() {
        const { featureFlags, featureFlagsLoaded, ...other } = this.props;

        return this.allowed ? (
          <WrappedComponent {...other} />
        ) : (
          <Redirect to={redirectUrl || '/main'} />
        );
      }
    }
  );

export default withFeatureFlag;
