import * as React from 'react';
import cx from 'classnames';
import Button from 'components/Button';
import s from './styles.scss';

interface ButtonCfg {
  onClick: () => void;
  label: string;
}

/**
 * If string, then just output text
 * If null, don't render
 * If React element, render it
 * If Array of objects, render buttons with config being each object
 */
export interface Props {
  bar: React.ReactElement | string | null | ButtonCfg[];
  top?: boolean;
  bottom?: boolean;
  small?: boolean;
}

interface BarButtonProps {
  selected?: boolean;
  top: boolean;
  bottom: boolean;
  small?: boolean;
  children?: React.ReactNode;
}

const BarButton = ({ selected, top, bottom, small, ...rest }: BarButtonProps) => {
  let variation;

  if (bottom) {
    variation = 'default';
  } else if (top && small) {
    variation = 'link';
  } else {
    variation = selected ? 'navLinkSelected' : 'navLink';
  }

  return <Button variation={variation} {...rest} />;
};

BarButton.defaultProps = { selected: false, small: false };

const Toolbar = ({ bar, top, bottom, small }: Props) => {
  if (!bar) {
    return null;
  }

  const content = Array.isArray(bar)
    ? bar.map(({ label, ...cfg }) => (
        <BarButton key={label} top={top} bottom={bottom} small={small} {...cfg}>
          {label}
        </BarButton>
      ))
    : bar;

  const css = cx(s.bar, {
    [s.stretch]: top && typeof bar !== 'string',
    [s.bottom]: bottom,
    [s.top]: top,
    [s.small]: small,
  });

  return <div className={css}>{content}</div>;
};

Toolbar.defaultProps = {
  top: false,
  bottom: false,
  small: false,
};

export default Toolbar;
