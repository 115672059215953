import React from 'react';
import { NeonIcon } from '@ps-refarch-ux/neon';
import s from './styles.scss';

export default function StudentHelpCenterLink() {
  return (
    <a
      href="https://ps.powerschool-docs.com/naviance-student/"
      className={s.studentHelpCenterLink}
      title={'Student Help Center'}
      target="_blank"
      rel="nofollow external noopener noreferrer"
    >
      <NeonIcon dataIcon="circle-help" />
    </a>
  );
}
