// @flow
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import collectIds from 'utils/collectIds';
import normalizeItems from 'utils/normalizeItems';
import {
  getStudentScholarshipsEntities,
  getStudentScholarshipsAdhocEntities,
} from 'selectors/colleges';
import { GET_RESPONSES_SUCCESS, SAVE_RESPONSE_SUCCESS } from 'modules/surveys';
import { CreateScholarshipApplication } from 'types/scholarships';
import { notify } from 'modules/notification';

const generateConstants = constantsGenerator('fc/scholarships');

const [
  GET_STUDENT_SCHOLARSHIPS,
  GET_STUDENT_SCHOLARSHIPS_SUCCESS,
  GET_STUDENT_SCHOLARSHIPS_FAIL,
]: string[] = generateConstants('get-student-scholarships');
const [
  GET_STUDENT_SCHOLARSHIPS_ADHOC,
  GET_STUDENT_SCHOLARSHIPS_ADHOC_SUCCESS,
  GET_STUDENT_SCHOLARSHIPS_ADHOC_FAIL,
]: string[] = generateConstants('get-student-scholarships-adhoc');
const [
  UPDATE_ADHOC_SCHOLARSHIPS,
  UPDATE_ADHOC_SCHOLARSHIPS_SUCCESS,
  UPDATE_ADHOC_SCHOLARSHIPS_FAIL,
]: string[] = generateConstants('UPDATE_ADHOC_SCHOLARSHIPS');
const [
  UPDATE_SCHOLARSHIPS,
  UPDATE_SCHOLARSHIPS_SUCCESS,
  UPDATE_SCHOLARSHIPS_FAIL,
]: string[] = generateConstants('UPDATE_SCHOLARSHIPS');
const [
  ADD_STUDENT_SCHOLARSHIP,
  ADD_STUDENT_SCHOLARSHIP_SUCCESS,
  ADD_STUDENT_SCHOLARSHIP_FAIL,
]: string[] = generateConstants('add-student-scholarship');
const [
  ADD_MULTIPLE_STUDENT_SCHOLARSHIP,
  ADD_MULTIPLE_STUDENT_SCHOLARSHIP_SUCCESS,
  ADD_MULTIPLE_STUDENT_SCHOLARSHIP_FAIL,
]: string[] = generateConstants('add-multiple-student-scholarship');
const [
  GET_SCHOLARSHIPS,
  GET_SCHOLARSHIPS_SUCCESS,
  GET_SCHOLARSHIPS_FAIL,
]: string[] = generateConstants('get-scholarships');

const CHANGE_SCHOLARSHIP_VALUE = 'fc/scholarships/CHANGE_SCHOLARSHIP_VALUE';
const ADD_TEMPORARY_SCHOLARSHIP = 'fc/scholarships/ADD_TEMPORARY_SCHOLARSHIP';
const CLEAR_TEMPORARY_SCHOLARSHIPS = 'fc/scholarships/CLEAR_TEMPORARY_SCHOLARSHIPS';
const CHANGE_TEMPORARY_SCHOLARSHIP = 'fc/scholarships/CHANGE_TEMPORARY_SCHOLARSHIP';

type State = {
  studentScholarships: {
    results: Array<Object>,
    entities: Object,
    loading: boolean,
    loaded: boolean,
    invalidated: boolean,
  },
  studentScholarshipsAdhoc: {
    results: Array<Object>,
    entities: Object,
    loading: boolean,
    loaded: boolean,
  },
  scholarships: {
    results: Array<Object>,
    entities: Object,
    loading: boolean,
    loaded: boolean,
  },
  temporaryScholarships: [],
};

const initialState: State = {
  studentScholarships: {
    results: [],
    entities: {},
    loading: false,
    loaded: false,
    invalidated: false,
  },
  studentScholarshipsAdhoc: {
    results: [],
    entities: {},
    loading: false,
    loaded: false,
  },
  scholarships: {
    results: [],
    entities: {},
    loading: false,
    loaded: false,
  },
  temporaryScholarships: [],
};

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_STUDENT_SCHOLARSHIPS:
      return {
        ...state,
        studentScholarships: {
          ...state.studentScholarships,
          loading: true,
        },
      };
    case GET_STUDENT_SCHOLARSHIPS_SUCCESS: {
      return {
        ...state,
        studentScholarships: {
          ...state.studentScholarships,
          results: collectIds(action.result.data),
          entities: normalizeItems(action.result.data),
          loading: false,
          loaded: true,
          invalidated: false,
        },
      };
    }
    case GET_STUDENT_SCHOLARSHIPS_FAIL:
      return {
        ...state,
        studentScholarships: {
          ...state.studentScholarships,
          loading: false,
          loaded: false,
        },
      };
    case GET_STUDENT_SCHOLARSHIPS_ADHOC:
      return {
        ...state,
        studentScholarshipsAdhoc: {
          ...state.studentScholarshipsAdhoc,
          loading: true,
        },
      };
    case GET_STUDENT_SCHOLARSHIPS_ADHOC_SUCCESS:
      return {
        ...state,
        studentScholarshipsAdhoc: {
          results: collectIds(action.result.data),
          entities: normalizeItems(action.result.data),
          loading: false,
          loaded: true,
        },
      };

    case GET_STUDENT_SCHOLARSHIPS_ADHOC_FAIL:
      return {
        ...state,
        studentScholarshipsAdhoc: {
          ...state.studentScholarshipsAdhoc,
          loading: false,
          loaded: false,
        },
      };

    case ADD_MULTIPLE_STUDENT_SCHOLARSHIP_SUCCESS:
      return {
        ...state,
        studentScholarshipsAdhoc: {
          ...state.studentScholarshipsAdhoc,
          results: [...state.studentScholarshipsAdhoc.results, ...collectIds(action.result.data)],
          entities: [
            ...state.studentScholarshipsAdhoc.entities,
            ...normalizeItems(action.result.data),
          ],
        },
      };

    case GET_SCHOLARSHIPS:
      return {
        ...state,
        scholarships: {
          ...state.scholarships,
          loading: true,
        },
      };
    case GET_SCHOLARSHIPS_SUCCESS: {
      return {
        ...state,
        scholarships: {
          ...state.scholarships,
          results: collectIds(action.result.data),
          entities: normalizeItems(action.result.data),
          loading: false,
          loaded: true,
        },
      };
    }
    case GET_SCHOLARSHIPS_FAIL:
      return {
        ...state,
        scholarships: {
          ...state.scholarships,
          loading: false,
          loaded: false,
        },
      };
    case CHANGE_SCHOLARSHIP_VALUE: {
      const { id, name, value, isAdHoc } = action;

      if (isAdHoc) {
        return {
          ...state,
          studentScholarshipsAdhoc: {
            ...state.studentScholarshipsAdhoc,
            entities: {
              ...state.studentScholarshipsAdhoc.entities,
              [id]: {
                ...state.studentScholarshipsAdhoc.entities[id],
                [name]: value,
              },
            },
          },
        };
      }
      return {
        ...state,
        studentScholarships: {
          ...state.studentScholarships,
          entities: {
            ...state.studentScholarships.entities,
            [id]: {
              ...state.studentScholarships.entities[id],
              [name]: value,
            },
          },
        },
      };
    }
    case ADD_TEMPORARY_SCHOLARSHIP:
      return {
        ...state,
        temporaryScholarships: state.temporaryScholarships.concat(action.scholarship),
      };
    case CLEAR_TEMPORARY_SCHOLARSHIPS:
      return {
        ...state,
        temporaryScholarships: [],
      };
    case CHANGE_TEMPORARY_SCHOLARSHIP:
      return {
        ...state,
        temporaryScholarships: state.temporaryScholarships.map((item) =>
          item.studentScholarshipId === action.scholarship.studentScholarshipId
            ? action.scholarship
            : item
        ),
      };
    case UPDATE_SCHOLARSHIPS_SUCCESS:
      return {
        ...state,
        studentScholarships: {
          ...state.studentScholarships,
          invalidated: true,
        },
      };
    case SAVE_RESPONSE_SUCCESS:
    case GET_RESPONSES_SUCCESS:
      return {
        ...state,
        studentScholarships: {
          ...state.studentScholarships,
          invalidated: true,
        },
      };
    default:
      return state;
  }
}

export function changeScholarshipValue(id: string, name: string, value: any, isAdHoc: boolean) {
  return {
    type: CHANGE_SCHOLARSHIP_VALUE,
    id,
    name,
    value,
    isAdHoc,
  };
}

export function fetchStudentScholarships(force?: boolean) {
  return (dispatch: Function, getState: Function): Promise<*> => {
    const {
      studentScholarships,
      studentScholarships: { invalidated },
    } = getState().colleges.scholarships;

    if (studentScholarships.loaded && !force && !invalidated) {
      return Promise.resolve(studentScholarships);
    }

    return dispatch({
      types: [
        GET_STUDENT_SCHOLARSHIPS,
        GET_STUDENT_SCHOLARSHIPS_SUCCESS,
        GET_STUDENT_SCHOLARSHIPS_FAIL,
      ],
      promise: (client: Object) =>
        client.get(`${api.scholarships}/student-scholarships?limit=${FETCH_MAX_RESULTS_LARGE}`),
    });
  };
}

export function fetchStudentScholarshipsAdhoc(force?: boolean) {
  return (dispatch: Function, getState: Function): Promise<*> => {
    const { studentScholarshipsAdhoc } = getState().colleges.scholarships;

    if (studentScholarshipsAdhoc.loaded && !force) {
      return Promise.resolve(studentScholarshipsAdhoc);
    }

    return dispatch({
      types: [
        GET_STUDENT_SCHOLARSHIPS_ADHOC,
        GET_STUDENT_SCHOLARSHIPS_ADHOC_SUCCESS,
        GET_STUDENT_SCHOLARSHIPS_ADHOC_FAIL,
      ],
      promise: (client: Object) =>
        client.get(
          `${api.scholarships}/student-scholarships-adhoc?limit=${FETCH_MAX_RESULTS_LARGE}`
        ),
    });
  };
}

export function searchScholarships(filter?: string, force?: boolean) {
  return (dispatch: Function, getState: Function): Promise<*> => {
    if (getState().colleges.scholarships.scholarships.loaded && !force) {
      return Promise.resolve();
    }

    return dispatch({
      types: [GET_SCHOLARSHIPS, GET_SCHOLARSHIPS_SUCCESS, GET_SCHOLARSHIPS_FAIL],
      promise: (client: Object) =>
        client.get(`${api.scholarships}/search?limit=${FETCH_MAX_RESULTS_LARGE}`),
    });
  };
}

export function updateScholarshipEntities() {
  return (dispatch: Function, getState: Function): Promise<*> => {
    const state = getState();
    const scholarships = getStudentScholarshipsEntities(state);

    if (scholarships && Object.keys(scholarships).length) {
      return dispatch({
        types: [UPDATE_SCHOLARSHIPS, UPDATE_SCHOLARSHIPS_SUCCESS, UPDATE_SCHOLARSHIPS_FAIL],
        promise: (client: Object) =>
          client.put(`${api.scholarships}/student-scholarships`, {
            data: {
              studentScholarshipApplications: Object.values(scholarships),
            },
          }),
      });
    }

    return Promise.resolve();
  };
}

export function updateAdHocScholarshipEntities() {
  return (dispatch: Function, getState: Function): Promise<*> => {
    const state = getState();
    const adHocScholarships = getStudentScholarshipsAdhocEntities(state);

    if (adHocScholarships && Object.keys(adHocScholarships).length) {
      return dispatch({
        types: [
          UPDATE_ADHOC_SCHOLARSHIPS,
          UPDATE_ADHOC_SCHOLARSHIPS_SUCCESS,
          UPDATE_ADHOC_SCHOLARSHIPS_FAIL,
        ],
        promise: (client: Object) =>
          client.put(`${api.scholarships}/student-scholarships-adhoc`, {
            data: {
              adhocStudentScholarshipApplications: Object.values(adHocScholarships),
            },
          }),
      });
    }

    return Promise.resolve();
  };
}

export function updateScholarships() {
  return (dispatch: Function): Promise<*> =>
    Promise.all([
      dispatch(updateScholarshipEntities()),
      dispatch(updateAdHocScholarshipEntities()),
    ]);
}

export function addStudentScholarship(data: {
  scholarshipId?: number,
  name?: string,
  amount: number,
  awarded: boolean,
}) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        ADD_STUDENT_SCHOLARSHIP,
        ADD_STUDENT_SCHOLARSHIP_SUCCESS,
        ADD_STUDENT_SCHOLARSHIP_FAIL,
      ],
      promise: (client: Object) =>
        client.post(`${api.scholarships}/student-scholarship`, {
          data,
        }),
    });
}

export function addStudentScholarshipAdhoc(data: {
  name: string,
  amount: number,
  awarded: boolean,
}) {
  return (dispatch: Function): Promise<*> =>
    dispatch({
      types: [
        ADD_STUDENT_SCHOLARSHIP,
        ADD_STUDENT_SCHOLARSHIP_SUCCESS,
        ADD_STUDENT_SCHOLARSHIP_FAIL,
      ],
      promise: (client: Object) =>
        client.post(`${api.scholarships}/student-scholarships-adhoc`, {
          data,
        }),
    });
}

export const addMultipleAdhocScholarships = (applications: CreateScholarshipApplication[]) => ({
  types: [
    ADD_MULTIPLE_STUDENT_SCHOLARSHIP,
    ADD_MULTIPLE_STUDENT_SCHOLARSHIP_SUCCESS,
    ADD_MULTIPLE_STUDENT_SCHOLARSHIP_FAIL,
  ],
  promise: (client: Object) =>
    client.post(`${api.scholarships}/student-scholarships-multiple-adhoc`, {
      data: { adhocStudentScholarshipApplications: applications.filter(Boolean) },
    }),
});

export function addMultipleStudentScholarshipAdhoc(
  applications: CreateScholarshipApplication[],
  noRegularScholarship: Boolean
) {
  return (dispatch: Function): Promise<*> =>
    dispatch(addMultipleAdhocScholarships(applications)).then(() => {
      if (noRegularScholarship) {
        dispatch(
          notify({
            type: 'success',
            title: 'Success',
            content: `Scholarship applications have been added successfuly`,
          })
        );
      }
      return dispatch(fetchStudentScholarshipsAdhoc());
    });
}

export function addTemporaryScholarship(scholarship: Object) {
  return {
    type: ADD_TEMPORARY_SCHOLARSHIP,
    scholarship,
  };
}

export function clearTemporaryScholarships() {
  return {
    type: CLEAR_TEMPORARY_SCHOLARSHIPS,
  };
}

export function changeTemporaryScholarship(scholarship: Object) {
  return {
    type: CHANGE_TEMPORARY_SCHOLARSHIP,
    scholarship,
  };
}
