// @flow
export type ControlsItemsById = {
  [string]: { label: string },
};

/**
 * Converts raw items came from server to same structure
 * @example
 *   const items = [{ id: '1', name: 'My Item1' },{ id: '2', name: 'My Item2' }];
 *   normalizeControlsItems(items, 'id', 'name');
 *   // => {'1': {id: '1', 'label': 'My Item1'}, '2': {id: '2', 'label': 'My Item2'}}
 * @param {Array<Object>} rawItems - list of raw items
 * @param {string} idName - name of object field which represent ID
 * @param {string} labelName - name of object field which represent control label
 * @param {Function} formatItem - format function be called for every item
 * @example
 *   const items = [{ id: '1', name: 'My Item1' },{ id: '2', name: 'My Item2' }];
 *   normalizeControlsItems(items, 'id', 'name', item => ({ ...item, value: item.id })));
 *   // => {'1': {id: '1', 'label': 'My Item1', value: '1'}, '2': {id: '2', 'label': 'My Item2', value: '2'}}
 */
export default function normalizeControlsItems(
  rawItems: Array<Object>,
  idName: string = 'id',
  labelName: string = 'name',
  formatItem?: (Object) => Object
): ControlsItemsById {
  return rawItems.reduce((acc: ControlsItemsById, item: Object): ControlsItemsById => {
    const { [labelName]: name } = item;
    const id = item[idName];
    acc[id] = {
      ...(formatItem ? formatItem(item) : item),
      label: name,
    };

    return acc;
  }, {});
}
