import * as React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import ClickHOC, { variations, sizes } from 'components/ClickHOC';
import HashLink from 'components/HashLink';
import { isLinkExternal, isMailLink, isFileLink, isLinkSameDomain } from 'utils/link';
import session from 'config/session';
import cx from 'classnames';
import s from './styles.scss';
import Icon from 'components/Icon';
import DownloadErrorModal from 'components/DownloadErrorModal';

interface CustomProps {
  children?: React.ReactNode;
  className: string;
  disabled?: boolean;
  wrapLongWords?: boolean;
  proxyAuthorization?: boolean;
  // Pass true to render the link as HashLink component, which
  // allows smooth anchor navigation
  hashLink?: boolean;
  to?: string | object;
  // invoked once the link action (download or page load) has been invoked and completed
  onAuthorizedActionComplete?: (href: string) => void;

  // props used by NavLink
  replace?: boolean;
  innerRef?: () => void;
  activeClassName?: string;
  activeStyle?: object;
  exact?: boolean;
  strict?: boolean;
  isActive?: () => void;
  location?: object;
  openInCurrentTab?: boolean;
  openInNewTab?: boolean;
  downloadStarted?: () => void;
  downloadSuccess?: () => void;
  showLoadingEllipsis?: boolean;
}

type Props = CustomProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

interface Window {
  webkitURL: any;
  navigator: any;
  open: any;
  URL: any;
}

declare var window: Window;

export class Link extends React.Component<Props, {}> {
  static defaultProps: Props = {
    children: null,
    className: '',
    disabled: false,
    hashLink: false,
    wrapLongWords: false,
    openInCurrentTab: true,
    openInNewTab: false,
  };

  static variation = variations;

  state = {
    isLoading: false,
    errorOccured: false,
  };

  static size = sizes;

  openAuthorizedPageInNewTab = (href: string) => {
    // The window must be opened synchronously to avoid being blocked as a popup
    const tab = window.open('about:blank', 'newTab');

    fetch(href, {
      headers: {
        authorization: session.isValid() && session.jwt ? session.jwt : '',
      },
    })
      .then((response) => response.text())
      .then((body) => {
        tab.document.documentElement.innerHTML = body;
        if (this.props.onAuthorizedActionComplete) {
          this.props.onAuthorizedActionComplete(href);
        }
      });
  };

  downloadAuthorizedFile = (href: string) => {
    let fileName: string;
    if (typeof this.props.download === 'string') {
      fileName = this.props.download;
    } else {
      const splitPath = href.split('/');
      fileName = splitPath[splitPath.length - 1];
    }

    this.setState({ isLoading: true });
    fetch(href, {
      headers: {
        authorization: session.isValid() && session.jwt ? session.jwt : '',
      },
    })
      .then((response) => response.blob())
      .then((downloadedFile) => {
        if (downloadedFile.type !== 'application/json') {
          this.setState({ isLoading: false });
          const URL = window.URL || window.webkitURL;
          const downloadUrl = URL.createObjectURL(downloadedFile);

          // MS Edge requires its own method of saving blobs
          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(downloadedFile, fileName);
          } else {
            const a = document.createElement('a');
            a.style.setProperty('display', 'none');
            a.href = downloadUrl;
            a.download = fileName;
            if (document.body) {
              document.body.appendChild(a);
            }

            setTimeout(() => {
              a.click();
            }, 500);

            setTimeout(() => {
              if (document.body) {
                document.body.removeChild(a);
              }
            }, 1000);
          }

          setTimeout(() => {
            if (this.props.downloadSuccess) {
              this.props.downloadSuccess();
            }
            URL.revokeObjectURL(downloadUrl);
            if (this.props.onAuthorizedActionComplete) {
              this.props.onAuthorizedActionComplete(href);
            }
          }, 1000);
        } else {
          this.setState({ errorOccured: true });
        }
      });
  };

  handleClick = (event: React.SyntheticEvent) => {
    if (this.props.downloadStarted) {
      this.props.downloadStarted();
    }
    event.preventDefault();

    const target: HTMLAnchorElement = event.currentTarget as HTMLAnchorElement;
    if (this.props.download) {
      this.downloadAuthorizedFile(target.href);
    } else {
      this.openAuthorizedPageInNewTab(target.href);
    }
  };

  handleErrorModalDismiss = () => {
    this.setState({ errorOccured: false, isLoading: false });
  };

  render() {
    const {
      to,
      children,
      proxyAuthorization,
      hashLink,
      wrapLongWords,

      // props used by NavLink
      replace,
      innerRef,
      activeClassName,
      activeStyle,
      exact,
      strict,
      isActive,
      location,
      openInCurrentTab,
      openInNewTab,
      showLoadingEllipsis,
      ...rest
    } = this.props;
    const isMail = isMailLink(to);
    const isExternal = isLinkExternal(to);
    const isFile = isFileLink(to);
    const { isLoading, errorOccured } = this.state;

    const isAnchorElement = isExternal || isMail || isFile;

    delete rest.onAuthorizedActionComplete;

    const baseParams = {
      target: openInNewTab || (isExternal && !openInCurrentTab) ? '_blank' : undefined,
      [isAnchorElement ? 'href' : 'to']: to,
      onClick: proxyAuthorization ? this.handleClick : undefined,
      ...rest,
    };

    const showEllipsisDuringLoading = this.props.showLoadingEllipsis
      ? this.props.showLoadingEllipsis
      : false;

    // include NavLink props if this isn't an anchor element
    const params = isAnchorElement
      ? baseParams
      : {
          ...baseParams,
          replace,
          innerRef,
          activeClassName,
          activeStyle,
          exact,
          strict,
          isActive,
          location,
        };

    // for anchor tags if target="_blank", add rel='opener' to get session storage to carry over
    if (isLinkSameDomain(to) && params.target === '_blank') {
      params.rel = 'opener';
    }

    let ComponentTag: RouterLink | string | HashLink = RouterLink;
    if (isAnchorElement) {
      ComponentTag = 'a';
    } else if (hashLink) {
      ComponentTag = HashLink;
    }

    if (showEllipsisDuringLoading && isLoading) {
      return !errorOccured ? (
        <div className={s.iconWrapper}>
          <Icon icon="file-pdf" className={s.icon} />
          <span className={s.iconLabelWithEllipsis}>Processing Document</span>
        </div>
      ) : (
        <div>
          <ComponentTag
            {...params}
            className={cx(params.className, { [s.breakWord]: wrapLongWords })}
          >
            {children}
          </ComponentTag>
          <DownloadErrorModal onDismiss={this.handleErrorModalDismiss} />
        </div>
      );
    } else {
      return (
        <ComponentTag
          {...params}
          className={cx(params.className, { [s.breakWord]: wrapLongWords })}
        >
          {children}
        </ComponentTag>
      );
    }
  }
}

export default ClickHOC(Link);
