// @flow
import * as React from 'react';
import cx from 'classnames';
import Icon from 'components/Icon';
import Input from 'components/Input';
import s from './styles.scss';

type Props = {
  className?: string,
  iconClassName?: string,
  inputClassName?: string,
  icon: string,
  testId?: string,
};

/**
 * Render an icon next to an input
 */
export default class IconInput extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    iconClassName: '',
    inputClassName: '',
    icon: '',
    testId: '',
  };

  render() {
    const { className, iconClassName, inputClassName, icon, testId, ...inputProps } = this.props;

    return (
      <div className={cx(s.inputWrap, className)}>
        <Input
          data-test-id={testId}
          className={inputClassName}
          label={<Icon icon={icon} className={cx(s.icon, iconClassName)} />}
          labelClassName={s.label}
          {...inputProps}
        />
      </div>
    );
  }
}
