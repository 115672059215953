import * as React from 'react';
import Icon from 'components/Icon';
import Button from 'components/Button';
import cx from 'classnames';
import noop from 'utils/noop';
import IfFeatureAllowed from 'components/IfFeatureAllowed';
import { FeatureFlag } from '../../constants/featureFlags';
import s from './styles.scss';

/*
  Basic Usage:
  <Alert
    display={showNotification} ---> May be used with container component's state or redux state
    onClose={onCloseNotification} ---> Function that should toggle the prop above ^ back to false
    variation={Alert.variations.success} ---> Use static variations in component
    message="Some success message on the alert" ---> Success message to be used
  />
  *** DISCLAIMER ***
  This should NOT be used directly into other components unless absolutely necessary.
  There's a notify function in place to use it within the notification module (app/modules/notification.js)
  which invokes a PageTemplate existing Notification component (app/components/PageTemplate/Notification.js)
  Basic usage:
  import {notify} from 'modules/notification';
  notify({
    type: Alert.variations.success, --> User variations exported as static variables.
    title: 'Any string title',
    content: 'Either a content message or JSX to render',
  }); --> This is a `Notification` typed object (types/notification.js)
*/
// Since this is intended only for alert/error messaging, variations are just the basics.
export const variations = {
  danger: 'danger',
  success: 'success',
  info: 'info',
  warning: 'warning',
  custom: 'custom',
};

// Variations control both the color scheme of the alert and the icon used for the message.
const icons = {
  danger: 'close',
  success: 'check',
  info: 'info-circle',
  warning: 'alert',
  custom: 'eye', // default icon to eye if no custom icon is passed in
};

export interface Props {
  variation: 'danger' | 'success' | 'info' | 'warning' | 'custom';
  // Accepts any custom class to be made to the alert. May be used with 'custom' variation.
  alertClassName?: string;
  // Accepts any text for message. If the message is too long it'll be clipped
  message: string;
  // Title for alert
  title?: string;
  // Styles for the title:
  titleClassName?: string;
  // Styles for the message, applied to the message span container
  messageClassName?: string;
  // Accepts use of any custom icon. If the custom icon prop doesnt exist defaults to the variation corresponding icon
  customIcon?: string;
  // Prop to control if the alert is being shown or not.
  display: boolean;
  // Prop to control what to do on click of the close icon
  onClose: () => void;
  // Prop to control display of close button
  dismissable: boolean;
  // Fixes list
  fixes?: [string];
  // what icon to pass to the Icon component as a prop in the close button
  closeButtonIcon?: string;
  // whether to render "Close" in the close button
  showCloseButtonText?: boolean;
}

export interface State {
  showFixes: boolean;
}

export default class Alert extends React.PureComponent<Props, State> {
  static defaultProps = {
    variation: 'info',
    wrapperClassName: '',
    message: '',
    customIcon: '',
    display: false,
    onClose: noop,
    title: '',
    titleClassName: '',
    dismissable: true,
    fixes: [],
    closeButtonIcon: 'delete-2',
    showCloseButtonText: true,
  };

  static variations = variations;

  state = {
    showFixes: false,
  };

  showMessage = (message: string | { error: any; Error: any }) => {
    if (message instanceof Object && message.error) {
      return message.Error;
    }
    return message;
  };

  renderFixes = () => {
    const { showFixes } = this.state;
    const { fixes } = this.props;
    if (showFixes && fixes.length) {
      return (
        <div className={s.fixes}>
          <ul className={s.fixes_list}>
            {fixes.map((fix, i) => (
              <li key={i}>{fix}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  handleShowFixes = () => {
    const { showFixes } = this.state;
    this.setState({
      showFixes: !showFixes,
    });
  };

  renderShowFixesButton = () => {
    const { showFixes } = this.state;
    const { fixes } = this.props;
    const text = `${showFixes ? 'Hide' : 'Show'} Fixes`;
    const icon = `${showFixes ? 'chevron-up' : 'chevron-down'}`;
    if (fixes.length) {
      return (
        <Button
          variation="noChrome"
          onClick={this.handleShowFixes}
          aria-label="Show Fixes"
          className={s.showFixesButton}
        >
          <span>{text}</span>
          <Icon icon={icon} />
        </Button>
      );
    }
  };

  renderCloseButton = () => {
    const { onClose, dismissable, closeButtonIcon, showCloseButtonText } = this.props;
    if (dismissable) {
      return (
        <Button
          className={s.closeButton}
          variation="noChrome"
          onClick={onClose}
          aria-label="Close Notification"
        >
          <Icon icon={closeButtonIcon} />
          {showCloseButtonText && <span>Close</span>}
        </Button>
      );
    }
  };

  renderMessage = () => {
    const { message, messageClassName } = this.props;
    const msg = this.showMessage(message);
    const msgProps = {
      className: messageClassName,
    };
    if (typeof msg === 'string') {
      return (
        <span
          {...msgProps}
          dangerouslySetInnerHTML={{
            __html: msg,
          }}
        />
      );
    }
    return <span {...msgProps}>{msg}</span>;
  };

  render() {
    const {
      variation,
      alertClassName,
      message,
      customIcon,
      display,
      title,
      titleClassName,
    } = this.props;
    if (!display) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={cx(s.component, [s[variation]], alertClassName, s.alert)}>
          <div className={s.content}>
            <IfFeatureAllowed target={FeatureFlag.MatchAndFerpaAlert}>
              <Icon className={s.iconLight} icon={customIcon || icons[variation]} />
            </IfFeatureAllowed>
            <div>
              <IfFeatureAllowed unless={FeatureFlag.MatchAndFerpaAlert}>
                <Icon className={s.icon} icon={customIcon || icons[variation]} />
              </IfFeatureAllowed>
            </div>
            <div>
              {title && (
                <div className={cx(s.title, titleClassName)}>
                  <strong>{title}</strong>
                </div>
              )}
              {this.renderMessage()}
            </div>
          </div>
          <div>
            {this.renderShowFixesButton()}
            {this.renderCloseButton()}
          </div>
        </div>
        {this.renderFixes()}
      </React.Fragment>
    );
  }
}
