// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export const ignoreTranslationPages: string[] = [
  '/colleges/scholarships/national',
  '/careers/career-key',
  '/careers2/assessments/career-key/questions',
];

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export const generalMessagePages: string[] = [
  '/about-me/strengths-explorer',
  '/colleges/scholarships/national',
  '/careers/career-key',
  '/careers2/assessments/career-key/questions',
];
