// @flow

/**
 * Memory storage object as a substitute to localStorage or sessionStorage
 * Uses WebStorage API
 */
const storage: Object = Object.create(null);

Object.defineProperties(
  storage,
  ({
    length: {
      enumerable: false,
      configurable: false,
      get: () => Object.keys(storage).length,
    },

    getItem: {
      value: (key: string) => storage[key] || null,
      enumerable: false,
      writable: false,
    },

    setItem: {
      value: (key: string, value: any) => (storage[key] = value),
      enumerable: false,
      writable: false,
    },

    removeItem: {
      value: (key: string) => delete storage[key],
      enumerable: false,
      writable: false,
    },

    key: {
      value: (idx: number): ?string => {
        const keys = Object.keys(storage);
        return idx >= 0 && idx < keys.length ? keys[idx] : null;
      },
      enumerable: false,
      writable: false,
    },

    clear: {
      value: () => Object.keys(storage).forEach((key) => delete storage[key]),
      enumerable: false,
      writable: false,
    },
  }: Object)
);

export default storage;
