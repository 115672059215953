// @flow
import { createSelector } from 'reselect';
import { arePermissionsLoaded, getPermissions } from './permissions';

export const areStudentsLoaded = (state: Object): boolean => state.parent.loaded;

export const getStudents = (state: Object): boolean => state.parent.students;

export const getStudentsForCombo = createSelector([getStudents], (students) =>
  students.map((s) => ({ label: `${s.firstName} ${s.lastName}`, value: s.id }))
);

export const getStudentsForAddressList = createSelector([getStudents], (students) =>
  students.map((s) => ({
    label: `Use address of ${s.firstName} ${s.lastName}`,
    value: String(s.id),
    address: s.studentAddress,
  }))
);

export const getParentEditPermissions = createSelector(
  [getPermissions, arePermissionsLoaded],
  (permissions, permissionsLoaded) =>
    (permissionsLoaded && permissions.resources['parent-edit']) || []
);
